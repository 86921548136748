import React, { useEffect, useMemo, useRef } from 'react';
import { compact, isNil, omitBy, uniq } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { EDateFormat, OptionType } from '@/types/global.types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ColumnSelect } from '@/components/ColumnSelect';
import { InputGroupFields } from '@/styles/FormStyles';
import { ColumnInput } from '@/components/ColumnInput';
import { ToggleSwitch } from '@/components/ToggleSwitch';
import { useEffectOnce } from '@/hooks/useEffectOnce';
import { RequiredNotNull } from '@/types/helper.types';
import { formatDate, isValidDate } from '@/utils/time';
import dayjs from 'dayjs';
import { EquipmentType } from '@/dashboard/constants';
import { useAsyncEffect } from '@/hooks/useAsyncEffect';
import { FullSessionType } from '@/session/constants';
import * as utils from '../utils';

import TraineeSelect, { TraineeSelectHandle } from './TraineeSelect';
import { Button, Cancel, Wrapper as ButtonWrapper } from '../../ui/Button';
import { CourseType, LessonPlanType, LessonPlanVariationType } from '../constants';
import getEquipmentName from '../../dashboard/utils';
import { schedule as scheduleRoute, sessionOverview, viewSession } from '../../utils/routes';

type SessionFormProps = {
  courses: CourseType[];
  equipment: EquipmentType[];
  isLoading: boolean;
  currentSession?: Partial<FullSessionType>;
  onCancel: (event: React.FormEvent) => void;
  onSubmit: (data: RequiredNotNull<SessionFormValues>) => void;
};

/**
 * Helper function to retrieve the name of a resource.
 */
const getName = (obj: {name: string}): string => obj.name;
const getOptionValue = (lessonPlan: LessonPlanType) => {
  if (lessonPlan.variations) {
    return `lesson-${lessonPlan.id}`;
  }
  return `variation-${lessonPlan.id}`;
};

const getButtonLabel = ({ isEdit }:{isEdit?:boolean}) => {
  if (isEdit) {
    return {
      submitting: 'EDITING...',
      default: 'EDIT',
    };
  }
  return {
    submitting: 'SAVING...',
    default: 'SAVE',
  };
};

export type SessionFormValues = {
    course: number | null,
    lessonPlan: string | null,
    pilotFlying: OptionType | null,
    pilotMonitoring: OptionType | null,
    equipment: string | number | null,
    scheduledDate: string | null,
    scheduledTime: string | null,
    isCrossTrainingSession: boolean,
    isCrossTrainingSessionDisabled: boolean,
    withStartAutomatically?: boolean,
}

const getLessonPlans = (selectedCourse?: CourseType) => {
  const lessonPlans:Array<LessonPlanType | LessonPlanVariationType> = [];
  if (selectedCourse) {
    selectedCourse.lessonPlans.forEach(lessonPlan => {
      lessonPlans.push(lessonPlan);
      lessonPlan.variations.forEach(variation => {
        lessonPlans.push(variation);
      });
    });
  }
  return lessonPlans;
};

const updatePilotStore = async (pilots: (string | undefined)[]) => {
  const trainees = compact(uniq(pilots));
  if (trainees.length > 0) {
    const names = await utils.getTrainees();
    const traineesToAdd = trainees.filter(trainee => !names.includes(trainee));
    await Promise.all(traineesToAdd.map(trainee => utils.addTrainee(trainee)));
  }
};

const getPreviousDefaultValue = ({
  currentSession,
  courses,
}: Pick<SessionFormProps, 'courses' > & {currentSession:Partial<FullSessionType>}) => {
  const { startedAt, endedAt } = currentSession;
  if (!isNil(startedAt) || !isNil(endedAt)) {
    return {};
  }
  const predefinedCourse = courses.find(c => c.name === currentSession.courseName);
  const defaultScheduledDate = formatDate(currentSession.scheduledFor, EDateFormat.Date);
  const defaultScheduledTime = formatDate(currentSession.scheduledFor, EDateFormat.Time);

  const trainees = currentSession?.trainees ? {
    pilotFlying: {
      label: currentSession.trainees.pilotFlying.name,
      value: currentSession.trainees.pilotFlying.name,
    },
    pilotMonitoring: {
      label: currentSession.trainees.pilotMonitoring.name,
      value: currentSession.trainees.pilotMonitoring.name,
    },
  } : {};

  const crossTrain = currentSession.plannedRuns?.length! > 1 ? {
    isCrossTrainingSession: true,
    isCrossTrainingSessionDisabled: false,
  } : {
    isCrossTrainingSession: false,
    isCrossTrainingSessionDisabled: false,
  };

  return {
    equipment: currentSession?.equipment?.id || null,
    course: predefinedCourse?.id,
    lessonPlan: currentSession?.lessonPlan ? `lesson-${currentSession.lessonPlan.id}` : undefined,
    scheduledDate: defaultScheduledDate,
    scheduledTime: defaultScheduledTime,
    withStartAutomatically: true,
    ...trainees,
    ...crossTrain,
  };
};

const getDefaultValues = ({
  courses, equipment, currentSession, defaultUrlParams,
} : Pick<SessionFormProps, 'courses' | 'equipment' | 'currentSession'> & {
    defaultUrlParams:Partial<SessionFormValues>
}) => {
  if (currentSession) {
    return getPreviousDefaultValue({
      courses, currentSession,
    });
  }

  const defaultLessonPlan = courses?.at(0)?.lessonPlans?.at(0);
  const defaultScheduledDate = formatDate(new Date(), EDateFormat.Date);
  const defaultScheduledTime = formatDate(dayjs().add(1, 'hour').minute(0).second(0)
    .toDate(), EDateFormat.Time);

  return {
    course: courses?.at(0)?.id || null,
    lessonPlan: defaultLessonPlan ? getOptionValue(defaultLessonPlan) : null,
    pilotFlying: {
      value: 'Pilot 1',
      label: 'Pilot 1',
    },
    pilotMonitoring: {
      value: 'Pilot 2',
      label: 'Pilot 2',
    },
    equipment: equipment?.at(0)?.id || null,
    scheduledDate: defaultScheduledDate,
    scheduledTime: defaultScheduledTime,
    isCrossTrainingSession: true,
    isCrossTrainingSessionDisabled: false,
    withStartAutomatically: true,
    ...defaultUrlParams,
  };
};

const getDefaultValuesFromUrl = (queryParams:URLSearchParams): Partial<SessionFormValues> => {
  const pilotFlyingName = queryParams.get('pilotFlying');
  const pilotMonitoringName = queryParams.get('pilotMonitoring');
  const course = queryParams.get('courseId');
  const equipment = queryParams.get('equipmentId');
  const lessonPlan = queryParams.get('lessonPlanId');
  const isCrossTrainingSession = queryParams.get('isCrossTrainingSession');
  const withStartAutomatically = queryParams.get('withStartAutomatically');

  return omitBy({
    course: course ? Number(course) : null,
    lessonPlan: lessonPlan ? `lesson-${lessonPlan}` : null,
    pilotFlying: pilotFlyingName ? {
      value: pilotFlyingName,
      label: pilotFlyingName,
    } : null,
    pilotMonitoring: pilotMonitoringName ? {
      value: pilotMonitoringName,
      label: pilotMonitoringName,
    } : null,
    equipment: equipment ? Number(equipment) : null,
    scheduledDate: queryParams.get('scheduledDate'),
    scheduledTime: queryParams.get('scheduledTime'),
    withStartAutomatically: withStartAutomatically ? Boolean(withStartAutomatically === 'true') : null,
    isCrossTrainingSession: isCrossTrainingSession ? Boolean(isCrossTrainingSession === 'true') : null,
    isCrossTrainingSessionDisabled: pilotFlyingName || pilotMonitoringName ? false : null,
  }, isNil);
};

const getSessionFormSchema = (
  courses: CourseType[],
  equipment: EquipmentType[],
) => yup.object<SessionFormValues>().shape({
  course: yup.number().test({
    name: 'isValidCourse',
    message: 'Please choose a valid Course.',
    test: value => courses.some(option => option.id === value),
  }).required().label('Course'),
  lessonPlan: yup.string().test({
    message: 'Please choose a valid Lesson plan.',
    test: (value, { parent }) => {
      if (!value) return false;
      const lessonId = Number(value.replace(/^\D+/g, ''));
      const lessonPlans = getLessonPlans(courses.find(c => c.id === Number(parent.course)));
      return lessonPlans.some(option => option.id === lessonId);
    },
  }).required().label('Lesson plan'),
  equipment: yup.number().test({
    name: 'isValidEquipment',
    message: 'Please choose a valid Simulator.',
    test: value => equipment.some(option => option.id === value),
  }).required().label('Simulator'),
  pilotFlying: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().trim().required(),
  }).required().label('Pilot Flying'),
  pilotMonitoring: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().trim().required(),
  }).required().label('Pilot Monitoring'),
  scheduledDate: yup.string().required()
    .test({
      name: 'isValidDate',
      message: 'Please input a valid Scheduled Date.',
      test: val => isValidDate(val, EDateFormat.Date),
    })
    .label('Scheduled Date'),
  scheduledTime: yup.string().required()
    .test({
      name: 'isValidTime',
      message: 'Please input a valid Scheduled Time.',
      test: val => isValidDate(val, EDateFormat.Time),
    })
    .label('Scheduled Time'),
  isCrossTrainingSession: yup.boolean().required(),
  isCrossTrainingSessionDisabled: yup.boolean().required(),
  withStartAutomatically: yup.boolean().optional(),
});

export const SessionForm = ({
  courses,
  equipment,
  onSubmit,
  onCancel,
  currentSession,
  isLoading,
}: SessionFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sessionSchema = useMemo(() => getSessionFormSchema(courses, equipment), [courses, equipment]);

  // Initialize form data.
  const { handleSubmit, control, watch, getValues, setValue } = useForm<SessionFormValues>({
    defaultValues: getDefaultValues({
      courses,
      equipment,
      currentSession,
      defaultUrlParams: getDefaultValuesFromUrl(new URLSearchParams(location.search)),
    }),
    resolver: yupResolver<SessionFormValues>(sessionSchema),
  });
  const isEdit = !!currentSession?.id;
  const crossTrainLabel = watch('course')
    ? 'Create Cross-Training Session'
    : 'Create Single Training Session';
  const monitoringPilotRef = useRef<TraineeSelectHandle>(null);
  const flyingPilotRef = useRef<TraineeSelectHandle>(null);

  const buttonLabel = getButtonLabel({
    isEdit,
  });
  // Handler for selecting a course.
  const selectedCourse = courses.find(c => c.id === Number(watch('course')));

  // Handler for selecting a lesson plan.
  const lessonPlans: Array<LessonPlanType | LessonPlanVariationType> = getLessonPlans(selectedCourse);

  useEffect(() => {
    if (currentSession) {
      const { startedAt, endedAt } = currentSession;
      if (!isNil(startedAt) || !isNil(endedAt)) {
        navigate(scheduleRoute());
      }
    }
  }, [currentSession, courses]);

  useEffectOnce(() => {
    if (!isEdit && location.search) {
      const currentUrl = `${window.location.protocol}//${window.location.host}${location.pathname}`;
      window.history.replaceState({}, '', currentUrl);
    }
  });

  useAsyncEffect(async () => {
    const formData = getValues();
    await updatePilotStore([formData.pilotMonitoring?.value, formData.pilotFlying?.value]);

    monitoringPilotRef.current && monitoringPilotRef.current.updateTrainees();
    flyingPilotRef.current && flyingPilotRef.current.updateTrainees();
  }, []);

  const onUpdatePilotMonitoring = (onChange:any) => (event:any) => {
    onChange(event);
    if (event !== null) {
      setValue('isCrossTrainingSessionDisabled', false);
      setValue('isCrossTrainingSession', true);
    } else {
      setValue('isCrossTrainingSessionDisabled', true);
      setValue('isCrossTrainingSession', false);
    }
  };

  const onUpdateCourse = (onChange:any) => (event:any) => {
    onChange(event);

    const firstLessonPlan = getLessonPlans(
      courses.find(c => c.id === Number(event.target.value)),
    ).at(0);
    setValue('lessonPlan', firstLessonPlan
      ? getOptionValue(firstLessonPlan as LessonPlanType)
      : null);
  };

  return (
    <form
      onSubmit={handleSubmit(data => onSubmit(data as RequiredNotNull<SessionFormValues>))}
    >
      <Controller
        control={control}
        name="course"
        render={({ field: { onChange, value, name }, fieldState }) => (
          <ColumnSelect
            name={name}
            label="Course"
            options={courses}
            getOptionLabel={getName}
            value={value}
            onChange={onUpdateCourse(onChange)}
            disabled={isLoading}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="lessonPlan"
        render={({ field: { onChange, value, name }, fieldState }) => (
          <ColumnSelect
            label="Lesson Plan"
            options={lessonPlans}
            getOptionLabel={getName}
            getOptionValue={getOptionValue}
            value={value}
            onChange={onChange}
            disabled={isLoading}
            name={name}
            error={fieldState.error?.message}
          />
        )}
      />
      <InputGroupFields>
        <Controller
          control={control}
          name="pilotFlying"
          render={({ field: { onChange, value, name }, fieldState }) => (
            <TraineeSelect
              ref={flyingPilotRef}
              label="Pilot Flying"
              placeholder="Input name"
              value={value}
              onChange={onChange}
              isDisabled={isLoading}
              name={name}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="pilotMonitoring"
          render={({ field: { onChange, value, name }, fieldState }) => (
            <TraineeSelect
              ref={monitoringPilotRef}
              label="Pilot Monitoring"
              placeholder="Input name"
              value={value}
              onChange={onUpdatePilotMonitoring(onChange)}
              isDisabled={isLoading}
              isClearable
              name={name}
              error={fieldState.error?.message}
            />
          )}
        />
      </InputGroupFields>
      <Controller
        control={control}
        name="equipment"
        render={({ field: { onChange, value, name }, fieldState }) => (
          <ColumnSelect
            onChange={onChange}
            value={value}
            getOptionLabel={getEquipmentName}
            disabled={isLoading}
            label="Simulator"
            options={equipment}
            name={name}
            error={fieldState.error?.message}
          />
        )}
      />
      <InputGroupFields>
        <Controller
          control={control}
          name="scheduledDate"
          render={({ field: { onChange, value, name }, fieldState }) => (
            <ColumnInput
              type="date"
              label="Scheduled Date"
              value={value}
              disabled={isLoading}
              onChange={onChange}
              name={name}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="scheduledTime"
          render={({ field: { onChange, value, name }, fieldState }) => (
            <ColumnInput
              type="time"
              label="Scheduled Time"
              value={value}
              disabled={isLoading}
              onChange={onChange}
              name={name}
              error={fieldState.error?.message}
            />
          )}
        />
      </InputGroupFields>
      <Controller
        control={control}
        name="isCrossTrainingSession"
        render={({ field: { onChange, value, name } }) => (
          <ToggleSwitch
            labelLeft={crossTrainLabel}
            disabled={watch('isCrossTrainingSessionDisabled') || isLoading}
            checked={value}
            onChange={onChange}
            name={name}
          />
        )}
      />
      <Controller
        control={control}
        name="withStartAutomatically"
        render={({ field: { onChange, value, name } }) => (
          <ToggleSwitch
            labelLeft="Automatically start the session"
            disabled={isLoading}
            checked={value}
            onChange={onChange}
            name={name}
          />
        )}
      />
      <ButtonWrapper>
        <Cancel onClick={onCancel}>CANCEL</Cancel>
        <Button type="submit" disabled={isLoading}>{isLoading ? buttonLabel.submitting : buttonLabel.default}</Button>
      </ButtonWrapper>
    </form>
  );
};

export default SessionForm;
