import React from 'react';

const UnicodeWasteBasket = ({ fill }: { fill: string }) => (
  <svg
    viewBox="24 151 140 219"
    height="100%"
    width="100%"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="genericDefs" />
    <g>
      <g>
        <path
          d="M163.125 192.0938 Q163.125 198.2812 158.2031 202.3594
            Q157.2188 203.2031 156.2344 204.0469 L156.2344 338.625
            Q156.2344 353.3906 149.4844 357.8906 Q131.2031 370.2656 93.6562 370.2656
            Q56.25 370.2656 37.9688 357.8906 Q31.2188 353.3906 31.2188 338.625
            L31.2188 204.0469 Q30.2344 203.2031 29.25 202.3594
            Q24.3281 198.2812 24.3281 192.0938 L24.3281 174.9375
            Q24.3281 166.2188 31.5 162.2812 Q50.9062 151.7344 93.6562 151.7344
            Q136.5469 151.7344 155.9531 162.2812 Q163.125 166.2188 163.125 174.9375
            L163.125 192.0938 ZM156.2344 173.8125 Q156.2344 166.3594 128.1094 161.8594
            Q108 158.625 93.6562 158.625 Q79.3125 158.625 59.2031 161.8594
            Q31.2188 166.3594 31.2188 173.9531 Q31.2188 181.6875 59.4844 185.625
            Q77.7656 188.1562 93.7969 188.1562 Q110.1094 188.1562 128.1094 185.625
            Q156.2344 181.6875 156.2344 173.8125 ZM156.2344 187.1719 L156.2344 182.9531
            Q132.6094 195.0469 93.6562 195.0469 Q55.125 195.0469 31.2188 182.9531
            L31.2188 187.1719 Q31.2188 210.6562 93.6562 210.6562
            Q156.2344 210.6562 156.2344 187.1719 ZM149.2031 338.2031 L149.2031 207.7031
            Q144.4219 209.6719 138.7969 211.5 L138.7969 342.8438 L131.9062 344.6719
            L131.9062 213.3281 Q123.4688 214.875 111.0938 216.2812 L111.0938 347.7656
            L104.0625 347.7656 L104.0625 216.2812 Q95.625 216.7031 93.6562 216.7031
            Q91.8281 216.7031 83.25 216.2812 L83.25 347.7656 L76.3594 347.7656
            L76.3594 216.2812 Q63.9844 214.875 55.5469 213.3281 L55.5469 344.6719
            L48.6562 342.8438 L48.6562 211.5 Q43.0312 209.6719 38.25 207.7031
            L38.25 338.2031 Q38.25 350.7188 46.2656 354.7969 Q63.2812 363.375 93.6562 363.375
            Q124.1719 363.375 141.1875 354.7969 Q149.2031 350.7188 149.2031 338.2031 Z"
          stroke="none"
        />
      </g>
    </g>
  </svg>
);

export default UnicodeWasteBasket;
