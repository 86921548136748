import * as React from 'react';
import styled from 'styled-components';
import { getColor, GradeLevelType, GradingSchemeType } from '../constants';
import { useGradingScheme } from '../hooks';
import { ConvertToTransient } from '../../types/helper.types';

type Props = {
  gradeValue: GradeLevelType | null,
  type: string,
  isLiveGrade: boolean | null;
  isSelected?: boolean,
  onClick: () => void,
};

const GradeButton = ({ gradeValue, type, isLiveGrade, isSelected = false, onClick }: Props) => {
  const gradingScheme = useGradingScheme();

  return (
    <Wrapper
      $gradeValue={gradeValue}
      $gradingScheme={gradingScheme}
      $isLiveGrade={isLiveGrade}
      $isSelected={isSelected}
      type={type}
      onClick={onClick}
      data-grade-value={gradeValue}
    >
      {gradeValue}
    </Wrapper>
  );
};

export default GradeButton;

const getOpacity = ({ $isLiveGrade, $isSelected }: WrapperProps): string => {
  switch (true) {
    case $isSelected:
      return '1';

    case $isLiveGrade:
      return '0.38';

    default:
      return '1';
  }
};

type WrapperProps = ConvertToTransient<Props, 'gradeValue'| 'isLiveGrade' | 'isSelected'> & {
    $gradingScheme: GradingSchemeType
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${props => getColor(props.$gradingScheme, props.$gradeValue)};
  border-radius: 50%;
  line-height: ${props => (props.$isLiveGrade ? '51px' : '41px')};
  color: black;
  cursor: pointer;
  font-weight: ${props => props.theme.fontWeightBold};
  opacity: ${props => getOpacity(props)};
  text-align: center;
  display: table-cell;
  height: ${props => (props.$isLiveGrade ? '51px' : '41px')};
  width: ${props => (props.$isLiveGrade ? '51px' : '41px')};
  vertical-align: middle;
`;
