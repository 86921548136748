import { List, Record, RecordOf } from 'immutable';
import { FrameType } from './constants';

interface FramesStore {
  list: List<FrameType>,
  total: number,
  isFetchingFrames: boolean,
}

export type ImmutableFramesStore = RecordOf<FramesStore>;

export const createFramesStore = Record<FramesStore>({
  list: List(),
  total: 0,
  isFetchingFrames: true,
});

const defaultState: ImmutableFramesStore = createFramesStore();

export default defaultState;
