import React from 'react';
import styled from 'styled-components';
import { Label } from '../../summary/components/GradeContext/GradeContext';
import { gradeLevel5, lightestGrey, mediumGrey } from '../../theme/palette';
import { RecommendationsType } from '../store';

type Props = {
  recommendations: RecommendationsType,
  onSubmitInstructorFeedback: Function,
};

const Recommendations = ({ recommendations, onSubmitInstructorFeedback }: Props) => {
  const { recommendations: list, instructorApproved } = recommendations;

  return (
    <>
      <Label>TRAINING RECOMMENDATIONS</Label>
      <TrainingRecommendations>
        {list.map(recommendation => (
          <Recommendation key={recommendation}>{recommendation}</Recommendation>
        ))}
      </TrainingRecommendations>
      <InstructorFeedback>
        Do you agree with our recommendations?
        <YesButton filled={instructorApproved} onClick={() => onSubmitInstructorFeedback(true)}>YES</YesButton>
        <NoButton filled={instructorApproved} onClick={() => onSubmitInstructorFeedback(false)}>NO</NoButton>
      </InstructorFeedback>
    </>
  );
};

export default Recommendations;

const TrainingRecommendations = styled.div`
  max-width: 100%;
  background-color: ${mediumGrey.string()};
  border: 2px solid ${gradeLevel5.string()};
  border-radius: 5px;
  padding: 11px 12px 16px 12px;
  font-size: 16px;
`;

const Recommendation = styled.div`
  margin-top: 5px;
`;

const InstructorFeedback = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${lightestGrey.string()};
  font-size: 16px;
  margin-top: 10px;
`;

const FeedbackButton = styled.button<{filled: boolean}>`
  border: 2px solid ${lightestGrey.string()};
  border-radius: 5px;
  background-color: transparent;
  color: ${lightestGrey.string()};
  height: 28px;
  width: 50px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
`;

const YesButton = styled(FeedbackButton)`
  ${props => props.filled === true && `
    background-color: white;
    border: 2px solid white;
    color: black;
  `}
`;

const NoButton = styled(FeedbackButton)`
  ${props => props.filled === false && `
    background-color: white;
    border: 2px solid white;
    color: black;
  `}
`;
