/**
 * Plotly utility functions.
 *
 * Plotly's javascript reference:
 *  https://plot.ly/javascript/reference
 *
 * Reference for stacked sub-plots:
 *  https://plot.ly/javascript/subplots/#stacked-subplots-with-a-shared-x-axis
 */
import getAirPlotOptions from './plotOptions/air';
import getAutorotationPlotOptions from './plotOptions/autorotation';
import getAutorotationLandingPlotOptions from './plotOptions/autorotationLanding';
import getRejectedTakeoffPlotOptions from './plotOptions/rejectedTakeoff';
import getRejectedLandingPlotOptions from './plotOptions/rejectedLanding';
import getSteepTurnPlotOptions from './plotOptions/steepTurn';
import getUnusualAttitudeRecoveryPlotOptions from './plotOptions/unusualAttitudeRecovery';
import getGoAroundPlotOptions from './plotOptions/goAround';
import getMissedApproachPlotOptions from './plotOptions/missedApproach';
import getTaxiPlotOptions from './plotOptions/taxi';
import getLowAirspeedPlotOptions from './plotOptions/lowAirspeed';
import getApproachToStallPlotOptions from './plotOptions/approachToStall';
import getApproachPlotOptions from './plotOptions/approach';
import getChecklistPlotOptions from './beforeStartingEngineProceduresChecklist';
import getQuickstopPlotOptions from './plotOptions/quickstop';
import getSlopePlotOptions from './plotOptions/slope';
import { getVisualizationCategory } from '../visualization/activityCategory';
import * as visualization from '../visualization/constants';

import {
  VisualizationCategory,
  VisualizationMetaDataType,
} from '../visualization/constants';
import { PlotlyOptions, PlotlyHandlerType } from './types';
import { SessionEventType } from '../../session/constants';
import { FrameType } from '../../frames/constants';
import getBankTurnPlotOptions from './plotOptions/bankTurn';

/**
 * A map of handlers for generating plotly configs based on the type of plot.
 */
// @ts-ignore
const handlers: Map<VisualizationCategory, PlotlyHandlerType> = new Map([
  [visualization.CATEGORY_AIR, getAirPlotOptions],
  [visualization.CATEGORY_APPROACH, getApproachPlotOptions],
  [visualization.CATEGORY_APPROACH_TO_STALL, getApproachToStallPlotOptions],
  [visualization.CATEGORY_AUTOROTATION, getAutorotationPlotOptions],
  [visualization.CATEGORY_AUTOROTATION_LANDING, getAutorotationLandingPlotOptions],
  [visualization.CATEGORY_ENGINE_START, getChecklistPlotOptions],
  [visualization.CATEGORY_GO_AROUND, getGoAroundPlotOptions],
  [visualization.CATEGORY_GROUND, getAirPlotOptions],
  [visualization.CATEGORY_LOW_AIRSPEED, getLowAirspeedPlotOptions],
  [visualization.CATEGORY_MISSED_APPROACH, getMissedApproachPlotOptions],
  [visualization.CATEGORY_QUICKSTOP, getQuickstopPlotOptions],
  [visualization.CATEGORY_NONE, getAirPlotOptions],
  [visualization.CATEGORY_REJECTED_LANDING, getRejectedLandingPlotOptions],
  [visualization.CATEGORY_REJECTED_TAKEOFF, getRejectedTakeoffPlotOptions],
  [visualization.CATEGORY_SLOPE, getSlopePlotOptions],
  [visualization.CATEGORY_STEEP_TURN, getSteepTurnPlotOptions],
  [visualization.CATEGORY_BANK_TURN, getBankTurnPlotOptions],
  [visualization.CATEGORY_TAXI, getTaxiPlotOptions],
  [visualization.CATEGORY_UNUSUAL_ATTITUDE_RECOVERY, getUnusualAttitudeRecoveryPlotOptions],
]);

/**
 * Determines the plotly configuration for the given activity and malfunction
 * codes.
 */
const getPlotOptions = (
  activityCode: string,
  frames: FrameType[],
  markers: SessionEventType[],
  visualizationMetaData: VisualizationMetaDataType,
): PlotlyOptions => {
  // Determine what kind of plot we want to generate.
  const category = getVisualizationCategory(activityCode);

  // Find a handler for that type of plot.
  const handler = handlers.get(category) || getAirPlotOptions;

  // Generate the plotly configuration for that type of plot.
  const options = handler(frames || [], markers, activityCode, visualizationMetaData || {} as any);

  return {
    ...options,
    // @ts-ignore
    config: {
      displayModeBar: false,
      responsive: true,
    },
    style: {
      height: '100%',
      width: '100%',
    },
    useResizeHandler: true,
  };
};

export default getPlotOptions;
