import getAirPlotOptions from './air';
import { annotationStickShaker, annotationTR } from '../annotations';
import { getLandingPlotParams } from '../utils';
import { PlotlyHandlerType } from '../types';

const getLandingPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  let plotOptions;
  if (metaData.aircraftDesignator === 'B737') {
    plotOptions = getAirPlotOptions(
      frames,
      markers,
      activityCode,
      metaData,
      ['gndSpeed',
        ['brakePedPositionPilotL',
          'brakePedPositionPilotR'],
        ['eng_1N1', 'eng_2N1'],
      ],
    );
  } else if (metaData.aircraftDesignator === 'TBM9') {
    plotOptions = getAirPlotOptions(
      frames,
      markers,
      activityCode,
      metaData,
      ['gndSpeed',
        ['brakePedPositionPilotLNorm',
          'brakePedPositionPilotRNorm'],
        'eng_1Trq',
      ],
    );
  } else if (metaData.aircraftDesignator === 'ALX') {
    plotOptions = getAirPlotOptions(
      frames,
      markers,
      activityCode,
      metaData,
      ['gndSpeed',
        ['brakePedPositionPilotL',
          'brakePedPositionPilotR'],
        ['eng_1ManifoldPressure'],
      ],
    );
  } else {
    plotOptions = getAirPlotOptions(
      frames,
      markers,
      activityCode,
      metaData,
      getLandingPlotParams(metaData, frames),
    );
  }

  if (metaData.trainingCenter !== 'NLA') {
    annotationTR(frames, plotOptions, metaData.aircraftDesignator);
    annotationStickShaker(frames, plotOptions, 'cas');
  }

  return plotOptions;
};

export default getLandingPlotOptions;
