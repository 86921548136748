import * as Plotly from 'plotly.js';
import { FrameType } from '../../../frames/constants';
import * as palette from '../../../theme/palette';

const getChecklistDataPoints = (
  params: string[],
  frames: FrameType[],
) => {
  const traces: Plotly.Data[] = [];
  const simParamPlotIndexMap = new Map();
  let numPlots = 0;

  params.forEach(plotName => {
    traces.push({
      mode: 'markers',
      name: plotName,
      type: 'scatter',
      xaxis: `x${numPlots + 2}`,
      yaxis: `y${numPlots + 2}`,
      x: [],
      y: [null],
      showlegend: false,
      marker: {
        color: palette.white.darken(0.6).string(),
        size: 16,
      },
    });
    simParamPlotIndexMap.set(plotName, traces.length - 1);
    numPlots++;
  });

  if (!frames || frames.length < 2) {
    return traces;
  }

  params.forEach(param => {
    const yValues: Array<boolean | number | string> = [];
    frames.forEach(frame => {
      yValues.push(frame.data[param]);
    });
    for (let i = 1; i < frames.length; i++) {
      const traceIndex = simParamPlotIndexMap.get(param);
      if (typeof traceIndex === 'number') {
        if (yValues[i] !== yValues[i - 1]) {
          // @ts-ignore
          traces[traceIndex].y.push(0);
        } else {
          // @ts-ignore
          traces[traceIndex].y.push(null);
        }
      }
    }
  });

  const timeDelta = (Number(new Date(frames[1].timestamp)) - Number(new Date(frames[0].timestamp))) / 1000;

  const xAxis: number[] = [];
  let xAxisCounter = 0;

  frames.forEach(() => {
    xAxis.push(xAxisCounter += timeDelta);
  });

  traces.forEach((trace, index) => {
    // TODO Check proper types why Data do not have "x" property
    // @ts-ignore
    traces[index].x = xAxis;
  });

  return traces;
};

export default getChecklistDataPoints;
