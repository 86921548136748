import moment from 'moment';
import { ClientInterface } from './client';

// API
export default class Activities {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  /**
   * Marks an activity as started, marking the currently active one as ended
   * (if any).
   */
  startActivity = async (id: number): Promise<any> => this.client.postWhenOnline(
    `/activities/${id}/start/`,
    { started_at: moment().toISOString() },
  );

  /**
   * Duplicates an activity record, marking the currently active one as
   * repeated.
   */
  repeatActivity = async (id: number): Promise<any> => this.client.postResource(
    `/activities/${id}/repeat/`,
  );

  editActivity = async (
    activity: Object,
    activityId: number,
    sessionRun: number,
  ): Promise<any> => this.client.postResource(`/activities/${activityId}/edit/`, {
    ...activity,
    sessionRun,
  });

  /**
   * Toggles "isDemo" value on an activity
   */
  demo = async (
    activityId: number,
  ): Promise<any> => this.client.post(`/activities/${activityId}/demo/`);

  createNote = async (
    activity: number,
    content: string,
  ): Promise<any> => this.client.post('/notes/', {
    activity,
    created_at: moment().toISOString(),
    content,
  });

  updateNote = async (
    noteId: number,
    activity: number,
    text: string,
  ): Promise<any> => this.client.patch(`/notes/${noteId}/`, {
    activity,
    updated_at: moment().toISOString(),
    content: text,
  });
}
