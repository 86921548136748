import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';

export const DateTime = ({ dt }: {dt: string | null}) => {
  const date = moment(dt).format('DD/MM/YYYY');
  const time = moment(dt).format('HH:mm:ss');
  const DateTimeText = styled.div`
      color: #DDD;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 100;
      line-height: normal;
      text-align: right;
    `;
  return (
    <div>
      <DateTimeText>{date}</DateTimeText>
      <DateTimeText>{time}</DateTimeText>
    </div>
  );
};
