import React, { FC } from 'react';
import * as palette from '../../../../../theme/palette';

type ArcPathTriangleProps = {
    direction: number,

}
export const ArcPathTriangle:FC<ArcPathTriangleProps> = ({ direction }) => {
  if ([0, 360, -360].includes(direction)) return null;
  return (
    <defs>
      <marker
        id="triangle"
        viewBox="0 0 10 10"
        refX="1"
        refY="5"
        markerUnits="strokeWidth"
        markerWidth="6"
        markerHeight="10"
        orient="auto"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" fill={palette.white.toString()} />
      </marker>
    </defs>
  );
};

export default ArcPathTriangle;
