import { fromJS } from 'immutable';
import { Reducer } from 'redux';
import defaultState, { ImmutableInsightStore } from './store';
import * as actions from './actions';

const insightReducer: Reducer<ImmutableInsightStore, actions.InsightAction> = (
  // eslint-disable-next-line default-param-last
  state = defaultState,
  action: actions.InsightAction,
): ImmutableInsightStore => {
  switch (action.type) {
    case actions.GRADE_CONTEXT_LOADED:
      return state.set('isLoading', true);

    case actions.INSIGHT_DATA_LOADED: {
      const { activityCode, ksaComponentCode, insight } = action.payload.insightData;

      return state.merge({
        activityCode: activityCode || null,
        ksaCode: ksaComponentCode || null,
        data: fromJS(insight),
        isLoading: false,
      });
    }

    case actions.CLEAR_INSIGHT_DATA: {
      return state.merge({
        activityCode: null,
        ksaCode: null,
        data: null,
      });
    }

    default:
      return state;
  }
};

export default insightReducer;
