import axios from 'axios';
import applyConverters from 'axios-case-converter';

import reporter from '../utils/reporter';
import localStore from '../utils/localStore';
import * as auth from '../auth/actions';
import * as messages from '../auth/messages';

type InternalClientFactoryConfig = {
  apiEndpoint: string;
};

type InternalClientType = {
  get: Function;
  post: Function;
  patch: Function;
  put: Function;
  delete: Function;
};

const defaultClientFactory = (
  params: InternalClientFactoryConfig,
): InternalClientType => applyConverters(axios.create({ baseURL: params.apiEndpoint }));

type ClientConfig = {
  apiEndpoint: string;
  clientFactory?: (params: InternalClientFactoryConfig) => InternalClientType;
};

export interface ClientInterface {
  readonly constructor: Function;
  get: (resource: string, params?: object) => Promise<any>;
  post: (resource: string, params?: object) => Promise<any>;
  patch: (resource: string, params?: object, removeNullValues?: boolean) => Promise<any>;
  put: (resource: string, params?: object) => Promise<any>;
  delete: (resource: string, params?: object) => Promise<any>;
  getResource: <R, D>(resource: string, params?: object | null, defaultValue?: D | false) => Promise<R | D | false>;
  postResource: <R, D>(resource: string, params?: object, defaultValue?: D | false) => Promise<R | D | false>;
  postWhenOnline: <R, D>(resource: string, params?: object, defaultValue?: D | false) => Promise<R | D | false>;
  setDispatcher: (dispatcher: Function) => void;
  logError: (error: any) => false;
}

export class Client implements ClientInterface {
  authToken?: string;

  client: InternalClientType;

  dispatch?: Function;

  constructor({ apiEndpoint, clientFactory }: ClientConfig) {
    if (!apiEndpoint) {
      throw new Error('API endpoint must be provided');
    }

    const makeInternalClient = clientFactory || defaultClientFactory;
    this.client = makeInternalClient({ apiEndpoint });
  }

  getToken = async (): Promise<string> => {
    if (this.authToken) {
      return Promise.resolve(this.authToken);
    }

    const token = await localStore.getParam<string>('token');

    if (token instanceof Error) {
      return Promise.reject(token);
    }

    if (token && token.length > 0) {
      this.authToken = token;
    }

    return Promise.resolve(token);
  };

  get = async (
    resource: string,
    params?: object,
  ) => this.client.get(resource, { params, headers: await this.getHeaders() });

  delete = async (
    resource: string,
    params?: object,
  ) => this.client.delete(resource, { params, headers: await this.getHeaders() });

  post = async (
    resource: string,
    params?: object,
  ) => this.client.post(resource, params, { headers: await this.getHeaders() });

  patch = async (
    resource: string,
    params?: object,
    removeNullValues = true,
  ) => {
    if (removeNullValues) {
      // TODO
    }

    return this.client.patch(resource, params, { headers: await this.getHeaders() });
  };

  put = async (
    resource: string,
    params?: object,
  ) => this.client.put(resource, params, { headers: await this.getHeaders() });

  /**
   * Helper method to perform a GET request and return the result.
   */
  getResource = async <ResponseType, DefaultType>(
    resource: string,
    params?: object | null,
    defaultValue: DefaultType | false = false,
  ): Promise<ResponseType | DefaultType | false> => {
    try {
      const { data } = await this.get(resource, params || {});

      return data;
    } catch (error) {
      this.handleError(error);

      return defaultValue;
    }
  };

  /**
   * Helper method to perform a POST request and return the result.
   */
  postResource = async <ResponseType, DefaultType>(
    resource: string,
    params?: object | null,
    defaultValue: DefaultType | false = false,
  ): Promise<ResponseType | DefaultType | false> => {
    try {
      const { data } = await this.post(resource, params || {});

      return data;
    } catch (error) {
      this.handleError(error);

      return defaultValue;
    }
  };

  /**
   * Waits for an internet connection before making a POST request.
   */
  postWhenOnline = async <ResponseType, DefaultType>(
    resource: string,
    params?: object,
    defaultValue: DefaultType | false = false,
  ): Promise<ResponseType | DefaultType | false> => {
    if (navigator.onLine === false) {
      return new Promise((resolve, reject) => {
        const listener = async () => {
          try {
            resolve(await this.postResource<ResponseType, DefaultType>(resource, params, defaultValue));
          } catch (error) {
            reject(error);
          }

          window.removeEventListener('online', listener);
        };

        window.addEventListener('online', listener);
      });
    }

    return this.postResource(resource, params, defaultValue);
  };

  getHeaders = async (): Promise<object> => {
    const token = await this.getToken();

    return { Authorization: `JWT ${token}` };
  };

  setDispatcher = (dispatcher: Function) => {
    this.dispatch = dispatcher;
  };

  handleDispatchAction = (action: object) => {
    if (typeof this.dispatch !== 'function') {
      this.logError('Store dispatcher not instantiated.');

      return;
    }

    this.dispatch(action);
  };

  handleError = (error: any): false => {
    if ('response' in error && error.response.status === 401) {
      this.handleDispatchAction(auth.logout('', messages.SESSION_EXPIRED));

      return false;
    }

    if ('response' in error && [400, 500].includes(error.response.status)) {
      return false;
    }

    return this.logError(error);
  };

  // eslint-disable-next-line class-methods-use-this
  logError = (error: any): false => {
    reporter.error(error);

    return false;
  };
}

export default Client;
