import { ApproachInsightPlot } from '../../visualization/constants';

type Degrees = number;

interface GlidePathParams {
  runwayRange: [number, number];
  glideSlopeRange: [number, number];
  glideSlopeUpperTail: [number, number];
  glideSlopeLowerTail: [number, number];
  glideSlopeInnerTail: [number, number];
  glideSlopeStarLineTail: [number, number];
  glideSlopeHead: [number, number];
  latDevRange: [number, number];
  latDevUpperTail: [number, number];
  latDevLowerTail: [number, number];
  latDevInnerTail: [number, number];
  latDevHead: [number, number];
  fafSymbolPoints: Array<[number, number]>;
  xAxisFaf: number;
  xAxisThresholdPosition: number;
}

/**
 * Final approach fix symbol, drawn in feet (using the feet scale
 * from the y-axis).
 *
 * The star is drawn in the following order:
 *  1. Starting from the center.
 *  2. Drawing a line for the left-side of the top-right leg of the star.
 *  3. Outlining the rest of the star clock-wise.
 */
const getFinalApproachFixSymbol = (
  xAxisPadding: number,
  xAxisLength: number,
  fafElevation: number,
  yAxisLength: number,
): Array<[number, number]> => {
  const xAxisFeetRatio = xAxisLength / yAxisLength;
  const fafShortX = 30 * xAxisFeetRatio;
  const fafLongX = 40 * xAxisFeetRatio;
  const fafShortY = 50;
  const fafLongY = 72;

  const getFafPoint = (x: number, y: number): [number, number] => ([
    ((xAxisPadding + x) / xAxisLength),
    (fafElevation + y),
  ]);

  return [
    getFafPoint(0, 0),
    getFafPoint(fafShortX, fafLongY),
    getFafPoint(fafLongX, fafShortY),
    getFafPoint(0, 0),
    getFafPoint(fafLongX, -fafShortY),
    getFafPoint(fafShortX, -fafLongY),
    getFafPoint(0, 0),
    getFafPoint(-fafShortX, -fafLongY),
    getFafPoint(-fafLongX, -fafShortY),
    getFafPoint(0, 0),
    getFafPoint(-fafLongX, fafShortY),
    getFafPoint(-fafShortX, fafLongY),
  ];
};

// TODO: memoize this function.
export const getGlidePathParams = (
  insight: ApproachInsightPlot,
  glideSlopeWidth: Degrees = 0.8,
  lateralDeviationWidth: Degrees = 3.1,
): GlidePathParams => {
  const { data, fafDistance, fafElevation, thresholdCrossingHeight, tdze } = insight;

  const adjustedElevation = fafElevation - tdze;

  // Glide path angles, in radians
  const gsWidthInRadians = glideSlopeWidth * (Math.PI / 180);
  const gsAngle = Math.atan(adjustedElevation / fafDistance);
  const gsUpperAngle = gsAngle + gsWidthInRadians;
  const gsLowerAngle = gsAngle - gsWidthInRadians;
  const latDevAngle = lateralDeviationWidth * (Math.PI / 180);

  // Length of glide path line, in feet.
  const glidePathLength = fafDistance / Math.cos(gsAngle);
  const upperGlidePathLength = 0.98 * glidePathLength;
  const lowerGlidePathLength = 1.02 * glidePathLength;
  const innerGlidePathLength = 0.9 * glidePathLength;
  const starGlidePathLength = 0.975 * glidePathLength;

  // Range of x-axis, in feet.
  const xAxisRangePadding = 2000;
  const xAxisRangeStart = -fafDistance - xAxisRangePadding;
  const xAxisRangeEnd = 2000 + xAxisRangePadding;

  // Scales distances (in feet) to fractional positions on the x-axis.
  const xAxisTotalLength = -xAxisRangeStart + xAxisRangeEnd;
  const scaleX = (value: number): number => ((xAxisRangePadding / xAxisTotalLength) + (value / xAxisTotalLength));

  // Position of glide path vertices, as fractions of the x-axis.
  const xAxisThresholdPosition = scaleX(fafDistance);
  const xAxisUpperGlideSlopeTail = scaleX(fafDistance - upperGlidePathLength * Math.cos(gsUpperAngle));
  const xAxisLowerGlideSlopeTail = scaleX(fafDistance - lowerGlidePathLength * Math.cos(gsLowerAngle));
  const xAxisInnerGlideSlopeTail = scaleX(fafDistance - innerGlidePathLength * Math.cos(gsAngle));
  const xAxisStarLineTail = scaleX(fafDistance - starGlidePathLength * Math.cos(gsAngle));
  const xAxisLatDevTail = scaleX(fafDistance - glidePathLength * Math.cos(latDevAngle));
  const xAxisInnerLatDevTail = scaleX(fafDistance - innerGlidePathLength);
  const xAxisFaf = scaleX(0);

  // Position of glide path vertices on y-axis, in feet.
  const upperGlideSlopeElevation = upperGlidePathLength * Math.sin(gsUpperAngle);
  const lowerGlideSlopeElevation = lowerGlidePathLength * Math.sin(gsLowerAngle);
  const innerGlideSlopeElevation = innerGlidePathLength * Math.sin(gsAngle);
  const starLineGlideSlopeElevation = starGlidePathLength * Math.sin(gsAngle);
  const upperLateralDeviation = glidePathLength * Math.sin(latDevAngle);
  const lowerLateralDeviation = glidePathLength * Math.sin(-latDevAngle);

  // Padding above and below lateral deviation plot, in feet. We want to show
  // as much trajectory as possible without unreasonably distorting the plot.
  const minLatDevPad = 200;
  const maxLatDevPad = 2500;
  const firstLatDevIndex = data.findIndex(({ distanceFromThreshold: d }) => d > -fafDistance);
  const maxLatDev = data
    .slice(firstLatDevIndex)
    .reduce((max, { latDev }) => Math.max(max, Math.abs(latDev)), 0);
  const idealLatDevPad = maxLatDev - upperLateralDeviation;
  const latDevRangePadding = Math.max(minLatDevPad, Math.min(maxLatDevPad, idealLatDevPad));

  // Range of y-axis, in feet.
  const gsRangePadding = 500;
  const gsRangeStart = 0;
  const gsRangeEnd = upperGlideSlopeElevation + gsRangePadding;
  const latDevRangeStart = lowerLateralDeviation - latDevRangePadding;
  const latDevRangeEnd = upperLateralDeviation + latDevRangePadding;

  return {
    runwayRange: [xAxisRangeStart, xAxisRangeEnd],
    glideSlopeRange: [gsRangeStart, gsRangeEnd],
    glideSlopeUpperTail: [xAxisUpperGlideSlopeTail, upperGlideSlopeElevation],
    glideSlopeLowerTail: [xAxisLowerGlideSlopeTail, lowerGlideSlopeElevation],
    glideSlopeInnerTail: [xAxisInnerGlideSlopeTail, innerGlideSlopeElevation],
    glideSlopeStarLineTail: [xAxisStarLineTail, starLineGlideSlopeElevation],
    glideSlopeHead: [xAxisThresholdPosition, thresholdCrossingHeight],
    latDevRange: [latDevRangeStart, latDevRangeEnd],
    latDevUpperTail: [xAxisLatDevTail, upperLateralDeviation],
    latDevLowerTail: [xAxisLatDevTail, lowerLateralDeviation],
    latDevInnerTail: [xAxisInnerLatDevTail, 0],
    latDevHead: [xAxisThresholdPosition, 0],
    fafSymbolPoints: getFinalApproachFixSymbol(
      xAxisRangePadding,
      xAxisTotalLength,
      adjustedElevation,
      gsRangeEnd,
    ),
    xAxisFaf,
    xAxisThresholdPosition,
  };
};
