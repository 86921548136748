import React, { useCallback } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { useParams } from 'react-router';
import { Header } from '@/components/Header';
import { BreadcrumbListType, Breadcrumbs } from '@/components/Breadcrumbs';
import Title from '../../../ui/head/Title';
import PageHeader from '../../../ui/PageHeader';
import Body from '../../../ui/Body';
import Loading from '../../../ui/Loading';
import { Button, Wrapper as ButtonWrapper } from '../../../ui/Button';
import { editSession, home, schedule as scheduleRoute, sessionOverview } from '../../../utils/routes';
import ScrollToTop from '../../../utils/ScrollToTop';

import { LessonPlanActivityType } from '../../constants';
import ContentContainer from '../../../ui/ContentContainer';
import { useAppDispatch, useAppSelector } from '../../../store';
import * as actions from '../../actions';

const ViewSessionScreen = () => {
  const dispatch = useAppDispatch();
  const schedule = useAppSelector(state => state.schedule);
  const lessonPlan = schedule?.get('lessonPlan')?.toJS();
  const currentVariationId = schedule.get('currentVariationId');
  const onComponentMounted = useCallback((
    sessionId: number,
  ) => dispatch(actions.viewSessionScreenLoaded(sessionId)), []);
  const onDone = () => dispatch(actions.setLessonPlan({} as any));

  const title = 'View Session';
  const params = useParams<{sessionId: string}>();
  React.useEffect(() => {
    const getSessionId = () => parseInt(params.sessionId!, 10);

    onComponentMounted(getSessionId());
  }, [params, onComponentMounted]);

  if (!lessonPlan || Object.entries(lessonPlan).length < 1) {
    return <Loading />;
  }

  let { name, activities } = lessonPlan;

  const currentVariation = lessonPlan.variations.find(({ id }:any) => id === currentVariationId);
  if (currentVariationId && currentVariation) {
    name = currentVariation.name;
    activities = currentVariation.activities;
  }

  const breadcrumbs:BreadcrumbListType = [
    {
      link: home(),
      title: 'Home',
    },
    {
      link: scheduleRoute(),
      title: 'Schedule',
    },
    {
      link: sessionOverview(params.sessionId!),
      title: lessonPlan.shortName,
    },
    {
      title,
    },
  ];

  return (
    <>
      <ScrollToTop />
      <Title title={title} />
      <Header />
      <ContentContainer>
        <Body>
          <Breadcrumbs crumbs={breadcrumbs} />
          <Subheader>{name}</Subheader>

          {activities.map((activity: LessonPlanActivityType) => (
            <Activity key={activity.ordering}>{activity.name}</Activity>
          ))}
          <ButtonWrapper>
            <NavLink to={editSession(parseInt(params.sessionId!, 10))}>
              <Button onClick={onDone} width="180px">EDIT</Button>
            </NavLink>
          </ButtonWrapper>
        </Body>
      </ContentContainer>
    </>
  );
};

export default ViewSessionScreen;

const Subheader = styled.div`
  color: ${props => props.theme.textColor.string()};
  text-align: center;
  background-color: black;
margin-bottom: 24px;
`;

const Activity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0 20px;
  background-color: ${props => props.theme.textColor.darken(0.9).string()};
  color: ${props => props.theme.textColor.string()};
  margin: 10px 1vw;
`;
