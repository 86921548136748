import moment from 'moment';
import { List, fromJS, RecordOf } from 'immutable';

import * as mock from '../utils/mock';
import * as c from './constants';
import { ImmutableSummaryStore } from './store';
import { SessionEventType, SessionMarkerMapType } from '../session/constants';
import { ActivityType } from '../activities/types';

export const createGradeObject = (grade?: Partial<c.GradeType>): c.GradeType => ({
  id: 0,
  activityCode: null,
  activityId: null,
  ksaComponentCode: null,
  ksaComponentId: null,
  gradeValue: null,
  afmAGrade: null,
  afmMGrade: null,
  aopGrade: null,
  comGrade: null,
  ltwGrade: null,
  pwdGrade: null,
  sawGrade: null,
  wlmGrade: null,
  reasonCode: undefined,
  comment: undefined,
  isAutomatic: false,
  updatedAt: '',
  ...grade,
});

export const createEmptyInstructorGrade = (
  grade?: Partial<c.GradeType>,
): c.GradeType => createGradeObject({
  ...grade,
  isAutomatic: false,
  tempId: mock.id(),
});

export const getGradeableType = (grade: c.GradeType): c.GradeableType => {
  if (grade.ksaComponentId) {
    return c.GRADEABLE_KSA;
  }

  return c.GRADEABLE_ACTIVITY;
};

export const getGradeableId = (
  grade: c.GradeType,
  type?: c.GradeableType | null,
): c.GradeableIdType | null => {
  switch (type || getGradeableType(grade)) {
    case c.GRADEABLE_KSA:
      return grade.ksaComponentId;

    case c.GRADEABLE_ACTIVITY:
      return grade.activityId;

    default:
      return null;
  }
};

export const createGradePair = (
  instructorGrade: c.GradeType,
  predictedGrade: c.GradeType | null,
  gradeableType: c.GradeableType | null,
  gradeableId?: c.GradeableIdType | null,
): c.GradePairType => ({
  type: gradeableType || getGradeableType(instructorGrade),
  gradeableId: (gradeableId || getGradeableId(instructorGrade) || 0),
  instructorGrade,
  predictedGrade,
});

const instructorGradeFilter = ({ isAutomatic }: c.GradeType): boolean => !isAutomatic;

export const getInstructorGrade = (
  grades?: c.GradeType[],
): c.GradeType | null => (grades || []).find(instructorGradeFilter) || null;

export const getAllInstructorGrades = (
  grades?: List<c.GradeType>,
): List<c.GradeType> => (grades || List()).filter(instructorGradeFilter);

export const getOrCreateInstructorGrade = (
  grades: c.GradeType[],
  defaultGrade?: Partial<c.GradeType>,
) => getInstructorGrade(grades) || createEmptyInstructorGrade(defaultGrade);

export const getPredictedGrade = (
  grades: c.GradeType[],
): c.GradeType | null => (grades && grades.find(({ isAutomatic }) => isAutomatic)) || null;

export const getGradeableGradePair = (
  gradeMap: {[key: number]: c.GradeType[]},
  gradeableType: c.GradeableType,
  gradeableId: c.GradeableIdType,
): c.GradePairType => {
  const grades = gradeMap[gradeableId];
  const predicted = getPredictedGrade(grades);
  const instructor = getOrCreateInstructorGrade(grades, { [`${gradeableType}Id`]: gradeableId });

  return createGradePair(instructor, predicted, gradeableType, gradeableId);
};

export const getGradingContext = (
  item: c.GradingSheetItemType | null,
  activityGradesMap: c.ActivityGradesMapType,
  ksaGradesMap: c.KsaGradeMapType,
  markers: SessionEventType[],
  activities: ActivityType[],
): c.GradingContextType => {
  const context: c.GradingContextType = {
    grades: new Map(),
    // @ts-ignore
    markers: new Map(),
    activities: new Map(),
  };

  if (!item || !activityGradesMap || !ksaGradesMap) {
    return context;
  }

  item.activities && item.activities.forEach(id => {
    context.markers.set(id, []);
    context.grades.set(id, getGradeableGradePair(
      activityGradesMap,
      c.GRADEABLE_ACTIVITY,
      id,
    ));
  });

  item.ksaComponents && item.ksaComponents.forEach(id => {
    context.grades.set(id, getGradeableGradePair(ksaGradesMap, c.GRADEABLE_KSA, id));
  });

  activities && activities.forEach(activity => (
    context.markers.has(activity.id)
      ? context.activities.set(activity.id, activity)
      : null
  ));

  markers && markers.forEach(marker => {
    for (const { id, startedAt, endedAt } of context.activities.values()) {
      if (!startedAt || !endedAt) {
        return;
      }

      if (marker.timestamp && marker.timestamp.isSameOrAfter(startedAt) && marker.timestamp.isBefore(endedAt)) {
        context.markers.set(id, [...(context.markers.get(id) || []), marker]);
        break;
      }
    }
  });

  return context;
};

export const getDisplayGrade = (pair: c.GradePairType): c.GradeType => {
  if (pair.instructorGrade.id) {
    return pair.instructorGrade;
  }

  return pair.predictedGrade || pair.instructorGrade;
};

// TODO: this function should be memoized.
export const getGradeValues = (
  scheme: c.GradingSchemeType,
): c.GradeLevelType[] => {
  // Only two schemes are supported at the moment.
  if (scheme === '4-1') {
    return [
      c.GRADE_LEVEL_13,
      c.GRADE_LEVEL_9,
      c.GRADE_LEVEL_5,
      c.GRADE_LEVEL_1,
    ];
  }
  if (scheme === '1-4') {
    return [
      c.GRADE_LEVEL_1,
      c.GRADE_LEVEL_5,
      c.GRADE_LEVEL_9,
      c.GRADE_LEVEL_13,
    ];
  }
  if (scheme === '5-1') {
    return [
      c.GRADE_LEVEL_17,
      c.GRADE_LEVEL_13,
      c.GRADE_LEVEL_9,
      c.GRADE_LEVEL_5,
      c.GRADE_LEVEL_1,
    ];
  }
  if (scheme === '1-5') {
    return [
      c.GRADE_LEVEL_1,
      c.GRADE_LEVEL_5,
      c.GRADE_LEVEL_9,
      c.GRADE_LEVEL_13,
      c.GRADE_LEVEL_17,
    ];
  }
  return [
    c.GRADE_LEVEL_1,
    c.GRADE_LEVEL_5,
    c.GRADE_LEVEL_9,
    c.GRADE_LEVEL_13,
  ];
};

export const findUpdatedGrade = (
  activityGrades: c.ActivityGradesMapType,
  ksaComponentGrades: c.KsaGradeMapType,
  grade: c.GradeType,
): c.GradeType | null => {
  const gradeableId = getGradeableId(grade);
  const gradesMap = getGradeableType(grade) === c.GRADEABLE_KSA
    ? ksaComponentGrades
    : activityGrades;

  if (!gradeableId || !(gradeableId in gradesMap)) {
    return null;
  }

  // @ts-ignore
  return gradesMap[gradeableId].find(
    // @ts-ignore
    ({ id, tempId }) => (id === grade.id || tempId === grade.tempId),
  ) || null;
};

const updateGradingSheet = (
  state: ImmutableSummaryStore,
  gradingSheet: c.GradingSheetType,
): ImmutableSummaryStore => state.setIn(['gradingSheets', gradingSheet.sessionRunId], {
  id: gradingSheet.id,
  name: gradingSheet.name,
  sessionRunId: gradingSheet.sessionRunId,
  sections: gradingSheet.sections,
});

export const updateAllGradingSheets = (
  state: ImmutableSummaryStore,
  updatedSheets: c.GradingSheetType[],
): ImmutableSummaryStore => updatedSheets.reduce(
  (updatedState, updatedSheet) => updateGradingSheet(updatedState, updatedSheet),
  state,
);

type GradeStoreKey = 'activityGrades' | 'ksaComponentGrades';
const getGradeStoreKey = (
  type: c.GradeableType,
  defaultKey: GradeStoreKey = 'activityGrades',
): GradeStoreKey => {
  switch (type) {
    case c.GRADEABLE_KSA:
      return 'ksaComponentGrades';
    case c.GRADEABLE_ACTIVITY:
      return 'activityGrades';
    default:
      return defaultKey;
  }
};

export const updateGrade = (
  state: ImmutableSummaryStore,
  grade: c.GradeType,
) => {
  const gradeableType = getGradeableType(grade);
  const stateKey = getGradeStoreKey(gradeableType);
  const grades = state.get(stateKey);
  const gradeableId = getGradeableId(grade, gradeableType);
  const gradeIndex = gradeableId && grades.has(gradeableId)
    ? grades.get(gradeableId)!
      .findIndex((g: RecordOf<c.GradeType>) => g.get('id') === grade.id)
    : -1;

  if (gradeableId && grades.has(gradeableId)) {
    return gradeIndex >= 0
      ? state.setIn([stateKey, gradeableId, gradeIndex], fromJS(grade))
      : state.setIn([stateKey, gradeableId], state.getIn([stateKey, gradeableId]).push(fromJS(grade)));
  }

  return state.setIn([stateKey, gradeableId], List([fromJS(grade)]));
};

export const updateAllGrades = (
  state: ImmutableSummaryStore,
  grades: c.GradeType[],
) => grades.reduce(
  (updatedState, grade) => updateGrade(updatedState, grade),
  state,
);

const updateMarker = (
  state: ImmutableSummaryStore,
  marker: c.GradingMarkerType,
): ImmutableSummaryStore => {
  const markers = state.getIn(['markers', marker.sessionRun]);

  if (markers && !markers.find((m: { get: (arg0: string) => number; }) => m.get('id') === marker.id)) {
    return state.setIn(['markers', marker.sessionRun], state.getIn(
      ['markers', marker.sessionRun],
    ).push(fromJS({ ...marker, timestamp: moment(marker.timestamp) })));
  }

  if (!markers) {
    return state.setIn(['markers', marker.sessionRun], List(
      [fromJS({ ...marker, timestamp: moment(marker.timestamp) })],
    ));
  }

  return state;
};

export const updateAllMarkers = (
  state: ImmutableSummaryStore,
  markers: c.GradingMarkerType[],
): ImmutableSummaryStore => markers.reduce(
  (updatedState, marker) => updateMarker(updatedState, marker),
  state,
);

export const getGradeableIdForMarker = (
  markers: SessionMarkerMapType,
): c.GradeableIdType | null => {
  for (const [gradeableId, gradeableMarkers] of markers) {
    if (gradeableMarkers.length > 0) {
      return gradeableId;
    }
  }

  return null;
};
