import {
  LandingInsightType,
  TYPE_PLOTLY,
  TYPE_SUMMARY,
  TYPE_LANDING_SVG,
  VisualizationHandlerType,
} from '../constants';
import getLandingPlotOptions from '../../plotly/plotOptions/landing';
import getLandingFlarePhasePlotOptions from '../../plotly/plotOptions/landingFlarePhase';

// @ts-ignore
const getLandingVisualizations: VisualizationHandlerType<LandingInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const visualizations = [];

  if (
    data.insight
    && data.insight.summary
    && data.insight.summary.payload !== null
  ) {
    const exists = data.insight.summary.payload.some(a => a.score !== null);
    if (exists) {
      visualizations.push({
        type: TYPE_SUMMARY,
        data: data.insight.summary,
      });
    }
  }

  if (
    data.insight
    && data.insight.landing
    && data.insight.summary
    && data.insight.summary.payload !== null
  ) {
    visualizations.push({
      type: TYPE_LANDING_SVG,
      data,
      page: 'Rollout',
    });
  }

  if (
    !metaData.equipmentId
    || (metaData.equipmentId && metaData.equipmentId !== 'simcom-tbm940-frasca-01')
  ) {
    visualizations.push({
      type: TYPE_PLOTLY,
      data: getLandingPlotOptions(data.frames, markers, activity, metaData),
      page: 'Brake Application',
    });
  }

  const landingFlarePhasePlotOptions = getLandingFlarePhasePlotOptions(
    data.frames,
    markers,
    activity,
    metaData,
  );

  if (landingFlarePhasePlotOptions) {
    visualizations.push({
      type: TYPE_PLOTLY,
      data: landingFlarePhasePlotOptions,
      page: 'Flare to Touchdown',
    });
  }

  return visualizations;
};

export default getLandingVisualizations;
