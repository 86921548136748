import { SIDDepartureInsightType } from '../../visualization/constants';

const getSIDPlotOptions = (departure: SIDDepartureInsightType) => {
  const lat: number[] = [];
  const lon: number[] = [];

  // @ts-ignore
  const dhpIndex = departure.beacons.findIndex(({ name }) => name === 'DHP');
  // @ts-ignore
  const pottrIndex = departure.beacons.findIndex(({ name }) => name === 'POTTR');
  // @ts-ignore
  const druumIndex = departure.beacons.findIndex(({ name }) => name === 'DRUUM');
  // @ts-ignore
  const hedlyIndex = departure.beacons.findIndex(({ name }) => name === 'HEDLY');

  // @ts-ignore
  departure.data.forEach(dataPoint => {
    // @ts-ignore
    lat.push(dataPoint.lat);
    // @ts-ignore
    lon.push(dataPoint.lon);
  });

  const data = [{
    x: lon,
    y: lat,
    mode: 'lines',
  }];

  const layout = {
    annotations: [{
      xref: 'paper',
      yref: 'paper',
      yanchor: 'top',
      x: 1,
      y: 1,
      showarrow: false,
      text: `${departure.airport} ${departure.runway}`,
      font: {
        color: 'white',
        size: 16,
      },
    }],
    shapes: [{
      // @ts-ignore
      type: 'line',
      // @ts-ignore
      x0: departure.beacons[dhpIndex].lon,
      // @ts-ignore
      x1: departure.beacons[pottrIndex].lon,
      // @ts-ignore
      y0: departure.beacons[dhpIndex].lat,
      // @ts-ignore
      y1: departure.beacons[pottrIndex].lat,
      // @ts-ignore
      layer: 'below',
      // @ts-ignore
      line: {
        // @ts-ignore
        color: 'white',
        // @ts-ignore
        width: 3,
      },
    },
    {
      // @ts-ignore
      type: 'line',
      // @ts-ignore
      x0: departure.beacons[druumIndex].lon,
      // @ts-ignore
      x1: departure.beacons[hedlyIndex].lon,
      // @ts-ignore
      y0: departure.beacons[druumIndex].lat,
      // @ts-ignore
      y1: departure.beacons[hedlyIndex].lat,
      // @ts-ignore
      layer: 'below',
      // @ts-ignore
      line: {
        // @ts-ignore
        color: 'white',
        // @ts-ignore
        width: 3,
      },
    },
    {
      // @ts-ignore
      type: 'line',
      // @ts-ignore
      x0: departure.beacons,
      // @ts-ignore
      x1: departure.runwayLon_2,
      // @ts-ignore
      y0: departure.runwayLat,
      // @ts-ignore
      y1: departure.runwayLat_2,
      // @ts-ignore
      layer: 'below',
      // @ts-ignore
      line: {
        // @ts-ignore
        color: 'white',
        // @ts-ignore
        width: 3,
      },
    }],
    autosize: true,
    clickmode: 'none',
    dragmode: false,
    hovermode: false,
    margin: {
      l: 65,
      r: 10,
      t: 20,
      b: 40,
    },
    mode: 'lines',
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    xaxis: {
      title: 'LON',
      showticklabels: true,
      tickcolor: '#888',
      ticklen: 3,
      ticks: 'inside',
      color: '#ccc',
      domain: [0, 1],
      showgrid: false,
      type: 'linear',
    },
    yaxis: {
      title: 'LAT',
      showticklabels: true,
      tickcolor: '#888',
      ticklen: 3,
      ticks: 'inside',
      color: '#ccc',
      domain: [0, 1],
      showgrid: false,
      type: 'linear',
    },
  };

  // @ts-ignore
  Object.values(departure.beacons).forEach(({ name, lat: beaconLat, lon: beaconLon }) => {
    layout.annotations.push({
      xref: 'container',
      yref: 'container',
      yanchor: 'top',
      x: beaconLon,
      y: beaconLat,
      showarrow: true,
      // @ts-ignore
      arrowhead: 6,
      // @ts-ignore
      arrowcolor: 'ccc',
      text: name,
      // @ts-ignore
      ax: 0,
      // @ts-ignore
      ay: -40,
      font: {
        color: 'white',
        size: 16,
      },
    });
  });

  return {
    data,
    layout,
    config: {
      displayModeBar: false,
      responsive: true,
    },
    style: {
      height: '100%',
      width: '100%',
    },
    useResizeHandler: true,
  };
};

export default getSIDPlotOptions;
