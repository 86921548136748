import { Client as ClientInterface } from './client';
import { EventTypeType } from '../session/constants';

// API
export default class Events {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  /**
   * Creates a new event record.
   */
  create = async ({
    sessionRunId,
    eventType,
    data,
    timestamp,
  }: {
    sessionRunId: number,
    eventType: EventTypeType,
    data: string | null | undefined,
    timestamp: string,
  }): Promise<object> => this.client.post('/events/', {
    sessionRun: sessionRunId,
    eventType,
    data,
    timestamp,
  });
}
