/**
 * Tippy popover settings.
 *
 * https://atomiks.github.io/tippyjs/all-options
 */
export default {
  arrow: true,
  arrowType: 'round',
  interactive: true,
  trigger: 'click',
  theme: 'paladin',
  boundary: 'viewport',
} as const;
