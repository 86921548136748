export const moveTo = (x: number, y: number) => `M ${x} ${y}`;

export const lineTo = (x: number, y: number) => `L ${x} ${y}`;

export const closePath = (path: string) => `${path} Z`;

export const getLinePath = (points: Array<[number, number]>): string => {
  let path = moveTo(...points[0]);

  for (let i = 1; i < points.length; i++) {
    path += ` ${lineTo(...points[i])}`;
  }

  return closePath(path);
};
