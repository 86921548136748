import TraineeProfile from './traineeProfile';
import { FullSessionType } from '../../../session/constants';

export type TraineeProfileProps = {
  isLoading: boolean,
  sessions: FullSessionType[],
  onComponentMounted: Function,
};

export default TraineeProfile;
