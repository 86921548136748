import { getCockpitPlotOptions } from '@/utils/plotly/plotOptions/cockpitPrepratation';
import { get, isNil, min } from 'lodash';
import { FlattenType } from '@/types/helper.types';
import { accentPrimary, secondary } from '@/theme/palette';
import { NormSummaryAnnotation, NormSummaryBarAnnotation } from '@/utils/plotly/annotations/types';
import { getAnnotationBanner, getAnnotationMetaData, isAnnotationBarData } from '@/utils/annotationHelper';
import {

  VisualizationHandlerType,
  TYPE_COCKPIT_PREPARATION,
  CockpitPreparationInsightType,
  CockpitPreparationMetaType, VisualizationDefinitionType, VisualizationMetaDataType,
} from '../constants';

const ANNOTATION_PROPERTIES = {
  'Time To Start Engine': {
    type: 'bar',
  },
  Norm: {
    description: 'Comparison with the pilot baseline',
    baseColor: accentPrimary.toString(),
    type: 'bar',
  },
  Standard: {
    description: 'Comparison with the training benchmark',
    baseColor: secondary.toString(),
    type: 'bar-line',
  },
} as const;

const getMetaData = (data: NormSummaryAnnotation): FlattenType<CockpitPreparationMetaType['data']> | null => {
  const isBarAnnotation = isAnnotationBarData(data);
  if (!isBarAnnotation) return null;

  const barAnnotation = get(ANNOTATION_PROPERTIES, (data as NormSummaryBarAnnotation).label);
  if (!barAnnotation || isNil((data as NormSummaryBarAnnotation).value)) return null;

  return {
    title: (data as NormSummaryBarAnnotation).label,
    description: barAnnotation?.description,
    type: barAnnotation?.type,
    time: (data as NormSummaryBarAnnotation).value!,
    color: barAnnotation?.baseColor ?? (data as NormSummaryBarAnnotation).color,
  };
};

export type CockpitPreparationVisualizationType = VisualizationHandlerType<
  CockpitPreparationInsightType, CockpitPreparationMetaType
>;

const getCockpitPreparationVisualizations: CockpitPreparationVisualizationType = (
  _,
  data,
) => {
  const visualizations = [];

  if (data.insight && data.insight.annotation && data.insight.annotation.engineStartTime) {
    const annotations = data.insight.annotation.engineStartTime;

    const plotData = annotations.map(val => getMetaData(val)!).filter(val => !isNil(val)!);
    const annotationBanner = getAnnotationBanner(annotations);

    const plotMetaData = {
      banner: annotationBanner,
      data: plotData,
    } as VisualizationMetaDataType<CockpitPreparationMetaType>;

    visualizations.push({
      type: TYPE_COCKPIT_PREPARATION,
      metaData: plotMetaData,
      data: getCockpitPlotOptions({ plotData: plotData.toReversed() }),
    });
  }

  return visualizations as VisualizationDefinitionType<CockpitPreparationMetaType>[];
};

export default getCockpitPreparationVisualizations;
