import {
  CATEGORY_APPROACH,
  CATEGORY_APPROACH_TO_STALL,
  CATEGORY_GO_AROUND,
  CATEGORY_GROUND,
  CATEGORY_LANDING,
  CATEGORY_MISSED_APPROACH,
  CATEGORY_NONE,
  CATEGORY_REJECTED_LANDING,
  CATEGORY_REJECTED_TAKEOFF,
  CATEGORY_STEEP_TURN,
  CATEGORY_TAKEOFF,
  CATEGORY_TAKEOFF_OEI,
  CATEGORY_TAXI,
  CATEGORY_UNUSUAL_ATTITUDE_RECOVERY,
  CATEGORY_ENGINE_START,
  CATEGORY_AUTOROTATION,
  CATEGORY_AUTOROTATION_LANDING,
  CATEGORY_LOW_AIRSPEED,
  CATEGORY_QUICKSTOP,
  CATEGORY_SLOPE,
  VisualizationCategory,
  CATEGORY_UPRT,
  CATEGORY_STALL,
  CATEGORY_BANK_TURN,
  CATEGORY_COCKPIT_PREPARATION,
  CATEGORY_DEPARTURE,
} from './constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isAirActivity = (activityCode: string): false => false;

export const isApproachActivity = (activityCode?: string): typeof CATEGORY_APPROACH | false => (
  typeof activityCode === 'string'
  && activityCode.substr(0, 4) === 'APP_'
    ? CATEGORY_APPROACH
    : false
);

const isApproachToStallActivity = (activityCode?: string)
: typeof CATEGORY_APPROACH_TO_STALL | false => (
  typeof activityCode === 'string'
  && activityCode.startsWith('STALL_APPROACH_')
    ? CATEGORY_APPROACH_TO_STALL
    : false
);

const isAutorotationActivity = (activityCode: string)
: typeof CATEGORY_AUTOROTATION | false => {
  const autorotation = {
    DESCENT_AUTOROTATION: 1,
    AUTOROTATION_IMC: 1,
    AUTOROTATION_VMC: 1,
    AUTOROTATION_IMC_POWER_RECOVERY: 1,
  };

  return activityCode in autorotation ? CATEGORY_AUTOROTATION : false;
};

const isAutorotationLandingActivity = (activityCode: string)
: typeof CATEGORY_AUTOROTATION_LANDING | false => (
  activityCode === 'LANDING_AUTOROTATION' ? CATEGORY_AUTOROTATION_LANDING : false
);

const isEngineStartActivity = (activityCode?: string): typeof CATEGORY_ENGINE_START | false => (
  typeof activityCode === 'string'
  && activityCode.substr(0, 13) === 'ENGINE_START_'
    ? CATEGORY_ENGINE_START
    : false
);

export const isGoAroundActivity = (
  activityCode?: string,
): typeof CATEGORY_GO_AROUND | false => (typeof activityCode === 'string'
&& activityCode.substr(0, 9) === 'GO_AROUND'
  ? CATEGORY_GO_AROUND
  : false);

export const isUprtActivity = (activityCode: string): typeof CATEGORY_UPRT | false => (typeof activityCode === 'string'
&& activityCode.substr(0, 4) === 'UPRT'
  ? CATEGORY_UPRT
  : false);

export const isStallActivity = (activityCode: string): typeof CATEGORY_STALL | false => (
  typeof activityCode === 'string' && activityCode.substr(0, 5) === 'STALL'
    ? CATEGORY_STALL
    : false);

const isGroundActivity = (activityCode: string): typeof CATEGORY_GROUND | false => {
  const groundActivities = {
    EQUIPMENT_EXAMINATION: 1,
    BEFORE_ENGINE_START_CHECKLIST: 1,
    BEFORE_TAXI_PROC: 1,
    PRE_TAKEOFF_PROC: 1,
    AFTER_LANDING_PROC: 1,
    PARKING_AND_SECURING: 1,
    SHUTDOWN_PROC: 1,
  };

  return activityCode in groundActivities ? CATEGORY_GROUND : false;
};

const isLandingActivity = (activityCode?: string): typeof CATEGORY_LANDING | false => (
  typeof activityCode === 'string'
  && activityCode.substr(0, 8) === 'LANDING_'
    ? CATEGORY_LANDING
    : false
);

const isLowAirspeedActivity = (activityCode: string): typeof CATEGORY_LOW_AIRSPEED | false => {
  const lowAirspeed = {
    HOVER: 1,
    HOVER_TURN: 1,
    QUARTERING_FLIGHT: 1,
    TAXI_AIR: 1,
  };

  return activityCode in lowAirspeed ? CATEGORY_LOW_AIRSPEED : false;
};

const isMissedApproachActivity = (activityCode?: string)
: typeof CATEGORY_MISSED_APPROACH | false => (
  typeof activityCode === 'string'
  && activityCode.substr(0, 11) === 'MISSED_APP_'
    ? CATEGORY_MISSED_APPROACH
    : false
);

const isQuickstopActivity = (activityCode?: string): typeof CATEGORY_QUICKSTOP | false => (
  typeof activityCode === 'string' && activityCode.substr(0, 10) === 'QUICKSTOP_'
    ? CATEGORY_QUICKSTOP
    : false
);

const isRejectedLanding = (activityCode: string): typeof CATEGORY_REJECTED_LANDING | false => (
  activityCode === 'REJECTED_LDG'
    ? CATEGORY_REJECTED_LANDING
    : false
);

const isRejectedTakeoff = (activityCode: string): typeof CATEGORY_REJECTED_TAKEOFF | false => (
  activityCode === 'TAKEOFF_REJECTED'
    ? CATEGORY_REJECTED_TAKEOFF
    : false
);

const isSlopeActivity = (activityCode: string): typeof CATEGORY_SLOPE | false => {
  const slope = {
    TAKEOFF_SLOPE: 1,
    LANDING_SLOPE: 1,
  };

  return activityCode in slope ? CATEGORY_SLOPE : false;
};

const isSteepTurn = (activityCode?: string): typeof CATEGORY_STEEP_TURN | false => (
  typeof activityCode === 'string'
  && (activityCode.substr(0, 10) === 'STEEP_TURN')
    ? CATEGORY_STEEP_TURN
    : false
);

const isBankTurn = (activityCode?: string): typeof CATEGORY_BANK_TURN | false => (
  typeof activityCode === 'string'
  && (activityCode.substr(0, 9) === 'BANK_TURN')
    ? CATEGORY_BANK_TURN
    : false
);
const isCockpitPreparation = (activityCode?: string): typeof CATEGORY_COCKPIT_PREPARATION | false => (
  typeof activityCode === 'string'
    && (activityCode === 'COCKPIT_PREPARATION')
    ? CATEGORY_COCKPIT_PREPARATION
    : false
);

const isDeparture = (activityCode?: string): typeof CATEGORY_DEPARTURE | false => (
  typeof activityCode === 'string'
    && (activityCode === 'DEPARTURE')
    ? CATEGORY_DEPARTURE
    : false
);

export const isTakeoffActivity = (activityCode?: string): typeof CATEGORY_TAKEOFF | false => (
  typeof activityCode === 'string'
  && activityCode.substr(0, 8) === 'TAKEOFF_'
  && activityCode !== 'TAKEOFF_REJECTED'
    ? CATEGORY_TAKEOFF
    : false
);

const isTakeoffOEI = (activityCode: string): typeof CATEGORY_TAKEOFF_OEI | false => (
  activityCode === 'TAKEOFF_OEI'
    ? CATEGORY_TAKEOFF_OEI
    : false
);

const isTaxiActivity = (activityCode: string): typeof CATEGORY_TAXI | false => {
  const taxiActivities = {
    TAXI_TO_RWY: 1,
    TAXI_AFTER_LDG: 1,
  };

  return activityCode in taxiActivities ? CATEGORY_TAXI : false;
};

const isUnusualAttitudeRecovery = (activityCode: string)
: typeof CATEGORY_UNUSUAL_ATTITUDE_RECOVERY | false => (
  typeof activityCode === 'string'
  && activityCode.substr(0, 5) === 'UPRT_'
    ? CATEGORY_UNUSUAL_ATTITUDE_RECOVERY
    : false
);

/**
 * List of activity helpers, ordered from the more specific ones to the more
 * general ones.
 */
const categoryHelpers = [
  isAutorotationLandingActivity,
  isSlopeActivity,
  isQuickstopActivity,
  isLowAirspeedActivity,
  isAutorotationActivity,
  isEngineStartActivity,
  isRejectedTakeoff,
  isTakeoffOEI,
  isRejectedLanding,
  isSteepTurn,
  isBankTurn,
  isUnusualAttitudeRecovery,
  isGoAroundActivity,
  isMissedApproachActivity,
  isTaxiActivity,
  isApproachToStallActivity,
  isTakeoffActivity,
  isLandingActivity,
  isGroundActivity,
  isApproachActivity,
  isAirActivity,
  isUprtActivity,
  isStallActivity,
  isCockpitPreparation,
  isDeparture,
];

/**
 * Determines the category based on the activity code and active malfunctions.
 */
export const getVisualizationCategory = (activityCode: string): VisualizationCategory => {
  if (!activityCode) {
    return CATEGORY_NONE;
  }

  // Loop through all category helpers, and use the first category we can find.
  let result: VisualizationCategory | false;
  for (let i = 0; i < categoryHelpers.length; i++) {
    result = categoryHelpers[i](activityCode);

    if (result) {
      return result;
    }
  }

  return CATEGORY_NONE;
};
