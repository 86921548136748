import * as Plotly from 'plotly.js';
import { FrameType } from '../../../frames/constants';

const getFlapsPlot = (frames: FrameType[], numPlots: number): Plotly.Data => ({
  line: {
    color: '#8ab674',
    width: 3,
  },
  mode: 'lines',
  name: 'flapLeverPos',
  type: 'scatter',
  xaxis: `x${numPlots + 2}`,
  yaxis: `y${numPlots + 2}`,
  y: [],
  showlegend: false,
});

export default getFlapsPlot;
