import SessionRunScreen from './Screen';

import { SessionStore } from '../../store';
import { ActivitiesStore } from '../../../activities/store';
import { SimulatorStatus } from '../../../training/types';

export type LiveTrainingProps = SessionStore & ActivitiesStore & {
  onComponentMounted: (sessionId: number, runId: number) => void,
  onUpdateActiveView: Function,
  onUpdateFlightPhase: Function,
  onEndRun: (id: number, callback?: Function) => void,
  onDone: Function,
  simulatorStatus: SimulatorStatus | null
};

export default SessionRunScreen;
