import React, { FC } from 'react';
import styled from 'styled-components';
import { ROLL_HEIGHT, ROLL_WIDTH } from '../../constants';

type RollGroupProps = {
    x: number,
    y: number,
    label: string,
    value: string
    left: {
        value: number,
        color?:string
    }
    right: {
        value:number,
        color?:string
    }
}
export const RollGroup:FC<RollGroupProps> = ({
  x, y,
  left,
  right, value, label,
}) => (
  <foreignObject x={x} y={y} width={ROLL_WIDTH} height={ROLL_HEIGHT}>
    <RollWrapper>
      <RollLabel>            {label}        </RollLabel>
      <RollInfo>
        <RollNearValue color={left.color}>{left.value}</RollNearValue>
        <RollRect color={left.color} />
        <RollValue>{value} </RollValue>
        <RollRect color={right.color} />
        <RollNearValue color={right.color}>{right.value}</RollNearValue>
      </RollInfo>
    </RollWrapper>
  </foreignObject>
);

export default RollGroup;

const RollWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    font-family: Arial, Arial,serif;
    line-height: 1;
    padding-top: 44px;
`;

const RollLabel = styled.div`
    display: flex;
    color: white;
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 4px;
`;

const RollInfo = styled.div`
    display: flex;
    align-items: center;
`;
const RollNearValue = styled.div<{color?:string}>`
    display: flex;
    flex-direction: column;
    font-size: 22px;
    margin: 0 4px;
    color: ${props => props.color || 'white'};
`;

const RollRect = styled.div<{color?:string}>`
    width: 30px;
    height: 20px;
    margin: 0 auto;
    background: ${props => props.color || 'white'};
`;
const RollValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 50px;
    border: 2px solid white;
    border-radius: 20px;
    font-size: 26px;
`;
