import reporter from '../utils/reporter';

const logError = (error: Error): void => {
  // Report error
  if (error.toString().match(/Request failed with status code 4[0-9][0-9]/)) {
    return reporter.error(error);
  }

  // Log error to the console.
  return reporter.log(error);
};

export default logError;
