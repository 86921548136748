import { Map, Record, RecordOf } from 'immutable';

export type RecommendationsType = {
  id: number,
  recommendations: string[],
  instructorApproved: boolean,
};

type ImmutableRecommendationsType = Map<string, number | string[] | boolean>;

export type RecommendationsStore = {
  activityRecommendations: ImmutableRecommendationsType,
};

export type ImmutableRecommendationsStore = RecordOf<RecommendationsStore>;

export const createRecommendationsStore = Record<RecommendationsStore>({
  activityRecommendations: Map(),
});

const defaultState: ImmutableRecommendationsStore = createRecommendationsStore();

export default defaultState;
