import { Record, Map, List, RecordOf } from 'immutable';
import { CompetencyScoresByRun, CompetencyInstructorScores } from './types';
import { NormType } from '../utils/visualization/constants';
import * as gradingConstants from '../summary/constants';

export interface CompetencyStore {
  spiderGraph: gradingConstants.SpiderGraphCompetencyMapType;
  scoresByRun: CompetencyScoresByRun;
  instructorScores: CompetencyInstructorScores;
  drilldown: List<NormType>;
  isLoading: boolean;
  isLoadingCompetency: boolean;
}

export type ImmutableCompetencyStore = RecordOf<CompetencyStore>;

export const createCompetencyStore = Record<CompetencyStore>({
  spiderGraph: Map([
    ['afmA', null],
    ['afmM', null],
    ['aop', null],
    ['com', null],
    ['kno', null],
    ['ltw', null],
    ['psd', null],
    ['saw', null],
    ['wlm', null],
  ]),
  scoresByRun: Map(),
  instructorScores: Map(),
  drilldown: List(),
  isLoading: false,
  isLoadingCompetency: false,
});

const defaultState: ImmutableCompetencyStore = createCompetencyStore();

export default defaultState;
