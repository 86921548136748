import getAirPlotOptions from './air';
import { annotationTR } from '../annotations';
import { getEngineParam } from '../utils';
import { PlotlyHandlerType } from '../types';

const getTaxiPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  const plotOptions = getAirPlotOptions(frames, markers, activityCode, metaData, [
    'gndSpeed',
    ['brakeTempRatioL', 'brakeTempRatioR'],
    ['brakePedForcePilotL', 'brakePedForcePilotR'],
    getEngineParam(metaData.aircraftDesignator, metaData.aircraftType),
  ]);

  if (metaData.trainingCenter !== 'NLA') {
    annotationTR(frames, plotOptions, metaData.aircraftDesignator);
  }

  return plotOptions;
};

export default getTaxiPlotOptions;
