import * as React from 'react';
import { InputField } from '@/styles/FormStyles';

interface PickerProps {
    onChange?: (event: React.ChangeEvent) => void;
    value?: string;
}
export const DatePicker = (props: PickerProps) => {
  const { value = '', onChange, ...inputProps } = props;

  // Control the date value.
  const [date, setDate] = React.useState(value);

  // onChange handler.
  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setDate(target.value);

    if (typeof props.onChange === 'function') {
      props.onChange(event);
    }
  };

  return (
    <InputField
      {...inputProps}
      type="date"
      value={date}
      onChange={handleChange}
    />
  );
};

export default DatePicker;
