import { TYPE_PLOTLY, VisualizationHandlerType } from '../constants';
import getChecklistOptions from '../../plotly/beforeStartingEngineProceduresChecklist';
import getPlotlyOptions from '../../plotly/plotOptions/air';
import { ETrainingCenter } from '../../../types/enums.types';

// @ts-ignore
const getEngineStartVisualizations: VisualizationHandlerType<void> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const visualizations = [];

  if (metaData.trainingCenter === ETrainingCenter.SIMCOM) {
    visualizations.push({
      type: TYPE_PLOTLY,
      data: getChecklistOptions(data.frames),
    });
  }

  visualizations.push({
    type: TYPE_PLOTLY,
    data: getPlotlyOptions(data.frames, markers, activity, metaData),
  });

  return visualizations;
};

export default getEngineStartVisualizations;
