/**
 * Wrapper for the contents of a screen.
 */
import styled from 'styled-components';

const Body = styled.div`
  margin-top: ${({ theme }) => theme.headerHeight};
  padding-bottom: 2rem;
`;

export default Body;
