export const UPDATE_ACTIVITY_RECOMMENDATIONS = 'Received training recommendations for activity.';
export const UPDATE_SESSION_RECOMMENDATIONS = 'Received training recommendations for session.';
export const SUBMIT_INSTRUCTOR_FEEDBACK = 'Instructor has submitted feedback.';

export const updateActivityRecommendations = (activityRecommendations: Object) => ({
  type: UPDATE_ACTIVITY_RECOMMENDATIONS,
  payload: { activityRecommendations },
} as const);

export const submitInstructorFeedback = (type: string, id: number, instructorFeedback: boolean) => ({
  type: SUBMIT_INSTRUCTOR_FEEDBACK,
  payload: { type, id, instructorFeedback },
} as const);

type UpdateActivityRecommendationsAction = ReturnType<typeof updateActivityRecommendations>;
export type SubmitInstructorFeedbackAction = ReturnType<typeof submitInstructorFeedback>;

export type RecommendationsAction =
  | UpdateActivityRecommendationsAction
  | SubmitInstructorFeedbackAction;
