import { createGlobalStyle } from 'styled-components';
import './fonts.css';

export const GlobalStyles = createGlobalStyle`
  body, html {
    background-color: black;
    color: white;
    font: normal 500 18px/1.613 'Roboto', sans-serif;
    min-height: 100vh;
    position: relative;
  }

  a, a:active, a:hover {
    outline: none;
    text-decoration: none;
  }

  button {
    outline: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
  }

  /**
   * TODO: review how costly this is on the client-side (in terms of rendering)
   */
  * {
    -ms-overflow-style: none;
    /* user-select: none; */
  }
`;

export default createGlobalStyle;
