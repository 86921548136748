import * as constants from './constants';
import { CompetencyScores } from '../competency/types';

export const GRADING_SCREEN_LOADED = 'Grading screen loaded.';
export const GRADING_SHEETS_UPDATED = 'Received updated grading sheets.';
export const LOADING_COMPETENCY_GRAPH = 'Displaying session competency graph...';
export const LOADING_COMPETENCY_TABLE = 'Displaying session competency table...';
export const COMPETENCY_GRAPH_UPDATED = 'Received session competency data.';
export const UPDATE_INSTRUCTOR_GRADE = 'Instructor has updated a grade.';
export const UPDATE_INSTRUCTOR_DEMO = 'Instructor has selected "Demo".';
export const GRADE_LEVEL_UPDATED = 'Received updated grade.';
export const RETRIEVE_FILTERED_ACTIVITIES = 'Retrieving filtered activities.';
export const UPDATE_FILTERED_ACTIVITIES = 'Updating filtered activities.';
export const CLEAR_TRAINEE_PROFILE = 'Clearing trainee profile data...';

export const gradingScreenLoaded = (sessionId: number) => ({
  type: GRADING_SCREEN_LOADED,
  payload: { sessionId, fetchGradingSheet: true },
} as const);

export const gradingSheetsUpdated = (gradingSheets: constants.GradingSheetType[]) => ({
  type: GRADING_SHEETS_UPDATED,
  payload: { gradingSheets },
} as const);

export const clearTraineeProfile = () => ({
  type: CLEAR_TRAINEE_PROFILE,
  payload: null,
} as const);

export const loadingCompetencyGraph = (sessionRunId: number) => ({
  type: LOADING_COMPETENCY_GRAPH,
  payload: { sessionRunId },
} as const);

export const updateSpiderGraphCompetency = (competency: constants.SpiderGraphCompetencyType) => ({
  type: COMPETENCY_GRAPH_UPDATED,
  payload: { ...competency },
} as const);

export const loadingCompetencyTable = (sessionRunId: number) => ({
  type: LOADING_COMPETENCY_TABLE,
  payload: { sessionRunId },
} as const);

export const updateInstructorGrade = (
  grade: constants.GradeType,
  level: constants.GradeLevelType,
  runId: number,
  type?: constants.GradeableType | null,
) => ({
  type: UPDATE_INSTRUCTOR_GRADE,
  payload: { grade, level, runId, type },
} as const);

export const gradeLevelUpdated = (
  grade: constants.GradeType,
  spiderGraphCompetency: CompetencyScores,
  tableCompetency: constants.TableCompetencyType,
  runId: number,
) => ({
  type: GRADE_LEVEL_UPDATED,
  payload: { grade, runId, spiderGraphCompetency, tableCompetency },
} as const);

export const loadingFilteredActivities = (
  runId: number,
  flightPhase: string | null,
  condition: string | null,
) => ({
  type: RETRIEVE_FILTERED_ACTIVITIES,
  payload: { runId, flightPhase, condition },
} as const);

export const updateFilteredActivities = (
  activities: Object,
) => ({
  type: UPDATE_FILTERED_ACTIVITIES,
  payload: { activities },
} as const);

export type GradingSheetsUpdatedAction = ReturnType<typeof gradingSheetsUpdated>;
export type GradingScreenAction = ReturnType<typeof gradingScreenLoaded>;
export type ClearTraineeProfileAction = ReturnType<typeof clearTraineeProfile>;
export type LoadingCompetencyGraphAction = ReturnType<typeof loadingCompetencyGraph>;
export type UpdateSpiderGraphCompetencyAction = ReturnType<typeof updateSpiderGraphCompetency>;
export type LoadingCompetencyTableAction = ReturnType<typeof loadingCompetencyTable>;
export type UpdateInstructorGradeAction = ReturnType<typeof updateInstructorGrade>;
export type GradeLevelUpdatedAction = ReturnType<typeof gradeLevelUpdated>;
export type LoadingFilteredActivitiesAction = ReturnType<typeof loadingFilteredActivities>;
export type UpdateFilteredActivitiesAction = ReturnType<typeof updateFilteredActivities>;

export type GradingAction =
    | GradingScreenAction
    | GradingSheetsUpdatedAction
    | ClearTraineeProfileAction
    | LoadingCompetencyGraphAction
    | UpdateSpiderGraphCompetencyAction
    | LoadingCompetencyTableAction
    | UpdateInstructorGradeAction
    | GradeLevelUpdatedAction
    | LoadingFilteredActivitiesAction
    | UpdateFilteredActivitiesAction;
