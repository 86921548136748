import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { media } from '@/theme/breakepoints';
import { formatDate } from '@/utils/time';
import { EDateFormat } from '@/types/global.types';
import { mediumGrey } from '@/theme/palette';
import { useAppDispatch, useAppSelector } from '@/store';
import { Tabs, TabWrapper } from '@/components/Tabs';
import { radarPlotMounted } from '@/competency/actions';
import { CompetencyScore } from '@/summary/components/CompetencyScore';
import { CompetencyGraph } from '@/components/CompetencyGraph';
import { SessionRunType } from '@/session/constants';
import { FlightManeuvers } from '@/summary/components/FlightManeuvers';
import { Header } from '@/components/Header';
import Body from '@/ui/Body';
import { BreadcrumbListType, Breadcrumbs } from '@/components/Breadcrumbs';
import { home, schedule as scheduleRoute, sessionOverview } from '@/utils/routes';
import TraineeTabs from './TraineeTabs';
import PageHeader from '../../../ui/PageHeader';
import Loading from '../../../ui/Loading';
import ContentContainer from '../../../ui/ContentContainer';
import * as actions from '../../actions';
import * as activityActions from '../../../activities/actions';
import * as sessionActions from '../../../session/actions';

export const GradingScreen = () => {
  const params = useParams<{ id?: string }>();
  const [selectedRunId, setSelectedRunId] = React.useState<number | null>(null);
  const navigate = useNavigate();
  const sessionId = Number(params.id);
  const dispatch = useAppDispatch();
  const competencySelector = useAppSelector(state => state.competency);
  const summarySelector = useAppSelector(state => state.summary.toJS());
  const sessionSelector = useAppSelector(state => state.session.toJS());
  const activitiesSelector = useAppSelector(state => state.activities.toJS());
  const sessionListSelector = useAppSelector(state => state.sessionList.toJS());
  const {
    gradingSheets,
    activityGrades,
  } = summarySelector;

  const { runs } = sessionSelector;
  const fullSession = sessionSelector.currentSession;
  const { currentRunId } = activitiesSelector;
  const { sessions, isLoading } = sessionListSelector;

  const onComponentMounted = useCallback((id: number) => {
    dispatch(
      actions.gradingScreenLoaded(id),
    );
  }, [sessionId, params]);

  const onSelectSessionRun = (id: number) => dispatch(actions.loadingCompetencyGraph(id));
  const onDone = () => {
    dispatch(sessionActions.updateRuns([]));
    dispatch(activityActions.clearActivities());
  };

  const onUpdateRunId = (runId: number) => dispatch(activityActions.updateRunId(runId));

  const onClearTraineeProfile = () => dispatch(actions.clearTraineeProfile());

  React.useEffect(() => {
    onComponentMounted(sessionId);
  }, [onComponentMounted, sessionId, params]);
  const runIds = runs.map((run: SessionRunType) => run.id);
  const currentRun: SessionRunType | undefined = runs.find((run: SessionRunType) => run.id && run.id === selectedRunId);

  const gradingSheet = gradingSheets && selectedRunId && gradingSheets[selectedRunId]
    ? gradingSheets[selectedRunId]
    : null;

  const handleSelectRun = useCallback((runId: number) => {
    onUpdateRunId(runId);
    onClearTraineeProfile();
    // Update selected run ID in state and
    // call the session run handler.
    setSelectedRunId(runId);
    onSelectSessionRun(runId);
    dispatch(radarPlotMounted(runId));
  }, [selectedRunId, currentRunId, runs]);

  useEffect(() => () => {
    onClearTraineeProfile();
    onDone();
  }, []);

  // If no run is selected, select the first available one.
  useEffect(() => {
    if (runs.length <= 0) return;
    if (currentRunId && !selectedRunId) {
      const isCurrentRunExist = runs.some((i: SessionRunType) => i?.id === currentRunId);
      handleSelectRun(!isCurrentRunExist ? runs[0].id : currentRunId);
      dispatch(radarPlotMounted(!isCurrentRunExist ? runs[0].id : currentRunId));
    } else if (!selectedRunId && runs.length > 0) {
      handleSelectRun(runs[0].id);
      dispatch(radarPlotMounted(runs[0].id));
    }
  }, [selectedRunId, currentRunId, runs]);

  if (!gradingSheet || !gradingSheet.sections || gradingSheet.sections.length < 1 || !currentRun || isLoading) {
    return (
      <Loading />
    );
  }

  const sessionIndex = sessions.findIndex(({ id }: any) => id === sessionId);
  const session = sessions?.[sessionIndex];

  const aircraftType = session?.equipment?.aircraftDetails?.aircraft?.at(0)?.name;
  const startDate = formatDate(session?.startedAt, EDateFormat.FullDate);

  const scores = competencySelector.getIn(['scoresByRun', selectedRunId])?.toJS();
  const instructorScores = competencySelector.getIn(['instructorScores', selectedRunId])?.toJS() || {};

  const breadcrumbs: BreadcrumbListType = [
    {
      link: home(),
      title: 'Home',
    },
    {
      link: scheduleRoute(),
      title: 'Schedule',
    },
    {
      link: sessionOverview(sessionId),
      title: fullSession.shortName,
    },
    {
      title: 'Training Summary',
    },
  ];

  return (
    <>
      <Header />
      <Body>
        <ContentContainer>
          <Breadcrumbs crumbs={breadcrumbs} />

          <CourseDetails>
            <TraineeWrapper>
              <TraineeTabs
                onSelectRun={handleSelectRun}
                runIds={runIds}
                selectedRunId={selectedRunId}
                pilotFlying={session.trainees.pilotFlying}
                pilotMonitoring={session.trainees.pilotMonitoring}
              />
            </TraineeWrapper>
          </CourseDetails>
          <Tabs>
            <TabWrapper tabName="Competency summary">
              <>
                <CompetencyGraph
                  scores={scores}
                  instructorScores={instructorScores}
                />
                {selectedRunId && (
                  <CompetencyScore
                    sessionId={sessionId}
                    runId={selectedRunId}
                    scores={scores}
                    instructorScores={instructorScores}
                  />
                )}
              </>
            </TabWrapper>
            <TabWrapper tabName="Flight Maneuvers">
              <FlightManeuvers
                sessionId={sessionId}
                run={currentRun}
                activityGrades={activityGrades}
                session={session}
              />
            </TabWrapper>
          </Tabs>
          <Description>
            <DescriptionList>
              <DescriptionItem>
                <DescriptionItemLabel>Lesson Plan:</DescriptionItemLabel>
                <DescriptionItemLabel $isWhite>{fullSession.shortName}</DescriptionItemLabel>
              </DescriptionItem>
              <DescriptionItem>
                <DescriptionItemLabel>Course:</DescriptionItemLabel>
                <DescriptionItemLabel $isWhite>{fullSession.courseName}</DescriptionItemLabel>
              </DescriptionItem>
              <DescriptionItem>
                <DescriptionItemLabel>Aircraft Type:</DescriptionItemLabel>
                <DescriptionItemLabel $isWhite>{aircraftType}</DescriptionItemLabel>
              </DescriptionItem>
              <DescriptionItem>
                <DescriptionItemLabel>Date:</DescriptionItemLabel>
                <DescriptionItemLabel $isWhite>{startDate}</DescriptionItemLabel>
              </DescriptionItem>
            </DescriptionList>
          </Description>
        </ContentContainer>
      </Body>
    </>
  );
};

const TraineeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-inline: 12px;
  box-sizing: border-box;
`;

const CourseDetails = styled.div`
  line-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${mediumGrey.string()};
  padding: 12px 0;
  border-radius: 8px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 12px;
  padding-block: 12px;
  margin-top: 24px;
  box-sizing: border-box;
  background-color: ${mediumGrey.string()};
  border-radius: 8px;
  margin-bottom: 24px;
`;
const DescriptionList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const DescriptionItemLabel = styled.div<{ $isWhite?: boolean }>`
  display: inline-flex;
  color: ${props => (props.$isWhite ? props.theme.white.toString() : props.theme.lightestGrey.toString())};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
`;

const DescriptionItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: calc(50% - 4px);
  gap: 4px;


  ${media.xs} {
    flex-basis: calc(100% - 4px);
  }
`;

export default GradingScreen;
