import { Record, RecordOf } from 'immutable';

export interface InstructorInfoStore {
  id: number | null;
}

export type ImmutableInstructorInfoStore = RecordOf<InstructorInfoStore>;

const createInstructorInfoStore = Record<InstructorInfoStore>({
  id: null,
});

const defaultState: ImmutableInstructorInfoStore = createInstructorInfoStore();

export default defaultState;
