import {
  DepartureInsightType,
  TYPE_DEPARTURE,
  VisualizationDefinitionType,
  VisualizationHandlerType,
} from '../constants';

export type DepartureVisualizationType = VisualizationHandlerType<
  DepartureInsightType
>;

const getDepartureVisualizations: DepartureVisualizationType = (
  _,
  data,
) => {
  const visualizations = [];

  if (data.insight && data.insight.annotation) {
    visualizations.push({
      type: TYPE_DEPARTURE,
      data: data.insight,
    });
  }

  return visualizations as VisualizationDefinitionType[];
};

export default getDepartureVisualizations;
