import * as React from 'react';
import { FC, useMemo } from 'react';
import 'leaflet/dist/leaflet.css';
// @ts-ignore
import { ReactComponent as WayPointIcon } from '@/assets/icons/WayPointIcon.svg';
import { JSXMarker } from '@/components/Map/components/JSXMarker';
import { BoundaryLoad, getBoundaries } from '@/utils/map';
import { MapPoint } from '@/types/map.types';
import { GeoPathCurve } from '@/components/Map/components/GeoPathCurve';
import { AirPortSvg } from '@/components/Map/components/AirPortSvg';
import { lightGrey, primary, secondary, secondGradientEnd, secondGradientStart, tertiary, white } from '@/theme/palette';
import styled from 'styled-components';
import { DepartureInsightType } from '@/utils/visualization/constants';
import { WaypointAltAnnotation } from '@/utils/plotly/annotations/types';
import { Accordion } from '@/components/Accordion';
import { FrameType } from '@/frames/constants';
import { isEmpty, orderBy } from 'lodash';
import { MapWrapper } from '../Map/components/MapWrapper';

interface DepartureViewProps {
  data: DepartureInsightType
  frames: FrameType[]
}

const amberColor = '#FFBF00';

const getAltitude = ({
  minAlt, maxAlt,
}: {
  minAlt?: WaypointAltAnnotation,
  maxAlt?: WaypointAltAnnotation
}) => {
  const alts = [minAlt, maxAlt].find(value => value?.color === 'amber');
  const alt = alts || maxAlt;
  return {
    isOutNorm: alt?.color === 'amber',
    value: alt?.altMsl.toFixed(0),
    unit: alt?.altMslUnit,
    threshold: minAlt?.threshold?.toString() ?? '',
  };
};

type WaypointDescriptionType = {
  name:string,
  isOutNorm?: boolean
  value?: number | string,
  unit?: string
}

type WaypointType = {
  name?: string,
  lat: number,
  lon: number,
  isOutNorm?: boolean,
  dangerAltitude?: number | string,
  description?: WaypointDescriptionType[]
}

const departureData = (data: DepartureInsightType) => ({
  waypoints: (data?.annotation ?? [])?.map<WaypointType>(val => {
    const isOutOfNorm = Object.values(val ?? {}).map(i => i?.[1]?.color).some(item => item === 'amber');
    const altitude = getAltitude({
      minAlt: val?.minAltAtWaypoint?.[1],
      maxAlt: val?.maxAltAtWaypoint?.[1],
    });

    return ({
      name: val?.lateralDistFromWaypoint?.[1]?.waypointName,
      lat: val?.lateralDistFromWaypoint?.[1]?.waypointLat!,
      lon: val?.lateralDistFromWaypoint?.[1]?.waypointLon!,
      isOutNorm: isOutOfNorm,
      dangerAltitude: altitude.threshold,
      description: [
        {
          name: 'Airspeed',
          isOutNorm: val?.transitionMaxCas[1].color === 'amber',
          value: val?.transitionMaxCas?.[1]?.cas.toFixed(0),
          unit: val?.transitionMaxCas?.[1]?.casUnit,
        },
        {
          name: 'Altitude',
          isOutNorm: altitude.isOutNorm,
          value: altitude.value,
          unit: altitude.unit,
        },
        {
          name: 'Ground Track',
          isOutNorm: val?.transitionGndTrackDeviation?.[1]?.color === 'amber',
          value: val?.transitionGndTrackDeviation?.[1]?.gndTrackAtWaypoint.toFixed(2),
          unit: val?.transitionGndTrackDeviation?.[1]?.gndTrackAtWaypointUnit,
        },
        {
          name: 'Lateral Distance',
          isOutNorm: val?.lateralDistFromWaypoint?.[1]?.color === 'amber',
          value: val?.lateralDistFromWaypoint?.[1]?.threshold?.toFixed(2),
          unit: val?.lateralDistFromWaypoint?.[1]?.thresholdUnit,
        },
      ],
    });
  }).filter(i => !!i.lat || !!i.lon),
  runway: {
    name: data?.departure?.runway?.name,
    lat: data?.departure?.runway?.latitude,
    lon: data?.departure?.runway?.longitude,
    trueHeading: data?.departure?.runway?.trueHeading ?? 0,
  },
  sid: {
    name: data?.departure?.sid?.name,
    waypoints: data?.departure?.sid?.waypoints,
    transitions: data?.departure?.sid?.transitions ?? [],
  },
});

export const DepartureView: FC<DepartureViewProps> = ({ data, frames }) => {
  const departure = departureData(data);
  const runwayPoint: MapPoint | undefined = !!departure.runway.lat || !!departure.runway.lon
    ? [departure.runway.lat, departure.runway.lon]
    : undefined;

  const points = frames.map<MapPoint>(item => ([item.data.lat, item.data.lon]));
  const transitionPoints = orderBy(departure.sid.transitions ?? [], 'sequenceNumber')
    .map<MapPoint>(({ fix }) => [fix.latitude!, fix.longitude!]);

  const pointsWithRunwayPoint = [
    ...(runwayPoint ? [runwayPoint] : []),
    ...points,
  ];

  const transitionWithRunwayPoint = [
    ...(runwayPoint ? [runwayPoint] : []),
    ...transitionPoints,
  ];

  const uniqueTransitionsWaypoints = departure.sid.transitions
    .filter(item => departure.waypoints?.findIndex(waypoint => waypoint.name === item.fix.name) === -1)
    .map< WaypointType>(waypoint => ({
      name: waypoint.fix.name,
      lat: waypoint.fix.latitude,
      lon: waypoint.fix.longitude,
    }));

  // TODO: Uncomment after demo
  // const waypoints = [...departure.waypoints, ...uniqueTransitionsWaypoints];
  const waypoints = [...departure.waypoints];

  const bounds = useMemo(() => getBoundaries({
    points: [...transitionWithRunwayPoint, ...points],
  }), [transitionWithRunwayPoint, points]);

  if (!bounds || points.length <= 0) return null;

  return (
    <div>
      <Title>
        {departure.sid.name}
      </Title>
      <MapWrapper height="500px" width="100%" minZoom={7}>
        <BoundaryLoad markerBounds={bounds} />
        {waypoints.map(point => {
          if (!point.name) return null;
          return (
            <JSXMarker
              key={point.name}
              position={[point.lat, point.lon]}
              iconOptions={{
                className: 'jsx-marker',
                iconSize: [0, 0],
              }}
              tooltip={!isEmpty(point.description) ? (
                <TooltipMarker>
                  {point.description!.map(item => (
                    <TooltipMarkerText key={`tooltip-marker-text-${item.name}`} $isOutNorm={item.isOutNorm}>
                      {item.name}: {item.value} {item.unit}
                    </TooltipMarkerText>
                  ))}
                </TooltipMarker>
              ) : undefined}
            >
              <InfoMarker $withDescription={!isEmpty(point.description)}>
                <ImagerWrapper $isOutNorm={point.isOutNorm}>
                  <WayPointIcon />
                </ImagerWrapper>
                <InfoMarkerText $isOutNorm={point.isOutNorm}>
                  {point.name}
                  <AltitudeText $isOutNorm={point.isOutNorm}>
                    {point?.dangerAltitude}
                  </AltitudeText>
                </InfoMarkerText>
              </InfoMarker>
            </JSXMarker>
          );
        })}
        <AirPortSvg direction={departure.runway.trueHeading} center={runwayPoint} />
        <GeoPathCurve
          id="points"
          points={pointsWithRunwayPoint}
          gradient={{
            start: secondGradientStart.toString(),
            end: secondGradientEnd.toString(),
          }}
        />
        {/* TODO: UNCOMMENT after demo */}
        {/* <GeoPathCurve
          id="transitionPoints"
          points={transitionWithRunwayPoint}
          gradient={{
            start: primary.toString(),
            end: tertiary.toString(),
          }}
        /> */}
      </MapWrapper>
      <Points>
        {departure.waypoints.map(point => {
          if (isEmpty(point.description)) return null;
          return (
            <Accordion
              key={point.name}
              title={(
                <AccordionTitle $isOutNorm={point.isOutNorm}>
                  {point.name}
                  <AltitudeText $isOutNorm={point.isOutNorm}>
                    {point.dangerAltitude}
                  </AltitudeText>
                </AccordionTitle>
              )}
            >
              <PointWrapper>
                {point.description?.map(item => (
                  <Point key={`accordion-point-${item.name}`} $isOutNorm={item.isOutNorm}>
                    <PointTitle>
                      {item.name}
                    </PointTitle>
                    <PointValue>
                      {item.value} {item.unit}
                    </PointValue>
                  </Point>
                ))}
              </PointWrapper>
            </Accordion>
          );
        })}
      </Points>
    </div>
  );
};

const Title = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
`;
const InfoMarker = styled.div<{$withDescription?: boolean}>`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  gap: 4px;
  opacity: ${props => (props.$withDescription ? 1 : 0.7)};
  cursor: ${props => (props.$withDescription ? 'pointer' : 'default')};
  transform: translateX(-6px);
`;
const Points = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-bottom: 48px;
`;
const PointWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-block: 10px;
  padding-inline: 16px;
`;

const Point = styled.div<{ $isOutNorm?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: ${props => (props.$isOutNorm ? amberColor : white.toString())};
  white-space: nowrap;
  overflow: hidden;
`;

const PointTitle = styled.div`
  border-bottom: 1px solid ${lightGrey.string()};
  padding-inline: 4px;
  color: inherit;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

const PointValue = styled.div`
  padding-inline: 4px;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;

`;

const AccordionTitle = styled.div<{ $isOutNorm?: boolean }>`
  display: flex;
  color: ${props => (props.$isOutNorm ? amberColor : white.toString())};
  gap: 4px;
`;

const InfoMarkerText = styled.div<{ $isOutNorm?: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${props => (props.$isOutNorm ? amberColor : white.toString())};
  line-height: 1.2;
`;
const AltitudeText = styled.span<{ $isOutNorm?: boolean }>`
  text-align: center;
  color: ${props => (props.$isOutNorm ? amberColor : secondary.toString())};
  text-decoration: underline;
`;

const TooltipMarker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TooltipMarkerText = styled.div<{ $isOutNorm?: boolean }>`
  color: ${props => (props.$isOutNorm ? amberColor : white.toString())};
`;

const ImagerWrapper = styled.div<{ $isOutNorm?: boolean }>`
  display: flex;
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    path {
      stroke: ${props => (props.$isOutNorm ? amberColor : white.toString())};
    }
  }
`;

export default DepartureView;
