import * as React from 'react';
import styled from 'styled-components';

import { GradeLevelType } from '../constants';
import { NoteType } from '../../activities/types';

import { useGradingScheme, useTrainingCenter } from '../hooks';
import GradeButton from './GradeButton';
import { getGradeValues } from '../utils';
import { Button } from '../../ui/Button';
import DemoButton from '../../activities/components/DemoButton';
import { NotesTextArea } from '../../ui/Notes';
import { ETrainingCenter } from '../../types/enums.types';

type Props = {
  gradeValue: GradeLevelType | null,
  activityId: number | null,
  type: string,
  isDemo: boolean,
  isLiveGrade: boolean | null,
  onSelectGrade: (level: GradeLevelType) => void,
  onShowGradeContext: (() => void | null) | null,
  onShowCompetencyDrilldown: (() => void | null) | null,
  onSelectDemo: Function,
  onSaveNote?: Function,
  note?: NoteType | null,
};

const GradeSelector = (props: Props) => {
  const gradingScheme = useGradingScheme();
  const trainingCenter = useTrainingCenter();
  const gradeValues = getGradeValues(gradingScheme);

  return (
    <Wrapper $live={props.isLiveGrade}>
      <ButtonWrapper $competencyDrilldown={typeof props.onShowCompetencyDrilldown === 'function'}>
        {trainingCenter === ETrainingCenter.EPST && props.type === 'activity' && (
          <DemoButton
            isDemo={props.isDemo}
            isLiveGrade={props.isLiveGrade}
            activityId={props.activityId}
            onSelectDemo={props.onSelectDemo}
          />
        )}
        {gradeValues.map(gradeValue => {
          const onClick = () => gradeValue && props.onSelectGrade(gradeValue);
          const isSelected = props.gradeValue !== null && props.gradeValue === gradeValue;

          return (
            <GradeButton
              key={gradeValue}
              gradeValue={gradeValue}
              type={props.type}
              isLiveGrade={props.isLiveGrade}
              isSelected={isSelected}
              onClick={onClick}
            />
          );
        })}
      </ButtonWrapper>

      {props.note !== undefined && props.onSaveNote && (
        <NotesTextArea onSaveNote={props.onSaveNote} note={props.note} />
      )}

      {typeof props.onShowGradeContext === 'function' && (
      <Button onClick={props.onShowGradeContext} width="233px" $margin="0">
        VIEW CONTEXT
      </Button>
      )}

      {typeof props.onShowCompetencyDrilldown === 'function' && (
        <Button onClick={props.onShowCompetencyDrilldown} width="233px" $margin="0">
          SEE DETAILS
        </Button>
      )}
    </Wrapper>
  );
};

GradeSelector.defaultProps = {
  onSaveNote: null,
  note: null,
};

export default GradeSelector;

const Wrapper = styled.div<{ $live: boolean | null }>`
  width: ${props => (props.$live ? '100%' : '233px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonWrapper = styled.div<{ $competencyDrilldown: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-around;

  margin-bottom: ${props => (props.$competencyDrilldown ? '15px' : '')}
`;
