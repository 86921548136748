import createSagaMiddleware from 'redux-saga';
import { configureStore, isImmutableDefault, isPlain } from '@reduxjs/toolkit';
import { isImmutable } from 'immutable';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import config from '../config';
import reducers from '../reducers';
import registerSagasWith from '../registerSagas';
import { GeneralStoreType } from './constants';

// Redux enhancers

// Integrate with Redux Dev Tools for development builds
// if ((config.isDev || config.debugEnvs.includes(config.deployEnv)) && window.__REDUX_DEVTOOLS_EXTENSION__) {
//   enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
// }

export const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const makeStore = () => {
  const store = configureStore({
    // @ts-ignore
    reducer: reducers,
    // @ts-ignore
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      immutableCheck: {
        isImmutable: (value: any) => isImmutable(value) || isImmutableDefault(value),
      },
      serializableCheck: {
        isSerializable: (value: any) => isImmutable(value) || isPlain(value),
        getEntries: (value: any): any => (isImmutable(value)
          ? value.entries()
          : Object.entries(value)),
      },
    }).concat(middleware),
  });

  // Run all sagas
  registerSagasWith(sagaMiddleware);

  return store;
};

const store = makeStore();

// export type RootState = ReturnType<GeneralStoreType>;
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch; // this is "Dispatch<AnyAction>"
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
