import * as React from 'react';
import styled from 'styled-components';

interface ContainerProps {
  children: React.ReactNode;
}

export const ContainerWrapper = styled.section<ContainerProps>`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 768px) {
        width: 750px;
    }
    @media (min-width: 992px) {
        width: 970px;
    }
    @media (min-width: 1200px) {
        width: 1170px;
    }
  `;

export const Container = ({ children }: ContainerProps) => <ContainerWrapper>{children}</ContainerWrapper>;

interface RowProps {
  children?: React.ReactNode;
  style?: React.CSSProperties
  spacing?: number;
}

export const RowWrapper = styled.div<RowProps>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    row-gap: ${props => (props.spacing ? props.spacing * 8 : 0)}px;
  `;

export const Row = ({ children, ...rest }: RowProps) => <RowWrapper {...rest}>{children}</RowWrapper>;

interface ColumnProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  spacing?: number;
}

export const ColumnWrapper = styled.div<ColumnProps>`
    flex-direction: column;
    flex: inherit;
    min-width: 0;
    margin: -1px;
    gap: ${props => (props.spacing ? props.spacing * 8 : 0)}px;


    @media (min-width: 0px) {
      flex-basis: ${props => (props.xs ? `${(props.xs / 12) * 100}%` : undefined)};
    }

    @media (min-width: 600px) {
      flex-basis: ${props => (props.sm ? `${(props.sm / 12) * 100}%` : undefined)};
    }

    @media (min-width: 900px) {
      flex-basis: ${props => (props.md ? `${(props.md / 12) * 100}%` : undefined)};
    }

    @media (min-width: 1200px) {
      flex-basis: ${props => (props.lg ? `${(props.lg / 12) * 100}%` : undefined)};
    }

    @media (min-width: 1536px) {
      flex-basis: ${props => (props.xl ? `${(props.xl / 12) * 100}%` : undefined)};
    }
  `;

export const Column = ({ children, ...rest }:ColumnProps) => <ColumnWrapper {...rest}>{children}</ColumnWrapper>;
