import { SessionRunType } from '../session/constants';
import { ActivityOptionType, ActivityType, NoteType } from './types';
import { LessonPlanActivityType } from '../schedule/constants';

export const UPDATE_CURRENT_RUN = 'Received new run ID.';
export const UPDATE_CURRENT_ACTIVITY = 'Received new activity ID.';
export const UPDATE_ACTIVITIES = 'Received meta data for activities.';
export const CLEAR_ACTIVITIES = 'Clearing activity meta data.';
export const LAUNCH_ACTIVITY = 'Launching activity...';
export const LAUNCH_NEXT_ACTIVITY = 'Launching next activity...';
export const REPEAT_ACTIVITY = 'Repeating activity...';
export const EDIT_ACTIVITY = 'Editing activity...';
export const UPDATE_EDITED_ACTIVITY = 'Received edited activity.';
export const UPDATE_REPEATED_ACTIVITY = 'Received ID for repeated activity.';
export const UPDATE_ACTIVITY_DEMO = 'Instructor has selected "Demo".';
export const ACTIVITY_DEMO_UPDATED = 'Activity has been set as "Demo".';
export const ACTIVITY_OPTIONS_UPDATED = 'Received activity options.';
export const UPDATE_RUN_ID = 'Updating current run id...';
export const SAVE_INSTRUCTOR_NOTE = 'Saving instructor note...';
export const UPDATE_INSTRUCTOR_NOTE = 'Received updated instructor note.';

export const updateCurrentRun = (currentRunId: number | null) => ({
  type: UPDATE_CURRENT_RUN,
  payload: { currentRunId },
} as const);

export const updateCurrentActivity = (currentActivityId: number | null) => ({
  type: UPDATE_CURRENT_ACTIVITY,
  payload: { currentActivityId },
} as const);

export const updateActivities = (runs?: SessionRunType[]) => ({
  type: UPDATE_ACTIVITIES,
  payload: { runs },
} as const);

export const clearActivities = () => ({ type: CLEAR_ACTIVITIES } as const);

export const launchActivity = (id: number) => ({
  type: LAUNCH_ACTIVITY,
  payload: { id },
} as const);

export const launchNextActivity = () => ({ type: LAUNCH_NEXT_ACTIVITY } as const);

export const repeatActivity = (id: number, timestamp: string) => ({
  type: REPEAT_ACTIVITY,
  payload: { id, timestamp },
} as const);

export const editActivity = (
  activityDetails: ActivityOptionType,
  currentActivityId: number,
  currentRunId: number,
) => ({
  type: EDIT_ACTIVITY,
  payload: { activityDetails, currentActivityId, currentRunId },
} as const);

export const updateEditedActivity = (
  currentActivityId: number,
  editedActivity: ActivityType,
) => ({
  type: UPDATE_EDITED_ACTIVITY,
  payload: { currentActivityId, editedActivity },
} as const);

export const setActivityDemo = (currentActivityId: number) => ({
  type: UPDATE_ACTIVITY_DEMO,
  payload: { currentActivityId },
} as const);

export const updateActivityDemo = (currentActivityId: number, activityDetails: Object) => ({
  type: ACTIVITY_DEMO_UPDATED,
  payload: { currentActivityId, activityDetails },
} as const);

export const setActivityOptions = (activityOptions: LessonPlanActivityType[]) => ({
  type: ACTIVITY_OPTIONS_UPDATED,
  payload: { activityOptions },
} as const);

export const updateRepeatedActivity = (previousId: number, updatedActivity: ActivityType) => ({
  type: UPDATE_REPEATED_ACTIVITY,
  payload: { previousId, updatedActivity },
} as const);

export const updateRunId = (runId: number) => ({
  type: UPDATE_RUN_ID,
  payload: { runId },
} as const);

export const saveInstructorNote = (activityId: number, text: string, note: NoteType | null) => ({
  type: SAVE_INSTRUCTOR_NOTE,
  payload: { activityId, text, note },
} as const);

export const updateInstructorNote = (activityId: number, note: NoteType) => ({
  type: UPDATE_INSTRUCTOR_NOTE,
  payload: { activityId, note },
} as const);

export type UpdateCurrentRunAction = ReturnType<typeof updateCurrentRun>;
export type UpdateCurrentActivityAction = ReturnType<typeof updateCurrentActivity>;
export type UpdateActivitiesAction = ReturnType<typeof updateActivities>;
export type ClearActivitiesAction = ReturnType<typeof clearActivities>;
export type LaunchActivityAction = ReturnType<typeof launchActivity>;
export type LaunchNextActivityAction = ReturnType<typeof launchNextActivity>;
export type RepeatActivityAction = ReturnType<typeof repeatActivity>;
export type EditActivityAction = ReturnType<typeof editActivity>;
export type UpdateEditedActivityAction = ReturnType<typeof updateEditedActivity>;
export type SetActivityDemoAction = ReturnType<typeof setActivityDemo>;
export type UpdateActivityDemoAction = ReturnType<typeof updateActivityDemo>;
export type SetActivityOptionsAction = ReturnType<typeof setActivityOptions>;
export type UpdateRepeatedActivityAction = ReturnType<typeof updateRepeatedActivity>;
export type UpdateRunIdAction = ReturnType<typeof updateRunId>;
export type SaveInstructorNoteAction = ReturnType<typeof saveInstructorNote>;
export type UpdateInstructorNoteAction = ReturnType<typeof updateInstructorNote>;

export type ActivitiesAction =
    | UpdateCurrentRunAction
    | UpdateCurrentActivityAction
    | UpdateActivitiesAction
    | ClearActivitiesAction
    | LaunchActivityAction
    | LaunchNextActivityAction
    | RepeatActivityAction
    | EditActivityAction
    | UpdateEditedActivityAction
    | SetActivityDemoAction
    | UpdateActivityDemoAction
    | SetActivityOptionsAction
    | UpdateRepeatedActivityAction
    | UpdateRunIdAction
    | SaveInstructorNoteAction
    | UpdateInstructorNoteAction;
