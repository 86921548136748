import getAirPlotOptions from './air';
import {
  annotationGearDown,
  annotationFlaps,
  annotationStickShaker,
  annotationVRef,
  annotationVApp,
} from '../annotations';
import { PlotlyHandlerType } from '../types';

const getApproachPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  const plotOptions = getAirPlotOptions(frames, markers, activityCode, metaData);

  if (metaData.trainingCenter !== 'NLA') {
    annotationGearDown(frames, plotOptions, 'cas');
    annotationFlaps(frames, plotOptions, 'flapLeverPos');
    annotationVRef(frames, plotOptions, 'cas');
    annotationVApp(frames, plotOptions, 'cas');
    annotationStickShaker(frames, plotOptions, 'cas');
  }

  return plotOptions;
};

export default getApproachPlotOptions;
