import {
  TYPE_PLOTLY,
  TYPE_STEEP_TURN_SVG,
  SteepTurnInsightType,
  VisualizationHandlerType,
} from '../constants';
import getPlotlyOptions from '../../plotly/plotOptions/steepTurn';

// @ts-ignore
const getSteepTurnVisualizations: VisualizationHandlerType<SteepTurnInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const visualizations = [];

  if (data.insight && data.insight.payload) {
    data.insight.payload.forEach(occurrence => {
      visualizations.push({
        type: TYPE_STEEP_TURN_SVG,
        data: occurrence,
      });
    });
  }

  visualizations.push({
    type: TYPE_PLOTLY,
    data: getPlotlyOptions(data.frames, markers, activity, metaData),
  });

  return visualizations;
};

export default getSteepTurnVisualizations;
