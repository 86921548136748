import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import { primary, tertiary } from '../../../theme/palette';

type Props = {
  progress: number;
  width?: string | number;
};

const ClassifierProgress = ({ progress, width }: Props) => {
  const [label, setLabel] = useState('Computing results');
  useEffect(() => {
    if (progress === 1) {
      setLabel('Completed');
    }
  }, [progress]);

  return (
    <Wrapper>
      <ProgressLabel>
        {label}
      </ProgressLabel>
      <ProgressWrapper width={width}>
        <ProgressBar width={`${Math.round(progress * 100)}%`} />
        <ProgressValue>{Math.round(progress * 100)}%</ProgressValue>
      </ProgressWrapper>
    </Wrapper>
  );
};

export default ClassifierProgress;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
`;

const ProgressLabel = styled.p`
    margin: auto;
    text-align: center;
    margin-bottom: 0;
    font-size: 0.8rem;
`;

const ProgressWrapper = styled.div<{width?: string | number}>`
  position: relative;
  width: ${props => (typeof props.width === 'number' ? `${props.width}px` : props.width || '290px')};
  height: 20px;
  margin: auto;
  margin-top: 5px;
  background: linear-gradient( #000000, #000000 ) padding-box, linear-gradient(
    to right,
    ${primary.string()},
    ${tertiary.string()}
  ) border-box;
  border: 2px solid transparent;

  @media (max-width: 900px) {
    max-width: 290px;
}
`;

const ProgressBar = styled.div<{ width: string
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, ${theme.primary.string()}, ${theme.secondary.string()}) border-box;
  width: ${props => props.width};
  opacity: .8;
`;

const ProgressValue = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  margin: 0;
  font-size: 0.75rem;
`;
