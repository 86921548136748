import { List, Record, RecordOf } from 'immutable';
import { FullSessionListType } from '../session/constants';
import { ClassifierStatus } from './types';

export type SessionListStore = {
  isLoading: boolean,
  isExtendedLoading: boolean,
  sessions: FullSessionListType,
  count: number,
  classifierStatus?: ClassifierStatus,
};

export type ImmutableSessionListStore = RecordOf<SessionListStore>;

const createSessionListStore = Record<SessionListStore>({
  isLoading: true,
  isExtendedLoading: false,
  sessions: List(),
  count: 0,
  classifierStatus: undefined,
});

const defaultState: ImmutableSessionListStore = createSessionListStore();

export default defaultState;
