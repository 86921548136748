import { fromJS, List, Map } from 'immutable';
import { Reducer } from 'redux';
import * as actions from './actions';
import * as constants from './constants';
import defaultState, { ImmutableSummaryStore } from './store';
import { updateAllGrades, updateAllGradingSheets, updateAllMarkers, updateGrade } from './utils';

const gradingReducer: Reducer<ImmutableSummaryStore, actions.GradingAction> = (
  // eslint-disable-next-line default-param-last
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case actions.GRADING_SHEETS_UPDATED:
    {
      const { gradingSheets: newGradingSheets } = action.payload;

      if (!newGradingSheets || newGradingSheets.length < 1) {
        return state;
      }

      const gradeList: constants.GradeType[] = [];
      const markerList: constants.GradingMarkerType[] = [];

      newGradingSheets.forEach((gradingSheet: constants.GradingSheetType) => {
        const { grades, markers } = gradingSheet;

        grades.forEach(grade => {
          gradeList.push(grade);
        });

        markers.forEach(marker => {
          markerList.push(marker);
        });
      });

      const { gradingSheets } = updateAllGradingSheets(state, newGradingSheets);
      const { activityGrades, ksaComponentGrades } = updateAllGrades(state, gradeList);
      const { markers } = updateAllMarkers(state, markerList);

      return state.merge(
        {
          gradingSheets,
          activityGrades,
          ksaComponentGrades,
          markers,
        },
      );
    }

    case actions.COMPETENCY_GRAPH_UPDATED:
      return state.set('spiderGraphCompetency', fromJS(action.payload));

    case actions.LOADING_COMPETENCY_TABLE:
      return state.set('isLoading', true);

    case actions.GRADE_LEVEL_UPDATED:
    {
      const { grade, spiderGraphCompetency, tableCompetency } = action.payload;

      const { activityGrades, ksaComponentGrades } = updateGrade(state, grade);

      return state.merge(
        {
          activityGrades,
          ksaComponentGrades,
          spiderGraphCompetency: fromJS(spiderGraphCompetency),
          tableCompetency: fromJS(tableCompetency),
        },
      );
    }

    case actions.RETRIEVE_FILTERED_ACTIVITIES:
      return state.set('isLoading', true);

    case actions.UPDATE_FILTERED_ACTIVITIES:
      return state.merge({
        filteredActivities: fromJS(action.payload.activities),
        isLoading: false,
      });

    case actions.CLEAR_TRAINEE_PROFILE:
      return state.merge({
        tableCompetency: Map(),
        filteredActivities: List(),
      });

    default:
      return state;
  }
};

export default gradingReducer;
