import * as Plotly from 'plotly.js';
import { getGlidePathParams } from './utils';
import * as palette from '../../../theme/palette';
import { ApproachInsightPlot } from '../../visualization/constants';
import { getLinePath } from '../../../ui/svg/utils/path';

const getApproachDots = (
  approachData: ApproachInsightPlot,
  plots: Plotly.Data[],
  layout: Plotly.Layout,
) => {
  const {
    fafElevation,
    thresholdCrossingHeight,
    airport,
    runway,
  } = approachData;

  if (fafElevation === null || thresholdCrossingHeight === null) {
    return;
  }

  const {
    glideSlopeUpperTail,
    glideSlopeLowerTail,
    glideSlopeInnerTail,
    glideSlopeStarLineTail,
    glideSlopeHead,
    latDevUpperTail,
    latDevLowerTail,
    latDevInnerTail,
    latDevHead,
    fafSymbolPoints,
    xAxisThresholdPosition,
  } = getGlidePathParams(approachData);

  const altIndex = plots.findIndex(({ name }) => name === 'altMsl');

  // Runway name.
  layout.annotations.push({
    xref: 'paper',
    yref: 'paper',
    yanchor: 'top',
    x: 1,
    y: 1,
    showarrow: false,
    text: `${airport} ${runway}`,
    font: {
      color: 'white',
      size: 16,
    },
  });

  const getShape = (yref: string | undefined, path: string, fill?: string) => {
    layout.shapes.push({
      xref: 'paper',
      // @ts-ignore
      yref,
      type: 'path',
      layer: 'below',
      path,
      fillcolor: fill || palette.white.darken(0.8).string(),
      line: {
        color: palette.white.darken(0.4).string(),
        width: 2,
      },
    });
  };

  const getAnnotation = (yref: string | undefined, position: [number, number], text: string) => {
    layout.annotations.push({
      xref: 'paper',
      // @ts-ignore
      yref,
      xanchor: 'right',
      x: position[0],
      y: position[1],
      showarrow: false,
      text,
      font: {
        color: 'white',
      },
    });
  };

  plots.forEach(plot => {
    if (plot.name === 'latDev') {
      const upperLatDevPath = getLinePath([
        latDevUpperTail,
        latDevHead,
        latDevInnerTail,
      ]);

      const lowerLatDevPath = getLinePath([
        latDevLowerTail,
        latDevHead,
        latDevInnerTail,
      ]);
      // TODO Check proper types why Data do not have "yaxis" property
      // @ts-ignore
      getShape(plot.yaxis, upperLatDevPath, 'none');
      // @ts-ignore
      getShape(plot.yaxis, lowerLatDevPath);
      // @ts-ignore
      getAnnotation(plot.yaxis, latDevUpperTail, '+3.1&#xb0;');
      // @ts-ignore
      getAnnotation(plot.yaxis, latDevLowerTail, '-3.1&#xb0;');
    }

    if (plot.name === 'altMsl') {
      // Glide slope line (final approach fix to threshold).
      layout.shapes.push({
        xref: 'paper',
        // @ts-ignore
        yref: plot.yaxis,
        type: 'line',
        x0: glideSlopeStarLineTail[0],
        y0: glideSlopeStarLineTail[1],
        x1: xAxisThresholdPosition,
        y1: thresholdCrossingHeight,
        layer: 'below',
        line: {
          color: palette.white.darken(0.2).string(),
          width: 2,
        },
      });

      const upperGlideSlopePath = getLinePath([
        glideSlopeUpperTail,
        glideSlopeHead,
        glideSlopeInnerTail,
      ]);

      const lowerGlideSlopePath = getLinePath([
        glideSlopeLowerTail,
        glideSlopeHead,
        glideSlopeInnerTail,
      ]);
      // TODO Check proper types why Data do not have "yaxis" property
      // @ts-ignore
      getShape(plot.yaxis, upperGlideSlopePath, 'none');
      // @ts-ignore
      getShape(plot.yaxis, lowerGlideSlopePath);
      // @ts-ignore
      getAnnotation(plot.yaxis, glideSlopeUpperTail, '+0.8&#xb0;');
      // @ts-ignore
      getAnnotation(plot.yaxis, glideSlopeLowerTail, '-0.8&#xb0;');

      // Final approach fix symbol.
      layout.shapes.push({
        xref: 'paper',
        // @ts-ignore
        yref: plots[altIndex].yaxis,
        type: 'path',
        layer: 'below',
        path: getLinePath(fafSymbolPoints),
        fillcolor: palette.white.darken(0.2).string(),
        line: {
          color: palette.white.darken(0.2).string(),
          width: 2,
        },
      });
    }
  });
};

export default getApproachDots;
