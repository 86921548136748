import {
  TYPE_PLOTLY,
  VisualizationHandlerType,
  GoAroundInsightType,
  TYPE_SUMMARY_PLOTS,
} from '../constants';
import getDefaultOptions from '../../plotly';

// @ts-ignore
const getUprtVisualizations: VisualizationHandlerType<
GoAroundInsightType
> = (activity, { insight, frames }, markers, metaData) => {
  const visualizations = [];

  if (insight && insight.summary && insight.summary.payload !== null) {
    const exists = insight.summary.payload.some(a => a.score !== null);
    if (exists) {
      visualizations.push({
        type: TYPE_SUMMARY_PLOTS,
        data: insight.summary,
      });
    }
  }

  visualizations.push({
    type: TYPE_PLOTLY,
    data: getDefaultOptions(activity, frames, markers, metaData),
  });

  return visualizations;
};

export default getUprtVisualizations;
