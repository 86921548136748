import {
  MetadataAnnotation,
  NormSummaryAnnotation,
  NormSummaryAnnotationType,
  NormSummaryBarAnnotation,
  NormSummaryBannerAnnotation,
} from '@/utils/plotly/annotations/types';
import { keyBy } from 'lodash';

export const getAnnotationByType = <T>(
  annotationType: NormSummaryAnnotationType,
  annotation?: NormSummaryAnnotation[],
): T | undefined => {
  if (!annotation) {
    return undefined;
  }
  const annotationDictionary = keyBy(annotation, 'type');

  if (annotationType in annotationDictionary) {
    return annotationDictionary[annotationType] as T;
  }
  return undefined;
};

export const getAnnotationMetaData = (
  annotation?: NormSummaryAnnotation[],
): MetadataAnnotation | undefined => getAnnotationByType<MetadataAnnotation>(
  NormSummaryAnnotationType.Metadata,
  annotation,
);

export const getAnnotationBanner = (
  annotation?: NormSummaryAnnotation[],
): NormSummaryBannerAnnotation | undefined => getAnnotationByType<NormSummaryBannerAnnotation>(
  NormSummaryAnnotationType.Banner,
  annotation,
);
export const isAnnotationBarData = (annotation?:NormSummaryAnnotation): annotation is NormSummaryBarAnnotation => {
  if (!annotation) {
    return false;
  }
  return annotation.type === NormSummaryAnnotationType.Bar;
};
