import ListScreen from './ListScreen';
import { FullSessionType } from '../../../session/constants';

// Props provided by this container
export type ListScreenPropsType = {
  isLoading: boolean,
  isExtendedLoading: boolean,
  sessions: FullSessionType[],
  count: number,
  onComponentMounted: () => void,
  onLogout: () => void,
  getInstructorInfo: () => void,
  getFilteredSessions : (showInstructorSessions: boolean) => void,
  getUserInfo : () => void,
  extendSessionList: (showInstructorSessions: boolean) => void
};
export default ListScreen;
