import getAirPlotOptions from './air';
import { PlotlyHandlerType } from '../types';

const getQuickstopPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => getAirPlotOptions(frames, markers, activityCode, metaData, [
  'radalt',
  'gndSpeed',
  'pitch',
  ['eng_1Trq', 'eng_2Trq'],
]);

export default getQuickstopPlotOptions;
