import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserInfoStore {
  username: string | null;
  id: number | null;
}

const initialState:UserInfoStore = {
  username: null,
  id: null,
};

const userProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserInfo: (_, action: PayloadAction<{ username: string, id: number }>) => {
      const { username, id } = action.payload;
      return {
        username,
        id,
      };
    },
    getUserInfo: (state => state),
  },
});

export const {
  getUserInfo, setUserInfo,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
