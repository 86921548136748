import { SimulatorStatus } from '../../training/types';
import { tertiary } from '../../theme/palette';

export class SimulatorStatusUtil {
  public static getMessage(simulatorStatus: SimulatorStatus): string {
    switch (simulatorStatus) {
      case SimulatorStatus.CONNECTED:
        return 'Receiving data from simulator';
      case SimulatorStatus.NOT_CONNECTED:
        return 'Simulator data not received';
      default:
        return '';
    }
  }

  public static getColor(simulatorStatus: SimulatorStatus): string {
    switch (simulatorStatus) {
      case SimulatorStatus.CONNECTED:
        return tertiary.toString();
      case SimulatorStatus.NOT_CONNECTED:
        return '#781212';
      default:
        return '';
    }
  }
}
