import { fromJS, List, Map, RecordOf } from 'immutable';
import moment from 'moment';
import * as actions from './actions';
import defaultState, { ImmutableActivitiesStore } from './store';
import { ActivityType, LessonPlanType } from './types';
import * as utils from '../utils/actions';

export default (
  // eslint-disable-next-line default-param-last
  state: ImmutableActivitiesStore = defaultState,
  action: actions.ActivitiesAction,
): ImmutableActivitiesStore => {
  switch (action.type) {
    case actions.UPDATE_CURRENT_RUN:
      return state.set('currentRunId', action.payload.currentRunId);

    case actions.UPDATE_CURRENT_ACTIVITY:
      return state.set('currentActivityId', action.payload.currentActivityId);

    case actions.UPDATE_ACTIVITIES:
    {
      const { runs } = action.payload;
      const activities: {[key: number]: ActivityType[]} = {};
      const lessonPlan: {[key: number]: LessonPlanType[]} = {};

      if (!runs) {
        return state;
      }

      runs.forEach(run => {
        // Key activity lists by run ID.
        activities[run.id] = run.activities || [];
        lessonPlan[run.id] = run.lessonPlan || [];
      });

      return state.merge({
        map: fromJS(activities),
        lessonPlan: fromJS(lessonPlan),
      });
    }

    case actions.CLEAR_ACTIVITIES:
      return state.set('map', Map());

    // Optimistically launch an activity during a live training session.
    case actions.LAUNCH_ACTIVITY:
    {
      const { id } = action.payload;
      const { currentRunId, currentActivityId } = state;

      if (!currentRunId) {
        utils.logError('Invalid Redux state [currentRunId].', actions.LAUNCH_ACTIVITY);

        return state;
      }

      const activities: List<RecordOf<ActivityType>> = state.getIn(['map', currentRunId.toString()]);

      if (!activities) {
        utils.logError('Invalid Redux state [activities].', actions.LAUNCH_ACTIVITY);

        return state;
      }

      const timestamp = moment().toISOString();
      const targetActivityIndex = activities.findIndex(act => act.get('id') === id);

      if (targetActivityIndex < 0) {
        utils.logError('Invalid Redux state [targetActivityIndex].', actions.LAUNCH_ACTIVITY);

        return state;
      }

      const currentActivityIndex = activities.findIndex(act => act.get('id') === currentActivityId);

      return currentActivityIndex >= 0
        ? state.merge({
          currentActivityId: id,
          nextActivityId: null,
        }).setIn(
          [
            'map',
            currentRunId.toString(),
            targetActivityIndex.toString(),
            'startedAt',
          ],
          timestamp,
        ).setIn(
          [
            'map',
            currentRunId.toString(),
            currentActivityIndex.toString(),
            'endedAt',
          ],
          timestamp,
        )
        : state.merge({
          currentActivityId: id,
          nextActivityId: null,
        }).setIn(
          [
            'map',
            currentRunId.toString(),
            targetActivityIndex.toString(),
            'startedAt',
          ],
          timestamp,
        );
    }

    // Optimistically launch next activity during a live training session.
    case actions.LAUNCH_NEXT_ACTIVITY:
    {
      const { currentRunId, currentActivityId } = state;

      const timestamp = moment().toISOString();

      if (!currentRunId || !currentActivityId) {
        utils.logError(
          'Invalid Redux state [currentRunId | currentActivityId].',
          actions.LAUNCH_NEXT_ACTIVITY,
        );

        return state;
      }

      const activities: List<RecordOf<ActivityType>> = state.getIn(['map', currentRunId.toString()]);

      if (!activities) {
        utils.logError(
          'Invalid Redux state [activities].',
          actions.LAUNCH_NEXT_ACTIVITY,
        );

        return state;
      }

      const currentActivityIndex = activities.findIndex(act => act.get('id') === currentActivityId);

      if (currentActivityIndex < 0) {
        utils.logError('Invalid Redux state [currentActivityIndex].', actions.LAUNCH_NEXT_ACTIVITY);

        return state;
      }

      // Find next activity. We assume the list has already been sorted.
      const nextActivityIndex = activities.findIndex(
        a => !a.get('isRepeated') && a.get('ordering') > activities.getIn(
          [
            currentActivityIndex,
            'ordering',
          ],
        ),
      );

      if (nextActivityIndex < 0) {
        return state;
      }

      return state.merge({
        currentActivityId: nextActivityIndex >= 0
          ? activities.getIn([nextActivityIndex, 'id'])
          : null,
        nextActivityId: nextActivityIndex >= 0
          ? activities.getIn([nextActivityIndex, 'id'])
          : null,
      }).setIn(
        [
          'map',
          currentRunId.toString(),
          nextActivityIndex.toString(),
          'startedAt',
        ],
        timestamp,
      ).setIn(
        [
          'map',
          currentRunId.toString(),
          currentActivityIndex.toString(),
          'endedAt',
        ],
        timestamp,
      );
    }

    // Optimistically repeat an activity.
    case actions.REPEAT_ACTIVITY:
    {
      const { id, timestamp } = action.payload;
      const { currentRunId } = state;
      let { currentActivityId } = state;

      if (!currentRunId) {
        utils.logError('Invalid Redux state [currentRunId].', actions.REPEAT_ACTIVITY);

        return state;
      }

      const activities: List<RecordOf<ActivityType>> = state.getIn(['map', currentRunId.toString()]);

      if (!activities) {
        utils.logError('Invalid Redux state [activities].', actions.REPEAT_ACTIVITY);

        return state;
      }

      // Mark current activity as ended.
      const currentActivityIndex = activities.findIndex(act => act.get('id') === currentActivityId);

      if (currentActivityIndex < 0) {
        return state;
      }
      // Set current activity to repeated one. Once we receive a response from
      // the API, we will add the new ID to the activities list.
      currentActivityId = id;

      return state.merge({
        currentActivityId,
        nextActivityId: null,
      }).setIn(
        [
          'map',
          currentRunId.toString(),
          currentActivityIndex.toString(),
          'endedAt',
        ],
        timestamp,
      );
    }

    case actions.UPDATE_REPEATED_ACTIVITY:
    {
      const { previousId, updatedActivity } = action.payload;
      const { currentRunId } = state;

      if (!currentRunId) {
        utils.logError('Invalid Redux state [currentRunId].', actions.UPDATE_REPEATED_ACTIVITY);

        return state;
      }

      const activities: List<RecordOf<ActivityType>> = state.getIn(['map', currentRunId.toString()]);

      if (!activities) {
        utils.logError('Invalid Redux state [activities].', actions.UPDATE_REPEATED_ACTIVITY);

        return state;
      }

      // Find the repeated activity.
      const repeatedActivityIndex = activities.findIndex(act => act.get('id') === previousId);

      if (repeatedActivityIndex < 0) {
        return state;
      }

      return state.set(
        'currentActivityId',
        updatedActivity.id,
      ).setIn(
        [
          'map',
          currentRunId.toString(),
          repeatedActivityIndex.toString(),
        ],
        fromJS(updatedActivity),
      );
    }

    case actions.EDIT_ACTIVITY:
    {
      const { currentActivityId, currentRunId } = action.payload;

      if (!currentRunId || !state.getIn(['map', currentRunId.toString()])) {
        utils.logError('Invalid Redux state [currentRunId].', actions.EDIT_ACTIVITY);

        return state;
      }

      const currentActivityIndex = state.getIn(['map', currentRunId.toString()]).findIndex(
        (activity: RecordOf<ActivityType>) => activity.get('id') === currentActivityId,
      );

      if (currentActivityIndex < 0) {
        utils.logError('Invalid Redux state [currentActivityId].', actions.EDIT_ACTIVITY);

        return state;
      }

      const { code, name } = action.payload.activityDetails;

      return state.mergeIn(
        ['map', currentRunId.toString(), currentActivityIndex.toString()],
        { activityCode: code, name },
      );
    }

    case actions.ACTIVITY_DEMO_UPDATED:
    {
      const { currentActivityId, activityDetails } = action.payload;
      const { currentRunId } = state;

      if (!currentRunId) {
        utils.logError('Invalid Redux state [currentRunId].', actions.ACTIVITY_DEMO_UPDATED);

        return state;
      }

      const activities: List<RecordOf<ActivityType>> = state.getIn(['map', currentRunId.toString()]);

      if (!activities) {
        utils.logError('Invalid Redux state [activities].', actions.ACTIVITY_DEMO_UPDATED);

        return state;
      }

      const demoedActivityIndex = activities.findIndex(act => act.get('id') === currentActivityId);

      if (demoedActivityIndex < 0) {
        return state;
      }

      return state.setIn(
        [
          'map',
          currentRunId.toString(),
          demoedActivityIndex.toString(),
        ],
        fromJS(activityDetails),
      );
    }

    case actions.ACTIVITY_OPTIONS_UPDATED:
    {
      const { activityOptions } = action.payload;

      return state.set('activityOptions', fromJS(activityOptions));
    }

    case actions.UPDATE_RUN_ID:
      return state.set('currentRunId', action.payload.runId);

    case actions.UPDATE_INSTRUCTOR_NOTE:
    {
      const { activityId, note } = action.payload;
      const { currentRunId } = state;

      if (!currentRunId) {
        utils.logError('Invalid Redux state [currentRunId].', actions.ACTIVITY_DEMO_UPDATED);

        return state;
      }

      const activities: List<RecordOf<ActivityType>> = state.getIn(['map', currentRunId.toString()]);
      const activityIndex = activities.findIndex(act => act.get('id') === activityId);

      if (activityIndex < 0) {
        return state;
      }

      return state.setIn(
        [
          'map',
          currentRunId.toString(),
          activityIndex.toString(),
          'note',
        ],
        fromJS(note),
      );
    }

    default:
      return state;
  }
};
