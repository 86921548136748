import SessionOverview from './SessionOverview';
import { FullSessionType, SessionRunType } from '../../../session/constants';
import { EquipmentType } from '../../../dashboard/constants';
import { ClassifierStatus } from '../../types';

// Props provided by this container
export type SessionOverviewPropsType = {
  currentSession: FullSessionType;
  runs: SessionRunType[];
  sessions: FullSessionType[];
  isLoading: boolean;
  equipment: EquipmentType[];
  onComponentMounted: Function;
  classifierStatus: ClassifierStatus;
  currentEquipmentActiveRunIds: number[];
  onEndActiveSessions: Function;
  fetchCurrentEquipmentActiveRuns: Function;
  canStartSession: boolean;
};

export default SessionOverview;
