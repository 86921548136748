import { annotationV1 } from '../annotations';
import { TakeoffInsightType, VisualizationHandlerType } from '../../visualization/constants';
import getTakeoffGroundRollPlotOptions from './takeoffGroundRoll';

const getTakeoffOEIGroundRollPlotOptions: VisualizationHandlerType<TakeoffInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const plotOptions = getTakeoffGroundRollPlotOptions(activity, data, markers, metaData);

  if (metaData.trainingCenter !== 'NLA') {
    // @ts-ignore
    annotationV1(data.frames, plotOptions);
  }

  return plotOptions;
};

export default getTakeoffOEIGroundRollPlotOptions;
