export const AUTH_STATUS_REQUIRING = 'requiring';
export const AUTH_STATUS_VERIFYING = 'verifying';
export const AUTH_STATUS_AUTHENTICATED = 'authenticated';
export const AUTH_STATUS_ERROR = 'error';

export type AuthenticationStatusType =
  | typeof AUTH_STATUS_REQUIRING
  | typeof AUTH_STATUS_VERIFYING
  | typeof AUTH_STATUS_AUTHENTICATED
  | typeof AUTH_STATUS_ERROR;
