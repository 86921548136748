import { Annotations, Shape } from 'plotly.js';
import {
  NormSummaryAnnotationHandlerReturnType,
  NormSummaryBoxAnnotation,
  NormSummaryLineAnnotation,
  NormSummaryPointAnnotation,
  NormSummaryThreshold,
  NormSummaryZoneAnnotation,
  normSummaryAnnotationColorMap,
} from '../annotations/types';
import { white } from '../../../theme/palette';

const ANNOTATION_TEXT_HEIGHT = 32;
const ANNOTATION_GRAPH_HEIGHT = 450;

const getMaxYValue = (arr: number[]) => arr.reduce((a, b) => Math.max(a, b), -Infinity);
const getMinYValue = (arr: number[]) => arr.reduce((a, b) => Math.min(a, b), Infinity);

const getTimestampsCenter = (x0: string, x1: string): string => {
  const [min, max] = x1 > x0 ? [x0, x1] : [x1, x0];
  const [xMin, xMax] = [new Date(min).getTime(), new Date(max).getTime()];
  const xMid = Math.round((xMax - xMin) / 2);
  return new Date(xMin + xMid).toISOString();
};

const getTextYScale = (
  y0: number,
  y1: number,
): number => ((y1 - y0) * ANNOTATION_TEXT_HEIGHT) / ANNOTATION_GRAPH_HEIGHT;

const getBoxShape = ({
  x0,
  x1,
  y0,
  y1,
}: NormSummaryThreshold): Partial<Shape> => ({
  type: 'rect',
  xref: 'x',
  yref: 'y',
  x0,
  x1,
  y0,
  y1,
  fillcolor: white.fade(0.9).string(),
  layer: 'below',
  line: {
    width: 0,
  },
});

const getTextAnnotation = (
  { text, x, y }: { text: string; x: string; y: number },
  {
    xanchor,
    yanchor = 'auto',
  }: {
    xanchor: Annotations['xanchor'];
    yanchor: Annotations['yanchor'];
  },
  angle: number = 0,
): Partial<Annotations> => ({
  text,
  x,
  y,
  font: { color: 'white' },
  showarrow: false,
  yanchor,
  xanchor,
  textangle: `${angle}`,
});

const getTextAnnotationPosition = (
  location1: string,
): { xanchor: Annotations['xanchor']; yanchor: Annotations['yanchor'] } => {
  const [x, y] = location1.split(':');
  const yVal = y === 'center' ? 'middle' : (y as Annotations['yanchor']);
  return { xanchor: x as Annotations['xanchor'], yanchor: yVal };
};

const getArrowAnnotation = ({
  text,
  x,
  y,
  index,
  color,
}: {
  text: string;
  x: string;
  y: number;
  index: number;
  color?: keyof typeof normSummaryAnnotationColorMap;
}): Partial<Annotations> => {
  const annotationColor = color
    ? normSummaryAnnotationColorMap[color]
    : white.toString();
  return {
    text,
    x,
    y,
    ax: index % 2 ? -20 : 20,
    ay: index % 2 ? -20 : -40,
    font: { color: annotationColor },
    arrowhead: 6,
    arrowcolor: annotationColor,
  };
};

export const getPointAnnotation = (
  { x, y, label: text, color }: NormSummaryPointAnnotation,
  index: number,
): NormSummaryAnnotationHandlerReturnType => {
  if (!(x && y && text)) {
    return null;
  }

  return {
    annotations: [getArrowAnnotation({ text, x, y, color, index })],
  };
};

export const getBoxAnnotation = (
  { x0, x1, y0, y1, text }: NormSummaryBoxAnnotation,
  y: number[],
): NormSummaryAnnotationHandlerReturnType => {
  const yVal = getTextYScale(getMinYValue(y), getMaxYValue(y));
  const annotations = text.map(boxtext => {
    const { xanchor, yanchor } = getTextAnnotationPosition(boxtext.location1);
    let xPosition = x0;
    let yPosition = yVal;

    switch (xanchor) {
      case 'center':
        xPosition = getTimestampsCenter(x0, x1);
        break;
      case 'left':
        xPosition = x0;
        break;
      case 'right':
        xPosition = x1;
        break;
      default:
        break;
    }

    switch (yanchor) {
      case 'bottom':
        yPosition = y0 + yVal;
        break;
      case 'middle':
        yPosition = (y1 - y0) / 2 + y0 + yVal;
        break;
      case 'top':
        yPosition = y1 - yVal;
        break;
      default:
        break;
    }

    return getTextAnnotation(
      { text: boxtext.label, x: xPosition, y: yPosition },
      { xanchor, yanchor },
      boxtext.labelAngle,
    );
  });

  return {
    shapes: [getBoxShape({ x0, x1, y0, y1 })],
    annotations,
  };
};

export const getZoneAnnotation = (
  { x0, x1, text }: NormSummaryZoneAnnotation,
  y: number[],
): NormSummaryAnnotationHandlerReturnType => {
  const yVal = getTextYScale(getMinYValue(y), getMaxYValue(y));

  const annotations = text.map(boxtext => {
    const { xanchor, yanchor } = getTextAnnotationPosition(boxtext.location1);
    let xPosition = x0;
    let yPosition = yVal;

    switch (xanchor) {
      case 'center':
        xPosition = getTimestampsCenter(x0, x1);
        break;
      case 'left':
        xPosition = x0;
        break;
      case 'right':
        xPosition = x1;
        break;
      default:
        break;
    }

    switch (yanchor) {
      case 'bottom':
        yPosition = getMinYValue(y) + yVal;
        break;
      case 'middle':
        yPosition = (getMaxYValue(y) - getMinYValue(y)) / 2 + getMinYValue(y) + yVal;
        break;
      case 'top':
        yPosition = getMaxYValue(y) - yVal;
        break;
      default:
        break;
    }

    return getTextAnnotation(
      { text: boxtext.label, x: xPosition, y: yPosition },
      { xanchor, yanchor },
      boxtext.labelAngle,
    );
  });

  return {
    shapes: [getBoxShape({ x0, x1, y0: getMinYValue(y), y1: getMaxYValue(y) })],
    annotations,
  };
};

export const getLineAnnotation = (
  { label: text, x }: NormSummaryLineAnnotation,
  yValues: number[],
): NormSummaryAnnotationHandlerReturnType => ({
  shapes: [
    {
      type: 'line',
      x0: x,
      x1: x,
      y0: getMinYValue(yValues),
      y1: getMaxYValue(yValues),
      line: {
        color: white.string(),
        width: 2,
        dash: 'dot',
      },
    },
  ],
  annotations: [
    getTextAnnotation(
      { text, x, y: getMaxYValue(yValues) },
      { yanchor: 'top', xanchor: 'left' },
      10,
    ),
  ],
});
