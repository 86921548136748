import { annotationV1 } from '../annotations';
import { TakeoffInsightType, VisualizationHandlerType } from '../../visualization/constants';
import getTakeoffLateralDirectionalPlotOptions from './takeoffLateralDirect';

const getTakeoffOEILateralDirectionPlotOptions: VisualizationHandlerType<TakeoffInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const plotOptions = getTakeoffLateralDirectionalPlotOptions(activity, data, markers, metaData);

  if (metaData.trainingCenter !== 'NLA') {
    // @ts-ignore
    annotationV1(data.frames, plotOptions);
  }

  return plotOptions;
};

export default getTakeoffOEILateralDirectionPlotOptions;
