import * as React from 'react';
import styled from 'styled-components';
import reporter from '../../utils/reporter';

// TODO: Refactor when componentDidCatch hook is available

/**
 * Error boundaries help us catch Component errors without crashing the whole
 * app.
 */
type BoundaryProps = {
  children: React.ReactNode,
};

type BoundaryState = {
  errorInfo: any,
};

export default class ErrorBoundary extends React.Component<BoundaryProps, BoundaryState> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    errorInfo: null,
  };

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ errorInfo });

    // Report error
    reporter.error(error, errorInfo);
  }

  render() {
    const { errorInfo } = this.state;
    let url;
    const undfinedUrl = '/';
    const urlStorage = localStorage.getItem('url');
    if (urlStorage !== null) {
      url = urlStorage !== null ? urlStorage : undfinedUrl;
    }

    if (errorInfo) {
      return (
        <div style={{ textAlign: 'center' }}>
          <ErrorWrapper details={errorInfo} />
          <br />
          <br />
          <a style={{ color: 'white' }} href={url}> Going Back to Main Page </a>
        </div>
      );
    }

    return this.props.children;
  }
}

/**
 * The Error component provides a standardized way to display error messages.
 */
type ErrorProps = {
  details?: any,
  margin?: string,
  message?: string,
};

const Wrapper = styled.div<{margin?: string}>`
  background-color: ${props => props.theme.primary.string()};
  color: ${props => props.theme.textColor.string()};
  font-size: 1.4em;
  padding: 15px 25px;
  margin: ${props => props.margin || '15px'};
  text-align: center;
`;

const Details = styled.div``;

const ErrorWrapper = (props: ErrorProps) => {
  const { margin, message, details } = props;

  return (
    <Wrapper margin={margin}>
      {message}

      {details && typeof details === 'string'
        ? (<Details>{details}</Details>)
        : null}
    </Wrapper>
  );
};

ErrorWrapper.defaultProps = {
  details: '',
  margin: '15px',
  message: 'An error occurred.',
};

/**
 * Bugsnag's ErrorBoundary handles reporting for us as well.
 */
const GlobalErrorBoundary = reporter.bugsnagClient
  ? reporter.bugsnagClient.getPlugin('react')
  : ErrorBoundary;

export {
  ErrorWrapper,
  GlobalErrorBoundary,
};
