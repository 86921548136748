import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { createBrowserRouter } from 'react-router-dom';
import { sagaMiddleware } from '@/store';
import { RouterProvider } from 'react-router';
import {
  home,
  schedule,
  createSession,
  viewSession,
  editSession,
  sessionOverview,
  liveSessionRun,
  gradingSheet,
  gradeContext,
  userGuide,
  traineeProfile,
  editSessionDetails,
  competencyRoute,
} from '@/utils/routes';
import { CompetencyPage } from '@/competency/components/CompetencyPage';
import { GradingScreen } from '@/summary/components/GradingScreen';
import { ResourceNotFound } from './ResourceNotFound';
import { dashboardLoaded } from '../actions';
import config from '../../config';
import CreateSession from '../../schedule/components/CreateSessionScreen';
import EditSession from '../../schedule/components/EditSessionScreen';
import GradeContext from '../../summary/components/GradeContext';
import LandingPage from '../../landing/components/LandingPage';
import SessionList from '../../sessionList/components/ListScreen';
import SessionOverview from '../../sessionList/components/SessionOverview';
import SessionRunScreen from '../../session/components/SessionRunScreen';
import TraineeProfile from '../../traineeProfile/components/traineeProfile';
import UserGuide from '../../userGuide/components/UserGuide';
import ViewSession from '../../schedule/components/ViewSessionScreen';

const router = createBrowserRouter([
  {
    path: home(),
    element: <LandingPage />,
  },
  {
    path: schedule(),
    element: <SessionList />,
  },
  {
    path: createSession(),
    element: <CreateSession />,
  },
  {
    path: editSessionDetails(),
    element: <CreateSession />,
  },
  {
    path: sessionOverview(),
    element: <SessionOverview />,
  },
  {
    path: viewSession(),
    element: <ViewSession />,
  },
  {
    path: editSession(),
    element: <EditSession />,
  },

  {
    path: liveSessionRun(),
    element: <SessionRunScreen />,
  },
  {
    path: gradingSheet(),
    element: <GradingScreen />,
  },
  {
    path: competencyRoute(),
    element: <CompetencyPage />,
  },
  {
    path: gradeContext(),
    element: <GradeContext />,
  },
  {
    path: userGuide(),
    element: <UserGuide />,
  },
  {
    path: traineeProfile(),
    element: <TraineeProfile />,
  },
  {
    path: '*',
    element: <ResourceNotFound />,
  },
]);

sagaMiddleware.setContext({
  router,
});

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardLoaded());
  }, [dispatch]);

  return (
    <Wrapper>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{config.appName}</title>
        </Helmet>
        <RouterProvider router={router} />
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${props => props.theme.background.string()};
  color: ${props => props.theme.textColor.string()};
  display: flex;
  flex-direction: column;
  height: 100vh;

  ::-webkit-scrollbar {
    width: 5px; /* for vertical scrollbars */
    height: 5px; /* for horizontal scrollbars */
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.primary.string()};
  }
`;

export default App;
