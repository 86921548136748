import reporter from './reporter';

/**
 * Returns a pre-fixed action type generator. Pre-fixing the action types makes
 * it easier to follow the flow of actions inside the Redux devtools panel.
 */
type Factory = (t: string) => string;

// eslint-disable-next-line arrow-body-style
export const actionTypeFactory = (prefix: string): Factory => {
  return (t: string): string => `[${prefix}] ${t}`;
};

/**
 * Returns an action creator for payloads which only require an ID.
 */
export const forResourcePayload = (type: string): Function => (id: number) => ({
  type,
  payload: { id },
});

/**
 * Returns an action creator for "page loaded" action types.
 */
export const forPage = (type: string): Function => (id?: number) => ({
  type,
  payload: { id },
});

/**
 * Returns an action creator with a single payload + callback
 */
export const withCallback = (type: string): Function => (id: number, callback: Function) => ({
  type,
  payload: { id, callback },
});

/**
 * Returns an action creator for "fetching" action types.
 */
export const fetching = (type: string): Function => (isLoading: boolean = true) => ({
  type,
  payload: { isLoading },
});

/**
 * Generic action creator. Mostly useful for testing.
 */
export const createAction = (type: string, payload: Record<string, any>) => ({ type, payload });

/**
 * Logs a Redux-related error message.
 */
export const logError = (message: string, action: string) => reporter.report(message, {
  action,
});
