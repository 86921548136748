export type OptionType = {
  label: string;
  value: string;
}

export enum EDateFormat {
  FullDate = 'M/D/YYYY, h:mm:ss A',
  FullDateRequest = 'YYYY-MM-DD HH:mm:ss',
  Date = 'YYYY-MM-DD',
  Time = 'HH:mm'
}
