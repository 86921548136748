export function detectOS() {
  const { userAgent, platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const os = null;

  if ((/iPad|iPhone|iPod/.test(navigator.platform)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))) {
    return 'IOS';
  }

  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'MAC OS';
  }

  if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'WINDOWS';
  }
  if (/Android/.test(userAgent)) {
    return 'ANDROID';
  }
  if (!os && /Linux/.test(platform)) {
    return 'LINUX';
  }

  return 'UNKNOWN OS';
}
