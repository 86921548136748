import Core from 'plotly.js/lib/core';
import Scatter from 'plotly.js/lib/scatter';
import Bar from 'plotly.js/lib/bar';
import ScatterPolar from 'plotly.js/lib/scatterpolar';
import createComponent from 'react-plotly.js/factory';

Core.register([
  Scatter,
  ScatterPolar,
  Bar,
]);

// Lighter implementation of Plotly supporting only the relevant plot types.
// https://github.com/plotly/react-plotly.js/#customizing-the-plotlyjs-bundle
const Plotly = createComponent(Core);

export default Plotly;
