import * as React from 'react';
import ReactSelect, { MenuPlacement, Options,
  StylesConfig, GetOptionLabel, GetOptionValue, Props as SelectProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';
import { FieldErrorMessage, InputLabel } from '@/styles/FormStyles';
import { GridColumn } from '@/styles/GridStyles';
import inputStyles from '../../theme/input';

interface Props extends SelectProps<any>{
    loadOptions?: (input: string, cb: (options: Options<any>) => void) => void;
    menuPlacement?: MenuPlacement;
    formatCreateLabel?: Function | any;
    options: Options<any>,
    getOptionLabel: GetOptionLabel<any>| any,
    getOptionValue: GetOptionValue<any>| any,
    label?: string;
    error?: string;
    isLive?: boolean;
}

const styles: StylesConfig = {
  menu: base => ({
    ...base,
    backgroundColor: inputStyles.background.string(),
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused
      ? inputStyles.optionBackgroundFocused.string()
      : inputStyles.optionBackground.string(),
    color: inputStyles.textColor.string(),
  }),
  container: base => ({
    ...base,
    margin: '10px 0 10px 0',
  }),
  control: (base, { isDisabled }) => ({
    ...base,
    backgroundColor: 'transparent',
    border: `1px solid ${!isDisabled
      ? inputStyles.borderColor.string()
      : inputStyles.borderColor.darken(0.7).string()}
    `,
    boxSizing: 'border-box',
    minHeight: 50,
    borderRadius: 3,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  input: (base, { isDisabled }) => ({
    ...base,
    color: !isDisabled
      ? inputStyles.textColor.string()
      : inputStyles.textColor.darken(0.4).string(),
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    color: !isDisabled
      ? inputStyles.textColor.string()
      : inputStyles.textColor.darken(0.4).string(),
  }),
};

export const DynamicSelect = (props: Props) => {
  // Determine if the select component should be asynchronous.
  const isAsync = typeof props.loadOptions === 'function';

  // Determine if the select component should allow creating new options.
  const isCreatable = typeof props.formatCreateLabel === 'function';

  const selectProps = {
    // Styling.
    styles,
    // Default props.
    delimiter: ',',
    maxMenuHeight: 550,
    menuPlacement: 'auto',
    openMenuOnFocus: true,

    // Override props.
    ...props,
  } as const;

  switch (true) {
    case isCreatable:
      return <Creatable {...selectProps} />;

    case isAsync:
      return <AsyncSelect {...selectProps} />;

    default:
      return <ReactSelect {...selectProps} />;
  }
};

export const DynamicColumnSelect = (props: Props) => {
  const { label, error, ...inputProps } = props;

  return (
    <GridColumn>
      <InputLabel>{label}</InputLabel>

      <DynamicSelect {...inputProps} />

      {error ? (
        <FieldErrorMessage>
          {error}
        </FieldErrorMessage>
      ) : null}
    </GridColumn>
  );
};
