import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useParams } from 'react-router';
import { DynamicColumnSelect } from '@/components/DynamicSelect';
import { CompetencyGraph } from 'src/components/CompetencyGraph';
import { radarPlotMounted } from '@/competency/actions';
import { useAppDispatch, useAppSelector } from '@/store';
import { FullSessionType } from '@/session/constants';
import ContentContainer from '@/ui/ContentContainer';
import { Header } from '@/components/Header';
import { BreadcrumbListType } from '@/components/Breadcrumbs';
import { home, schedule as scheduleRoute, sessionOverview } from '@/utils/routes';
import Body from '@/ui/Body';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../ui/PageHeader';
import Loading from '../../../ui/Loading';
import * as sessionListActions from '../../../sessionList/actions';

type SessionOptionType = {
  session: string,
  sessionRunId: number,
};

type TraineeSessionType = { session: string | undefined, sessionRunId: number | undefined }

const TraineeProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sessionListSelector = useAppSelector(state => state.sessionList);
  const isLoading = sessionListSelector.get('isLoading');
  const sessions:FullSessionType[] = sessionListSelector.get('sessions').toJS();
  const competencySelector = useAppSelector(state => state.competency);

  const onComponentMounted = useCallback(() => {
    dispatch(sessionListActions.sessionListScreenLoaded());
  }, []);

  const params = useParams<{trainee:string}>();
  const [traineeName, setTraineeName] = React.useState<string | null>(null);
  const [runId, setRunId] = React.useState<number | undefined>(undefined);

  useEffect(() => {
    onComponentMounted();
  }, [onComponentMounted]);

  const trainee = parseInt(params?.trainee!, 10);
  const handleSetTraineeName = (t: string) => setTraineeName(t);

  const getLabel = (session: SessionOptionType): string => session.session;

  const getValue = (session: SessionOptionType): number => session.sessionRunId;

  const handleChangeSession = (session: SessionOptionType) => {
    setRunId(session.sessionRunId);
  };

  const sessionWithTrainees = sessions
    .filter(session => session?.trainees.pilotFlying.id === trainee
      || session?.trainees.pilotMonitoring.id === trainee);

  const updatedTraineeSessions = sessionWithTrainees
    .reduce((acc: TraineeSessionType[], session) => {
      if (!session.plannedRuns) {
        return acc;
      }
      const sessionInfo = {
        session: `${session.lessonPlanName} - ${moment(String(session.scheduledFor)).format('MMMM Do, h:mm A')}`,
        // eslint-disable-next-line no-nested-ternary
        sessionRunId: session.trainees.pilotFlying.id === trainee
          ? session.plannedRuns[0]
          : (session.plannedRuns[1] ? session.plannedRuns[1] : session.plannedRuns[0]),
      };

      return [...acc, sessionInfo];
    }, []);

  useEffect(() => {
    if (traineeName === null && sessionWithTrainees?.[0]?.trainees) {
      handleSetTraineeName(
        sessionWithTrainees[0].trainees.pilotFlying.id === trainee
          ? sessionWithTrainees[0].trainees.pilotFlying.name
          : sessionWithTrainees[0].trainees.pilotMonitoring.name,
      );
    }
  }, [sessionWithTrainees]);

  useEffect(() => {
    if (!runId && sessionWithTrainees?.[0]) {
      const sessionId = updatedTraineeSessions[0].sessionRunId;
      setRunId(sessionId);
    }
  }, [updatedTraineeSessions]);

  useEffect(() => {
    if (runId) {
      dispatch(radarPlotMounted(Number(runId)));
    }
  }, [runId]);

  const scores = competencySelector.getIn(['scoresByRun', runId])?.toJS();
  const instructorScores = competencySelector.getIn(['instructorScores', runId])?.toJS() || {};
  const isLoadingCompetency = competencySelector.get('isLoading');

  if (isLoading || isLoadingCompetency) {
    return <Loading />;
  }

  return (
    <>
      <Header />
      <Body>
        <ContentContainer>
          <BackIcon
            onClick={() => navigate(-1)}
          />
          <PageTitle>
            {traineeName}
          </PageTitle>
          <SelectWrapper>
            <DynamicColumnSelect
              options={updatedTraineeSessions}
              isSearchable={false}
              defaultValue={updatedTraineeSessions[0]}
              getOptionLabel={getLabel}
              getOptionValue={getValue}
              onChange={handleChangeSession}
              value={updatedTraineeSessions.find(s => s.sessionRunId === runId)}
            />
          </SelectWrapper>
          {scores ? (
            <CompetencyGraph
              isLoading={isLoadingCompetency}
              scores={scores}
              instructorScores={instructorScores}
            />
          ) : null}
        </ContentContainer>
      </Body>
    </>
  );
};

export default TraineeProfile;

const SelectWrapper = styled.div`
  width: 50%;
  margin: 24px auto 0 auto;
`;

const PageTitle = styled.h2`
  background-color: ${props => props.theme.background.string()};
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 2px;
  white-space: nowrap;
  margin-top: 24px;
  text-transform: uppercase;
`;

const BackIcon = styled.div`
  position: absolute;
  cursor: pointer;
  background: center center transparent no-repeat url(/icons/BackArrow.svg);
  background-size: contain;
  height: 20px;
  width: 26px;
`;
