import { InsightResponseType } from '../utils/visualization/constants';

export const GRADE_CONTEXT_LOADED = 'Grade context loaded.';
export const INSIGHT_DATA_LOADED = 'Received insight data.';
export const CLEAR_INSIGHT_DATA = 'Clearing insight data.';

export const gradeContextLoaded = (sessionId: number, runId: number, activityId: number) => ({
  type: GRADE_CONTEXT_LOADED,
  payload: { sessionId, runId, activityId },
} as const);

export const insightDataLoaded = (
  insightData: InsightResponseType,
) => ({
  type: INSIGHT_DATA_LOADED,
  payload: { insightData },
} as const);

export const clearInsightData = () => ({
  type: CLEAR_INSIGHT_DATA,
  payload: null,
} as const);

export type GradeContextLoadedAction = ReturnType<typeof gradeContextLoaded>;
export type InsightDataLoadedAction = ReturnType<typeof insightDataLoaded>;
export type ClearInsightDataAction = ReturnType<typeof clearInsightData>;

export type InsightAction =
  | GradeContextLoadedAction
  | InsightDataLoadedAction
  | ClearInsightDataAction;
