import { DefaultTheme } from 'styled-components';
import button from './button';
import input from './input';
import palette from './palette';
import popovers from './popovers';
import animations, { transition } from './animations';

const theme: DefaultTheme = {
  ...palette,

  animations,
  button,
  input,
  popovers,

  // Borders
  borderRadius: '3px',

  // Default container "max-widths"
  containerWidth: '1100px',
  containerWidthLarge: '1400px',

  // Headers
  headerHeight: '40px',
  pageHeaderHeight: '165px',

  // Shortcuts
  deviceWidthPhone: '576px',
  deviceWidthTablet: '768px',
  deviceWidthDesktop: '992px',
  fontWeightLight: 300,
  fontWeightNormal: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  transitionTime: transition.time,
};

export default theme;
