import { List, Map, Record, RecordOf } from 'immutable';

import * as constants from './constants';
import { SessionMarkerMapType } from '../session/constants';

export type SummaryStore = {
  activityGrades: constants.ActivityGradesMapType,
  ksaComponentGrades: constants.KsaGradeMapType,
  filteredActivities: constants.FilteredActivitiesType,
  gradingSheets: constants.GradingSheetRunMapType,
  markers: SessionMarkerMapType,
  spiderGraphCompetency: constants.SpiderGraphCompetencyMapType,
  tableCompetency: constants.TableCompetencyMapType,
  isLoading: boolean,
};

export type ImmutableSummaryStore = RecordOf<SummaryStore>;

export const createSummaryStore = Record<SummaryStore>({
  gradingSheets: Map(),
  activityGrades: Map(),
  ksaComponentGrades: Map(),
  markers: Map(),
  filteredActivities: List(),
  spiderGraphCompetency: Map([
    ['afmA', null],
    ['afmM', null],
    ['aop', null],
    ['com', null],
    ['ltw', null],
    ['psd', null],
    ['saw', null],
    ['wlm', null],
  ]),
  tableCompetency: Map(),
  isLoading: true,
});

const defaultState: ImmutableSummaryStore = createSummaryStore();

export default defaultState;
