import moment from 'moment-timezone';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import { EDateFormat } from '@/types/global.types';

dayjs.extend(duration);
dayjs.extend(customParseFormat);

export const isValidDate = (
  value: Date | string | null | undefined,
  format?: EDateFormat,
) => dayjs(value, format, true).isValid();

export const formatDate = (
  value:Date | string | null | undefined,
  format: EDateFormat,
) => {
  if (!isValidDate(value)) return '';
  return dayjs(value).format(format);
};

export const formatSeconds = (totalSeconds?: number): string => {
  if (!totalSeconds) return '';
  const totalDuration = dayjs.duration(totalSeconds, 'seconds');
  const minutes = totalDuration.minutes();
  const seconds = totalDuration.seconds();
  return `${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`;
};

export const getMinutes = (totalSeconds?: number): number => {
  if (!totalSeconds) return 0;
  return dayjs.duration(totalSeconds, 'seconds').asMinutes();
};

const withLeadingZero = (val:number) => (val > 9 ? val : `0${val}`);

export const timeDiff = (
  startTime:Date | string | null,
  endTime:Date | string | null,
) => {
  // Define the two times
  const timeStart = dayjs(startTime);
  const timeEnd = dayjs(endTime);

  // Step 3: Calculate the difference in milliseconds
  const diff = timeEnd.diff(timeStart);

  // Step 4: Convert the difference to minutes and seconds
  const diffDuration = dayjs.duration(diff);
  const hours = diffDuration.hours();
  const minutes = diffDuration.minutes();
  const seconds = diffDuration.seconds();

  return `${withLeadingZero(hours)}:${withLeadingZero(minutes)}:${withLeadingZero(seconds)}`;
};

// TODO refactor it to Dayjs due to smaller bundle size
/**
 * Combines date and time strings into a UTC datetime string
 */
export const combineDateTime = (date: string, time: string): string => {
  const [hours, minutes] = time.split(':', 2);

  return moment(date)
    .hours(Number(hours))
    .minutes(Number(minutes))
    .utc()
    .format();
};

/**
 * Splits a UTC datetime string into localized date and time strings.
 */
export const splitDateTime = (datetime?: string): string[] => {
  if (!datetime) {
    return ['', ''];
  }

  const dt = moment(datetime).tz('America/Montreal');

  return [dt.format('YYYY-MM-DD'), dt.format('HH:mm')];
};
