import React, { useCallback, useEffect } from 'react';
import { camelCase } from 'lodash';
import Plotly from '../../../ui/Plotly';
import { getPlotlyData } from './utils';
import { NormType } from '../../visualization/constants';
import { FrameType } from '../../../frames/constants';
import { MetadataAnnotation, NormSummaryAnnotation, NormSummaryAnnotationType } from '../annotations/types';
import { isGoAroundActivity } from '../../visualization/activityCategory';

const NormSummaryGraph = (props: {
  norm: NormType & { frames: FrameType[]};
}) => {
  const {
    norm: {
      annotation: annotations,
      frames,
    },
  } = props;

  const metadata: MetadataAnnotation | undefined = annotations?.find(
    x => x.type === NormSummaryAnnotationType.Metadata,
  ) as MetadataAnnotation;

  if (!metadata) {
    console.log(`Metadata annotation is missed in ${JSON.stringify(annotations)}}`);
    return null;
  }

  const flightParam = Array.isArray(metadata.flightParameter)
    ? metadata.flightParameter.map(x => camelCase(x))
    : camelCase(metadata.flightParameter);

  const [startTime, endTime] = [
    new Date(metadata.scaledTimestampStart).getTime(),
    new Date(metadata.scaledTimestampEnd).getTime(),
  ];

  const xValues = frames
    .map(x => x.timestamp)
    .filter(x => {
      const time = new Date(x).getTime();
      return time >= startTime && time <= endTime;
    });

  const startIndex = frames.map(x => x.timestamp).indexOf(xValues[0]);
  const endIndex = frames
    .map(x => x.timestamp)
    .indexOf(xValues[xValues.length - 1]);

  const getYValues = (param: string) => frames.map(x => x.data[param]).slice(startIndex, endIndex + 1) as number[];

  const yValues:Record<string, number[]> = {};

  if (Array.isArray(flightParam)) {
    flightParam.forEach(x => {
      yValues[x] = getYValues(x);
    });
  } else {
    yValues[flightParam] = getYValues(flightParam);
  }

  // TODO: Remove temporary backend fix, https://app.clickup.com/t/86885kqra
  const extendFrameWithAnnotation = useCallback(() => {
    const firstFrameTimeStamp = frames.at(0)!.timestamp;
    const firstFrameTime = new Date(firstFrameTimeStamp).getTime();

    annotations?.forEach(item => {
      if ('x' in item
            && 'y' in item
            && item.label === 'Power Applied'
            && item.type === 'point'
            && flightParam?.at(0)
            && new Date(item.x).getTime() < firstFrameTime
      ) {
        xValues.unshift(item.x);

        if (Array.isArray(flightParam)) {
          flightParam.forEach(param => {
            yValues[param].unshift(item.y);
          });
        } else {
          yValues[flightParam].unshift(item.y);
        }
      }
    });
  }, [annotations, frames]);

  useEffect(() => {
    if (isGoAroundActivity(props.norm.activityCode)) {
      extendFrameWithAnnotation();
    }
  }, [annotations, frames, props.norm.activityCode]);

  const plotly = getPlotlyData({
    x: xValues,
    y: yValues,
    annotations: annotations!,
  });

  return (
    <Plotly {...plotly} />
  );
};

export default NormSummaryGraph;
