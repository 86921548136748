import { TimeBoundResourceType } from './constants';

// TODO: should be `export * as routes from './routes';`
import routes from './routes';

export { routes };

/**
 * Checks if a session is currently running.
 */
export const isRunning = (res: TimeBoundResourceType) => res.startedAt && !res.endedAt;

/**
 * Checks if a session has ended.
 */
export const isEnded = (res: TimeBoundResourceType) => !!res.endedAt;

/**
 * Retrieves the active resource based on the started and ended timestamps.
 */
export const getActive = <R extends TimeBoundResourceType>(list: R[]): R | undefined => list
  && list.find
  && list.find(resource => {
    if (typeof resource !== 'object' || !resource) {
      return undefined;
    }

    if (!('startedAt' in resource)) {
      return undefined;
    }

    if (!('endedAt' in resource)) {
      return undefined;
    }

    return resource.startedAt && !resource.endedAt;
  });
