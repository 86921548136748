/**
 * Utility for setting up and interacting with the IndexedDB database.
 */
import reporter, { Reporter as ReporterInterface } from './reporter';

/**
 * The version of the local database. Bump this number up by 1 anytime a change
 * is made to the schema.
 */
const VERSION = 1;

/**
 * The name of the local database.
 */
const NAME = 'dashboard';

/**
 * Type definition for the LocalStore class.
 */
type LocalStoreConfig = {
  lib: IDBFactory;
  name: string;
  version: number;
  reporter: ReporterInterface;
};

export class LocalStore {
  /**
   * Cache.
   */
  cache: { [index: string]: any } = {};

  /**
   * Instance of the local database.
   */
  db?: IDBDatabase;

  /**
   * Name of the local database.
   */
  name: string;

  /**
   * Version of the database schema.
   */
  version: number;

  /**
   * An implementation of the IndexedDB library.
   */
  lib: IDBFactory;

  /**
   * An instance of the error reporting utility.
   */
  reporter: ReporterInterface;

  constructor(config: LocalStoreConfig) {
    this.lib = config.lib;
    this.name = config.name;
    this.version = config.version;
    this.reporter = config.reporter;
  }

  /**
   * Sets a parameter in the "params" object store.
   */
  setParam = async (name: string, value: any): Promise<object | Error> => {
    const conn = await this.connect();

    // Exit early if we don't have a valid database connection.
    if (conn instanceof Error) {
      return Promise.reject(conn);
    }

    return new Promise((resolve, reject) => {
      const transaction = conn.transaction('params', 'readwrite');

      transaction.addEventListener('complete', (event: Event) => {
        // Clear local cache.
        const cacheKey = `params.${name}`;

        if (cacheKey in this.cache) {
          delete this.cache[cacheKey];
        }

        // @ts-ignore
        resolve(event.target.result);
      });

      transaction.addEventListener('error', (event: Event) => {
        // @ts-ignore: (TS2339) Property 'error' does not exist on type 'EventTarget'.
        reject(new Error(event.target && event.target.error));
      });

      // Add parameter to "params" store.
      transaction.objectStore('params').put({ name, value });
    });
  };

  /**
   * Retrieves a parameter from the "params" object store.
   */
  getParam = async <T>(name: string, defaultValue?: any): Promise<T | Error> => {
    const conn = await this.connect();

    // Exit early if we don't have a valid database connection.
    if (conn instanceof Error) {
      return Promise.reject(conn);
    }

    // Return cached value if available.
    const cacheKey = `params.${name}`;

    if (cacheKey in this.cache) {
      return Promise.resolve(this.cache[cacheKey]);
    }

    return new Promise((resolve, reject) => {
      const request = conn
        .transaction('params')
        .objectStore('params')
        .get(name);

      request.addEventListener('success', () => {
        // Cache the result.
        const { result } = request;
        this.cache[cacheKey] = typeof result === 'undefined'
          ? defaultValue
          : result.value;

        resolve(this.cache[cacheKey]);
      });

      request.addEventListener('error', (event: Event) => {
        // @ts-ignore: (TS2339) Property 'error' does not exist on type 'EventTarget'.
        reject(new Error(event.target && event.target.error));
      });
    });
  };

  /**
   * Adds records to the specified object store.
   */
  addRecords = async (storeName: string, records: any[]): Promise<object | Error> => {
    const conn = await this.connect();

    // Exit early if we don't have a valid database connection.
    if (conn instanceof Error) {
      return Promise.reject(conn);
    }

    return new Promise((resolve, reject) => {
      const transaction = conn.transaction(storeName, 'readwrite');

      transaction.addEventListener('complete', (event: Event) => {
        // Clear local cache.
        const cacheKey = `${storeName}.getAll`;

        if (cacheKey in this.cache) {
          delete this.cache[cacheKey];
        }

        // @ts-ignore: (TS2339) Property 'result' does not exist on type 'EventTarget'.
        resolve(event.target && event.target.result);
      });

      transaction.addEventListener('error', (event: Event) => {
        // @ts-ignore: (TS2339) Property 'error' does not exist on type 'EventTarget'.
        reject(new Error(event.target && event.target.error));
      });

      const store = transaction.objectStore(storeName);

      records.forEach(record => store.add(record));
    });
  };

  getRecords = async <R>(
    storeName: string,
    range?: IDBKeyRange,
    limit?: number,
  ): Promise<R | Error> => {
    const conn = await this.connect();

    // Exit early if we don't have a valid database connection.
    if (conn instanceof Error) {
      return Promise.reject(conn);
    }

    // Return cached records if they are available.
    const cacheKey = `${storeName}.getAll`;

    if (cacheKey in this.cache) {
      return Promise.resolve(this.cache[cacheKey]);
    }

    return new Promise((resolve, reject) => {
      const request = conn
        .transaction(storeName)
        .objectStore(storeName)
        .getAll(range, limit);

      request.addEventListener('success', (event: Event) => {
        // @ts-ignore: (TS2339) Property 'result' does not exist on type 'EventTarget'.
        const { result } = event.target;

        // Cache the result.
        this.cache[cacheKey] = [...result];

        resolve(result);
      });

      request.addEventListener('error', (event: Event) => {
        // @ts-ignore: (TS2339) Property 'error' does not exist on type 'EventTarget'.
        reject(new Error(event.target && event.target.error));
      });
    });
  };

  /**
   * Opens a connection to the local database.
   */
  connect = async (): Promise<IDBDatabase | Error> => {
    if (this.db instanceof IDBDatabase) {
      return Promise.resolve(this.db);
    }

    return new Promise((resolve, reject) => {
      // Open a connection to the database.
      const connection = this.lib.open(this.name, this.version);

      // Error handler.
      connection.addEventListener('error', (event: Event) => {
        // @ts-ignore: (TS2339) Property 'result' does not exist on type 'EventTarget'.
        const { error } = event.target;

        this.reporter.error('Could not open IndexedDB database.', {
          name: this.name,
          version: this.version,
          message: error,
        });

        reject(new Error(error));
      });

      // Upgrade handler.
      connection.addEventListener('upgradeneeded', (event: IDBVersionChangeEvent) => {
        // Make changes to local database.
        // @ts-ignore: (TS2339) Property 'result' does not exist on type 'EventTarget'.
        this.setupDatabase(event.target && event.target.result, event.oldVersion);
      });

      // Success handler.
      connection.addEventListener('success', (event: Event) => {
        // @ts-ignore: (TS2339) Property 'result' does not exist on type 'EventTarget'.
        const { result: db } = event.target;

        this.db = db;

        // Generic error handler for database transactions.
        // @ts-ignore: (TS2532) Object is possibly 'undefined'.
        this.db.addEventListener('error', (dbEvent: Event) => {
          // @ts-ignore: (TS2339) Property 'error' does not exist on type 'EventTarget'.
          this.reporter.error('IndexedDB error', dbEvent.target && dbEvent.target.error);
        });

        resolve(db);
      });
    });
  };

  /**
   * Creates the object stores.
   */
  // eslint-disable-next-line class-methods-use-this
  setupDatabase = (db: IDBDatabase, oldVersion: number) => {
    // Initial setup.
    if (oldVersion < 1) {
      // List of trainees.
      db.createObjectStore('trainees', { autoIncrement: true });

      // General parameters, stored in the format {name: '', value: ''}
      db.createObjectStore('params', { keyPath: 'name' });
    }
  };
}

export default new LocalStore({
  reporter,
  lib: window.indexedDB,
  name: NAME,
  version: VERSION,
});
