import * as Plotly from 'plotly.js';
import { FrameType } from '../../../frames/constants';

export const getGearUpAnnotation = (frames: FrameType[], layout: Plotly.Layout) => {
  // TODO: GEAR_LVR_POS is still a boolean in the Citation XLS ICD.
  const gearUpIndex = frames.findIndex(({ data }) => data.gearLvrPos === 0 || data.gearLvrPos === false);

  if (gearUpIndex > 0) {
    layout.annotations.push({
      text: 'GEAR UP',
      x: frames[gearUpIndex].data.cas,
      y: frames[gearUpIndex].data.radalt,
      font: { color: 'white' },
      arrowhead: 6,
      arrowcolor: 'ccc',
    });
  }
};

export const getFlapsAnnotations = (frames: FrameType[], layout: Plotly.Layout) => {
  frames.forEach((frame, index) => {
    if (index !== 0 && frame.data.flapLeverPos !== frames[index - 1].data.flapLeverPos) {
      layout.annotations.push({
        text: `FLP ${frame.data.flapLeverPos}`,
        x: frame.data.cas,
        y: frame.data.radalt,
        font: { color: 'white' },
        arrowhead: 6,
        arrowcolor: 'ccc',
      });
    }
  });
};

export const getVrAnnotation = (frames: FrameType[], layout: Plotly.Layout) => {
  const { vr } = frames[0].data;

  if (!vr) {
    return;
  }

  const flapIndex = frames.findIndex(({ data }) => data.flapLeverPos === 0);
  const vrY = flapIndex !== -1 ? frames[flapIndex].data.radalt : 0;

  if (vrY !== 0) {
    layout.annotations.push({
      text: 'VR',
      x: +vr,
      y: vrY,
      font: { color: 'white' },
      showarrow: false,
      yanchor: 'bottom',
    });

    layout.shapes.push({
      type: 'line',
      x0: +vr,
      x1: +vr,
      y0: 0,
      y1: vrY,
      line: {
        color: 'white',
        width: 2,
        dash: 'dot',
      },
    });
  } else {
    layout.annotations.push({
      text: 'VR',
      x: +vr,
      y: 0,
      font: { color: 'white' },
      arrowhead: 6,
      arrowcolor: 'ccc',
    });
  }
};

export const getRunwayReferenceAnnotation = (
  { x0, x1, y0, y1 }: { x0: number, x1: number, y0: number, y1: number},
  layout: Plotly.Layout,
) => {
  layout.annotations.push({
    text: `${y1} ft`,
    x: x0,
    y: y0,
    font: { color: 'white' },
    showarrow: false,
    yanchor: 'bottom',
  });

  layout.shapes.push({
    type: 'line',
    x0,
    x1,
    y0,
    y1,
    line: {
      color: 'white',
      width: 2,
      dash: 'dot',
    },
  });
};
