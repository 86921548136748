import React from 'react';
import styled from 'styled-components';

interface Props {
  text?: string;
}

const PreviewRibbon = ({ text }: Props) => (
  <Ribbon>{text}</Ribbon>
);

PreviewRibbon.defaultProps = {
  text: 'Beta',
};

const Ribbon = styled.div`
  background-image: linear-gradient(
    to right,
    ${props => props.theme.primary.string()},
    ${props => props.theme.tertiary.string()}
  );
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;

  position: fixed;
  top: 20px;
  right: -55px;
  width: 200px;
  height: 40px;
  overflow: hidden;
  z-index: 12;
  transform: rotate(45deg);
`;

export default PreviewRibbon;
