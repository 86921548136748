import LandingPage from './LandingPage';
import { FullSessionType } from '../../../session/constants';

export type LandingPageProps = {
  isLoading: boolean,
  sessions: FullSessionType[],
  onComponentMounted: Function,
  onLogout: Function,
};
export default LandingPage;
