import getAirPlotOptions from './air';
import attitudesLegend from './attitudesLegend';
import { PlotlyHandlerType } from '../types';

const getSlopePlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  const plotOptions = getAirPlotOptions(frames, markers, activityCode, metaData, [
    'radalt',
    'hdgTrue',
    ['roll', 'pitch'],
    ['eng_1Trq', 'eng_2Trq'],
  ]);

  attitudesLegend(plotOptions);

  return plotOptions;
};

export default getSlopePlotOptions;
