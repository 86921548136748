import { keyBy } from 'lodash';
import getAirPlotOptions from './air';
import {
  annotationFlaps,
  annotationGearUp,
  annotationInitialPitchReference,
  annotationStickShaker,
  annotationV2,
  annotationVR,
} from '../annotations';
import { normAirspeed, normGearUp, normPitch } from '../annotations/norms';
import { GearRetractionNormType, TakeoffInsightType, VisualizationHandlerType } from '../../visualization/constants';
import { getEngineParam } from '../utils';

const hasRadaltDataDefined = (insight: TakeoffInsightType): boolean => {
  try {
    const gearRetractionData = insight.summary?.payload.find(
      e => e.normCode === 'GEARUP_TIME',
    )?.envelope as GearRetractionNormType;
    return (
      Object.keys(gearRetractionData) as (keyof GearRetractionNormType)[]
    ).every(i => 'radalt' in gearRetractionData[i]);
  } catch (_) {
    return false;
  }
};

// @ts-ignore
const getTakeoffPlotOptions: VisualizationHandlerType<TakeoffInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const { insight, frames } = data;
  const altCode = hasRadaltDataDefined(insight!) ? 'radalt' : 'altMsl';

  const plotOptions = getAirPlotOptions(frames, markers, activity, metaData, [
    altCode,
    'cas',
    'pitch',
    getEngineParam(metaData.aircraftDesignator, metaData.aircraftType),
  ]);

  if (metaData.trainingCenter !== 'NLA') {
    const norm = keyBy(insight?.summary?.payload, 'normCode');
    annotationVR(frames, plotOptions, 'pitch', norm?.ROT_CAS);
    annotationV2(frames, plotOptions, 'cas', norm?.SEG2CLIMB_CAS);
    annotationGearUp(frames, plotOptions, altCode);
    annotationFlaps(frames, plotOptions, 'flapLeverPos');
    annotationStickShaker(frames, plotOptions, 'cas');
    annotationInitialPitchReference(plotOptions, 'pitch');

    let airspeed; let pitch;
    let gearRetraction: undefined | GearRetractionNormType;

    if (insight && insight.summary) {
      // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
      insight.summary?.payload.filter((e, i) => {
        if (e && e.normCode === 'SEG2CLIMB_CAS') {
          airspeed = insight.summary?.payload[i].envelope;
          return true;
        }
        if (e && e.normCode === 'PITCH_AFTER_LIFTOFF') {
          pitch = insight.summary?.payload[i].envelope;
          return true;
        }
        if (e && e.normCode === 'GEARUP_TIME') {
          gearRetraction = insight.summary?.payload[i].envelope;
          return true;
        }
        return false;
      });

      if (airspeed) {
        normAirspeed(frames, plotOptions, airspeed, 'cas');
      }

      if (gearRetraction) {
        normGearUp(frames, plotOptions, gearRetraction, altCode);
      }

      if (pitch) {
        normPitch(frames, plotOptions, pitch, 'pitch');
      }
    }
  }

  return plotOptions;
};

export default getTakeoffPlotOptions;
