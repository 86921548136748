export const NormCodeToNameMap = new Map([
  // Takeoff Norms A320
  // TODO: temporary solution for https://app.clickup.com/t/86885v5p7
  ['GNDROLL_FWD_COL_a320', 'Ground Roll Forward Inceptor Position'],
  // Takeoff Norms
  ['GNDROLL_FWD_COL', 'Ground Roll Forward Column Pressure'],
  ['GNDROLL_HDG', 'Ground Roll Heading Control'],
  ['ROTATION_HDG', 'Rotation Heading Control'],
  ['GEARUP_TIME', 'Gear Up Selection Time'],
  ['SEG2CLIMB_CAS', 'Second Segment Climb Airspeed'],
  ['SEG2CLIMB_LATACC', 'Balanced Flight After Liftoff'],
  ['PITCH_AFTER_LIFTOFF', 'Pitch Control After Liftoff'],

  // Rejected Takeoff Norms
  ['RTO_REACTION_TIME', 'Reaction Time from Malfunction Activation'],
  ['RTO_PROCEDURE_TIMING', 'RTO Procedure Timing'],
  ['RTO_PROCEDURE_SEQ', 'RTO Procedure Sequence'],
  ['RTO_HDG', 'Heading Deviation'],
  ['RTO_BRAKES_PROFILE', 'Brakes Application'],
  // new
  // payload
  ['TLA_REACTION_ERROR', 'Application time of thrust lever idle'],
  ['REVERSER_DELAY_ERROR', 'Application time of max reverser thrust'],
  ['BRAKE_DELAY_ERROR', 'Brake application time'],
  ['BRAKE_DIST_ERROR', 'Braking Distance'],
  ['HDG_MSE', 'Deviation from the runway heading'],
  ['REVERSER_STOWED', 'Reverser thrust stowing time'],
  // insight
  ['mff', 'MALFUNCTION'],
  ['tla', 'THRUST LEVER IDLE'],
  ['revStowed', 'REV THRUST STOWED'],
  ['brake', 'MANUAL BRAKE'],
  ['maxBrake', 'MAX BRAKE'],
  ['maxRevThrust', 'MAX REV THRUST'],
  ['autobrake', 'AUTO BRAKE'],
  ['revDeployed', 'REV THRUST DEPLOYED'],
  ['stopped', 'TURNOFF'],
  ['speedbrakeDeployed', 'SPEEDBRAKE DEPLOYED'],
  ['speedbrakeStowed', 'SPEEDBRAKE STOWED'],

  // Landing Norms
  ['IDLE_ALT_LDG', 'Height at Idle Thrust Selection'],
  ['FLARE_CAS', 'Airspeed Through Flare to Touchdown'],
  ['TOUCHDOWN_ROD', 'Touchdown Rate of Descent'],
  ['TOUCHDOWN_PITCH', 'Touchdown Pitch Attitude'],
  ['TOUCHDOWN_CAS', 'Touchdown Airspeed'],
  ['TOUCHDOWN_DIST', 'Touchdown Distance Along Runway'],
  ['SPD_BRK_DEPLOYMENT_TIME', 'Speed Brake Deployment Timing'],
  ['REV_DEPLOYMENT_TIME', 'Airspeed at Thrust Reverser Deployment'],
  ['BRAKES_APPLIED_CAS', 'Airspeed at Brakes Application After Landing'],
  ['ROLLOUT_FWD_COL', 'Rollout Forward Pitch Control'],
  ['ROLLOUT_HDG', 'Rollout Heading Control'],
  ['REV_IDLE_CAS', 'Airspeed at Thrust Idle'],
  ['BRAKES_RELEASED_GSPD', 'Ground Speed at Brakes Release'],
  ['SPD_BRK_RETRACTED_CAS', 'Speed Brake Retraction After Landing Complete'],

  // Final Approach Norms
  ['APP_FINAL_FLAP_EXT_CAS', 'Airspeed at Landing Flap Selection'],
  ['APP_FINAL_FLAP_EXT_LOCATION', 'Glideslope Deviation at Flap Selection'],
  ['APP_FINAL_GEAR_DN_CAS', 'Airspeed at Gear Down Selection'],
  ['APP_FINAL_GEAR_DN_LOCATION', 'Location at Gear Down Selection'],
  ['APP_FINAL_CAS', 'VRef during Approach'],
  ['APP_FINAL_THRUST', 'Thrust Setting during Approach'],
  ['APP_FINAL_LAT_PATH', 'LOC Deviation'],
  ['APP_FINAL_VERT_PATH', 'Glideslope Deviation'],
  ['APP_FINAL_AP_DISC_ALTITUDE', 'Altitude at AP Disconnect'],

  // Go-Around
  ['GA_GEARUP_POSITIVE_RATE', 'Gear up at positive rate'],
  ['GA_ALT_LOSS_AFTER_PWR_APPLIED', 'Altitude Loss after power applied'],
  ['GA_FLAPS_REACTION_TIME', 'Reaction time between power applied and flap change'],
  ['GA_GEARUP_PITCH', 'Pitch at gear retraction'],

  //  Simcom TBM 940 norms
  //  takeoff
  ['ROT_CAS', 'Rotation Airspeed'],
  ['FIFTYFEET_CAS', 'Airspeed at 50 Feet'],
  ['TAKEOFF_FLAPS_UP_CAS', 'Flap Retraction after Climb'],
  //  final approach
  ['APP_FINAL_VERTICAL_SPEED', 'Vertical Speed during Approach'],
  ['APP_FINAL_TORQUE', 'Engine Power during Approach'],
  //  go around
  ['GA_CAS', 'Airspeed during Go-Around'],
  ['GA_FLAPS_UP_CAS', 'Flaps Up Airspeed'],
  ['GA_GEARUP_CAS', 'Gear Up Airspeed'],

  // UPRT
  ['RECOVERY_TIME', 'Recovery Time'],
  ['STABILIZATION_SEQUENCE', 'Stabilize Sequence'],
  ['CAS_RATE_OF_CHANGE', 'Airspeed Variation'],
  ['ALT_RATE_OF_CHANGE', 'Altitude Variation'],

  // COCKPIT_PREPARATION
  ['ENGINE_START_TIME', 'Time to Engine Start'],

  // STALL
  ['NB_SECONDARY_STALLS', 'Secondary Stall Avoidance'],
  ['RECOVERY_SEQUENCE', 'Recovery Procedure'],
  ['STALL_FINAL_HDG', 'Final Heading Deviation'],
]);

export const NormOrderingFromCodeMap = new Map([
  ['ROT_CAS', 1],
  ['FIFTYFEET_CAS', 2],
  ['TAKEOFF_FLAPS_UP_CAS', 3],
  // Takeoff Norms
  ['GNDROLL_FWD_COL', 4],
  ['GNDROLL_HDG', 5],
  ['ROTATION_HDG', 6],
  ['GEARUP_TIME', 7],
  ['SEG2CLIMB_CAS', 8],
  ['SEG2CLIMB_LATACC', 9],
  ['PITCH_AFTER_LIFTOFF', 10],

  // Rejected Takeoff Norms
  ['RTO_REACTION_TIME', 1],
  ['RTO_PROCEDURE_TIMING', 2],
  ['RTO_PROCEDURE_SEQ', 3],
  ['RTO_HDG', 4],
  ['RTO_BRAKES_PROFILE', 5],
  ['TLA_REACTION_ERROR', 6],
  ['REVERSER_DELAY_ERROR', 7],
  ['BRAKE_DELAY_ERROR', 8],
  ['REVERSER_STOWED', 9],
  ['HDG_MSE', 10],

  // Landing Norms
  ['IDLE_ALT_LDG', 1],
  ['FLARE_CAS', 2],
  ['TOUCHDOWN_ROD', 3],
  ['TOUCHDOWN_PITCH', 4],
  ['TOUCHDOWN_CAS', 5],
  ['TOUCHDOWN_DIST', 6],
  ['SPD_BRK_DEPLOYMENT_TIME', 7],
  ['REV_DEPLOYMENT_TIME', 8],
  ['BRAKES_APPLIED_CAS', 9],
  ['ROLLOUT_FWD_COL', 10],
  ['ROLLOUT_HDG', 11],
  ['REV_IDLE_CAS', 12],
  ['BRAKES_RELEASED_GSPD', 13],
  ['SPD_BRK_RETRACTED_CAS', 14],

  // Final Approach Norms
  ['APP_FINAL_FLAP_EXT_CAS', 1],
  ['APP_FINAL_FLAP_EXT_LOCATION', 2],
  ['APP_FINAL_GEAR_DN_CAS', 3],
  ['APP_FINAL_GEAR_DN_LOCATION', 4],
  ['APP_FINAL_CAS', 5],
  ['APP_FINAL_THRUST', 6],
  ['APP_FINAL_LAT_PATH', 7],
  ['APP_FINAL_VERT_PATH', 8],
  ['APP_FINAL_AP_DISC_ALTITUDE', 9],

  // Go Around Norms
  ['GA_CAS', 1],
  ['GA_GEARUP_POSITIVE_RATE', 2],
  ['GA_ALT_LOSS_AFTER_PWR_APPLIED', 3],
  ['GA_FLAPS_UP_CAS', 4],
  ['GA_FLAPS_REACTION_TIME', 5],
  ['GA_GEARUP_PITCH', 6],

  // UPRT norms
  ['RECOVERY_TIME', 1],
  ['STABILIZATION_SEQUENCE', 2],
  ['CAS_RATE_OF_CHANGE', 3],
  ['ALT_RATE_OF_CHANGE', 4],

  // COCKPIT_PREPARATION
  ['ENGINE_START_TIME', 1],

  // STALL
  ['NB_SECONDARY_STALLS', 1],
  ['RECOVERY_SEQUENCE', 1],
  ['STALL_FINAL_HDG', 1],

]);
