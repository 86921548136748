import localStore from '../utils/localStore';

/**
 * Retrieves trainees from the local database. We supress errors since they'll
 * get logged in the localStore util anyway.
 */
export const getTrainees = async (filterByName?: string): Promise<string[]> => {
  const trainees = await localStore.getRecords<string[]>('trainees');

  // Suppress errors if any.
  if (trainees instanceof Error) {
    return Promise.resolve([]);
  }

  // Filter trainees.
  if (filterByName) {
    return Promise.resolve(trainees.filter(name => name === filterByName));
  }

  return Promise.resolve(trainees);
};

/**
 * Adds a trainee to the local database.
 */
export const addTrainee = async (name: string): Promise<boolean | Error> => {
  const result = await localStore.addRecords('trainees', [name]);

  if (result instanceof Error) {
    return Promise.reject(result);
  }

  return Promise.resolve(true);
};
