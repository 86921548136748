import getAirPlotOptions from './air';
import { annotationVR } from '../annotations';
import {
  normGroundRollColumn,
  normGroundRollHeading,
  normTrueHeadingScale,
} from '../annotations/norms';
import {
  TakeoffInsightType,
  VisualizationHandlerType,
} from '../../visualization/constants';
import { filterParamsByFramesValue } from '../utils';

// @ts-ignore
const getTakeoffGroundRollPlotOptions: VisualizationHandlerType<
TakeoffInsightType
> = (activity, data, markers, metaData) => {
  const { insight, frames } = data;

  const wowIndex = frames.findIndex(
    ({ data: framesData }) => framesData.wowAny === false || framesData.wowAny === 0.0,
  );
  const framesFromBeginningToLiftoff = wowIndex !== 0 ? frames.slice(0, wowIndex + 1) : frames;
  let plotOptions;

  if (metaData.aircraftDesignator === 'A320') {
    plotOptions = getAirPlotOptions(
      framesFromBeginningToLiftoff,
      markers,
      activity,
      metaData,
      ['hdgTrue', ['fcInceptorLPosPitch', 'fcInceptorRPosPitch'], 'cas'],
    );
  } else if (metaData.aircraftDesignator === 'TBM9') {
    plotOptions = getAirPlotOptions(
      framesFromBeginningToLiftoff,
      markers,
      activity,
      metaData,
      ['hdgTrue', 'colForcePilot', 'cas'],
    );
  } else {
    const params = ['hdgTrue', 'colPosPilot', 'cas'];
    plotOptions = getAirPlotOptions(
      framesFromBeginningToLiftoff,
      markers,
      activity,
      metaData,
      filterParamsByFramesValue(params, frames),
    );
  }

  if (metaData.trainingCenter !== 'NLA') {
    annotationVR(framesFromBeginningToLiftoff, plotOptions, 'cas');

    let groundRollColumn;
    let groundRollHeading;

    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    if (insight && insight.summary) {
      insight.summary.payload.forEach((e, i) => {
        if (e && e.normCode === 'GNDROLL_FWD_COL') {
          groundRollColumn = insight.summary?.payload[i].envelope;
        }
        if (e && e.normCode === 'GNDROLL_HDG') {
          groundRollHeading = insight.summary?.payload[i].envelope;
        }
      });

      if (groundRollColumn) {
        normGroundRollColumn(
          framesFromBeginningToLiftoff,
          plotOptions,
          groundRollColumn,
          'colPosPilot',
        );
      }

      if (groundRollHeading) {
        normGroundRollHeading(
          framesFromBeginningToLiftoff,
          plotOptions,
          groundRollHeading,
          'hdgTrue',
        );
        normTrueHeadingScale(plotOptions, groundRollHeading, 'hdgTrue-scale');
      }
    }
  }

  return plotOptions;
};

export default getTakeoffGroundRollPlotOptions;
