import { fromJS, List } from 'immutable';
import defaultState, { ImmutableFramesStore } from './store';
import { UPDATE_FRAMES, CLEAR_FRAMES, FETCH_FRAMES, FramesAction } from './actions';

export default (
  // eslint-disable-next-line default-param-last
  state: ImmutableFramesStore = defaultState,
  action: FramesAction,
): ImmutableFramesStore => {
  switch (action.type) {
    case FETCH_FRAMES:
      return state.set('isFetchingFrames', true);

    case UPDATE_FRAMES:
    {
      // TODO: make sure this doesn't break when streaming data
      const list = state.list.concat(action.payload.list);
      const total = list.size;

      return state.merge({
        list: fromJS(list),
        total,
        isFetchingFrames: false,
      });
    }

    case CLEAR_FRAMES:
      return state.merge({
        total: 0,
        list: List(),
        isFetchingFrames: true,
      });

    default:
      return state;
  }
};
