import * as React from 'react';
import { FieldErrorMessage, FormColumn, InputField, InputLabel } from '@/styles/FormStyles';

type OptionType = {
    id: number | string,
};

type ColumnSelectProps = {
    label: string,
    options: OptionType[],
    value?: string | number | null | any,
    error?: string,
    getOptionValue?: Function,
    getOptionLabel?: Function,
    [key: string]: any,
};

/**
 * Default getter for retrieving the value from a select option.
 */
const defaultGetValue = (option: OptionType): string | number => option.id;

export const ColumnSelect = ({
  label = '',
  getOptionLabel = (option: OptionType): string | number => option.id,
  getOptionValue = (option: OptionType): string | number => option.id,
  options,
  error,
  ...selectProps
}: ColumnSelectProps) => {
  // Getters
  const getValue = typeof getOptionValue === 'function'
    ? getOptionValue
    : defaultGetValue;

  const getLabel = typeof getOptionLabel === 'function'
    ? getOptionLabel
    : getValue;

  // Select options
  const selectOptions = Array.isArray(options) ? options : [];

  return (
    <FormColumn>
      <InputLabel>{label}</InputLabel>
      <InputField {...selectProps} as="select">
        <option key="empty" value="" hidden>Choose here</option>
        {selectOptions.map(option => {
          const optionValue = getValue(option);
          const optionLabel = getLabel(option);
          return (
            <option key={optionValue} value={optionValue}>
              {optionLabel}
            </option>
          );
        })}
      </InputField>
      {error ? (
        <FieldErrorMessage>
          {error}
        </FieldErrorMessage>
      ) : null}

    </FormColumn>
  );
};

export default ColumnSelect;
