import { FullSessionType, RunIdType } from '../session/constants';
import { GeneralStoreType } from '../store/constants';

/**
 * Retrieves the end timestamp for a session run.
 *
 * Note: ideally, if the store was flat, we could simply retrieve the
 * run data from the store by ID. Until we do that, we try our best to
 * come up with a sensible result for the end timestamp.
 */
export const selectEndedAt = (store: GeneralStoreType, id: RunIdType): string | null => {
  // Try getting timestamp from "session" store.
  if (store.getIn(['activities', 'currentRunId']) === id) {
    return store.getIn(['session', 'currentSession', 'endedAt']);
  }

  // Try getting timestamp from "sessionList" store. In this case, we'll
  // only have access to the timestamp for the full session.
  const sessions = store.getIn(['sessionList', 'sessions']);

  if (!sessions || !sessions.size) {
    return null;
  }

  const fullSession = sessions.find((fs: FullSessionType) => fs.plannedRuns?.includes(id));

  return fullSession?.endedAt;
};
