import React from 'react';
import styled from 'styled-components';
import { getAnnotationMetaData } from '@/utils/annotationHelper';
import {
  gradeLevel5,
  gradeLevel9,
  lightGrey,
  mediumGrey,
  white,
} from '../../theme/palette';
import { SectionTitle } from '../../summary/components/TaskList';
import { NormType, NormVisualizationType, VisualizationMetaDataType } from '../visualization/constants';
import { NormCodeToNameMap, NormOrderingFromCodeMap } from './constants';
import NormRow from './NormRow';
import { FrameType } from '../../frames/constants';

const getNormName = ({ equipmentId, norm }:{norm:NormType, equipmentId?:string}) => {
  const annotationMetadata = getAnnotationMetaData(norm?.annotation);
  if (annotationMetadata?.normName) return annotationMetadata.normName;

  // TODO: temporary solution for https://app.clickup.com/t/86885v5p7
  const airCraftType = equipmentId?.split('-')?.[1];
  if (airCraftType === 'a320' && norm.normCode === 'GNDROLL_FWD_COL') {
    return NormCodeToNameMap.get(`${norm.normCode}_${airCraftType}`);
  }
  return NormCodeToNameMap.get(norm.normCode);
};

const NormSummary = (props:{
    frames?: FrameType[];
    expand: boolean;
    metaData?: VisualizationMetaDataType<{equipmentId:string}>;
    payload: Array<NormType>
}) => {
  const { frames, expand } = props;

  const norms = props?.payload
    .reduce<Array<NormVisualizationType & NormType & { frames: FrameType[]; }>>((acc, norm) => {
      const normName = getNormName({
        norm,
        equipmentId: props?.metaData?.equipmentId,
      });
      if (normName) {
        return [...acc, {
          ...norm,
          name: normName,
          pass: norm.pass,
          ordering: NormOrderingFromCodeMap.get(norm.normCode)! + (norm.pass ? 100 : 0),
          frames: frames ?? [],
          annotation: norm.annotation,
        }];
      }
      return acc;
    }, [])
    .filter(norm => typeof norm.pass === 'boolean')
    .sort((a, b) => a.ordering - b.ordering);

  const onRowExpanded = (index: number) => {
    // eslint-disable-next-line no-console
    console.log(index);
    //  todo: hide all inactive
  };
  return (
    <NormCardWrapper>
      <AssessmentSeciontTitle $top>
        Performance Assessment
      </AssessmentSeciontTitle>
      <NormTitle>
        <LeftColumn />
        <RightColumn>
          <Label>OUT OF NORM</Label>
          <Label>WITHIN NORM</Label>
        </RightColumn>
      </NormTitle>
      {norms.map((norm, index) => (
        <NormRow
          key={`${norm.name}_${norm.ordering}`}
          norm={norm}
          expand={expand}
          onExpand={() => onRowExpanded(index)}
        >
          <LeftColumn>{norm.name}</LeftColumn>
          <RightColumn>
            <Fail $fail={!norm.pass} />
            <Pass $pass={norm.pass!} />
          </RightColumn>
        </NormRow>
      ))}
    </NormCardWrapper>
  );
};

export default NormSummary;

const NormCardWrapper = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  background-color: ${mediumGrey.string()};
  border-radius: 3px;
  max-height: 770px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Norm = styled.div`
  display: flex;
  line-height: 60px;
  border-top: 1px solid ${lightGrey.string()};
  font-size: 16px;
`;

const NormTitle = styled(Norm)`
  color: ${white.string()};
  font-size: 12px;
  line-height: 30px;
  border-top: 0;
  max-width: 100%;
`;

const LeftColumn = styled.div`
  min-width: 60%;
  text-align: left;
  padding-left: 20px;
`;

const RightColumn = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
    align-items: center;
`;

const Checkmark = styled.div`
  & {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  &:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #fff;
    left: 11px;
    top: 6px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #fff;
    left: 8px;
    top: 12px;
  }
`;

const Warning = styled.div`
    & {
        position: relative;
        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom-width: 19px;
        border-bottom-style: solid;
    }

    &:after {
        content: "!";
        color: white;
        position: absolute;
        line-height: 28px;
        top: -3px;
        left: -3px;
    }
`;

const Fail = styled(Warning)<{ $fail: boolean }>`
  border-bottom-color: ${props => (props.$fail ? gradeLevel5.string() : '')};
  visibility: ${props => (props.$fail ? 'inherit' : 'hidden')};
`;

const Pass = styled(Checkmark)<{ $pass: boolean }>`
  background-color: ${props => (props.$pass ? gradeLevel9.string() : '')};
  visibility: ${props => (props.$pass ? 'inherit' : 'hidden')};
`;

const AssessmentSeciontTitle = styled(SectionTitle)`
  position: inherit;
  border-top: 0;
`;

const Label = styled.div`
  z-index: -1;
`;
