import {
  TYPE_PLOTLY,
  ApproachInsightType,
  VisualizationHandlerType, TYPE_SUMMARY, TYPE_APPROACH,
} from '../constants';
import getPlotlyOptions from '../../plotly/plotOptions/approach';
import getApproachOptions from '../../plotly/approach';

// @ts-ignore
const getApproachVisualizations: VisualizationHandlerType<ApproachInsightType> = (
  activity,
  { insight, frames },
  markers,
  metaData,
) => {
  const visualizations = [];

  if (insight && insight.summary && insight.summary.payload !== null) {
    const exists = insight.summary.payload.some(a => a.score !== null);
    if (exists) {
      visualizations.push({
        type: TYPE_SUMMARY,
        data: insight.summary,
      });
    }
  }

  visualizations.push({
    type: TYPE_PLOTLY,
    data: getPlotlyOptions(frames, markers, activity, metaData),
  });

  insight && insight.approach && visualizations.push({
    type: TYPE_APPROACH,
    data: getApproachOptions(insight.approach),
  });

  return visualizations;
};

export default getApproachVisualizations;
