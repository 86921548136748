import { MapPoint } from '@/types/map.types';
import * as d3 from 'd3';
import L, { LineUtil, Point } from 'leaflet';
import { useMap } from 'react-leaflet';
import { useEffect } from 'react';
import simplify = LineUtil.simplify;

export const getBoundaries = ({ points }: { points: MapPoint[] }): [MapPoint, MapPoint] | undefined => {
  if (points.length <= 0) return undefined;
  const latitudes = points.map(p => p[0]);
  const longitudes = points.map(p => p[1]);
  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);
  const minLng = Math.min(...longitudes);
  const maxLng = Math.max(...longitudes);
  return [[minLat, minLng], [maxLat, maxLng]];
};

export const getLayerPoints = ({ points, map }: { points: MapPoint[], map: L.Map }) => simplify(points
  .map(p => new Point(p[0], p[1])), 0.0001)
  .map<MapPoint>(p => {
    const layerPoint = map.latLngToLayerPoint([p.x, p.y]);
    return [layerPoint.x, layerPoint.y] as [number, number];
  });

export const generateLine = () => d3.line<[number, number]>()
  .x((d: number[]) => d[0])
  .y((d: number[]) => d[1]);

export const BoundaryLoad = ({
  markerBounds,
}: {
  markerBounds: L.LatLngBoundsExpression
}) => {
  const map = useMap();
  useEffect(() => {
    if (map) {
      map.fitBounds(markerBounds);
    }
  }, [map]);
  return null;
};
