import { ClientInterface } from './client';
import { GradeableIdType, GradeableType, GradeLevelType } from '../summary/constants';

export default class Grades {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  create = async (
    gradeableType: GradeableType,
    gradeableId: GradeableIdType,
    gradeValue: GradeLevelType,
  ): Promise<object> => this.client.post('/grades/', { [gradeableType]: gradeableId, gradeValue });

  list = async (
    session: number,
    trainee: number,
  ): Promise<object | false> => this.client.getResource('/grades/', { session, trainee });

  update = async (
    id: number,
    gradeValue: GradeLevelType,
  ): Promise<object | false> => this.client.patch(`/grades/${Number(id)}/`, { gradeValue });

  createCompetency = async (
    gradeableType: string,
    gradeableId: GradeableIdType,
    competencyName: string,
    competencyValue: GradeLevelType,
  ): Promise<object | false> => this.client.post('/grades/', {
    [gradeableType]: gradeableId,
    [competencyName]: competencyValue,
  });

  updateCompetency = async (
    id: GradeableIdType,
    competencyName: string,
    competencyValue: GradeLevelType,
  ): Promise<object | false> => this.client.patch(`/grades/${Number(id)}/`, {
    [competencyName]: competencyValue,
  });
}
