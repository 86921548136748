import getAirPlotOptions from './air';
import { PlotlyHandlerType } from '../types';

const getLowAirspeedPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => getAirPlotOptions(frames, markers, activityCode, metaData, [
  'radalt',
  'gndSpeed',
  'hdgTrue',
  ['eng_1Trq', 'eng_2Trq'],
]);

export default getLowAirspeedPlotOptions;
