import { Record, RecordOf } from 'immutable';
import { AuthenticationStatusType } from './constants';

export interface AuthStore {
  isAuthenticating: boolean;
  status: AuthenticationStatusType | null;
  token: string | null;
  user: number | null;
  isInstructorAdmin: boolean;
}

export type ImmutableAuthStore = RecordOf<AuthStore>;

const createAuthStore = Record<AuthStore>({
  isInstructorAdmin: false,
  isAuthenticating: false,
  user: null,
  status: null,
  token: null,
});

const defaultState: ImmutableAuthStore = createAuthStore();

export default defaultState;
