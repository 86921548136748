import { ClientInterface } from './client';
import { TrainingCenterType } from '../dashboard/constants';

export default class TrainingCenter {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  get = async (): Promise<TrainingCenterType | false> => this.client.getResource('/training-center/');
}
