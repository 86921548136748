import getChecklistDataPoints from './checklistData';
import getChecklistLayout from './checklistLayout';
import getChecklistAnnotations from './checklistAnnotations';
import { FrameType } from '../../../frames/constants';

const getChecklistPlotOptions = (
  frames: FrameType[],
) => {
  const simParams = [
    'battSw',
    'gen_1ApuSw',
    'navLightsSw',
    'emerInternLightsSw',
    'parkingBrakeEngaged',
    'floodLightsSw',
    'stbyPwrSw',
    'anticolLightsSw',
  ];

  const data = getChecklistDataPoints(simParams, frames);

  const layout = getChecklistLayout(simParams);

  if (frames && frames.length > 1) {
    getChecklistAnnotations(frames, data, layout, simParams);
  }

  const plotProps = {
    config: {
      displayModeBar: false,
      staticPlot: true,
    },
    style: {
      height: '100%',
      width: '100%',
    },
    useResizeHandler: true,
    data,
    layout,
  };

  plotProps.layout.height = 800;

  return plotProps;
};

export default getChecklistPlotOptions;
