import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Header } from '@/components/Header';
import { BreadcrumbListType, Breadcrumbs } from '@/components/Breadcrumbs';
import { gradingSheet as gradingSheetRoute, home, schedule as scheduleRoute, sessionOverview } from '@/utils/routes';
import Body from '@/ui/Body';
import { media } from '@/theme/breakepoints';
import { formatDate } from '@/utils/time';
import { EDateFormat } from '@/types/global.types';
import { SessionDescription } from '@/components/SessionDescription';
import { ContainerProps, StateMapProps } from './types';
import { FrameType } from '../../../frames/constants';
import { ActivityIdType, ActivitiesMapType, NoteType } from '../../../activities/types';
import Loading from '../../../ui/Loading';
import {
  ActivityGradesMapType,
  GradeLevelType, GradeType,
  GradingSheetRunMapType,
  KsaGradeMapType,
} from '../../constants';
import { SessionMarkerMapType } from '../../../session/constants';
import { getGradingContext } from '../../utils';
import { getDate } from './utils';
import { lightestGrey, lightGrey, mediumGrey } from '../../../theme/palette';
import ActivityPlot from '../ActivityPlot';
import { InsightType } from '../../../utils/visualization/constants';
import ContextOverlayNavigation from './Navigation';
import { NotesTextArea } from '../../../ui/Notes';
import Recommendations from '../../../recommendations/components';
import { RecommendationsType } from '../../../recommendations/store';
import ContentContainer from '../../../ui/ContentContainer';
import { GeneralStoreType } from '../../../store/constants';
import * as insightActions from '../../../insight/actions';
import * as actions from '../../actions';
import * as activityActions from '../../../activities/actions';
import * as frameActions from '../../../frames/actions';
import * as recommendationActions from '../../../recommendations/actions';
import { useAppDispatch, useAppSelector } from '../../../store';

type Props = {
  activitiesMap: ActivitiesMapType,
  currentRunId: number,
  currentActivityId: number,
  framesList: FrameType[],
  isFetchingFrames: boolean,
  gradingSheets: GradingSheetRunMapType,
  activityGrades: ActivityGradesMapType,
  ksaComponentGrades: KsaGradeMapType,
  markersMap: SessionMarkerMapType,
  insight: InsightType | null,
  isLoading: boolean,
  activityRecommendations: RecommendationsType,
};

const GradeContext = () => {
  const params = useParams<{sessionId: string, runId: string, activityId: string }>();
  const { sessionId, runId, activityId } = params;
  const dispatch = useAppDispatch();
  const summarySelector = useAppSelector(state => state.summary).toJS();
  const framesSelector = useAppSelector(state => state.frames.toJS());
  const activitiesSelector = useAppSelector(state => state.activities.toJS());
  const insightSelector = useAppSelector(state => state.insight.toJS());
  const recommendationsSelector = useAppSelector(state => state.recommendations.toJS());
  const sessionSelector = useAppSelector(state => state.session.toJS());

  const fullSession = sessionSelector.currentSession;

  const { gradingSheets, activityGrades, ksaComponentGrades } = summarySelector;
  const { list: framesList, isFetchingFrames } = framesSelector;
  const { map: activitiesMap, currentRunId, currentActivityId } = activitiesSelector;
  const { data, isLoading } = insightSelector;
  const { activityRecommendations } = recommendationsSelector;

  const markersMap = summarySelector.markers;
  // @ts-ignore
  const insight = data;

  const onComponentMounted = useCallback((sId: number, rId: number, aId: number) => {
    dispatch(insightActions.gradeContextLoaded(sId, rId, aId));
  }, [sessionId, runId, activityId]);
  const onUpdateGradeLevel = (
    grade: GradeType,
    level: GradeLevelType,
    id: number,
  ) => dispatch(actions.updateInstructorGrade(grade, level, id));
  const onSaveNote = (
    id: number,
    text: string,
    note: NoteType | null,
  ) => dispatch(activityActions.saveInstructorNote(id, text, note));
  const onSelectDemo = (id: number) => dispatch(activityActions.setActivityDemo(id));
  const onDone = () => {
    dispatch(insightActions.clearInsightData());
    dispatch(frameActions.clearFrames());
    dispatch(activityActions.updateCurrentActivity(null));
  };

  const onSubmitInstructorFeedback = (type: string, id: number, instructorFeedback: boolean) => {
    dispatch(recommendationActions.submitInstructorFeedback(type, id, instructorFeedback));
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    onComponentMounted(Number(sessionId), Number(runId), Number(activityId));
  }, [onComponentMounted, sessionId, runId, activityId]);

  useEffect(() => () => {
    onDone();
  }, []);

  if (isFetchingFrames || isLoading) {
    return <Loading />;
  }

  const gradingSheet = gradingSheets && currentRunId && gradingSheets[currentRunId]
    ? gradingSheets[currentRunId]
    : null;

  // @ts-ignore
  const activities = activitiesMap && currentRunId ? activitiesMap[currentRunId] : [];
  // @ts-ignore
  const markers = currentRunId && markersMap ? (markersMap[currentRunId] || []) : [];

  let gradeContextItem = null;

  gradingSheet && gradingSheet.sections.forEach(({ items }:any) => {
    items.forEach((item:any) => {
      if (item.activities.includes(currentActivityId)) {
        gradeContextItem = item;
      }
    });
  });

  const gradeContext = getGradingContext(
    gradeContextItem,
    activityGrades,
    ksaComponentGrades,
    markers,
    activities,
  );

  const { instructorGrade, predictedGrade } = gradeContext.grades.get(currentActivityId) || {};
  const activity = gradeContext.activities.get(currentActivityId);
  const isDemo = (activity && activity.isDemo) || false;
  const date = framesList.length > 0 && getDate(framesList);

  const handleUpdateGrade = (
    level: GradeLevelType,
  ) => instructorGrade && onUpdateGradeLevel(instructorGrade, level, currentRunId);

  const handleSaveNote = (
    text: string,
    note: NoteType,
  ) => currentActivityId && onSaveNote(currentActivityId, text, note);

  const handleChangeActivity = (
    id: ActivityIdType,
  ) => {
    onDone();
    navigate(`/context/${sessionId}/${runId}/${id}`);
  };

  const handleSubmitInstructorFeedback = (instructorFeedback: boolean) => {
    onSubmitInstructorFeedback('activity', activityId! as any, instructorFeedback);
  };

  const breadcrumbs:BreadcrumbListType = [
    {
      link: home(),
      title: 'Home',
    },
    {
      title: 'Training Summary',
      link: `/summary/${Number(sessionId)}`,
    },
    ...(activity?.name ? [{
      title: activity.name,
    }] : []),
  ];
  return (
    <>
      <Header />
      <Body>
        <ContentContainer>
          <Breadcrumbs crumbs={breadcrumbs} />

          {framesList.length >= 2
            ? (
              <>
                <SessionDescription session={{
                  ...fullSession,
                  startedAt: date,
                }}
                />
                <PlotWrapper id="wrapper">
                  {gradeContextItem !== null && (
                    <ActivityPlot
                      frames={framesList}
                      insight={insight}
                      item={gradeContextItem}
                      markers={markers}
                    />
                  )}
                </PlotWrapper>
              </>
            )
            : (
              <MessageWrapper>No data available.</MessageWrapper>
            )}

          {activityRecommendations.recommendations && activityRecommendations.recommendations.length > 0 && (
            <TrainingRecommendationsWrapper>
              <Recommendations
                recommendations={activityRecommendations}
                onSubmitInstructorFeedback={handleSubmitInstructorFeedback}
              />
            </TrainingRecommendationsWrapper>
          )}

          <InstructorNoteWrapper>
            <Label>COMMENTS</Label>
            <NotesTextArea onSaveNote={handleSaveNote} note={activity ? activity.note : null} light />
          </InstructorNoteWrapper>
          <ContextOverlayNavigation
            onUpdateGrade={handleUpdateGrade}
            onSelectDemo={onSelectDemo}
            isDemo={isDemo}
            gradingContext={gradeContext}
            gradeableId={currentActivityId}
            instructorGradeLevel={instructorGrade && instructorGrade.gradeValue}
            predictedGradeLevel={predictedGrade && predictedGrade.gradeValue}
            onChangeActivity={handleChangeActivity}
          />
        </ContentContainer>
      </Body>
    </>
  );
};

export default GradeContext;

const MessageWrapper = styled.div`
  margin: 100px 5vw;
`;

const Title = styled.div`
  font-size: 24px;
  text-align: center;
  margin-top: 100px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
`;

const DateWrapper = styled.div`
  font-size: 12px;
  color: ${lightestGrey.string()};
  width: 100%;
  text-align: center;
  letter-spacing: 0.75px;
`;

const PlotWrapper = styled.div`
`;

const TrainingRecommendationsWrapper = styled.div`
  padding: 25px;
  max-width: 100%;
  border-top: 1px solid ${lightGrey.string()};
`;

const InstructorNoteWrapper = styled(TrainingRecommendationsWrapper)`
  border-bottom: 1px solid ${lightGrey.string()};
  margin-bottom: 25px;
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${lightestGrey.string()};
  letter-spacing: 0.75px;
  margin-bottom: 5px;
`;
