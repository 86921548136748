import { SessionRunType, FullSessionType } from './constants';
import { ActivityType } from '../activities/types';

export const UPDATE_SESSION_DETAILS = 'Received meta data.';
export const UPDATE_RUNS = 'Received meta data for session runs.';
export const CREATE_ACTIVITY = 'Creating new activity...';
export const FETCH_CURRENT_EQUIPMENT_ACTIVE_RUNS = 'Fetching active runs on current equipment.';
export const SET_CURRENT_EQUIPMENT_ACTIVE_RUNS = 'Received active runs on current equipment.';
export const END_ACTIVE_SESSIONS = 'Ending active sessions using current equipment.';
export const ACTIVE_SESSIONS_ENDED = 'Active sessions using current equipment ended.';

export const updateSession = (data: FullSessionType) => ({
  type: UPDATE_SESSION_DETAILS,
  payload: { data },
} as const);

export const updateRuns = (runs: SessionRunType[]) => ({
  type: UPDATE_RUNS,
  payload: { runs },
} as const);

export const createActivity = (
  runId?: number | null,
  placeholderId?: string,
  activity?: Partial<ActivityType>,
) => ({
  type: CREATE_ACTIVITY,
  payload: { runId, placeholderId, activity },
} as const);

export const fetchCurrentEquipmentActiveRuns = (redirectTo: string) => ({
  type: FETCH_CURRENT_EQUIPMENT_ACTIVE_RUNS,
  payload: { redirectTo },
} as const);

export const setCurrentEquipmentActiveRunsIds = (runIds: number[], canStartSession: boolean) => ({
  type: SET_CURRENT_EQUIPMENT_ACTIVE_RUNS,
  payload: { runIds, canStartSession },
} as const);

export const endActiveSessions = () => ({
  type: END_ACTIVE_SESSIONS,
  payload: { },
} as const);

export const activeSessionsEnded = () => ({
  type: ACTIVE_SESSIONS_ENDED,
  payload: { },
} as const);

export type UpdateSessionAction = ReturnType<typeof updateSession>;
export type UpdateRunsAction = ReturnType<typeof updateRuns>;
export type CreateActivityAction = ReturnType<typeof createActivity>;
export type FetchCurrentEquipmentActiveRuns = ReturnType<
  typeof fetchCurrentEquipmentActiveRuns
>;
export type SetCurrentEquipmentActiveRunsIdsAction = ReturnType<
  typeof setCurrentEquipmentActiveRunsIds
>;
export type EndActiveSessions = ReturnType<typeof endActiveSessions>;
export type ActiveSessionsEnded = ReturnType<typeof activeSessionsEnded>;

export type SessionAction =
  | UpdateSessionAction
  | UpdateRunsAction
  | CreateActivityAction
  | FetchCurrentEquipmentActiveRuns
  | SetCurrentEquipmentActiveRunsIdsAction
  | EndActiveSessions
  | ActiveSessionsEnded;
