import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMatches, useParams } from 'react-router';
import { RequiredNotNull } from '@/types/helper.types';
import { useAppDispatch, useAppSelector } from '@/store';
import { Header } from '@/components/Header';
import { BreadcrumbListType, Breadcrumbs } from '@/components/Breadcrumbs';
import Body from '../../../ui/Body';
import { SessionForm, SessionFormValues } from '../SessionForm';
import PageHeader from '../../../ui/PageHeader';
import { home, schedule as scheduleRoute, sessionOverview } from '../../../utils/routes';
import Title from '../../../ui/head/Title';
import Loading from '../../../ui/Loading';
import ContentContainer from '../../../ui/ContentContainer';
import * as actions from '../../actions';
import { ScheduleSessionActionProps as ScheduleType } from '../../actions';
import * as dashboardActions from '../../../dashboard/actions';
import { CourseType } from '../../constants';

const CreateSessionScreen = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{sessionId:string}>();
  const sessionId = parseInt(params.sessionId!, 10);
  const schedule = useAppSelector(state => state.schedule);
  const dashboard = useAppSelector(state => state.dashboard);
  const sessionListSelector = useAppSelector(state => state.sessionList.toJS());
  const { sessions } = sessionListSelector;
  const sessionIndex = sessions.findIndex(({ id }:any) => id === sessionId);
  const session = sessions[sessionIndex];
  const courseList: CourseType[] = schedule.courseList.toJS();

  const isScheduleLoading = schedule.get('isLoading');
  const isScheduleSubmitting = schedule.get('isSubmitting');
  const isSessionLoading = sessionId ? sessionListSelector.isLoading : false;
  const isLoading = isScheduleLoading || isSessionLoading;

  const equipmentList = dashboard.equipment.toJS();
  const onComponentMounted = useCallback((id:number) => {
    if (id) {
      dispatch(actions.editSessionDetailsScreenLoaded(id));
    } else {
      dispatch(actions.createSessionScreenLoaded());
    }
  }, [sessionId]);
  const onScheduleSession = (data: ScheduleType) => dispatch(actions.scheduleSession(data));
  const onEditSession = (data: ScheduleType & { sessionId: number }) => dispatch(actions.editSession(data));

  const onDone = () => {
    dispatch(actions.setCoursePlans([]));
    dispatch(dashboardActions.equipmentDetailsUpdated([]));
  };
  const navigate = useNavigate();
  const title = sessionId ? 'Edit Session' : 'Schedule a New Session';

  const breadcrumbs:BreadcrumbListType = [
    {
      link: home(),
      title: 'Home',
    },
    {
      link: scheduleRoute(),
      title: 'Schedule',
    },
    ...(sessionId ? [{
      link: sessionOverview(sessionId),
      title: session?.lessonPlanShortName,
    }] : []),
    {
      title,
    },
  ];

  // Trigger "component mounted" callback.
  useEffect(() => {
    onComponentMounted(sessionId);
  }, [onComponentMounted, sessionId]);

  // Cancel handler.
  const cancelHandler = () => {
    // TODO: trigger "cancel" saga, to cancel ongoing sagas and potentially
    // rollback committed changes.
    onDone();
    navigate(scheduleRoute());
  };

  // Submit handler.
  const submitHandler = (data: RequiredNotNull<SessionFormValues>) => {
    const {
      lessonPlan,
      pilotFlying,
      pilotMonitoring,
      equipment,
      scheduledDate,
      scheduledTime,
      isCrossTrainingSession,
      withStartAutomatically,
    } = data;

    const lessonKey = lessonPlan.split(/\s*-\s*/g);
    const lessonPlanData = {
      type: lessonKey[0],
      id: parseInt(lessonKey[1], 10),
    };

    let lessonPlanId = lessonPlanData.id;
    let lessonPlanVariationId: number | null = null;

    if (lessonPlanData.type === 'variation') {
      lessonPlanVariationId = lessonPlanData.id;
      courseList.forEach(_course => {
        _course.lessonPlans.forEach(lesson => {
          lesson.variations.forEach(variation => {
            if (variation.id === lessonPlanVariationId) {
              lessonPlanId = lesson.id;
            }
          });
        });
      });
    }

    const formData:ScheduleType = {
      lessonPlanId,
      lessonPlanVariationId: lessonPlanVariationId ?? undefined,
      pilotFlying: pilotFlying.value,
      pilotMonitoring: pilotMonitoring.value,
      equipmentId: Number(equipment),
      scheduledDate,
      scheduledTime,
      shouldCreateRelatedRun: isCrossTrainingSession,
      withStartAutomatically,
    };

    if (sessionId) {
      onEditSession({
        ...formData,
        sessionId,
      } as ScheduleType & { sessionId: number });
    } else {
      onScheduleSession(formData);
    }
  };

  if (equipmentList.length < 1 || isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Title title={title} />
      <Header />
      <ContentContainer>
        <Body>
          <Breadcrumbs crumbs={breadcrumbs} />
          <SessionForm
            courses={courseList}
            equipment={Object.values(equipmentList)}
            isLoading={isScheduleSubmitting}
            onCancel={cancelHandler}
            onSubmit={submitHandler}
            currentSession={session}
          />
        </Body>
      </ContentContainer>
    </>
  );
};

export default CreateSessionScreen;
