import moment from 'moment';
import { random } from 'lodash';

import {
  FullSessionType,
  SessionEventType,
  SessionRunType,
} from '../session/constants';
import { FrameType } from '../frames/constants';
import { ActivityType } from '../activities/types';

export const timestamp = (): string => moment().toISOString();

/**
 * Generates a random ID.
 */
export const id = (
  lower: number = 20,
  upper: number = 20000,
): number => random(lower, upper, false);

export const fullSession = (session?: object): FullSessionType => ({
  id: id(),
  name: 'Normal Procedures',
  shortName: 'NP',
  courseName: '',
  scheduledFor: '2019-01-01 14:00:00',
  startedAt: '2019-01-01 14:00:00',
  endedAt: null,
  trainees: {
    pilotFlying: {
      name: 'Jane Doe',
      id: id(),
    },
    pilotMonitoring: {
      name: 'John Smith',
      id: id(),
    },
  },
  ...session,
});

export const sessionRun = (run?: object): SessionRunType => ({
  id: id(),
  pilotFlying: {
    name: '',
    id: id(),
  },
  pilotMonitoring: {
    name: '',
    id: id(),
  },
  startedAt: '2019-01-01 14:00:00',
  endedAt: null,
  completedAt: null,
  comment: '',
  markers: [],
  activities: [],
  ...run,
});

export const activity = (act?: object): ActivityType => ({
  id: id(),
  name: 'Normal Takeoff',
  activityCode: null,
  malfunctionCode: null,
  ordering: 1,
  isDemo: false,
  isRepeated: false,
  repeatCount: 0,
  startedAt: null,
  endedAt: null,
  grades: [],
  note: null,
  sessionRun: 1,
  ...act,
});

export const sessionEvent = (event?: object): SessionEventType => ({
  id: id(),
  sessionRunId: id(),
  eventType: 3,
  data: 'Some Event',
  timestamp: null,
  ...event,
});

type FrameArgsType = Array<boolean | number | string>;
type FrameConverterType = (...params: FrameArgsType) => FrameType;
export const frameCreator = (toFrame: FrameConverterType) => {
  let frameId = id();
  const time = moment().subtract(1, 'day');

  return (...params: FrameArgsType) => {
    const activeMalfunctions: string[] = [];

    return {
      activeMalfunctions,
      data: toFrame(...params),
      id: frameId++,
      predictedActivityCode: 'UNKNOWN',
      timestamp: time.add(1, 'second').toISOString(),
    };
  };
};
