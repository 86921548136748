import { Record, RecordOf } from 'immutable';
import { InsightDataMapType } from '../utils/visualization/constants';

export interface InsightStore {
  activityCode: string | null,
  ksaCode: string | null,
  data: InsightDataMapType | null,
  isLoading: boolean,
}

export type ImmutableInsightStore = RecordOf<InsightStore>;

export const createInsightStore = Record<InsightStore>({
  activityCode: null,
  ksaCode: null,
  data: null,
  isLoading: false,
});

const defaultState = createInsightStore();

export default defaultState;
