import getCockpitPreparationVisualizations from '@/utils/visualization/activity/cockpitPreparetion';
import getDepartureVisualizations from '@/utils/visualization/activity/departure';
import * as constants from '../constants';
import getDefaultVisualizations from './default';
import getSteepTurnVisualizations from './steepTurn';
import getEngineStartVisualizations from './engineStart';
import getApproachVisualizations from './approach';
import getTakeoffVisualizations from './takeoff';
import getTakeoffOEIVisualizations from './takeoffOEI';
import getLandingVisualizations from './landing';
import getRejectedTakeoffVisualizations from './rejectedTakeoff';
import { getVisualizationCategory } from '../activityCategory';

import {
  ApproachInsightType,
  SteepTurnInsightType,
  TakeoffInsightType,
  LandingInsightType,
  VisualizationCategory,
  VisualizationHandlerType,
  BankTurnInsightType,
  VisualizationDataType,
  VisualizationMetaDataType,
  InsightType,
  CockpitPreparationInsightType, CockpitPreparationMetaType, DepartureInsightType,
} from '../constants';
import getGoAroundVisualizations from './goAround';
import getUprtVisualizations from './uprt';
import getStallVisualizations from './stall';
import getBankTurnVisualizations from './bankTurn';
import { SessionEventType } from '../../../session/constants';

// TODO: why can't we use a generic of the union type inside the Map type?
type HandlerType =
  | VisualizationHandlerType<ApproachInsightType>
  | VisualizationHandlerType<SteepTurnInsightType>
  | VisualizationHandlerType<BankTurnInsightType>
  | VisualizationHandlerType<TakeoffInsightType>
  | VisualizationHandlerType<LandingInsightType>
  | VisualizationHandlerType<CockpitPreparationInsightType, CockpitPreparationMetaType>
  | VisualizationHandlerType<DepartureInsightType>
  | VisualizationHandlerType<void>;

const handlers = new Map<VisualizationCategory, HandlerType>([
  [constants.CATEGORY_APPROACH, getApproachVisualizations],
  [constants.CATEGORY_ENGINE_START, getEngineStartVisualizations],
  [constants.CATEGORY_STEEP_TURN, getSteepTurnVisualizations],
  [constants.CATEGORY_BANK_TURN, getBankTurnVisualizations],
  [constants.CATEGORY_TAKEOFF_OEI, getTakeoffOEIVisualizations],
  [constants.CATEGORY_TAKEOFF, getTakeoffVisualizations],
  [constants.CATEGORY_LANDING, getLandingVisualizations],
  [constants.CATEGORY_REJECTED_TAKEOFF, getRejectedTakeoffVisualizations],
  [constants.CATEGORY_GO_AROUND, getGoAroundVisualizations],
  [constants.CATEGORY_UNUSUAL_ATTITUDE_RECOVERY, getUprtVisualizations],
  [constants.CATEGORY_STALL, getStallVisualizations],
  [constants.CATEGORY_COCKPIT_PREPARATION, getCockpitPreparationVisualizations],
  [constants.CATEGORY_DEPARTURE, getDepartureVisualizations],
]);

const getVisualizationsForActivity = <TData extends InsightType, TMeta extends Object | void = void>(
  activity:string,
  data: VisualizationDataType<TData>,
  markers: SessionEventType[],
  metaData:VisualizationMetaDataType<TMeta>,
) => {
  const category = getVisualizationCategory(activity);

  const handler = handlers.get(category) || getDefaultVisualizations;
  return handler(
    activity,
    // TODO: investigate more about VisualizationDataType
    data as VisualizationDataType<any>,
    markers,
    metaData as any,
  );
};

export default getVisualizationsForActivity;
