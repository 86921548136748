import * as React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

import UnicodeWasteBasket from '../../../ui/svg/UnicodeWasteBasket';
import { gradeLevel1 } from '../../../theme/palette';

export const DroppableArea = styled.div<{ $isVisible?: boolean }>`
  position: sticky;
  top: 40vh;
  left: 2vw;
  height: 20vh;
  width: 10vw;

  opacity: ${props => (props.$isVisible ? 1 : 0)};
  transition: opacity ${props => props.theme.transitionTime};
`;

const WasteBinWrapper = styled.div<{ $isActive?: boolean }>`
  position: relative;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${props => (
    props.$isActive
      ? gradeLevel1.string()
      : gradeLevel1.fade(0.8).string()
  )};
  border-radius: 3px;
  color: ${gradeLevel1.fade(0.5).string()};
  transition: all ${props => props.theme.transitionTime};
`;

const IconWrapper = styled.div`
  width: 2em;
  padding: 1em 0.5em;
`;

export const WasteBin = ({ isActive }: { isActive?: boolean }) => (
  <WasteBinWrapper $isActive={isActive}>
    <IconWrapper>
      <UnicodeWasteBasket fill={gradeLevel1.darken(0.5).string()} />
    </IconWrapper>
  </WasteBinWrapper>
);

WasteBin.defaultProps = { isActive: false };

const ActivityWasteBin = ({ isVisible }: { isVisible: boolean }) => (
  // @ts-ignore
  <Droppable droppableId="remove-activity" type="ACTIVITY">
    {(provided, { draggingOverWith }) => (
      <DroppableArea ref={provided.innerRef} {...provided.droppableProps} $isVisible={isVisible}>
        <>
          <WasteBin isActive={!!draggingOverWith} />
          {provided.placeholder}
        </>
      </DroppableArea>
    )}
  </Droppable>
);

export default ActivityWasteBin;
