import { ClientInterface } from './client';

// API
export default class GradingSheets {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  /**
   * Lists grading sheets for the training center.
   */
  list = async (
    limit: number = 20,
    offset: number = 0,
    ordering: string = '',
    extraParams: Object = {},
  ): Promise<any> => this.client.getResource('/grading-sheets/', {
    ...extraParams,
    limit,
    offset,
    ordering,
  });

  /**
   * Retrieves a grading sheet by ID.
   */
  get = async (id: number): Promise<any> => this.client.getResource(`/grading-sheets/${id}/`);
}
