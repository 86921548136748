import * as Plotly from 'plotly.js';
import { FrameType } from '../../../frames/constants';
import * as palette from '../../../theme/palette';

const getDataPointText = (value: number | boolean, param: string) => {
  if (typeof value === 'boolean') {
    if (param === 'parkingBrakeEngaged') {
      return value ? 'SET' : 'OFF';
    }
    return value ? 'ON' : 'OFF';
  }
  if (value === 0) {
    return 'OFF';
  }
  if (param === 'gen_1ApuSw') {
    return value === 1 ? 'RESET' : 'ON';
  }
  if (value === 1) {
    return 'ON';
  }
  if (param === 'emerInternLightsSw') {
    return 'ARM';
  }
  if (param === 'stbyPwrSw') {
    return 'TEST';
  }
  if (param === 'anticolLightsSw') {
    return 'ON WITH WING STROBES';
  }
  return '?';
};

const idealValueMap = new Map([
  ['battSw', 'ON'],
  ['gen_1ApuSw', 'ON'],
  ['navLightsSw', 'ON'],
  ['emerInternLightsSw', 'ARM'],
  ['parkingBrakeEngaged', 'SET'],
  ['floodLightsSw', 'ON'],
  ['stbyPwrSw', 'ON'],
  ['anticolLightsSw', 'ON'],
]);

const getChecklistAnnotations = (
  frames: FrameType[],
  data: Plotly.Data[],
  layout: Partial<Plotly.Layout>,
  params: string[],
) => {
  params.forEach(param => {
    const paramIndex = data.findIndex(({ name }) => name === param);
    const indices = [0];
    const yValues: Array<boolean | number> = [];
    frames.forEach(frame => {
      // @ts-ignore
      yValues.push(frame.data[param]);
    });
    for (let i = 1; i < frames.length; i++) {
      if (yValues[i] !== yValues[i - 1]) {
        indices.push(i);
      }
    }
    indices.forEach(index => {
      const text = getDataPointText(yValues[index], param);
      const idealValue = idealValueMap.get(param);
      const xPaperValue = index / frames.length;
      layout.annotations && layout.annotations.push({
        xref: 'paper',
        // @ts-ignore
        yref: data[paramIndex].yaxis,
        yanchor: 'bottom',
        x: xPaperValue,
        y: 0.1,
        text,
        showarrow: false,
        font: {
          color: idealValue === text
            ? palette.gradeLevel9.string()
            : palette.white.darken(0.1).string(),
          size: 16,
        },
      });
    });
  });
};

export default getChecklistAnnotations;
