import { List, Map, Record, RecordOf } from 'immutable';
import { FullSessionType, SessionRunType } from './constants';

export type SessionStore = {
  currentSession: Map<keyof FullSessionType, any>,
  runs: List<SessionRunType>,
  currentEquipmentActiveRunIds: List<number>,
  canStartSession: boolean
};

export type ImmutableSessionStore = RecordOf<SessionStore>;

export const createSessionStore = Record<SessionStore>({
  currentSession: Map(),
  runs: List(),
  currentEquipmentActiveRunIds: List(),
  canStartSession: false,
});

const defaultState: ImmutableSessionStore = createSessionStore();

export default defaultState;
