import React, { FC } from 'react';
import styled from 'styled-components';
import { TOP_BAR_HEIGHT, TOP_BAR_WIDTH } from '../../constants';

type TopBarProps = {
    x: number,
    y: number,
    label: string,
    labelPosition?: 'start' | 'end',
    value: string
    top: {
        value: string,
        color?:string
    }
    bottom: {
        value:string,
        color?:string
    }
}
export const TopBar:FC<TopBarProps> = ({
  x,
  y,
  label,
  value,
  top,
  bottom,
  labelPosition = 'start',
}) => (
  <foreignObject x={x} y={y} width={TOP_BAR_WIDTH} height={TOP_BAR_HEIGHT}>
    <TopBarWrapper>
      <TopBarLabel $labelPosition={labelPosition}>{label}</TopBarLabel>
      <TopBarInfo>
        <TopBarNearValue color={top.color}>{top.value}</TopBarNearValue>
        <TopBarRect color={top.color} />
        <TopBarValue>{value}</TopBarValue>
        <TopBarRect color={bottom.color} />
        <TopBarNearValue color={bottom.color}>{bottom.value}</TopBarNearValue>
      </TopBarInfo>
    </TopBarWrapper>
  </foreignObject>
);

export default TopBar;

const TopBarWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    font-family: Arial, Arial,serif;
    line-height: 1;
`;

const TopBarLabel = styled.div<{$labelPosition: TopBarProps['labelPosition']}>`
    display: flex;
    color: white;
    text-transform: uppercase;
    font-size: 26px;
    order: ${props => (props.$labelPosition === 'start' ? 1 : 3)};
`;

const TopBarInfo = styled.div`
    display: flex;
    flex-direction: column;
    order: 2;
`;
const TopBarNearValue = styled.div<{color?:string}>`
    display: flex;
    flex-direction: column;
    font-size: 22px;
    margin: 2px 0;
    color: ${props => props.color || 'white'};
`;

const TopBarRect = styled.div<{color?:string}>`
    width: 20px;
    height: 30px;
    margin: 0 auto;
    background: ${props => props.color || 'white'};
`;
const TopBarValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 42px;
    border: 2px solid white;
    font-size: 26px;
`;
