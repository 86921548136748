import getAirPlotOptions from './air';
import { annotationStickShaker, annotationV2 } from '../annotations';
import { PlotlyHandlerType } from '../types';

const getRejectedLandingPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  // The rejected landing plots are the same as the in-air plots, but with annotations.
  const plotOptions = getAirPlotOptions(frames, markers, activityCode, metaData);

  if (metaData.trainingCenter !== 'NLA') {
    annotationV2(frames, plotOptions, 'cas');
    annotationStickShaker(frames, plotOptions, 'cas');
  }

  return plotOptions;
};

export default getRejectedLandingPlotOptions;
