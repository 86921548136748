import { eventChannel, Task } from 'redux-saga';
import {
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
  Effect,
  takeLatest, getContext,
} from 'redux-saga/effects';
import moment from 'moment';
import { createBrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchSessionData } from '@/session/sagas';
import { updateCurrentRun } from '@/activities/actions';
import { endActiveSessions } from '@/session/actions';
import * as actions from './actions';
import { getPollingSimulatorStatusSubscriber } from './subscribers/polling';
import { getRunId } from './selectors';
import { SimulatorStatusResponse } from './types';
import api from '../api';
import * as frameActions from '../frames/actions';

const createLiveSimulatorStatusChannel = (runId: number) => eventChannel(getPollingSimulatorStatusSubscriber(runId));

/**
 * Listens on the event channel and passes on live session data to the
 * Redux store.
 */
function* liveSessionSimulatorStatusListener(): Generator<Effect, void, any> {
  const runId = yield select(getRunId);
  const liveSessionChannel = yield call(createLiveSimulatorStatusChannel, runId);

  try {
    while (true) {
      const simulatorStatus: SimulatorStatusResponse = yield take(liveSessionChannel);

      yield put(actions.trainingSessionSimulatorStatusEvent(simulatorStatus));
    }
  } finally {
    if (yield cancelled()) {
      liveSessionChannel.close();
    }
  }
}

/**
 * Records an event.
 */
function* recordEvent({ payload }: actions.RecordEventAction) {
  const { sessionRunId, eventType, data } = payload;

  try {
    const event = {
      data,
      eventType,
      sessionRunId,
      timestamp: moment().toISOString(),
    };

    // @ts-ignore
    yield call(api.events.create, event);
  } catch (error) {
    api.logError(error as any);
  }
}

/**
 * Marks a session run as ended.
 */
function* endRun({ payload }: actions.EndRunAction) {
  const router: ReturnType<typeof createBrowserRouter> = yield getContext('router');

  const { id, redirectTo } = payload;

  try {
    yield call(api.sessions.endRun, id, dayjs().toISOString());
    yield put(frameActions.clearFrames());

    if (redirectTo) {
      yield call(router.navigate, redirectTo);
    }
  } catch (error) {
    api.logError(error as any);
  }
}

/**
 * Marks a session runs as ended.
 */
function* endMultipleRuns({ payload }: actions.EndMultipleRunsAction) {
  const router: ReturnType<typeof createBrowserRouter> = yield getContext('router');

  const { ids, redirectTo } = payload;

  try {
    for (const runId of ids) {
      yield call(api.sessions.endRun, runId, dayjs().toISOString());
    }

    yield put(frameActions.clearFrames());
    yield call(endActiveSessions);

    if (redirectTo) {
      yield call(router.navigate, redirectTo);
    }
  } catch (error) {
    api.logError(error as any);
  }
}
/**
 * Marks a session runs as ended.
 */
function* endSessionRuns({ payload }: actions.EndSessionRunAction) {
  const router: ReturnType<typeof createBrowserRouter> = yield getContext('router');

  const { id, redirectTo } = payload;

  try {
    yield call(api.sessions.endSession, id, dayjs().toISOString());

    yield put(frameActions.clearFrames());
    yield call(endActiveSessions);

    if (redirectTo) {
      yield call(router.navigate, redirectTo);
    }
  } catch (error) {
    api.logError(error as any);
  }
}

/**
 * Live session watchers.
 */
export default {
  // Determines when to start and stop listening for live session data.
  * watchTrainingSession(): Generator<Effect, void, any> {
    while (true) {
      const action = yield take([actions.TRAINING_SCREEN_LOADED]);
      const isActive = action.type === actions.TRAINING_SCREEN_LOADED;
      const [_, sessionRuns] = yield call(fetchSessionData, action);

      if (action.type === actions.TRAINING_SCREEN_LOADED && sessionRuns?.at(0)?.id) {
        // TODO SHOULD BE REFACTORED, done due to POC for task CU-8688h49d9
        const runID = sessionRuns.at(0).id;

        yield call(
          api.sessions.startRun,
          runID,
          dayjs().toISOString(),
        );

        yield put(actions.trainingSessionActive(isActive, runID));
        yield put(updateCurrentRun(runID));
      }
    }
  },

  // Updates live session data as it comes into the app.
  * watchLiveSessionListener(): Generator<Effect, void, any> {
    let simulatorStatuslistener: Task | null = null;

    while (true) {
      const { type } = yield take([actions.TRAINING_SESSION_STARTED, actions.TRAINING_SESSION_ENDED]);

      if (simulatorStatuslistener) {
        simulatorStatuslistener.cancel();
        simulatorStatuslistener = null;
      }

      if (type === actions.TRAINING_SESSION_STARTED && !simulatorStatuslistener) {
        simulatorStatuslistener = yield fork(liveSessionSimulatorStatusListener);
      }
    }
  },

  * watchRecordEvent(): Generator<Effect, void, void> {
    yield takeEvery(actions.RECORD_EVENT, recordEvent);
  },

  * watchEndRun(): Generator<Effect, void, void> {
    yield takeLatest(actions.END_RUN, endRun);
  },

  * watchEndMultipleRuns(): Generator<Effect, void, void> {
    yield takeLatest(actions.END_MULTIPLE_RUN, endMultipleRuns);
  },
};
