import React, { forwardRef } from 'react';
import styled from 'styled-components';
import * as palette from '../../../../../theme/palette';

type HDGLabelProps = {
    width: number,
    height: number,
    x: number,
    y: number,
    label: string,
    color?: string,
    labelPosition?: 'start' | 'end',
    value: string
}

export const HDGLabel = forwardRef<SVGForeignObjectElement, HDGLabelProps>(({
  width,
  height,
  x,
  y,
  label,
  value,
  color = palette.white,
  labelPosition = 'start',
}, ref) => (
  <foreignObject ref={ref} x={x} y={y} width={width} height={height}>
    <HDGLabelWrapper>
      <HDGLabelValue $color={color?.toString()}>{value}</HDGLabelValue>
      <HDGLabelLabel $labelPosition={labelPosition}>{label}</HDGLabelLabel>
    </HDGLabelWrapper>
  </foreignObject>
));

export default HDGLabel;

const HDGLabelWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 26px;
    font-family: Arial, Arial, serif;
    line-height: 1;
`;

const HDGLabelLabel = styled.div<{ $labelPosition: HDGLabelProps['labelPosition']}>`
    display: flex;
    color: white;
    text-transform: uppercase;
    text-align: ${props => (props.$labelPosition === 'start' ? 'right' : 'left')};
    order: ${props => (props.$labelPosition === 'start' ? 1 : 3)};
`;

const HDGLabelValue = styled.div<{$color:string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 42px;
    border: 2px solid white;
    color: ${props => props.$color};
    order: 2;
`;
