import * as Plotly from 'plotly.js';
import { ConditionType, getConditions } from '../../simData';
import { FrameType } from '../../../frames/constants';

type ConditionsType = {
  conditionTraces: Plotly.Data[],
  activeConditions: ConditionType[],
};

const getConditionTraces = (frames: FrameType[]): ConditionsType => {
  const conditionDataPoints: {
    conditionTraces: Plotly.Data[],
    activeConditions: ReturnType<typeof getConditions>,
  } = {
    conditionTraces: [],
    activeConditions: getConditions(frames),
  };
  let num = 0;

  conditionDataPoints.activeConditions.forEach(({ code }) => {
    conditionDataPoints.conditionTraces.push({
      line: {
        color: code === 'Windshear' ? 'white' : '#f2813d',
        width: 14,
      },
      mode: 'lines',
      name: code,
      type: 'scatter',
      xaxis: `x${num + 2}`,
      yaxis: `y${num + 2}`,
      y: [],
      showlegend: false,
    });
    num++;
  });

  return conditionDataPoints;
};

export default getConditionTraces;
