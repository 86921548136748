// eslint-disable-next-line no-shadow
export enum RejectedTakeoffNorm {
  MalfunctionDescription = 'mff',
  ReverseThrustStowed = 'rev_stowed',
  BrakesActive = 'brake',
  ReverseThrustDeployed = 'rev_deployed',
  Turnoff = 'stopped',
  SpeedbrakeStowed = 'speedbrake_stowed',
  SpeedbrakeDeployed = 'speedbrake_deployed',
  ThrustLeverIdle = 'tla',
  AutobrakeActive = 'autobrake'
}
