import { Layout } from 'plotly.js';
import { VisualizationMetaDataType } from '../visualization/constants';
import { AircraftType } from '../../common/aircraftType';
import { AircraftDesignator } from '../../common/aircraftDesignator';
import { FrameType } from '../../frames/constants';

export const getEngineParam = (
  aircraftDesignator: string,
  aircraftType: string | null | undefined,
) => {
  if (
    aircraftDesignator === AircraftDesignator.HELICOPTER_H145_135
    || (aircraftType && aircraftType === AircraftType.prop)
  ) {
    return ['eng_1Trq', 'eng_2Trq'];
  }
  if (aircraftDesignator === 'ALX') {
    return ['eng_1ManifoldPressure'];
  }
  return ['eng_1N1', 'eng_2N1'];
};

export const getRejectedTakeoffBrakeParam = (aircraftDesignator: string) => (aircraftDesignator === 'A320'
  ? ['brakePedPositionPilotL', 'brakePedPositionPilotR']
  : ['brakePedForcePilotL', 'brakePedForcePilotR']);

const hasFramesData = (frames: any[], param: string): boolean => {
  const values = frames.map(frame => frame.data[param]);
  const uniqueValues = [...new Set(values)];
  return !!uniqueValues.length && uniqueValues[0] !== undefined;
};

export const getLandingPlotParams = (
  metaData: VisualizationMetaDataType,
  frames: any[],
) => {
  const plotParamsMap = new Map([
    [
      AircraftDesignator.HELICOPTER_H145_135,
      [
        'radalt',
        'gndSpeed',
        'pitch',
        getEngineParam(metaData.aircraftDesignator, metaData.aircraftType),
      ],
    ],
    [
      'A320',
      [
        'gndSpeed',
        [
          'brakeTemp_1Ind',
          'brakeTemp_2Ind',
          'brakeTemp_3Ind',
          'brakeTemp_4Ind',
        ],
        ['brakePedPositionPilotL', 'brakePedPositionPilotR'],
        getEngineParam(metaData.aircraftDesignator, metaData.aircraftType),
      ],
    ],
  ]);

  const landingPlotParams = plotParamsMap.get(metaData.aircraftDesignator) || [
    'gndSpeed',
    ['brakeTempRatioL', 'brakeTempRatioR'],
    ['brakePedForcePilotL', 'brakePedForcePilotR'],
    getEngineParam(metaData.aircraftDesignator, metaData.aircraftType),
  ];

  const removeParams = landingPlotParams
    .flat()
    .filter(param => !hasFramesData(frames, param));

  const params = landingPlotParams.filter(param => {
    if (Array.isArray(param)) {
      const [param1, param2] = param;
      return !removeParams.includes(param1) && !removeParams.includes(param2);
    }

    return !removeParams.includes(param);
  });

  return params;
};

export const filterParamsByFramesValue = (params: string[], frames: FrameType[]): string[] => params.filter(param => {
  const [hasValue] = [
    ...new Set(frames.map(frame => !!frame.data[param])),
  ];
  return hasValue;
});

export const withGlobalPlotlyStyles = (layout: Partial<Layout>):Partial<Layout> => {
  const globalFont = {
    family: 'Roboto, sans-serif',
  };

  return {
    showlegend: false,
    paper_bgcolor: 'transparent',
    ...layout,
    font: { ...globalFont, ...layout.font },
  };
};
