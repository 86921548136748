import { ClientInterface } from './client';

// API
export default class Equipment {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  /**
   * Lists available courses.
   */
  list = async (params?: Object): Promise<any> => this.client.getResource('/equipment/', params);
}
