import React from 'react';
import styled, { keyframes } from 'styled-components';

const LogoPulseSVG = () => (
  <svg
    viewBox="0 0 68.4 85.7"
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
  >
    <linearGradient
      id="border"
      gradientUnits="userSpaceOnUse"
      x1="52.8783"
      y1="58.3485"
      x2="14.376"
      y2="21.0964"
    >
      <stop offset="0" style={{ stopColor: '#00FFEF' }} />
      <stop offset="4.448217e-02" style={{ stopColor: '#04ECE7' }} />
      <stop offset="0.1736" style={{ stopColor: '#0FBBD0' }} />
      <stop offset="0.3059" style={{ stopColor: '#1991BD' }} />
      <stop offset="0.4393" style={{ stopColor: '#206EAE' }} />
      <stop offset="0.5742" style={{ stopColor: '#2653A1' }} />
      <stop offset="0.7112" style={{ stopColor: '#2B4099' }} />
      <stop offset="0.8514" style={{ stopColor: '#2D3594' }} />
      <stop offset="1" style={{ stopColor: '#2E3192' }} />
    </linearGradient>
    <PulseBorder
      fill="none"
      stroke="url(#border)"
      strokeWidth="2"
      d="M52.1,39.7c0-6,1-11.7,2.8-16.1l0.3-0.7l-0.5-0.5c-5.4-4.9-12.7-7.7-20.4-7.7
        c-7.8,0-15,2.7-20.4,7.7L13.2,23l0.3,0.7c1.8,4.3,2.8,10.1,2.8,16.1c0,6-1,11.8-2.8,16.1
        l-0.2,0.4l0.2,0.4c5,9.1,18.8,13.5,20.3,14l0.3,0.1l0.3-0.1c0.6-0.2,15.2-4.5,20.4-14l0.2-0.4
        l-0.2-0.4C53.1,51.5,52.1,45.8,52.1,39.7z"
    />
    <linearGradient
      id="left"
      gradientUnits="userSpaceOnUse"
      x1="26.7124"
      y1="51.4626"
      x2="34.4328"
      y2="31.0614"
    >
      <stop offset="0" style={{ stopColor: '#00FFEF' }} />
      <stop offset="4.448217e-02" style={{ stopColor: '#04ECE7' }} />
      <stop offset="0.1736" style={{ stopColor: '#0FBBD0' }} />
      <stop offset="0.3059" style={{ stopColor: '#1991BD' }} />
      <stop offset="0.4393" style={{ stopColor: '#206EAE' }} />
      <stop offset="0.5742" style={{ stopColor: '#2653A1' }} />
      <stop offset="0.7112" style={{ stopColor: '#2B4099' }} />
      <stop offset="0.8514" style={{ stopColor: '#2D3594' }} />
      <stop offset="1" style={{ stopColor: '#2E3192' }} />
    </linearGradient>
    <path fill="url(#left)" d="M24.4,51.3H26c2.2,0,4.1-1.4,4.8-3.4l5.9-16.6h-5.2L24.4,51.3z" />
    <path fill="white" d="M44,51.3h-1.6c-2.2,0-4.1-1.4-4.8-3.4l-5.9-16.6h5.2L44,51.3z" />
  </svg>
);

export default LogoPulseSVG;

const PulseAnimation = keyframes`
  from {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(1);
  }
  to {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(1.5);
  }
`;

const PulseBorder = styled.path`
  fill: white;
  fill-opacity: 0;
  transform-origin: 50% 50%;
  animation-duration: 1.5s;
  animation-name: ${PulseAnimation};
  animation-iteration-count: infinite;
`;
