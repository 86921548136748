import getAirPlotOptions from './air';
import { getEngineParam, getRejectedTakeoffBrakeParam } from '../utils';
import { annotationStickShaker, annotationTR, annotationV1 } from '../annotations';
import { PlotlyHandlerType } from '../types';

const getRejectedTakeoffPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  const plotOptions = getAirPlotOptions(frames, markers, activityCode, metaData, [
    getRejectedTakeoffBrakeParam(metaData.aircraftDesignator),
    'cas',
    'hdgTrue',
    getEngineParam(metaData.aircraftDesignator, metaData.aircraftType),
  ]);

  if (metaData.trainingCenter !== 'NLA') {
    annotationTR(frames, plotOptions, metaData.aircraftDesignator);
    annotationV1(frames, plotOptions);
    annotationStickShaker(frames, plotOptions, 'cas');
  }

  return plotOptions;
};

export default getRejectedTakeoffPlotOptions;
