import { FrameType } from './constants';

export const FETCH_FRAMES = 'Fetching session frames';
export const UPDATE_FRAMES = 'Updating session frames';
export const CLEAR_FRAMES = 'Clearing session frames';

export const fetchFrames = (runId: number, activityId: number) => ({
  type: FETCH_FRAMES,
  payload: { runId, activityId },
} as const);

export const updateFrames = (list: FrameType[]) => ({
  type: UPDATE_FRAMES,
  payload: { list },
} as const);

export const clearFrames = () => ({ type: CLEAR_FRAMES } as const);

export type FetchFramesAction = ReturnType<typeof fetchFrames>;
export type UpdateFramesAction = ReturnType<typeof updateFrames>;
export type ClearFramesAction = ReturnType<typeof clearFrames>;
export type FramesAction =
  | FetchFramesAction
  | UpdateFramesAction
  | ClearFramesAction;
