import { call, Effect, put, select, take } from 'redux-saga/effects';

import * as actions from './actions';
import * as frameActions from '../frames/actions';
import * as activitySelectors from '../activities/selectors';
import api from '../api';

export default {
  * expectsGradeContext(): Generator<Effect, void, any> {
    while (true) {
      const action = yield take(frameActions.UPDATE_FRAMES);

      const { list, total } = action.payload;

      const runId = yield select(activitySelectors.selectRunId);
      const activityId = yield select(activitySelectors.selectActivityId);

      const activity = yield select(activitySelectors.selectActivityById, runId, activityId);

      const insightData = activity
        ? yield call(
          api.analytics.getActivityInsight,
          activity.id,
          (total > 0 && list[0].timestamp) || null,
          (total > 0 && list[total - 1].timestamp) || null,
        )
        : { activityCode: null, ksaComponentCode: null, insight: null };

      yield put(actions.insightDataLoaded(insightData));
    }
  },
};
