import getAirPlotOptions from './air';
import { PlotlyHandlerType } from '../types';

const getAutorotationLandingPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => getAirPlotOptions(frames, markers, activityCode, metaData, [
  'radalt',
  'cas',
  'pitch',
  'nr',
]);

export default getAutorotationLandingPlotOptions;
