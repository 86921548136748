import React from 'react';
import { FieldErrorMessage, FormColumn, InputField, InputLabel } from '@/styles/FormStyles';
import { DatePicker } from '@/components/DatePicker';
import { TimePicker } from '@/components/TimePicker';

interface InputProps {
    label: string,
    error?: string,
    type?: 'date' | 'time' | 'password',
    [key: string]: any;
}

const InputComponent = (props: Omit<InputProps, 'error'>) => {
  const { label, type, ...inputProps } = props;
  switch (type) {
    case 'date':
      return <DatePicker {...inputProps} />;
    case 'time':
      return <TimePicker {...inputProps} />;
    default:
      return <InputField {...inputProps} type={type} />;
  }
};

// Higher order components
export const ColumnInput = (props: InputProps) => {
  const { label, type, error, ...inputProps } = props;

  return (
    <FormColumn>
      <InputLabel>{label}</InputLabel>
      <InputComponent {...props} />
      {error ? (
        <FieldErrorMessage>
          {error}
        </FieldErrorMessage>
      ) : null}
    </FormColumn>
  );
};

ColumnInput.defaultProps = {
  type: 'text',
};
