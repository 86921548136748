type IdType = string | number;

export const home = () => '/';

export const instructorManager = () => '/manage';

export const login = () => ('/login');

export const logout = () => '/logout';

export const schedule = () => '/schedule';
export const createSession = () => '/schedule/new';
export const editSessionDetails = (id: IdType = ':sessionId') => `/schedule/edit/${id}`;

export const viewSession = (id: IdType = ':sessionId') => `/session/view/${id}`;
export const editSession = (id: IdType = ':sessionId') => `/session/edit/${id}`;
export const sessionOverview = (id: IdType = ':sessionId') => `/session/${id}`;

export const traineeProfile = (trainee: IdType = ':trainee') => `/trainee/${trainee}`;

export const liveSessionRun = (
  sessionId: IdType = ':sessionId',
) => `/training/${sessionId}`;

export const gradingSheet = (id: IdType = ':id') => `/summary/${id}`;
export const competencyRoute = (
  sessionId: IdType = ':sessionId',
  runId = ':runId',
  competency = ':competency',
) => `/summary/competency/${sessionId}/${runId}/${competency}`;
export const gradeContext = (
  sessionId: IdType = ':sessionId',
  runId: IdType = ':runId',
  activityId: IdType = ':activityId',
) => `context/${sessionId}/${runId}/${activityId}`;

export const userGuide = () => '/user-guide';

// For backwards compatibility.
export default {
  home,
  instructorManager,
  login,
  logout,
  schedule,
  createSession,
  editSessionDetails,
  viewSession,
  editSession,
  sessionOverview,
  liveSessionRun,
  gradingSheet,
};
