import { MapContainer, TileLayer } from 'react-leaflet';
import * as React from 'react';
import { FC, PropsWithChildren } from 'react';
import { MapContainerProps } from 'react-leaflet/lib/MapContainer';
import { MapPoint } from '@/types/map.types';
import './map.css';
import styled from 'styled-components';

const INITIAL_MAP_CONFIG = { zoom: 12, center: [12, 12] as MapPoint } as const;

type MapWrapperProps = {
  bounds?: [number, number][],
  zoom?: number,
  center?: number,
  height: string,
  width: string,
} & MapContainerProps
export const MapWrapper: FC<PropsWithChildren<MapWrapperProps>> = ({
  children,
  zoom = INITIAL_MAP_CONFIG.zoom,
  center = INITIAL_MAP_CONFIG.center,
  bounds,
  height, width,
  ...rest
}) => (
  <Map>
    <MapContainer
      center={center}
      zoom={zoom}
      bounds={bounds}
      touchZoom={false}
      scrollWheelZoom={false}
      doubleClickZoom={false}
      style={{
        height,
        width,
      }}
      {...rest}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution={'&copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'}
      />
      {children}
    </MapContainer>
  </Map>
);

const Map = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid ${props => props.theme.lightGrey.toString()};
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 24px;
`;

export default MapWrapper;
