import getDataPoints from '../data';
import getLayout from '../layout';
import getMarker from './marker';
import { getEngineParam } from '../utils';
import { getMaxValue } from '../annotations/utils';

import { PlotlyHandlerType } from '../types';

// @ts-ignore
const getAirPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  { aircraftDesignator, trainingCenter, aircraftType },
  params,
) => {
  // The relevant simulator parameters.
  const simParams = params || ['altMsl', 'cas', 'pitch', getEngineParam(aircraftDesignator, aircraftType), 'roc'];

  // Retrieve data points and malfunctions.
  const {
    conditions,
    traces: data,
    flaps,
  } = getDataPoints(simParams, frames, activityCode, trainingCenter);

  // Build layout JSON.
  const layout = getLayout(simParams, conditions, frames.length, flaps);

  // Adding Vertical Line for Flare Phase landing subplots.
  if (simParams[0] === 'radalt' && simParams[1] === 'roc' && simParams[2] === 'cas' && simParams[3] === 'pitch') {
    const condnPlotHeight = 0.05;
    const datum = 1 - 0 * condnPlotHeight;
    const wowIndex = frames.findIndex(({
      data: framesData,
    }) => framesData.wowAny === true || framesData.wowAny === 1.0);
    const maxValue = Number(getMaxValue(frames, 'radalt'));
    const lineEnd = maxValue > 100 ? maxValue - 10 : maxValue - 5;
    layout.shapes && layout.shapes.push({
      xref: 'paper',
      yref: 'paper',
      type: 'line',
      x0: wowIndex / frames.length,
      x1: wowIndex / frames.length,
      y0: 0,
      y1: datum - 0.088,
      yanchor: 'top',
      line: {
        color: 'white',
        width: 1,
      },
    });
    // Adding annotation "TD"
    layout.annotations && layout.annotations.push({
      x: wowIndex / frames.length,
      xanchor: 'left',
      xref: 'paper',
      y: Math.floor(lineEnd),
      yanchor: 'top',
      // @ts-ignore
      yref: `y${2}`,
      text: 'TD',
      font: {
        color: 'white',
      },
      showarrow: false,
    });
  }

  if (Array.isArray(markers) && markers.length > 0) {
    getMarker(frames, data, layout, markers);
  }

  return {
    data,
    layout,
    config: {
      displayModeBar: false,
      responsive: true,
    },
    style: {
      height: '100%',
      width: '100%',
    },
    useResizeHandler: true,
  };
};

export default getAirPlotOptions;
