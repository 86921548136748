import { END } from 'redux-saga';
import api from '../../api';
import config from '../../config';

export const getActivitiesClassifiedStatusSubscriber: any = (
  sessionId: number,
) => (sendUpdate: any) => {
  const intervalId = setInterval(async () => {
    try {
      const { data } = await api.sessions.pollActivitiesClassifiedStatus(
        sessionId,
      );
      sendUpdate(data);
    } catch (error) {
      sendUpdate(END);
    }
  }, config.pingRate);

  return () => clearInterval(intervalId);
};
