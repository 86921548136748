import { DataPointsType } from './data';
import { FrameType } from '../../frames/constants';

const getYValues = (
  plot: string,
  lowestValue: number,
  highestValue: number,
): number[] => {
  switch (plot) {
    case 'eng_1N1':
    case 'eng_1Trq':
      return [
        lowestValue - (lowestValue * 0.1),
        highestValue + (highestValue * 0.1),
      ];
    case 'roll':
      return [lowestValue - 10, highestValue + 10];
    // case 'pitch':
    //   return [lowestValue-3, highestValue + 10];
    case 'hdgTrue':
    // case 'cas':
      // eslint-disable-next-line no-case-declarations
      const normalizedMin = (lowestValue < 0 ? lowestValue + 360 : lowestValue);
      // eslint-disable-next-line no-case-declarations
      const normalizedMax = (highestValue < 0 ? highestValue + 360 : highestValue);
      return [normalizedMin - 20, normalizedMax + 20];
    case 'gndSpeed':
      return [lowestValue - 20, highestValue + 20];
    case 'altMsl':
      return [lowestValue - 300, highestValue + 300];
    case 'radalt':
      //  takeoff limit is 5000 feet
      return [lowestValue - 20, Math.min(highestValue + 20, 5000)];
    // case 'roc':
    //   return [lowestValue - 200, highestValue + 400];
    case 'nr':
      return [
        lowestValue - (lowestValue * 0.06),
        highestValue + (highestValue * 0.06),
      ];
    default:
      return [lowestValue, highestValue];
  }
};

export const getScale = (
  plotName: string | string[],
  dataPoints: DataPointsType,
  frames: FrameType[],
  numPlots: number,
) => {
  if (frames.length < 1) {
    return;
  }
  const name = Array.isArray(plotName) ? plotName[0] : plotName;
  const firstFrame = frames[0].data[name];
  const lastFrame = frames[frames.length - 1].data[name];

  if (typeof firstFrame === 'number' && typeof lastFrame === 'number') {
    const lowestValue = firstFrame > lastFrame ? lastFrame : firstFrame;
    const highestValue = firstFrame > lastFrame ? firstFrame : lastFrame;

    dataPoints.traces.push({
      line: { color: 'transparent' },
      showlegend: false,
      mode: 'lines',
      name: `${name}-scale`,
      type: 'scatter',
      xaxis: `x${numPlots + 2}`,
      yaxis: `y${numPlots + 2}`,
      x: [0, 0],
      y: getYValues(name, lowestValue, highestValue),
    });
  }
};
