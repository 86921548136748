import React from 'react';

import { isEmpty } from 'lodash';
import {
  getWindBox,
  getLandingLatDevLine,
  getLandingNormsMarkers,
} from './utils';
import RunwaySVG from '../Runway';
import { RunwaySVGProps } from '../Runway/types';
import CasMarkers from '../CasMarkers';
import { CasMarkerType, LandingInsightType, VisualizationDataType } from '../../../utils/visualization/constants';
import { CasMarkersProps } from '../CasMarkers/types';
import { minLandingCasMarkerValue } from './constants';
import NormsSvg from '../Norms';
import { NormMarkersProps } from '../Norms/types';
import { getNormalizers } from '../Runway/utils';

const LandingSVG = (data:VisualizationDataType<LandingInsightType>) => {
  if (
    isEmpty(data?.insight?.landing) || isEmpty(data.insight?.summary) || isEmpty(data?.insight?.landing)
  ) {
    return null;
  }
  const {
    insight: {
      landing: {
        runwayName,
        oppositeRunwayName,
        runwayLength,
        runwayWidth,
        casMarkers,
        windSpeed,
        windDirection,
        relWindDirection,
        latDev,
        runwayDisplacedThreshold,
        oppositeRunwayDisplacedThreshold,
        airportCode,
        norms,
      },
      summary: {
        payload,
      },
    },
    frames,
  } = data;

  const {
    normalizeFactor,
    normalizeFactorX,
    normalizedBottomThreshold,
    normalizedTopThreshold,
    getPosition,
  } = getNormalizers(
    runwayLength,
    runwayWidth,
    runwayDisplacedThreshold,
    oppositeRunwayDisplacedThreshold,
  );

  //  NORMS
  const normsSvg = getLandingNormsMarkers(
    payload,
    casMarkers,
    oppositeRunwayDisplacedThreshold,
    normalizeFactor,
  );

  const runwayProps: RunwaySVGProps = {
    runwayName,
    oppositeRunwayName,
    normalizedBottomThreshold,
    normalizedTopThreshold,
    normalizeFactor,
    runwayLength,
    airportCode,
  };

  const casMarkersProps: CasMarkersProps = {
    offset: normalizedTopThreshold,
    markers: (casMarkers as CasMarkerType[]).filter(
      marker => marker.cas >= minLandingCasMarkerValue,
    ),
    getPosition,
    normalizedRunwayLength: runwayLength * normalizeFactor,
    topThreshold: oppositeRunwayDisplacedThreshold,
  };

  const normMarkersProps: NormMarkersProps = {
    offset: normalizedTopThreshold,
    markers: normsSvg,
    getPosition,
    normalizedRunwayLength: runwayLength * normalizeFactor,
  };

  return (
    <RunwaySVG {...runwayProps}>
      {/* CAS Markers */}
      <CasMarkers {...casMarkersProps} />

      {/* Norms */}
      <NormsSvg {...normMarkersProps} />

      {/* Runway and Aircraft Conditions */}
      {getWindBox(windSpeed, windDirection, relWindDirection, frames[0].data, norms?.autobrake?.isActive)}

      {/* Lat Dev */}
      {getLandingLatDevLine(
        latDev,
        normalizeFactor,
        normalizeFactorX,
        normalizedTopThreshold,
        runwayLength * normalizeFactor,
        normMarkersProps.markers,
      )}
    </RunwaySVG>
  );
};

export default LandingSVG;
