import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import * as palette from '../../../theme/palette';
import { BankTurnInsightType } from '../../../utils/visualization/constants';
import { FlattenType } from '../../../types/helper.types';
import { TopBar, RollGroup, HDGLabel, ArcPathTriangle } from './components';

import {
  ARC_DIMENSIONS, EHdgXDirection,
  FINAL_HDG_LABEL_WIDTH,
  HDG_LABEL_HEIGHT,
  INITIAL_HDG_LABEL_WIDTH,
  ROLL_WIDTH,
  SVG_HEIGHT,
  SVG_WIDTH,
} from './constants';
import {
  getArcPath,
  getHdg,
  getHdgXDirection,
  getRoll,
  getStartLinePath,
  getTurnDiff,
  turnColors,
} from './utils';

export type BankTurnProps = {
    data: FlattenType<NonNullable<BankTurnInsightType['payload']>>
}

const getHdgPosition = (direction:number):{x:number, y:number, position:'start'|'end'} => {
  const angle = direction - 90;
  const angleInRadians = (angle * Math.PI) / 180.0;
  const directionType = getHdgXDirection(direction);
  const dx = 143 * Math.cos(angleInRadians) + 15;
  const dy = 103 * Math.sin(angleInRadians);
  switch (directionType) {
    case EHdgXDirection.NegativeStart: {
      const xVal = direction < -140 ? 300 : 160 + dx;
      const yVal = 391 + dy;
      return {
        x: xVal,
        y: yVal,
        position: 'start',
      };
    }
    case EHdgXDirection.NegativeEnd: {
      const xVal = direction < -270 ? 598 : 410 + dx;
      const yVal = direction < -270 ? 320 : 391 + dy;
      return {
        x: xVal,
        y: yVal,
        position: 'end',
      };
    }
    case EHdgXDirection.PositiveEnd: {
      const xVal = direction > 270 ? 15 : 180 + dx;
      const yVal = direction > 270 ? 320 : 391 + dy;

      return {
        x: xVal,
        y: yVal,
        position: 'start',
      };
    }
    case EHdgXDirection.PositiveStart:
    default: {
      const xVal = direction > 160 ? 390 : 400 + dx;
      const yVal = 391 + dy;
      return {
        x: xVal,
        y: yVal,
        position: 'end',
      };
    }
  }
};
export const BankTurnSVG: FC<BankTurnProps> = ({ data }) => {
  const { ref, width = 1, height = 1 } = useResizeObserver<SVGSVGElement>();
  const groupRef = useRef<SVGGElement>(null);
  const finalHDGRef = useRef<SVGForeignObjectElement>(null);
  const initialHDGRef = useRef<SVGForeignObjectElement>(null);
  const [groupWidth, setGroupWidth] = useState<number>(0);

  const {
    initialCas,
    maxCas,
    turnMaxCasGrade,
    minCas,
    turnMinCasGrade,
    initialAlt,
    maxAlt,
    turnMaxAltGrade,
    minAlt,
    turnMinAltGrade,
    averageRoll,
    maxRoll,
    turnMaxRollGrade,
    minRoll,
    turnMinRollGrade,
    initialHdg,
    finalHdg,
    direction,
  } = data;

  useLayoutEffect(() => {
    if (groupRef.current) {
      const bbox = groupRef.current.getBBox();
      const centerX = (SVG_WIDTH / 2) - (bbox.width / 2);

      groupRef.current.setAttribute('transform', `translate(${centerX + (-bbox.x)}, 0)`);
      setGroupWidth(groupRef.current.getBBox().width);
    }
  }, [groupRef.current, width, height]);

  const d = getArcPath({
    cx: ARC_DIMENSIONS.cx,
    cy: ARC_DIMENSIONS.cy,
    rx: ARC_DIMENSIONS.rx,
    ry: ARC_DIMENSIONS.ry,
    startAngle: ARC_DIMENSIONS.startAngle,
    sweepAngle: direction,
  });

  return (
    <BankTurnVisualisation
      ref={ref}
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
    >
      <g ref={groupRef}>
        <BaseGroup>
          <TopBar
            x={0}
            y={37}
            labelPosition="start"
            label="IAS"
            value={Math.round(initialCas).toString()}
            top={{
              value: getTurnDiff(initialCas, maxCas),
              color: turnColors.get(turnMaxCasGrade),

            }}
            bottom={{
              value: getTurnDiff(initialCas, minCas),
              color: turnColors.get(turnMinCasGrade),
            }}
          />
          <TopBar
            x={618}
            y={37}
            labelPosition="end"
            label="ALT"
            value={Math.round(initialAlt).toString()}
            top={{
              value: getTurnDiff(initialAlt, maxAlt),
              color: turnColors.get(turnMaxAltGrade),

            }}
            bottom={{
              value: getTurnDiff(initialAlt, minAlt),
              color: turnColors.get(turnMinAltGrade),
            }}
          />
          <RollGroup
            label="ROLL"
            x={(groupWidth / 2) - (ROLL_WIDTH / 2)}
            y={258}
            value={getRoll(averageRoll)}
            left={{
              value: Math.round(minRoll),
              color: turnColors.get(turnMinRollGrade),
            }}
            right={{
              value: Math.round(maxRoll),
              color: turnColors.get(turnMaxRollGrade),
            }}
          />

          <ArcPathTriangle direction={direction} />

          <g id="rollGroup" transform="translate(0,0)">
            <Line d={getStartLinePath(direction)} $withWhiteFill />
            <Arc
              markerEnd="url(#triangle)"
              id="Arc"
              d={d}
            />
          </g>
          <HDGLabel
            ref={initialHDGRef}
            height={HDG_LABEL_HEIGHT}
            width={INITIAL_HDG_LABEL_WIDTH}
            y={230}
            x={direction > 0 ? 0 : 598}
            value={getHdg(initialHdg)}
            label="INITIAL HDG"
            labelPosition={direction > 0 ? 'start' : 'end'}
          />
          <HDGLabel
            ref={finalHDGRef}
            height={HDG_LABEL_HEIGHT}
            width={FINAL_HDG_LABEL_WIDTH}
            y={getHdgPosition(direction).y - 19}
            x={getHdgPosition(direction).x}
            value={getHdg(finalHdg)}
            label="Final HDG"
            labelPosition={getHdgPosition(direction).position}
          />

        </BaseGroup>
      </g>
    </BankTurnVisualisation>
  );
};

export default BankTurnSVG;

const BankTurnVisualisation = styled.svg`
    height: 550px;
    width: 100%;
    margin-top: 100px;
    overflow: visible;

`;

const BaseGroup = styled.g`
    transform-origin: 50% 50%;
`;

const Line = styled.path<{ $withWhiteFill?: boolean }>`
    fill-rule: nonzero;
    ${props => (props.$withWhiteFill ? `fill: ${palette.white.string()};` : '')};
`;

const Arc = styled.path`
    stroke-width: 3;
    stroke: ${palette.white.string()};
`;
