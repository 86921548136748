import { ClientInterface } from './client';

import {
  CourseType,
  LessonPlanActivityType,
  LessonPlanVariationType,
} from '../schedule/constants';
import { ActivityOptionsParamsType } from '../activities/types';

export default class LessonPlans {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  courses = async (
    params: Object,
  ): Promise<CourseType[] | false> => this.client.getResource<CourseType[], false>(
    '/courses/',
    params,
  );

  createVariation = async (
    params: Object,
  ): Promise<LessonPlanVariationType | false> => this.client.postResource<LessonPlanVariationType, false>(
    '/lesson-variations/',
    params,
  );

  activities = async (
    params: ActivityOptionsParamsType,
  ): Promise<LessonPlanActivityType[] | false> => this.client.getResource<LessonPlanActivityType[], false>(
    '/lesson-plans/activities/',
    params,
  );

  approach = async (
    gradingSheet: number,
  ): Promise<LessonPlanActivityType[] | false> => this.client.getResource<LessonPlanActivityType[], false>(
    '/lesson-plans/approach/',
    { gradingSheet },
  );
}
