import React from 'react';
import Plotly from '@/ui/Plotly';
import styled from 'styled-components';
import { CockpitPreparationMetaType } from '@/utils/visualization/constants';
import { formatSeconds } from '@/utils/time';
import { PlotlyOptions } from '@/utils/plotly/types';
import { AlertBanner } from '@/components/AlertBanner';
import { Accordion } from '@/components/Accordion';
import HorizontalBarIcon from '@/assets/icons/HorizontalBarIcon.svg';

type CockpitPreparationProps = {
    plotData: PlotlyOptions,
    metaData: CockpitPreparationMetaType
}
export const CockpitPreparation = ({ plotData, metaData }: CockpitPreparationProps) => {
  const { data, banner } = metaData;
  const [first, ...rest] = data;

  return (
    <>
      <BlockContainer>
        <BlockWrapper>
          <BlockPrimary $color={first?.color}>
            <BlockTitle>{first?.title}</BlockTitle>
            <BlockValue>{formatSeconds(first?.time!)}</BlockValue>
          </BlockPrimary>
        </BlockWrapper>
        <BlockWrapper>
          {rest.map(val => (
            <Block key={val.title} $color={val?.color}>
              <BlockInfo>
                <BlockTitle>{val?.title}</BlockTitle>
                <BlockDescription>{val?.description}</BlockDescription>
              </BlockInfo>

              <BlockValue>{formatSeconds(val?.time!)}</BlockValue>
            </Block>
          ))}
        </BlockWrapper>
      </BlockContainer>
      <AccordionWrapper>
        <Accordion title="Highlights" iconPath={HorizontalBarIcon}>
          <ChartWrapper>
            <Plotly {...plotData} />
          </ChartWrapper>
        </Accordion>
      </AccordionWrapper>
      {!banner.hide ? (
        <AlertWrapper>
          <AlertBanner
            type="warning"
            title={banner.title}
            description={banner.label}
          />
        </AlertWrapper>
      ) : null}

    </>
  );
};

export default CockpitPreparation;

const BlockWrapper = styled.div`
    width: 510px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    column-gap: 8px;

`;

const BlockContainer = styled.div`
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
    margin-top: 48px;
    row-gap: 8px;
`;
const AccordionWrapper = styled.div`
    width: 700px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto 48px;
    display: flex;
    overflow: hidden;
`;

const ChartWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
`;

const Block = styled.div<{ $color?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
    width: 100%;
    border-top: 4px solid ${props => props.$color ?? props.theme.lightGrey.toString()};
    border-radius: 4px 4px 8px 8px;
    padding: 12px 12px 16px;
    background: ${props => props.theme.darkGrey.toString()};
    box-sizing: border-box;
`;

const BlockTitle = styled.div`
    font-size: 18px;
    line-height: 1;
    color: ${props => props.theme.white.toString()};
`;

const BlockInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    gap: 4px;

`;

const BlockDescription = styled.div`
    color: ${props => props.theme.lightestGrey.toString()};
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
`;

const BlockValue = styled.div`
    font-size: 32px;
    line-height: 1;
    color: ${props => props.theme.white.toString()};
`;

const BlockPrimary = styled(Block)<{ $color?: string }>`
    padding: 18px 22px 22px;
    gap: 8px;

    ${BlockTitle} {
        font-size: 18px;
        color: ${props => props.theme.white.toString()};
        line-height: 1;
    }

    ${BlockValue} {
        color: ${props => props.$color ?? props.theme.white.toString()};
        font-size: 40px;
        font-weight: 500;
        line-height: 1;
    }
`;

const AlertWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
`;
