import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import api from '../api';
import * as routes from '../utils/routes';
import * as actions from './actions';
import * as constants from './constants';
import * as messages from './messages';
import * as utils from './utils';
import { getUserInfo } from '../userProfile/slice';

/**
 * Authenticates a user against the API.
 */
function* authenticate({ payload }: actions.AuthenticateAction) {
  const { username, password } = payload;

  yield put(actions.setStatus(constants.AUTH_STATUS_VERIFYING));

  try {
    // Authenticate, and receive new token
    const {
      data: { access: accessToken, user },
    } = yield call(api.auth.authenticate, username, password);

    // Store JWT token.
    yield call(utils.storeToken, accessToken);

    // Set "instructor admin" flag
    const isInstructorAdmin = username.endsWith('@paladin.ai');
    yield call(utils.setIsInstructorAdmin, isInstructorAdmin);

    yield put(
      actions.setStatus(
        constants.AUTH_STATUS_AUTHENTICATED,
        accessToken,
        user,
        isInstructorAdmin,
      ),
    );
  } catch (error:any) {
    // Log errors, except for authentication errors.
    if (!error.request || ![400, 401, 403].includes(error.request.status)) {
      api.logError(error);
    }

    if (error.response && error.response.status === 400) {
      yield call(alert, 'Invalid username or password. Please try again.');
    }

    yield put(actions.setStatus(constants.AUTH_STATUS_ERROR));
  }
}

/**
 * Invalidates a token.
 */
function* invalidateToken() {
  // Invalidate token on API.
  try {
    yield call(api.auth.invalidate);
  } catch (error:any) {
    yield call(api.logError, error);
  }

  // Clear JWT token.
  yield call(utils.storeToken, null);
}

/**
 * Sends user to login page.
 */
type RedirectToLogin = {
  handleRedirect?: typeof window.location.assign;
  redirectTo?: string | null;
};

function* redirectToLogin({ handleRedirect }: RedirectToLogin) {
  // If we're already on the login page, do nothing.
  if (utils.isLoginRoute()) {
    return;
  }

  // Redirect handler.
  const redirect = handleRedirect || window.location.assign.bind(window.location);

  yield call(redirect, routes.login());
}

/**
 * Safely logs a user out of the dashboard.
 */
function* logout({ payload }: Partial<actions.LogoutAction>) {
  // Invalidate JWT.
  yield call(invalidateToken);

  // Unset "is instructor admin" flag.
  yield call(utils.setIsInstructorAdmin, false);

  // Send user to login page.
  const { message, redirectTo } = payload || {};

  if (typeof message === 'string' && message.length > 0) {
    yield call(alert, message);
  }

  yield call(redirectToLogin, { redirectTo });
}

/**
 * Validates a token.
 */
function* verifyToken({ payload }: actions.VerifyTokenAction) {
  try {
    // Authenticate and receive new token
    let { token } = payload;
    const {
      data: { token: newToken },
    } = yield call(api.auth.verify, token);
    if (newToken !== undefined) {
      token = newToken;
    }
    // user profile
    yield put(getUserInfo());
    yield put(actions.setStatus(constants.AUTH_STATUS_AUTHENTICATED, token));
  } catch (error:any) {
    if (!('response' in error) || error.response?.status !== 400) {
      api.logError(error);
    }
    yield put(actions.setStatus(constants.AUTH_STATUS_ERROR));
    yield call(logout, {
      payload: { message: messages.SESSION_EXPIRED, redirectTo: null },
    });
  }
}

/**
 * Sends the user to the login page.
 */
type RequireAuthenticationType = Generator<any, void, any>;
export function* requireAuthentication({
  payload,
}: actions.RequireTokenAction): RequireAuthenticationType {
  // Invalidate JWT.
  yield call(invalidateToken);

  // Send user to login page.
  yield call(redirectToLogin, { handleRedirect: payload.handleRedirect });
}

/**
 * Watchers.
 */
export default {
  * watchAuthenticate(): Generator<any, void, void> {
    yield takeEvery(actions.AUTHENTICATE, authenticate);
  },
  * watchRequireToken(): Generator<any, void, void> {
    yield takeLatest(actions.REQUIRE_TOKEN, requireAuthentication);
  },
  * watchVerifyToken(): Generator<any, void, void> {
    yield takeEvery(actions.VERIFY_TOKEN, verifyToken);
  },
  * watchLogout(): Generator<any, void, void> {
    // @ts-ignore
    yield takeEvery(actions.LOGOUT, logout);
  },
};
