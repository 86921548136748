/**
 * Sagas allow us to organize the logic and flow of how the state is updated.
 * A benefit of that is that Redux action-creators become pure and easier to
 * manage. As such, complex side-effects should be handled by Sagas.
 */
import activities from './activities/sagas';
import auth from './auth/sagas';
import competency from './competency/sagas';
import dashboard from './dashboard/sagas';
import frames from './frames/sagas';
import summary from './summary/sagas';
import insight from './insight/sagas';
import training from './training/sagas';
import recommendations from './recommendations/sagas';
import schedule from './schedule/sagas';
import session from './session/sagas';
import sessionList from './sessionList/sagas';
import instructor from './instructor/sagas';
import profile from './userProfile/sagas';

export const modules = [
  activities,
  auth,
  competency,
  dashboard,
  frames,
  summary,
  insight,
  training,
  recommendations,
  schedule,
  session,
  sessionList,
  instructor,
  profile,
];

export default (middleware: { run: Function }) => {
  modules.forEach(module => {
    const sagas = Array.isArray(module) ? module : Object.values(module);

    sagas.forEach(saga => middleware.run(saga));
  });
};
