import * as Plotly from 'plotly.js';
import { getGlidePathParams } from './utils';
import { getXAxis, getYAxis } from '../layout';
import { ApproachInsightPlot } from '../../visualization/constants';

const getApproachLayout = (
  simParamTags: string[],
  insight: ApproachInsightPlot,
): Plotly.Layout => {
  const { runwayRange, glideSlopeRange, latDevRange } = getGlidePathParams(insight);

  const layout: Plotly.Layout = {
    annotations: [],
    autosize: true,
    clickmode: 'none',
    dragmode: false,
    hovermode: false,
    margin: {
      l: 65,
      r: 10,
      t: 20,
      b: 40,
    },
    mode: 'lines',
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    shapes: [],
    showlegend: false,
    height: 800,
    width: 0,
    // @ts-ignore
    legend: [],
  };

  let axisNum = 2;
  const plotPadding = 0.1;
  const plotHeight = 1 / simParamTags.length;

  simParamTags.forEach((param, index) => {
    // @ts-ignore
    layout[`xaxis${axisNum}`] = getXAxis({
      showticklabels: false,
      ticks: '',
      anchor: (`y${axisNum}` as Plotly.AxisName),
      range: runwayRange,
      zeroline: false,
    });

    const datum = (simParamTags.length - index) * plotHeight;
    const yAxisRange = param === 'altMsl' ? glideSlopeRange : latDevRange;

    // @ts-ignore
    layout[`yaxis${axisNum}`] = getYAxis(
      [datum - plotHeight, datum - plotPadding],
      param,
      {
        range: yAxisRange,
        showticklabels: false,
        ticks: '',
        showline: false,
      },
    );
    axisNum++;
  });

  return layout;
};

export default getApproachLayout;
