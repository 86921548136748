import React from 'react';
import styled from 'styled-components';
import { lightestGrey, lightGrey } from '../theme/palette';
import PilotIcon from './svg/pilot-icon.svg';

type Props = {
  trainee: string;
  hideLine?: boolean
};

export const Trainee = ({ trainee, hideLine }: Props) => {
  const [traineeName, setTraineeName] = React.useState<string | null>(null);

  const handleSetDecryptedTraineeName = (name: string) => setTraineeName(name);

  if (traineeName === null) {
    handleSetDecryptedTraineeName(trainee);
  }

  return (
    <Wrapper hidden={traineeName === ''}>
      <Pilot>
        <Icon />
        {traineeName}
      </Pilot>
      <Line hidden={hideLine} />
    </Wrapper>
  );
};

Trainee.defaultProps = {
  hideLine: false,
};

export const Title = styled.div`
  font-size: 16px;
  color: ${lightestGrey.string()};
  line-height: 19px;
  padding: 15px 20px;
  border-bottom: 1px solid ${lightGrey.string()};
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin-bottom: 20px;
`;

const Wrapper = styled.div``;

const Pilot = styled.div`
  display: flex;
  padding: 0 20px;
  color: white;
`;

const Icon = styled.div`
  background: center center transparent no-repeat url('${PilotIcon}');
  background-size: contain;
  height: 28px;
  width: 28px;
  margin-right: 16px;
`;

const Line = styled.div`
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid ${lightGrey.string()};
`;
