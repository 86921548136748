import { ClientInterface } from './client';

export default class Instructors {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  /**
   * Creates a new instructor account.
   */
  create = async (username: string, password: string): Promise<any> => this.client.post('/instructors/', {
    username,
    password,
  });

  get = async (id: number): Promise<any> => this.client.getResource(`/instructors/${id}/`);

  getInstructorByUserId = async (user: number): Promise<any> => this.client.getResource('/instructors/', {
    user,
  });

  list = async (): Promise<any> => this.client.getResource('/instructors/');
}
