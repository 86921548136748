import { Record, RecordOf } from 'immutable';
import { RunIdType } from '../session/constants';
import { SimulatorStatus } from './types';

interface TrainingStore {
  isActive: boolean;
  runId: RunIdType | null;
  predictedActivityId: number | null;
  activeMalfunctions: string[];
  weatherConditions: string[];
  simulatorStatus: SimulatorStatus | null;
}

export type ImmutableTrainingStore = RecordOf<TrainingStore>;

export const createTrainingStore = Record<TrainingStore>({
  isActive: false,
  runId: null,
  predictedActivityId: null,
  activeMalfunctions: [],
  weatherConditions: [],
  simulatorStatus: null,
});

const defaultState: ImmutableTrainingStore = createTrainingStore();

export default defaultState;
