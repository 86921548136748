import { CompetencyCode } from './types';
import { ActivityType } from '../activities/types';

export const COMPETENCY_AFM_A = 'afmA';
export const COMPETENCY_AFM_M = 'afmM';
export const COMPETENCY_AOP = 'aop';
export const COMPETENCY_COM = 'com';
export const COMPETENCY_KNO = 'kno';
export const COMPETENCY_LTW = 'ltw';
export const COMPETENCY_PSD = 'psd';
export const COMPETENCY_SAW = 'saw';
export const COMPETENCY_WLM = 'wlm';

export const competencyShortNameMap: Map<CompetencyCode, string> = new Map([
  [COMPETENCY_SAW, 'Awareness'],
  [COMPETENCY_COM, 'Communication'],
  [COMPETENCY_AFM_A, 'Flight Path (Automatic)'],
  [COMPETENCY_AFM_M, 'Flight Path (Manual)'],
  [COMPETENCY_KNO, 'Knowledge'],
  [COMPETENCY_LTW, 'Leadership'],
  [COMPETENCY_PSD, 'Problem Solving'],
  [COMPETENCY_AOP, 'Procedures'],
  [COMPETENCY_WLM, 'Workload'],
]);

export const competencyShortNameMapTable: Map<CompetencyCode, string> = new Map([
  [COMPETENCY_SAW, '[SAW] - Situational Awareness'],
  [COMPETENCY_COM, '[COM] - Communication'],
  [COMPETENCY_AFM_A, '[FPM/A] - Flight Path Management, Automation'],
  [COMPETENCY_AFM_M, '[FPM/M] - Flight Path Management, Manual Control'],
  [COMPETENCY_KNO, '[KNO] - Application of Knowledge'],
  [COMPETENCY_LTW, '[LTM] - Leadership and Teamwork'],
  [COMPETENCY_PSD, '[PDM] - Problem Solving and Decision Making'],
  [COMPETENCY_AOP, '[AOP] - Application of Procedures '],
  [COMPETENCY_WLM, '[WLM] - Workload Management '],
]);

export const competencyShortKeyMapTable: Map<CompetencyCode, string> = new Map([
  [COMPETENCY_SAW, 'SAW'],
  [COMPETENCY_COM, 'COM'],
  [COMPETENCY_AFM_A, 'FPM/A'],
  [COMPETENCY_AFM_M, 'FPM/M'],
  [COMPETENCY_KNO, 'KNO'],
  [COMPETENCY_LTW, 'LTM'],
  [COMPETENCY_PSD, 'PDM'],
  [COMPETENCY_AOP, 'AOP'],
  [COMPETENCY_WLM, 'WLM'],
]);

export type CompetencyDrilldownType = {
  activityId: number,
  activityCode: string,
  pass: boolean,
  normCode: string,
  normName: string,
};

export type CompetencyDrilldownProps = {
  name: string | null,
  norms: CompetencyDrilldownType[],
  activities: ActivityType[],
  onShowGradeContext: Function,
  onCloseDrilldown: () => void,
};
// @deprecated usage only for CompetencyRadarPlot
type DrilldownNormType = {
  name: string,
  pass: boolean[],
};

// @deprecated usage only for CompetencyRadarPlot
type DrilldownType = {
  activityName: string,
  activityIds: number[],
  activityNorms: DrilldownNormType[],
  pass: number,
  fail: number,
};

// @deprecated usage only for CompetencyRadarPlot
export interface DrilldownObjectType {
  [key: string]: DrilldownType,
}

type MappedDrilldownNormType = {
  name: string,
  pass: boolean,
};

type MappedDrilldownType = {
  activityName: string,
  activityIds: number[],
  activityNorms: MappedDrilldownNormType[],
  pass: boolean,
};

export interface MappedDrilldownObjectType {
  [key: string]: MappedDrilldownType,
}
