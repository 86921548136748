import { useSelector } from 'react-redux';
import { GeneralStoreType } from '../store/constants';

export const useTrainingCenter = () => useSelector((
  state: GeneralStoreType,
) => state.dashboard.get('trainingCenterName'));

export const useGradingScheme = () => useSelector((
  state: GeneralStoreType,
) => state.dashboard.get('gradingScheme'));

export const useEquipment = () => {
  const equipmentId = useSelector((
    state: GeneralStoreType,
  ) => state.session.getIn(['currentSession', 'equipmentId']));

  const equipments = useSelector((state: GeneralStoreType) => state.dashboard.get('equipment')).toJS();

  return equipments.find((e: any) => e.equipmentId === equipmentId);
};
