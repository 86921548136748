import styled from 'styled-components';

export const GridColumn = styled.div<{ padding?: string, width?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: ${props => props.width || '200px'};
  padding: ${props => props.padding || '1em'};
`;

export const GridRow = styled.div<{ padding?: string }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: ${props => props.padding || '1em'};
`;
