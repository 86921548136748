import * as actions from './actions';
import defaultState, { ImmutableInstructorInfoStore } from './store';

export default (
  // eslint-disable-next-line default-param-last
  state: ImmutableInstructorInfoStore = defaultState,
  action: actions.InstructorAction,
): ImmutableInstructorInfoStore => {
  switch (action.type) {
    case actions.SET_INSTRUCTOR_INFO: {
      const { instructorId } = action.payload;
      return state.merge({
        id: instructorId,
      });
    }

    default:
      return state;
  }
};
