import { List } from 'immutable';
import defaultState, { ImmutableSessionListStore } from './store';
import * as actions from './actions';

export default (
  // eslint-disable-next-line default-param-last
  state: ImmutableSessionListStore = defaultState,
  action: actions.SessionListAction,
): ImmutableSessionListStore => {
  switch (action.type) {
    case actions.SESSION_LIST_RECEIVED:
    {
      const { sessions, count } = action.payload;

      return state.merge({
        sessions: List(sessions),
        isLoading: false,
        count,
      });
    }

    case actions.EXTENDED_SESSION_LIST_RECEIVED: {
      const { sessions, count } = action.payload;

      return state.merge({
        sessions: List([...state.sessions, ...sessions]),
        isExtendedLoading: false,
        count,
      });
    }
    case actions.CLEAR_SESSION: {
      return defaultState;
    }

    case actions.LOADING_SESSION_LIST:
    {
      const { isLoading } = action.payload;

      return state.set('isLoading', isLoading);
    }

    case actions.EXTENDED_LOADING_SESSION_LIST: {
      const { isLoading } = action.payload;

      return state.set('isExtendedLoading', isLoading);
    }

    case actions.SET_CLASSIFIER_STATUS: {
      const { classifierStatus } = action.payload;
      return state.set('classifierStatus', classifierStatus);
    }

    case actions.SESSION_OVERVIEW_LOADED: {
      return state.set('classifierStatus', undefined);
    }

    default:
      return state;
  }
};
