import { fromJS, Map } from 'immutable';
import * as actions from './actions';
import { SessionRunType } from './constants';
import defaultState, { ImmutableSessionStore } from './store';

export default (
  // eslint-disable-next-line default-param-last
  state: ImmutableSessionStore = defaultState,
  action: actions.SessionAction,
): ImmutableSessionStore => {
  switch (action.type) {
    case actions.UPDATE_SESSION_DETAILS: {
      const {
        id,
        courseName,
        lessonPlan,
        equipment,
        scheduledFor,
        startedAt,
        endedAt,
        lessonPlanName,
        activeRunId,
      } = action.payload.data;

      const { equipmentId, id: equipmentDeviceId } = equipment || {};
      const { name, shortName } = lessonPlan || {};

      // @ts-ignore
      return state.set('currentSession', Map({
        id,
        courseName,
        name,
        shortName,
        equipmentId,
        scheduledFor,
        startedAt,
        endedAt,
        lessonPlanName,
        activeRunId,
        equipmentDeviceId,
        equipment,
      }));
    }

    case actions.UPDATE_RUNS: {
      const runs: SessionRunType[] = [];
      action.payload.runs.forEach(run => {
        runs.push({
          id: run.id,
          pilotFlying: run.pilotFlying,
          pilotMonitoring: run.pilotMonitoring,
          startedAt: run.startedAt,
          endedAt: run.endedAt,
          completedAt: run.completedAt,
          comment: run.comment,
          markers: run.markers,
          activities: run?.activities,
        });
      });

      return state.set('runs', fromJS(runs));
    }

    case actions.SET_CURRENT_EQUIPMENT_ACTIVE_RUNS: {
      const { runIds, canStartSession } = action.payload;
      return state
        .set('currentEquipmentActiveRunIds', fromJS(runIds))
        .set('canStartSession', canStartSession);
    }

    case actions.ACTIVE_SESSIONS_ENDED: {
      return state
        .set('currentEquipmentActiveRunIds', fromJS([]))
        .set('canStartSession', true);
    }

    default:
      return state;
  }
};
