import React from 'react';
import { defaultStroke, SVGRunway } from '../Runway/constants';
import { CasMarkersProps } from './types';

const CasMarkers = (props: CasMarkersProps) => {
  const { offset, markers, getPosition, normalizedRunwayLength } = props;
  const casMarkersLineX = 409;
  const baseY = SVGRunway.y0 + offset;

  return (
    <>
      <text
        fontFamily="Roboto-Medium, Roboto"
        fontSize="12"
        fontWeight="400"
        fill={defaultStroke.color}
      >
        <tspan x="400" y={baseY + normalizedRunwayLength + 15}>
          IAS
        </tspan>
      </text>
      <line
        x1={casMarkersLineX}
        x2={casMarkersLineX}
        y1={baseY}
        y2={baseY + normalizedRunwayLength}
        stroke={defaultStroke.color}
        strokeLinecap="square"
      />

      {markers.length > 0
        && markers.map(marker => {
          const y0 = getPosition(marker.position);
          return (
            <g key={`${marker.position} ${marker.cas}`}>
              <line
                x1={casMarkersLineX}
                x2={casMarkersLineX}
                y1={y0 - 13}
                y2={y0 + 5}
                stroke="#000000"
                strokeLinecap="square"
              />
              <text
                fontFamily="Roboto-Medium, Roboto"
                fontSize="12"
                fontWeight="400"
                fill="#ffffff"
              >
                <tspan x={casMarkersLineX - 4 * `${marker.cas}`.length} y={y0}>
                  {marker.cas}
                </tspan>
              </text>
            </g>
          );
        })}
    </>
  );
};

export default CasMarkers;
