import { CompetencyScores } from './types';
import { GradeLevelType, GradeType } from '../summary/constants';
import { RunIdType } from '../session/constants';
import { CompetencyDrilldownType } from './constants';

export const RADAR_PLOT_MOUNTED = 'Radar plot mounted.';
export const LOADING_COMPETENCY_SCORES = 'Waiting for competency scores...';
export const SCORES_UPDATED = 'Received updated competency scores.';
export const COMPETENCY_SCORES_LOADED = 'Loaded instructor competency scores.';
export const UPDATE_COMPETENCY_SCORE = 'Updating instructor competency score.';
export const COMPETENCY_SCORES_UPDATED = 'Received updated instructor competency score.';
export const LOADING_COMPETENCY_DRILLDOWN = 'Waiting for competency drill-down data...';
export const COMPETENCY_DRILLDOWN_LOADED = 'Received competency drilldown data.';

export const radarPlotMounted = (runId: RunIdType) => ({
  type: RADAR_PLOT_MOUNTED,
  payload: { runId },
} as const);

export const loadingCompetencyScores = (isLoading: boolean = true) => ({
  type: LOADING_COMPETENCY_SCORES,
  payload: { isLoading },
} as const);

export const scoresUpdated = (runId: RunIdType, scores: CompetencyScores) => ({
  type: SCORES_UPDATED,
  payload: { runId, scores },
} as const);

export const competencyScoresLoaded = (runId: RunIdType, runGrade: GradeType) => ({
  type: COMPETENCY_SCORES_LOADED,
  payload: { runId, runGrade },
} as const);

export const updateCompetencyScore = (
  runId: RunIdType,
  competency: string,
  score: GradeType,
  level: GradeLevelType,
) => ({
  type: UPDATE_COMPETENCY_SCORE,
  payload: { runId, competency, score, level },
} as const);

export const competencyScoresUpdated = (runId: RunIdType, scores: GradeType) => ({
  type: COMPETENCY_SCORES_UPDATED,
  payload: { runId, scores },
} as const);

export const loadingCompetencyDrilldown = (runId: RunIdType, competency: string) => ({
  type: LOADING_COMPETENCY_DRILLDOWN,
  payload: { runId, competency },
} as const);

export const competencyDrilldownLoaded = (norms: CompetencyDrilldownType[] | null) => ({
  type: COMPETENCY_DRILLDOWN_LOADED,
  payload: { norms },
} as const);

export type RadarPlotMountedAction = ReturnType<typeof radarPlotMounted>;
export type LoadingCompetencyScoresAction = ReturnType<typeof loadingCompetencyScores>;
export type ScoresUpdatedAction = ReturnType<typeof scoresUpdated>;
export type CompetencyScoresLoadedAction = ReturnType<typeof competencyScoresLoaded>;
export type UpdateCompetencyScoreAction = ReturnType<typeof updateCompetencyScore>;
export type CompetencyScoresUpdatedAction = ReturnType<typeof competencyScoresUpdated>;
export type LoadingCompetencyDrilldownAction = ReturnType<typeof loadingCompetencyDrilldown>;
export type CompetencyDrilldownLoadedAction = ReturnType<typeof competencyDrilldownLoaded>;

export type CompetencyAction =
  | RadarPlotMountedAction
  | LoadingCompetencyScoresAction
  | ScoresUpdatedAction
  | CompetencyScoresLoadedAction
  | UpdateCompetencyScoreAction
  | CompetencyScoresUpdatedAction
  | LoadingCompetencyDrilldownAction
  | CompetencyDrilldownLoadedAction;
