import { annotationV1 } from '../annotations';
import getTakeoffPlotOptions from './takeoff';
import { TakeoffInsightType, VisualizationHandlerType } from '../../visualization/constants';

const getTakeoffOEIPlotOptions: VisualizationHandlerType<TakeoffInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const plotOptions = getTakeoffPlotOptions(activity, data, markers, metaData);

  if (metaData.trainingCenter !== 'NLA') {
    // @ts-ignore
    annotationV1(data.frames, plotOptions);
  }

  return plotOptions;
};

export default getTakeoffOEIPlotOptions;
