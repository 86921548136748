import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mediumGrey } from '@/theme/palette';
import { media } from '@/theme/breakepoints';
import { FullSessionType } from '@/session/constants';
import { FC } from 'react';
import { formatDate } from '@/utils/time';
import { EDateFormat } from '@/types/global.types';

type SessionDescriptionType = {
  session: FullSessionType
}

export const SessionDescription:FC<SessionDescriptionType> = ({ session }) => {
  const aircraftType = session?.equipment?.aircraftDetails?.aircraft?.at(0)?.name;
  const startDate = formatDate(session?.startedAt, EDateFormat.FullDate);
  return (
    <Description>
      <DescriptionList>
        <DescriptionItem>
          <DescriptionItemLabel>Lesson Plan:</DescriptionItemLabel>
          <DescriptionItemLabel $isWhite>{session.shortName}</DescriptionItemLabel>
        </DescriptionItem>
        <DescriptionItem>
          <DescriptionItemLabel>Course:</DescriptionItemLabel>
          <DescriptionItemLabel $isWhite>{session.courseName}</DescriptionItemLabel>
        </DescriptionItem>
        <DescriptionItem>
          <DescriptionItemLabel>Aircraft Type:</DescriptionItemLabel>
          <DescriptionItemLabel $isWhite>{aircraftType}</DescriptionItemLabel>
        </DescriptionItem>
        <DescriptionItem>
          <DescriptionItemLabel>Date:</DescriptionItemLabel>
          <DescriptionItemLabel $isWhite>{startDate}</DescriptionItemLabel>
        </DescriptionItem>
      </DescriptionList>
    </Description>
  );
};

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 12px;
  padding-block: 12px;
  margin-top: 24px;
  box-sizing: border-box;
  background-color: ${mediumGrey.string()};
  border-radius: 8px;
  margin-bottom: 24px;
`;
const DescriptionList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const DescriptionItemLabel = styled.div<{ $isWhite?: boolean }>`
  display: inline-flex;
  color: ${props => (props.$isWhite ? props.theme.white.toString() : props.theme.lightestGrey.toString())};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
`;

const DescriptionItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: calc(50% - 4px);
  gap: 4px;


  ${media.xs} {
    flex-basis: calc(100% - 4px);
  }
`;

export default SessionDescription;
