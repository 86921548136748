import { EquipmentType, TrainingCenterType } from './constants';

export const DASHBOARD_LOADED = 'Dashboard loaded.';
export const TRAINING_CENTER_DETAILS_UPDATED = 'Received training center details.';
export const EQUIPMENT_DETAILS_UPDATED = 'Received equipment details.';

export const dashboardLoaded = (id?: number) => ({
  type: DASHBOARD_LOADED,
  payload: { id },
} as const);

export const trainingCenterDetailsUpdated = (details: TrainingCenterType) => ({
  type: TRAINING_CENTER_DETAILS_UPDATED,
  payload: { ...details },
} as const);

export const equipmentDetailsUpdated = (equipment: EquipmentType[]) => ({
  type: EQUIPMENT_DETAILS_UPDATED,
  payload: { equipment },
} as const);

export type DashboardLoadedAction = ReturnType<typeof dashboardLoaded>;
export type TrainingCenterDetailsUpdatedAction = ReturnType<typeof trainingCenterDetailsUpdated>;
export type EquipmentDetailsUpdatedAction = ReturnType<typeof equipmentDetailsUpdated>;

export type DashboardAction =
  | DashboardLoadedAction
  | TrainingCenterDetailsUpdatedAction
  | EquipmentDetailsUpdatedAction;
