export const SVG_WIDTH = 1020;
export const SVG_HEIGHT = 550;
export const ROLL_HEIGHT = 216;
export const ROLL_WIDTH = 295;
export const TOP_BAR_HEIGHT = 152;
export const TOP_BAR_WIDTH = 182;
export const HDG_LABEL_HEIGHT = 54;
export const INITIAL_HDG_LABEL_WIDTH = 202;
export const FINAL_HDG_LABEL_WIDTH = 188;

export const ARC_DIMENSIONS = {
  startAngle: 0,
  sweepAngle: 135,
  cx: 400,
  cy: 358,
  rx: 160,
  ry: 102,
};

export enum EHdgXDirection {
  NegativeStart=-180,
  NegativeEnd=-360,
  PositiveStart=180,
  PositiveEnd=360
}
