import { END } from 'redux-saga';

import { LiveSessionSimulatorStatusSubscriber } from '../types';
import api from '../../api';
import config from '../../config';

// eslint-disable-next-line max-len
export const getPollingSimulatorStatusSubscriber: LiveSessionSimulatorStatusSubscriber = runId => updateSimulatorStatus => {
  const intervalId = setInterval(async () => {
    try {
      const { data } = await api.sessions.pollSimulatorStatus(runId);

      updateSimulatorStatus(data);
    } catch (error) {
      // @ts-ignore
      if (error.response && error.response.status === 409) {
        updateSimulatorStatus(END);
      } else {
        // @ts-ignore
        api.logError(error);
      }
    }
  }, config.simulatorStatusPingRate);

  return () => clearInterval(intervalId);
};
