import EditScreen from './EditSessionScreen';
import { ScheduleStore } from '../../store';

// Props provided by this container
export type EditSessionScreenProps = ScheduleStore & {
  onComponentMounted: Function,
  onSaveLessonPlanVariation: Function,
  onDone: () => void,
};

export default EditScreen;
