import * as React from 'react';
import { FC, ReactElement, ReactNode } from 'react';
import type { Instance } from 'tippy.js';
import { TippyThemes } from '@/ui/Tippy';
import Tippy from '@tippyjs/react';
import popoverTheme from '@/theme/popovers';
import styled from 'styled-components';

type TooltipProps = {
  hint: ReactNode | string,
  children: ReactElement
}
export const Tooltip: FC<TooltipProps> = ({ hint, children }) => {
  const popoverRef = React.useRef<Instance>();

  return (
    <>
      <TippyThemes />
      <Tippy
        {...popoverTheme}
        theme={typeof hint === 'string' ? 'hint' : popoverTheme.theme}
        onCreate={ref => {
          popoverRef.current = ref;
        }}
        appendTo={document.body}
        content={(
          <Hint $isString={typeof hint === 'string'}>
            {hint}
          </Hint>
        )}
      >
        {children}
      </Tippy>
    </>
  );
};

const Hint = styled.div<{$isString:boolean}>`
  font-size: ${props => (props.$isString ? '10px' : 'inherit')};
  font-style: normal;
  font-weight: 400;
`;
export default Tooltip;
