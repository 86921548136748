import React, { JSXElementConstructor, PropsWithChildren, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { primary, secondary } from '@/theme/palette';

export interface ITab {
    tabName: string;
    activeTabName: string;
    handleTabClick: (activeTab: string) => void;
}

export interface ITabs {
    children?: ReactElement[];
}

// eslint-disable-next-line react/no-unused-prop-types
export const TabWrapper: React.FC<PropsWithChildren<{ tabName: string }>> = ({
  children,
}) => <TabContent>{children}</TabContent>;

export const Tabs: React.FC<PropsWithChildren<ITabs>> = ({ children }) => {
  const tabWrappers = children?.filter(s => (s.type as JSXElementConstructor<any>).name === TabWrapper.name);
  const initialTabName: string = tabWrappers != null && tabWrappers?.length > 0 ? tabWrappers[0].props.tabName : '';
  const [activeTabName, setActiveTabName] = useState<string>(initialTabName);

  return (
    <TabsContainer>
      <TabsHeader>
        <TabsHeaderWrap>
          {tabWrappers?.map(child => (
            <Tab
              key={child.props.tabName}
              tabName={child.props.tabName}
              handleTabClick={setActiveTabName}
              activeTabName={activeTabName}
            />
          ))}
        </TabsHeaderWrap>
      </TabsHeader>
      <TabsContent>
        {tabWrappers?.map(child => (child.props.tabName === activeTabName ? (
          <TabItem key={child.props.tabName} $active>
            {child}
          </TabItem>
        ) : null))}
      </TabsContent>
    </TabsContainer>
  );
};

const Tab: React.FC<ITab> = ({ tabName, activeTabName, handleTabClick }) => {
  const isActive = activeTabName === tabName;
  return (
    <TabButton
      onClick={() => handleTabClick(tabName)}
      $active={isActive}
      data-testid={`tab-${tabName}`}
      tabIndex={isActive ? -1 : 0}
    >
      {tabName}
    </TabButton>
  );
};

// Styled Components

const TabsContainer = styled.div`
    background-color: transparent;
    margin-top: 24px;
`;

const TabsHeader = styled.div``;

const TabsHeaderWrap = styled.div`
    display: flex;
    background-color: transparent
`;

const TabButton = styled.button<{ $active: boolean }>`
    display: inline-flex;
    justify-content: center;
    color: ${({
    $active,
    theme,
  }) => ($active
    ? theme.white.toString()
    : theme.lightestGrey.toString())};
    height: 40px;
    flex-grow: 1;
    text-align: center;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    pointer-events: ${({ $active }) => ($active ? 'none' : 'auto')};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: transparent;
    background: ${props => (props.$active ? `
    linear-gradient(#000, #000) padding-box,
    linear-gradient(
      to right,
      ${primary.string()},
      ${secondary.string()}
    ) border-box
  ` : 'transparent')};
          ;
    border-bottom: 2px solid transparent;
    border-bottom-color: ${({ $active, theme }) => (!$active ? theme.lightGrey.toString() : 'transparent')};
`;

const TabsContent = styled.div`
    width: 100%;
`;

const TabItem = styled.div<{ $active: boolean }>`
    position: ${({ $active }) => ($active ? 'static' : 'absolute')};
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: ${({ $active }) => ($active ? '0' : '-999')};
    height: 100%;
    opacity: ${({ $active }) => ($active ? '1' : '0')};
    display: block;
    visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
`;

const TabContent = styled.div`
    height: 100%;
`;
