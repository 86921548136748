import React from 'react';
import { FormRow } from '@/styles/FormStyles';
import styled from 'styled-components';

interface ToggleSwitchRowProps {
    flexGrow?: number;
    labelLeft: string | null;
    name: string;
    labelRight?: string | null;
    checked?: boolean;
    disabled?: boolean;
    onChange?: any;
}

const Switch = ({ checked, onChange, disabled, labelRight, labelLeft }: Omit<ToggleSwitchRowProps, 'flexGrow'>) => (
  <SwitchLabel>
    <>
      <SwitchInput
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {labelRight}
      <SwitchToggle>
        <SwitchButton />
      </SwitchToggle>
      {labelLeft}
    </>
  </SwitchLabel>
);

export const ToggleSwitch = (props: ToggleSwitchRowProps) => {
  const { flexGrow, name, labelLeft, disabled, checked, onChange, labelRight = null } = props;

  return (
    <FormRow $flexGrow={flexGrow}>
      <Switch
        name={name}
        labelRight={labelRight}
        labelLeft={labelLeft}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />

    </FormRow>
  );
};

const SwitchToggle = styled.span`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 35px;
    height: 20px;
    border-radius: 100px;
    transition: background-color 0.2s;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        right: 0;
        height: 14px;
        transition: background-color 0.2s;
        background: ${props => props.theme.white.darken(0.65).toString()};
        border-radius: 40px;
        transform: translateY(-50%);
    }


`;
const SwitchButton = styled.span`
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    transition: 0.2s;
    background: ${props => props.theme.white.toString()};


`;

const SwitchInput = styled.input`
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked ~ ${SwitchToggle}  {
        &::before {
            background: ${props => props.theme.primary.darken(0.15).toString()};
        }

        ${SwitchButton} {
            left: calc(100%);
            transform: translateX(-100%);
            background: ${props => props.theme.primary.lighten(0.8).toString()};
        }
    }
    &:disabled ~ ${SwitchToggle}  {
        &::before {
            background: ${props => props.theme.lightGrey.darken(0.5).toString()};
        }

        ${SwitchButton} {
            background: ${props => props.theme.lightGrey.lighten(0.2).toString()};
        }
    }
`;

const SwitchLabel = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    user-select: none;
`;
export default ToggleSwitch;
