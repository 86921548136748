import React, { useRef } from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import * as palette from '../../../theme/palette';
import { VisualizationDefinitionType } from '../../../utils/visualization/constants';
import {
  ARC_DIMENSIONS,
  EHdgXDirection,
  FINAL_HDG_LABEL_WIDTH,
  HDG_LABEL_HEIGHT,
  INITIAL_HDG_LABEL_WIDTH,
  ROLL_WIDTH,
  SVG_HEIGHT,
  SVG_WIDTH,
} from './constants';
import { ArcPathTriangle, HDGLabel, RollGroup, TopBar } from './components';
import {
  getArcPath,
  getHdg,
  getHdgXDirection,
  getRoll,
  getStartLinePath,
  getTurnDiff,
  turnColors,
  useCenterGroup,
} from './utils';

const getMiddleLinePath = (direction: number) => {
  switch (direction) {
    case (EHdgXDirection.NegativeEnd):
      return 'M394 450 L414 460 L394 470 Z';
    case (EHdgXDirection.PositiveEnd):
      return 'M382 460 L402 470 L402 450 Z';
    default:
      return '';
  }
};

const getLineEndPath = (direction: number) => {
  const directionType = getHdgXDirection(direction);
  switch (directionType) {
    case EHdgXDirection.PositiveStart:
      return 'M325.5 458.5H231V450.5L212 460L231 469.5V461.5H413.5V458.5H325.5Z';
    case EHdgXDirection.NegativeStart:
      return 'M500 461.5H574.5V469.5L593.5 460L574.5 450.5V458.5H392V461.5H500Z';
    case EHdgXDirection.NegativeEnd:
      return 'M325.5 255H231V247L212 256.5L231 266V258H413.5V255H325.5Z';
    case EHdgXDirection.PositiveEnd:
      return 'M500 258H574.5V266L593.5 256.5L574.5 247V255H392V258H500Z';
    default:
      return '';
  }
};

const getHdgPosition = (direction: number): { x: number, y: number, position: 'start' | 'end' } => {
  const directionType = getHdgXDirection(direction);
  switch (directionType) {
    case EHdgXDirection.NegativeStart: {
      return {
        x: 598,
        y: 450,
        position: 'end',
      };
    }
    case EHdgXDirection.NegativeEnd: {
      return {
        x: 0,
        y: 250,
        position: 'start',
      };
    }
    case EHdgXDirection.PositiveEnd: {
      return {
        x: 600,
        y: 250,
        position: 'end',
      };
    }
    case EHdgXDirection.PositiveStart:
    default: {
      return {
        x: 14,
        y: 450,
        position: 'start',
      };
    }
  }
};

export const SteepTurnSVG = (data: VisualizationDefinitionType['data']) => {
  const { ref, width = 1, height = 1 } = useResizeObserver<SVGSVGElement>();
  const groupRef = useRef<SVGGElement>(null);
  const finalHDGRef = useRef<SVGForeignObjectElement>(null);
  const initialHDGRef = useRef<SVGForeignObjectElement>(null);
  const { groupWidth } = useCenterGroup({ groupRef, width, height });
  const {
    direction,
    initialCas,
    maxCas,
    turnMaxCasGrade,
    minCas,
    turnMinCasGrade,
    initialAlt,
    maxAlt,
    turnMaxAltGrade,
    minAlt,
    turnMinAltGrade,
    averageRoll,
    maxRoll,
    turnMaxRollGrade,
    minRoll,
    turnMinRollGrade,
    initialHdg,
    finalHdg,
    turnFinalHdgGrade,
    // TODO: Type VisualizationDefinitionType['data'] don't have proper fields
  } = data as any;
  const arcPath = getArcPath({
    cx: ARC_DIMENSIONS.cx,
    cy: ARC_DIMENSIONS.cy,
    rx: ARC_DIMENSIONS.rx,
    ry: ARC_DIMENSIONS.ry,
    startAngle: ARC_DIMENSIONS.startAngle,
    sweepAngle: direction,
  });

  return (
    <SteepTurnVisualisation
      ref={ref}
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
    >
      <g ref={groupRef}>
        <BaseGroup>
          <TopBar
            x={0}
            y={37}
            labelPosition="start"
            label="IAS"
            value={Math.round(initialCas).toString()}
            top={{
              value: getTurnDiff(initialCas, maxCas),
              color: turnColors.get(turnMaxCasGrade),
            }}
            bottom={{
              value: getTurnDiff(initialCas, minCas),
              color: turnColors.get(turnMinCasGrade),
            }}
          />
          <TopBar
            x={618}
            y={37}
            labelPosition="end"
            label="ALT"
            value={Math.round(initialAlt).toString()}
            top={{
              value: getTurnDiff(initialAlt, maxAlt),
              color: turnColors.get(turnMaxAltGrade),

            }}
            bottom={{
              value: getTurnDiff(initialAlt, minAlt),
              color: turnColors.get(turnMinAltGrade),
            }}
          />
          <RollGroup
            label="ROLL"
            x={(groupWidth / 2) - (ROLL_WIDTH / 2)}
            y={258}
            value={getRoll(averageRoll)}
            left={{
              value: Math.round(minRoll),
              color: turnColors.get(turnMinRollGrade),
            }}
            right={{
              value: Math.round(maxRoll),
              color: turnColors.get(turnMaxRollGrade),
            }}
          />
          <HDGLabel
            ref={initialHDGRef}
            height={HDG_LABEL_HEIGHT}
            width={INITIAL_HDG_LABEL_WIDTH}
            y={230}
            x={direction > 0 ? 0 : 598}
            value={getHdg(initialHdg)}
            label="INITIAL HDG"
            labelPosition={direction > 0 ? 'start' : 'end'}
          />
          <ArcPathTriangle direction={direction} />
          <g id="rollGroup" transform="translate(0,0)">
            <Line
              id="LineEnd"
              d={getLineEndPath(direction)}
              fill={turnColors.get(turnFinalHdgGrade)}
              fillRule="nonzero"
            />
            <Arc
              markerEnd="url(#triangle)"
              id="Arc"
              d={arcPath}
            />
            <Line d={getStartLinePath(direction)} $withWhiteFill />
            <Line id="Line" d={getMiddleLinePath(direction)} $withWhiteFill />
          </g>
          <HDGLabel
            ref={finalHDGRef}
            height={HDG_LABEL_HEIGHT}
            width={FINAL_HDG_LABEL_WIDTH}
            y={getHdgPosition(direction).y - 19}
            x={getHdgPosition(direction).x}
            value={getHdg(finalHdg)}
            label="Final HDG"
            color={turnColors.get(turnFinalHdgGrade)?.toString()}
            labelPosition={getHdgPosition(direction).position}
          />
        </BaseGroup>
      </g>
    </SteepTurnVisualisation>
  );
};

const SteepTurnVisualisation = styled.svg`
    height: 550px;
    width: 100%;
    margin-top: 100px;
    overflow: visible;
`;

const BaseGroup = styled.g`
    transform-origin: 50% 50%;
`;

const Line = styled.path<{ $withWhiteFill?: boolean }>`
    fill-rule: nonzero;
    ${props => (props.$withWhiteFill ? `fill: ${palette.white.string()};` : '')};
`;

const Arc = styled.path`
    stroke-width: 3;
    stroke: ${palette.white.string()};
`;

export default SteepTurnSVG;
