import React from 'react';
import { defaultStroke, SVGRunway } from '../Runway/constants';
import { NormMarkerSvg } from '../Runway/types';
import { normsLineX } from './constants';
import { getNormMarker } from './utils';

const NormsSvg = (props: any) => {
  const { offset, getPosition, markers, normalizedRunwayLength } = props;
  const baseY = offset + SVGRunway.y0;

  return (
    <>
      <line
        x1={normsLineX}
        x2={normsLineX}
        y1={baseY}
        y2={baseY + normalizedRunwayLength}
        stroke={defaultStroke.color}
        strokeLinecap="square"
      />
      {markers.map((norm: NormMarkerSvg) => getNormMarker(norm, getPosition))}
    </>
  );
};

export default NormsSvg;
