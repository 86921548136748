import { ClientInterface } from './client';

// API
export default class Frames {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  getFrames = async (
    sessionRunId: number,
    // eslint-disable-next-line default-param-last
    offset: number = 0,
    // eslint-disable-next-line default-param-last
    limit: number = 1000,
    params?: object,
  ): Promise<any> => this.client.getResource('/session-frames/', {
    offset,
    limit,
    sessionRunId,
    ...params,
  });
}
