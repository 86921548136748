import { RefObject, useLayoutEffect, useState } from 'react';
import { EHdgXDirection, SVG_WIDTH } from './constants';
import * as palette from '../../../theme/palette';

export const getArcPath = ({ cx, cy, rx, ry, startAngle, sweepAngle }: {
  cx: number,
  cy: number,
  rx: number,
  ry: number,
  startAngle: number,
  sweepAngle: number,
}) => {
  if (!sweepAngle) return `M${cx} ${cy}`;

  const start = startAngle % 360;
  let sweep = sweepAngle % 360;

  const isClosed = (sweep === 0);

  if (isClosed) {
    sweep = 180;
  }

  const largeArc = Math.abs(sweep) > 180 ? 1 : 0;

  // Convert degrees to radians
  const a1 = (start * Math.PI) / 180;
  const a2 = (start + sweep) * (Math.PI / 180);

  const x1 = cx + rx * Math.sin(a1);
  const y1 = cy - ry * Math.cos(a1);

  const x2 = cx + rx * Math.sin(a2);
  const y2 = cy - ry * Math.cos(a2);

  const sweepPosition = sweep > 0 ? 1 : 0;

  if (isClosed) {
    return `
      M ${x1} ${y1}
      A ${rx} ${ry} 0 1 1 ${x2} ${y2}
      A ${rx} ${ry} 0 1 1 ${x1} ${y1}z`;
  }

  return `
    M ${x1} ${y1}
    A ${rx} ${ry} 0 ${largeArc} ${sweepPosition} ${x2} ${y2}`;
};
export const getHdgXDirection = (direction: number) => {
  switch (true) {
    case (direction <= 0 && direction >= -180):
      return EHdgXDirection.NegativeStart;
    case (direction < -180 && direction >= -360):
      return EHdgXDirection.NegativeEnd;
    case (direction > 0 && direction <= 180):
      return EHdgXDirection.PositiveStart;
    case (direction > 180 && direction <= 360):
      return EHdgXDirection.PositiveEnd;
    default:
      return EHdgXDirection.PositiveStart;
  }
};

export const turnColors = new Map([
  [1, palette.gradeLevel1.string()],
  [2, palette.gradeLevel5.string()],
  [3, palette.gradeLevel5.string()],
  [4, palette.gradeLevel9.string()],
]);

export const getTurnDiff = (initial: number, deviation: number) => {
  const diff = deviation - initial;
  if (diff > 0) {
    return `+${Math.round(diff)}`;
  }
  return Math.round(diff).toString();
};

export const getRoll = (roll: number) => (
  roll > 0 ? `+${Math.round(roll)}` : Math.round(roll).toString()
);

export const getHdg = (hdg: number) => {
  if (hdg < 100) {
    return `0${Math.round(hdg)}`;
  }
  return Math.round(hdg).toString();
};

export const getStartLinePath = (direction: number) => (direction > 0
  ? 'M290 257H384.5V265L403.5 255.5L384.5 246V254H202V257Z'
  : 'M598 257.5H413V265.5L394 256L413 246.5V254.5H598V257.5Z');

export const useCenterGroup = ({
  groupRef,
  width,
  height,
}:{
  groupRef:RefObject<SVGGElement>,
  width:number,
  height:number
}) => {
  const [groupWidth, setGroupWidth] = useState<number>(0);

  useLayoutEffect(() => {
    if (groupRef.current) {
      const bbox = groupRef.current.getBBox();
      const centerX = (SVG_WIDTH / 2) - (bbox.width / 2);

      groupRef.current.setAttribute('transform', `translate(${centerX + (-bbox.x)}, 0)`);
      setGroupWidth(groupRef.current.getBBox().width);
    }
  }, [groupRef.current, width, height]);

  return { groupWidth };
};
