import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export type BreadcrumbType = {
  link?: string,
  title: string
}

export type BreadcrumbListType = BreadcrumbType[]

type BreadcrumbsProps = {
  crumbs: BreadcrumbListType
}

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => (
  <BreadcrumbList>
    {crumbs.map(crumb => (
      <BreadcrumbItem key={crumb.title} $withLink={!!crumb.link}>
        <Breadcrumb as={crumb.link ? Link : 'div'} to={crumb.link}>
          {crumb.title}
        </Breadcrumb>
      </BreadcrumbItem>
    ))}
  </BreadcrumbList>
);

const BreadcrumbList = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 24px;
  padding-top: 12px;
  box-sizing: border-box;
`;

const BreadcrumbItem = styled.div<{ $withLink: boolean }>`
  position: relative;
  display: flex;
  font-size: 14px;
  gap: 4px;
  color: ${({ theme, $withLink }) => ($withLink ? theme.lightGrey.toString() : theme.white.toString())};


  &::after {
    content: "/";
    color: ${({ theme }) => theme.lightGrey.toString()};
  }

  &:last-child::after {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.white.toString()};
  }

`;

const Breadcrumb = styled.div`
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  color: inherit;
  line-height: 1;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
`;

export default Breadcrumbs;
