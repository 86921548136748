import { GeneralStoreType } from '../store/constants';

export const getRunId = (state: GeneralStoreType) => state.training.get('runId');

export const getActiveMalfunctions = (state: GeneralStoreType) => state.training.get('activeMalfunctions');

export const getPredictedActivityId = (state: GeneralStoreType) => state.training.get('predictedActivityId');

export const getWeatherConditions = (state: GeneralStoreType) => state.training.get('weatherConditions');

export const getSimulatorStatus = (state: GeneralStoreType) => state.training.get('simulatorStatus');
