/**
 * The reporter helps us track errors across the application.
 */
import * as React from 'react';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Bugsnag, { Client } from '@bugsnag/js';

import DatadogClient from './DatadogClient';

import config from '../config';

interface Context {
  [x: string]: ContextValue;
}

interface ContextArray extends Array<string | number | boolean | Context | ContextArray | undefined> {}

type ContextValue = string | number | boolean | Context | ContextArray | undefined;

type ReporterOptions = {
  appConfig: typeof config;
  context?: {
    addEventListener?: Function;
  };
  devInfoMsg: (message: string, context?: Context) => void;
  devWarnMsg: (message: string, context?: Context) => void;
  devErrorMsg: (message: string, context?: Context) => void;
};

export class Reporter {
  bugsnagClient?: Client;

  datadogClient: DatadogClient;

  isDev = false;

  isTest = false;

  reporterConfigured = false;

  // Logs a message to the console.
  devInfoMsg: (message: string, context?: Context) => void;

  // Logs a warning to the console.
  devWarnMsg: (message: string, context?: Context) => void;

  // Logs an error to the console.
  devErrorMsg: (message: string, context?: Context) => void;

  constructor(options: ReporterOptions) {
    const {
      appConfig: {
        bugsnagApiKey,
        clientName,
        datadogConfig,
        debugEnvs,
        deployEnv,
        environment,
        version,
      },
      context,
      devInfoMsg,
      devWarnMsg,
      devErrorMsg,
    } = options;

    this.isDev = environment === 'development';
    this.isTest = environment === 'test';
    this.devInfoMsg = devInfoMsg;
    this.devWarnMsg = devWarnMsg;
    this.devErrorMsg = devErrorMsg;
    this.datadogClient = new DatadogClient({
      applicationId: datadogConfig.applicationId,
      appVersion: version,
      clientName,
      clientToken: datadogConfig.clientToken,
      enabled: datadogConfig.enabled,
      debugEnvs,
      deployEnv,
      forwardErrorsToLogs: datadogConfig.forwardErrorsToLogs,
      resourceSampleRate: datadogConfig.resourceSampleRate,
      sampleRate: datadogConfig.sampleRate,
    });

    // Create bugsnag client
    if (bugsnagApiKey) {
      this.bugsnagClient = Bugsnag.createClient({
        apiKey: bugsnagApiKey,
        appVersion: version,
        releaseStage: deployEnv,
        plugins: [new BugsnagPluginReact(React)],
      });
    }

    if (this.datadogClient.isConfigured || bugsnagApiKey) {
      this.reporterConfigured = true;
    }

    // Add a global exception handler.
    if (context && typeof context.addEventListener === 'function') {
      context.addEventListener('error', ({ error }: ErrorEventInit) => {
        this.report(error);
      });
    }
  }

  /**
   * Reports an event or message.
   */
  report = (error?: Error | string, details?: any) => {
    if (!error && !details) {
      return;
    }

    if (this.isDev) {
      this.log('Reporting:', String(error), details);
    } else if (this.bugsnagClient) {
      this.bugsnagClient.notify(error!, event => event.addMetadata('component', { details }));
    } else {
      this.log('No reporter configured.');
    }
  };

  addUserAction = (
    message: string,
    context: Context,
  ) => this.datadogClient.isConfigured && this.datadogClient.Rum?.addAction(message, context);

  debug = (message: string, context?: Context) => (
    this.datadogClient.isConfigured
      ? this.datadogClient.logger?.debug(message, context)
      : this.devInfoMsg(message, context)
  );

  info = (message: string, context?: Context) => (
    this.datadogClient.isConfigured
      ? this.datadogClient.logger?.info(message, context)
      : this.devInfoMsg(message, context)
  );

  warn = (message: string, context?: Context) => (
    this.datadogClient.isConfigured
      ? this.datadogClient.logger?.warn(message, context)
      : this.devWarnMsg(message, context)
  );

  /**
   * Reports application errors. Note: we should not report API errors, as
   * those should be handled by the API.
   */
  error = (error: Error | string, details?: any) => {
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    this.datadogClient.isConfigured && this.datadogClient.logger?.error(String(error), details);

    // Track error
    this.report(error, details);

    // Log to console
    if (this.isDev && typeof this.devErrorMsg === 'function') {
      this.devErrorMsg(String(error));
    }
  };

  /**
   * Log something without reporting it.
   */
  log = (...messages: any): void => {
    if (this.isDev && typeof this.devInfoMsg === 'function') {
      this.devInfoMsg(messages);
    }
  };
}

const reporter = new Reporter({
  appConfig: config,
  context: window,
  devInfoMsg: console.info, // eslint-disable-line no-console
  devWarnMsg: console.warn, // eslint-disable-line no-console
  devErrorMsg: console.error, // eslint-disable-line no-console
});

export default reporter;
