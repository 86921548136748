import React from 'react';

import { getColor } from '../Landing/utils';
import { minNormLabelsDistance, normsLineX } from './constants';
import { NormMarkerSvg } from './types';

export const getNormMarker = (
  { position, pass, label, color }: NormMarkerSvg,
  getPosition: Function,
) => {
  const y = getPosition(position);
  const fillColor = color || getColor(pass);
  return (
    <g key={`${label} ${y}`}>
      <circle cx={normsLineX} cy={y} r="5" fill={fillColor} />
      <text
        fontFamily="Roboto-Medium, Roboto"
        fontSize="14"
        fontWeight="400"
        fill={fillColor}
      >
        <tspan x={normsLineX + 20} y={y + 7}>
          {label}
        </tspan>
      </text>
    </g>
  );
};

export const combineNormMarkersLabels = (
  markers: NormMarkerSvg[],
  normalizeFactor: number,
): NormMarkerSvg[] => {
  const minNormalizedNormLabelsDistance = minNormLabelsDistance / normalizeFactor;
  return markers
    .sort((a, b) => a.position - b.position)
    .reduce((a, b, i) => {
      if (
        a[i - 1]
        && (b.position - a[i - 1].position) < minNormalizedNormLabelsDistance
      ) {
        // eslint-disable-next-line no-param-reassign
        a[i - 1] = {
          ...a[i - 1],
          label: `${a[i - 1].label} - ${b.label}`,
        };
        a.unshift({
          ...b,
          label: '',
        });
      } else {
        a.push(b);
      }
      return a;
    }, [] as NormMarkerSvg[]);
};
