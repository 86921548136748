import React from 'react';
import styled from 'styled-components';

import { mediumGrey } from '../theme/palette';

import ActivityCard from './images/ActivityCard.jpg';
import AddActivity from './images/AddActivity.jpg';
import AdminLogin from './images/AdminLogin.jpg';
import CompetencyGraph from './images/CompetencyGraph.png';
import FilterOnBox from './images/FilterOnBox.jpg';
import FilterOnHeading from './images/FilterOnHeading.jpg';
import GradingContext from './images/GradingContext.jpg';
import InstructorManager from './images/InstructorManager.jpg';
import LandingPage from './images/LandingPage.jpg';
import LaunchActivity from './images/LaunchActivity.jpg';
import LaunchIIQ from './images/LaunchIIQ.png';
import LiveGrade from './images/LiveGrade.jpg';
import LiveSession from './images/LiveSession.png';
import Login from './images/Login.png';
import NormSummary from './images/NormSummary.png';
import RemoveActivity from './images/RemoveActivity.jpg';
import ReorderingActivities from './images/ReorderingActivities.jpg';
// import Review from './images/Review.jpg';
import ScheduleSession from './images/ScheduleSession.jpg';
import SessionList from './images/SessionList.jpg';
import SessionOverview from './images/SessionOverview.jpg';
import SessionSummary from './images/SessionSummary.jpg';
// import StartPart1 from './images/StartPart1.jpg';
// import StartPart2 from './images/StartPart2.jpg';
import Swipeable from './images/Swipeable.jpg';
import TraineeHistory from './images/TraineeHistory.jpg';
import TraineeHistoryDropdown from './images/TraineeHistoryDropdown.jpg';
import TraineeProfile from './images/TraineeProfile.jpg';
import ViewSession from './images/ViewSession.jpg';

const CreateAccount = require('./videos/Create_Account.mp4');
const LiveTraining = require('./videos/Live_Training.mp4');
const Session = require('./videos/New_Session.mp4');
const Summary = require('./videos/Session_Summary.mp4');
const Profile = require('./videos/Trainee_Profile.mp4');

export const Introduction = () => (
  <>
    <Header>Introduction</Header>
    <Body>
      InstructIQ&trade;, the AI-enabled dashboard by Paladin AI&trade;, is meant to be an insightful tool for pilot
      instructors and aviation training centers to enhance the training process while making it more efficient.
    </Body>
    <Subheader>Encryption</Subheader>
    <Body>
      The dashboard uses 256-bit AES encryption to protect sensitive instructor and trainee data before it ever leaves
      the tablet. The identity of instructors and trainees cannot be ascertained by Paladin AI or any other third party.
    </Body>
  </>
);

export const LaunchingDashboard = () => (
  <>
    <Header>Launching the Dashboard</Header>
    <Body>To launch the dashboard, select the InstructIQ&trade; icon on the tablet’s home screen.</Body>
    <ImageWrapper>
      <Image image={LaunchIIQ} height="620px" $border />
      <ImageCaption>Figure 1: InstructIQ&trade; Icon</ImageCaption>
    </ImageWrapper>
  </>
);

export const Authentication = () => (
  <>
    <Header>Authentication & User Management</Header>
    <Body>
      There are two types of users in the dashboard: instructors and account managers.
      <ul>
        <li>
          Account managers are responsible for creating instructor accounts,
          <b>which should never be shared with Paladin AI</b>
          for confidentiality reasons. Account managers cannot run training sessions.
        </li>
        <li>
          Instructors, on the other hand, can run training sessions but cannot create other instructor accounts.
        </li>
      </ul>
    </Body>
    <Subheader>Instructor Account Management</Subheader>
    <Body>
      To login as an account manager, toggle the “Login as an account admin” option and use your admin credentials. Once
      logged in, you’ll be able to create separate credentials for each instructor that will use the dashboard.
    </Body>
    <ImageWrapper>
      <Image image={AdminLogin} />
      <ImageCaption>Figure 2: Manager Login</ImageCaption>
    </ImageWrapper>
    <ImageWrapper>
      <Image image={InstructorManager} $border />
      <ImageCaption>Figure 3: Account Management</ImageCaption>
    </ImageWrapper>
    <Subheader>Logging in as an Instructor</Subheader>
    <Body>
      To login as an instructor, deselect the “Login as an account admin” option and enter your instructor credentials.
      These credentials must be created by an account manager.
    </Body>
    <ImageWrapper>
      <Image image={Login} height="660px" />
      <ImageCaption>Figure 4: Instructor Login</ImageCaption>
    </ImageWrapper>
    <ImageWrapper>
      <Image image={LandingPage} height="660px" $border />
      <ImageCaption>Figure 5: Landing Page</ImageCaption>
    </ImageWrapper>
    <Body>
      Once logged in, an instructor will be redirected directly to the Landing Page which displays important information
      to the instructor including a list of upcoming sessions, a list of their trainees, and a link to the InstructIQTM
      User Guide.
    </Body><br /><br />
    <video width="100%" controls>
      <source src={CreateAccount} type="video/mp4" />
    </video><br /><br />
  </>
);

export const NewSession = () => (
  <>
    <Header>Configuring a New Training Session</Header>
    <Body>
      A training session needs to be configured and scheduled before it can be performed and recorded in the dashboard.
      To navigate to the “TRAINING SESSIONS” page from the landing page, click “View All” towards the top right-hand
      side of the screen, or open the navigation menu and select “Sessions”.
    </Body>
    <ImageWrapper>
      <Image image={SessionList} $border />
      <ImageCaption>Figure 6: Training Sessions Page</ImageCaption>
    </ImageWrapper>
    <Body>
      From the “TRAINING SESSIONS” page, select the round “+” button at the bottom right of the screen.The “SCHEDULE A
      NEW SESSION” page will appear. Most of the fields will be pre-filled. Once all the fields have been reviewed,
      select “SAVE” to save your changes.
    </Body>
    <ImageWrapper>
      <Image image={ScheduleSession} $border />
      <ImageCaption>Figure 7: Scheduling</ImageCaption>
    </ImageWrapper>
    <Body>
      <i>Summary of form fields:</i><br /><br />
      <b>Course</b><br />
      Select the course for the trainees.<br /><br />
      <b>Lesson Plan</b><br />
      Select the lesson plan the trainees will be following.<br /><br />
      <b>Pilot Flying / Pilot Monitoring</b><br />
      Enter the full, capitalized names of the trainees accordingly and select their names from the dropdown.<br />
      <br />
      <b>Simulator</b><br />
      Leave unchanged.<br /><br />
      <b>Scheduled Date / Time</b><br />
      Fill in accordingly.<br /><br />
      <b>Create a duplicate session</b><br />
      Toggle this option to create a duplicate session using the same lesson plan.
    </Body><br /><br />
    <video width="100%" controls>
      <source src={Session} type="video/mp4" />
    </video><br /><br />
  </>
);

export const Overview = () => (
  <>
    <Header>Session Overview</Header>
    <Body>
      Before starting a session, you can open the Session Overview screen to see all the relevant information for the
      session you are about to begin. To navigate to this screen, select “SEE DETAILS” on the session card related to
      the session you would like to begin.
    </Body>
    <ImageWrapper>
      <Image image={SessionOverview} height="600px" $border />
      <ImageCaption>Figure 8: Session Overview</ImageCaption>
    </ImageWrapper>
    <Body>
      From this page you’ll see the Lesson Plan, Pilots, Date, Aircraft Type, and Device associated with the session.
    </Body>
  </>
);

export const ModifySession = () => (
  <>
    <Header>Modifying a Training Session</Header>
    <Body>
      Once a training session has been configured, you have the option to modify said training session by selecting
      “EDIT” in the Lesson Plan card on the Session Overview page, which will take you to the “View Session” screen.
    </Body>
    <ImageWrapper>
      <Image image={ViewSession} height="600px" $border />
      <ImageCaption>Figure 9: View Session Screen</ImageCaption>
    </ImageWrapper>
    <Body>
      This screen displays a list of all the activities in the lesson plan included in the current training session. At
      the bottom of this screen there is an “EDIT” button that will take you to the “Edit Session” screen when selected.
      <br /><br />
      Here you can modify the current lesson plan by reordering, adding, and removing activities.
    </Body>
    <ImageWrapper>
      <Image image={ReorderingActivities} height="600px" $border />
      <ImageCaption>Figure 10: Reordering Activities</ImageCaption>
    </ImageWrapper>
    <Body>
      To reorder activities, press and hold on the activity card you wish to move. A yellow$border should appear
      signalling that you are now able to drag and drop it to a new position.
      <br /><br />
      To add an activity select the “ADD ACTIVITY” button on the bottom right corner of the screen. It will open a
      larger card. Here you can select the drop down menu to display all the activities available to add. When you have
      chosen the activity you would like to add to the lesson plan, click “ADD” and the activity will be added to the
      bottom of the current activity list.
      <br /><br />
      To remove an activity press and hold the activity card you would like to delete and drag it over the trash can
      icon on the left hand side of the screen. The area surrounding the trash can icon should turn bright red as should
      the$border around the activity card. Release the activity card and select “OK” when the confirmation popup
      appears.
    </Body>
    <ImageWrapper>
      <Image image={AddActivity} height="600px" $border />
      <ImageCaption>Figure 11: Adding and Activity</ImageCaption>
    </ImageWrapper>
    <ImageWrapper>
      <Image image={RemoveActivity} height="600px" $border />
      <ImageCaption>Figure 12: Removing an activity</ImageCaption>
    </ImageWrapper>
    <Body>
      Once you have finished modifying the lesson plan and before saving, you can choose to rename the lesson plan
      variation. If you don’t rename the lesson plan it will simply be saved as the old lesson plan name plus
      “Variation”.
      <br /><br />
      To save the lesson plan variation scroll to the bottom of the page and press “SAVE”. The current training session
      will now use this lesson plan variation, and the lesson plan variation will be available for use for future
      sessions when configuring a new session.
    </Body><br /><br />
  </>
);

export const History = () => (
  <>
    <Header>Trainee History</Header>
    <Body>Individual trainees each have a Trainee History page that displays a snapshot view of their performance in
      previously completed training sessions. To navigate to a trainee’s Trainee History page, select the trainee’s name
      on the Session Overview page.
    </Body>
    <ImageWrapper>
      <Image image={TraineeHistory} height="600px" $border />
      <ImageCaption>Figure 13: Trainee History Page</ImageCaption>
    </ImageWrapper>
    <ImageWrapper>
      <Image image={TraineeHistoryDropdown} height="600px" $border />
      <ImageCaption>Figure 14: Trainee History Page Dropdown</ImageCaption>
    </ImageWrapper>
    <Body>
      The competency graph shows the performance of the trainee in relation to the eight competencies defined by ICAO
      Manual of Evidence-Based Training (ICAO 9995 1st Ed., 2013). A curve that is closer to the outer edge of the grade
      represents a proficient pilot. A competency graph for a completed session can be accessed by selecting the session
      from the dropdown at the top of the page.
    </Body>
  </>
);

export const StartSession = () => (
  <>
    <Header>Starting a Training Session</Header>
    <Body>
      Once a training session has been configured, you may start it from the session overview screen by selecting “START
      PART 1”, which will take you to the live training screen.
      <br /><br />
      This screen displays the names of the trainees, the lesson plan being performed, and a list of activities to be
      performed during the lesson. You must follow along with the training session by launching each activity as they
      are performed by the trainee, as explained in the sub-sections below.
    </Body>
    <ImageWrapper>
      <Image image={LiveSession} $border />
      <ImageCaption>Figure 15: Live Training Page</ImageCaption>
    </ImageWrapper>
    <Subheader>Launching an Activity</Subheader>
    <Body>
      During the training session, you must follow along the lesson plan in the dashboard as it is being performed by
      the trainees. You can do so by pressing and holding on the related activity card. The background of the heading of
      the card will change from yellow to a blue gradient. Alternatively, you may select “NEXT” on an active card to
      move on to the following activity without having to press and hold.
    </Body>
    <ImageWrapper>
      <Image image={LaunchActivity} $border />
      <ImageCaption>Figure 16: Launching an Activity</ImageCaption>
    </ImageWrapper>
    <ImageWrapper>
      <Image image={ActivityCard} $border />
      <ImageCaption>Figure 17: Activity Card</ImageCaption>
    </ImageWrapper>
    <Body>
      The timing is important when launching an activity, as this will be matched to the data which is collected from
      the simulator. Each activity will be matched by timestamp while reviewing a training session, as explained later
      on in Reviewing a Training Session.
    </Body>
    <Subheader>Grading During the Training Session</Subheader>
    <Body>
      Each activity can be graded during the training session by selecting the desired grade. Once selected, the grade
      will become highlighted, confirming that the grade has been saved.
    </Body>
    <ImageWrapper>
      <Image image={LiveGrade} height="215px" />
      <ImageCaption>Figure 18: Live Grading</ImageCaption>
    </ImageWrapper>
    <Subheader>Repeating an Activity</Subheader>
    <Body>
      If for any reason an activity is repeated by the trainees during the session, it’s important to select “REPEAT”
      on the activity card. This is necessary for the Dashboard to display useful information when reviewing the
      training session once it’s completed.
    </Body>
    <Subheader>Event Markers</Subheader>
    <Body>
      You may use the “MARK EVENT” button on the activity card at any moment during the training session to mark a point
      in time. This marker will be visible in the summary screen.
    </Body>
    <Subheader>Free Text Entry</Subheader>
    <Body>
      At any point during a training session, you may enter notes in the text area on an active activity card. These
      notes will be saved automatically and can be viewed and modified during the session review.
    </Body>
    <Subheader>Switching the Pilot Flying and Pilot Monitoring / Ending a Training Session</Subheader>
    <Body>
      When a training session is complete, selecting “End Session” at the top right corner of the screen will navigate
      back to the session overview screen.
      <br /><br />
      When the trainees are ready to start the second part of the training session, select “START PART 2” to enter the
      lesson once again.
      <br /><br />
      At the end of the second part, select “End Session” once more to complete the second part of the session.
    </Body><br /><br />
    <video width="100%" controls>
      <source src={LiveTraining} type="video/mp4" />
    </video><br /><br />
  </>
);

export const ReviewSession = () => (
  <>
    <Header>Reviewing a Training Session</Header>
    <Body>
      The main benefit of the InstructIQ&trade; dashboard is the ability to debrief and review session data with
      trainees. With your feedback, the dashboard will become more and more insightful, allowing you to back-up your
      assessments and make data-driven decisions. The “Session Summary” page is also the place to review or complete
      your grading of the training session. To review a training session, select “REVIEW” on the session you wish to
      gain insight on from the session overview page.
      <br /><br />
      You will be presented with a summary of the session, where the grades given during the training session will be
      associated with grading sheet tasks. For every row, a grading box will be present for each execution of a planned
      activity. For example, if a Normal Takeoff was performed twice, then two grading boxes will be present in the
      Normal Takeoff row.
    </Body>
    <ImageWrapper>
      <Image image={SessionSummary} $border />
      <ImageCaption>Figure 19: Training Summary Page</ImageCaption>
    </ImageWrapper>
    <ImageWrapper>
      <Image image={GradingContext} height="600px" $border />
      <ImageCaption>Figure 20: Grading Context</ImageCaption>
    </ImageWrapper>
    <Body>
      A grade can be entered or modified at any time by selecting the grading box and choosing a grade from the popup.
      For each activity, a context can be brought up showing relevant flight parameters for the chosen activity.
      <br /><br />
      At the bottom of the grading context is the recommended grade from InstructIQ&trade;, along with the editable
      instructor grade. The “ACTIVITIES” label represents each occurrence  of the activity. Selecting one of the
      ACTIVITIES buttons will load and display the data for that occurrence.
    </Body>
    <Subheader>Norm Summary</Subheader>
    <Body>
      Certain activities will have norm summaries which are displayed first on the Grading Context. The norm summary
      displays all the norms InstructIQ&trade; has calculated, as well as an indication as to whether the pilot was
      within the norm boundary or not.
    </Body>
    <ImageWrapper>
      <Image image={NormSummary} height="560px" $border />
      <ImageCaption>Figure 21: Norm Summary</ImageCaption>
    </ImageWrapper>
    <Subheader>Added Activity Insight</Subheader>
    <Body>
      Some activities have extra pages on the grading context to show added insight. When the grading context contains
      more than one page you will see two or more dots below the plots.
    </Body>
    <ImageWrapper>
      <Image image={Swipeable} height="115px" $border />
      <ImageCaption>Figure 22: Swipeable Grading Context</ImageCaption>
    </ImageWrapper>
    <Body>
      These dots indicate the number of pages in the grading context, and the white dot represents the page currently
      being displayed. Swipe horizontally across the screen to transition from one page to another.
    </Body>
    <Subheader>Competency Graph</Subheader>
    <Body>
      At the bottom of the summary screen, a competency graph shows the performance of the trainee in relation to the
      eight competencies defined by ICAO Manual of Evidence-Based Training (ICAO 9995 1st Ed., 2013) . A curve that is
      closer to the outer edge of the grade represents a proficient pilot.
    </Body>
    <ImageWrapper>
      <Image image={CompetencyGraph} height="450px" />
      <ImageCaption>Figure 23: Competency Graph</ImageCaption>
    </ImageWrapper><br /><br />
    <video width="100%" controls>
      <source src={Summary} type="video/mp4" />
    </video><br /><br />
  </>
);

export const Trainee = () => (
  <>
    <Header>Trainee Profile</Header>
    <Body>
      The trainee profile can be accessed by clicking on the “View Trainee Profile” button near the top of the grading
      screen.
    </Body>
    <ImageWrapper>
      <Image image={TraineeProfile} $border />
      <ImageCaption>Figure 24: Trainee Profile</ImageCaption>
    </ImageWrapper>
    <Body>
      This page provides a snapshot view of the trainee’s performance during a training session. The table at the top
      contains boxes that are associated with conditions (Normal, Abnormal, Emergency), and phases of flight (Ground,
      Takeoff, Cruise, etc.). Individual boxes are coloured to reflect the average of all the assigned grades in a given
      category. If a box is left un-coloured it simply means that no maneuvers were performed that fall under that
      particular condition and phase of flight. Below the Competency Table is the Competency Graph, and at the bottom of
      the page is the list of activities and their associated grades.
    </Body>
    <Subheader>Filtering the Competency Table</Subheader>
    <Body>
      The Competency Table can be filtered by selecting boxes or headings. Selecting any box will filter the list of
      activities to only include ones pertaining to that particular condition, phase of flight, or a combination of the
      two, and the Competency Graph will change to reflect this.
    </Body>
    <ImageWrapper>
      <Image image={FilterOnBox} $border />
      <ImageCaption>Figure 25: Filtering the Trainee Profile by selecting a coloured box</ImageCaption>
    </ImageWrapper>
    <ImageWrapper>
      <Image image={FilterOnHeading} $border />
      <ImageCaption>Figure 26: Filtering the Trainee Profile by selecting a header</ImageCaption>
    </ImageWrapper><br /><br />
    <video width="100%" controls>
      <source src={Profile} type="video/mp4" />
    </video><br /><br />
  </>
);

export const Troubleshooting = () => (
  <>
    <Header>TroubleShooting</Header>
    <Subheader>Reloading the App</Subheader>
    <Body>
      If the app seems to be in a hung state, it can be reloaded at any time by tapping on the Paladin AI&trade; logo at
      the top of the screen.
    </Body>
    <Subheader>Going Back to a Previous Screen</Subheader>
    <Body>
      If for any reason, the app seems to be in a hung state and tapping the Paladin AI&trade;  logo has no effect, the
      app can be brought back to a previous screen by swiping from the left edge of the tablet all the way to the right
      edge.
    </Body>
    <Subheader>Network Errors</Subheader>
    <Body>
      “Network Error” messages can be safely dismissed by selecting “OK”. This is typically caused by a WiFi signal
      dropout.
    </Body>
  </>
);

const Header = styled.h1`
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
`;

const Subheader = styled.h2`
   font-size: 18px;
   line-height: 28px;
   font-weight: 400;
`;

const Body = styled.div`
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    opacity: 0.7;
`;

const ImageWrapper = styled.div`
    margin: 20px 0;
`;

const Image = styled.div<{image: string, height?: string, $border?: boolean}>`
    background: center center transparent no-repeat url('${props => props.image}');
    background-size: contain;
    height: ${props => props.height || '640px'};
    width: 100%;
    border: ${props => (props.$border ? `2px solid ${mediumGrey}` : '')};
    border-radius: ${props => (props.$border ? '5px' : '')};
`;

const ImageCaption = styled.div`
    text-align: center;
    font-size: 12px;
    opacity: 0.7;
    margin: 10px 0;
`;
