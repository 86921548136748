import * as Plotly from 'plotly.js';
import { getXAxis, getYAxis } from '../layout';
import * as palette from '../../../theme/palette';

const getChecklistLayout = (
  simParams: string[],
): Partial<Plotly.Layout> => {
  const layout: Partial<Plotly.Layout> = {
    annotations: [],
    autosize: true,
    clickmode: 'none',
    dragmode: false,
    hovermode: false,
    margin: {
      l: 65,
      r: 10,
      t: 20,
      b: 40,
    },
    // TODO: does this "mode" attribute really belong here?
    // @ts-ignore
    mode: 'markers',
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    shapes: [],
    showlegend: false,
  };

  let axisNum = 2;
  const plotPadding = 0.05;

  const plotHeight = 1 / simParams.length;

  simParams.forEach((param, index) => {
    // @ts-ignore
    layout[`xaxis${axisNum}`] = (index < simParams.length - 1)
      ? getXAxis()
      // @ts-ignore
      : getXAxis({
        showticklabels: true,
        tickcolor: palette.white.darken(0.6).string(),
        ticklen: 3,
        ticks: 'inside',
        anchor: (`y${axisNum}` as Plotly.AxisName),
      });

    const datum = (simParams.length - index) * plotHeight;

    // @ts-ignore
    layout[`yaxis${axisNum}`] = getYAxis(
      [datum - plotHeight, datum - plotPadding],
      param,
      {
        showticklabels: false,
        ticks: '',
        showline: false,
        zerolinewidth: 3,
        zerolinecolor: palette.white.darken(0.6).string(),
      },
    );

    axisNum++;
  });

  return layout;
};

export default getChecklistLayout;
