import React from 'react';
import styled, { keyframes } from 'styled-components';

const LogoSpinnerSVG = () => (
  <svg
    viewBox="0 0 68.4 85.7"
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
  >
    <StaticBorder
      fill="none"
      strokeWidth="2"
      d="M34.3 14.8 C26.5 14.8, 19.6 17.5, 13.9 22.5 L13.2 23 L13.5 23.7
        C15.3 28, 16.3 33.8, 16.3 39.8 C16.3 45.8, 15.3 51.6, 13.6 55.9 L13.4 56.3 L13.6 56.7
        C18.6 65.8, 32.4 70.2, 33.9 70.7 L34.2 70.8 L34.5 70.7 C35.1 70.5, 49.7 66.2, 54.9 56.7
        L55.1 56.3 L54.9 55.9 C53.1 51.5, 52.1 45.8, 52.1 39.7 C52.1 33.7, 53.1 28, 54.9 23.7
        L55.2 23 L54.7 22.5 C49.3 17.6, 42 14.8, 34.3 14.8z"
    />
    <linearGradient
      id="border"
      gradientUnits="userSpaceOnUse"
      x1="52.8783"
      y1="58.3485"
      x2="14.376"
      y2="21.0964"
    >
      <stop offset="0" style={{ stopColor: '#00FFEF' }} />
      <stop offset="4.448217e-02" style={{ stopColor: '#04ECE7' }} />
      <stop offset="0.1736" style={{ stopColor: '#0FBBD0' }} />
      <stop offset="0.3059" style={{ stopColor: '#1991BD' }} />
      <stop offset="0.4393" style={{ stopColor: '#206EAE' }} />
      <stop offset="0.5742" style={{ stopColor: '#2653A1' }} />
      <stop offset="0.7112" style={{ stopColor: '#2B4099' }} />
      <stop offset="0.8514" style={{ stopColor: '#2D3594' }} />
      <stop offset="1" style={{ stopColor: '#2E3192' }} />
    </linearGradient>
    <AnimatedBorder
      fill="black"
      stroke="url(#border)"
      strokeWidth="2"
      d="M34.3 14.8 C26.5 14.8, 19.6 17.5, 13.9 22.5 L13.2 23 L13.5 23.7
        C15.3 28, 16.3 33.8, 16.3 39.8 C16.3 45.8, 15.3 51.6, 13.6 55.9 L13.4 56.3 L13.6 56.7
        C18.6 65.8, 32.4 70.2, 33.9 70.7 L34.2 70.8 L34.5 70.7 C35.1 70.5, 49.7 66.2, 54.9 56.7
        L55.1 56.3 L54.9 55.9 C53.1 51.5, 52.1 45.8, 52.1 39.7 C52.1 33.7, 53.1 28, 54.9 23.7
        L55.2 23 L54.7 22.5 C49.3 17.6, 42 14.8, 34.3 14.8"
    />
    <linearGradient
      id="left"
      gradientUnits="userSpaceOnUse"
      x1="26.7124"
      y1="51.4626"
      x2="34.4328"
      y2="31.0614"
    >
      <stop offset="0" style={{ stopColor: '#00FFEF' }} />
      <stop offset="4.448217e-02" style={{ stopColor: '#04ECE7' }} />
      <stop offset="0.1736" style={{ stopColor: '#0FBBD0' }} />
      <stop offset="0.3059" style={{ stopColor: '#1991BD' }} />
      <stop offset="0.4393" style={{ stopColor: '#206EAE' }} />
      <stop offset="0.5742" style={{ stopColor: '#2653A1' }} />
      <stop offset="0.7112" style={{ stopColor: '#2B4099' }} />
      <stop offset="0.8514" style={{ stopColor: '#2D3594' }} />
      <stop offset="1" style={{ stopColor: '#2E3192' }} />
    </linearGradient>
    <path fill="url(#left)" d="M24.4,51.3H26c2.2,0,4.1-1.4,4.8-3.4l5.9-16.6h-5.2L24.4,51.3z" />
    <path fill="white" d="M44,51.3h-1.6c-2.2,0-4.1-1.4-4.8-3.4l-5.9-16.6h5.2L44,51.3z" />
  </svg>
);

export default LogoSpinnerSVG;

const StaticBorder = styled.path`
  stroke: #232323;
`;

const StrokeAnimation = keyframes`
  0% {
    stroke-dasharray: 0 200;
  }
  45% {
    stroke-dashoffset: 0;
    stroke-dasharray: 200 200;
  }
  90% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
  100% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
`;

const AnimatedBorder = styled.path`
  -webkit-animation: ${StrokeAnimation} 2s ease-in;
          animation: ${StrokeAnimation} 2s ease-in;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
`;
