import * as React from 'react';
import { gradeLevel4 } from '@/theme/palette';
import styled from 'styled-components';
import AlertWarningIcon from '@/assets/icons/AlertWarningIcon.svg';
import Color from 'color';

type AlertType = 'warning'

type AlertBannerProps = {
    type: AlertType,
    title: string,
    description?: string,
};

const config: Record<AlertType, { icon: string, color: Color }> = {
  warning: {
    icon: AlertWarningIcon,
    color: gradeLevel4,
  },
};

export const AlertBanner = ({
  title, description, type,
}: AlertBannerProps) => {
  const info = config[type];
  return (
    <AlertBannerStyle $color={info.color}>
      <IconWrapper>
        <img src={info.icon} alt="alert banner" />
      </IconWrapper>
      <AlertInfo>
        <AlertTitle $color={info.color}>
          {title}
        </AlertTitle>
        {description ? (
          <AlertDescription>
            {description}
          </AlertDescription>
        ) : null}
      </AlertInfo>
    </AlertBannerStyle>
  );
};

const AlertBannerStyle = styled.div<{ $color: Color }>`
    display: flex;
    width: 510px;
    max-width: 100%;
    min-height: 64px;
    padding: 13px 14px;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid ${props => props.$color.toString()};
    background: ${props => props.$color.darken(0.75).toString()};
    box-sizing: border-box;
`;

const IconWrapper = styled.div`
    margin: auto;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const AlertInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;
    width: 100%;
`;
const AlertTitle = styled.div<{ $color: Color }>`
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: ${props => props.$color.toString()};
`;
const AlertDescription = styled.div`
    color: white;
    font-size: 14px;
    line-height: 1;
    letter-spacing: -0.1px;
`;

export default AlertBanner;
