import { Marker, MarkerProps, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { createRoot } from 'react-dom/client';

interface Props extends MarkerProps {
  iconOptions?: L.DivIconOptions;
  tooltip?: ReactNode | string;
  children: ReactNode
}

export const JSXMarker = React.forwardRef<L.Marker, Props>(
  ({ children, iconOptions, tooltip, ...rest }, refInParent) => {
    const [ref, setRef] = useState<L.Marker>();

    const node = React.useMemo(
      () => (ref ? createRoot(ref.getElement()!) : null),
      [ref],
    );

    return (
      <>
        {React.useMemo(
          () => (
            <Marker
              {...rest}
              ref={(r: L.Marker) => {
                setRef(r as L.Marker);
                if (refInParent) {
                  // @ts-expect-error fowardref ts defs are tricky
                  // eslint-disable-next-line no-param-reassign
                  refInParent.current = r;
                }
              }}
              icon={L.divIcon(iconOptions)}
            >
              {tooltip ? (
                <Tooltip className="custom-tooltip">
                  {tooltip}
                </Tooltip>
              ) : null}
            </Marker>
          ),
          [],
        )}
        {ref && node?.render(children)}
      </>
    );
  },
);

export default JSXMarker;
