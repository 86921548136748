import getApproachDataPoints from './approachData';
import getApproachLayout from './approachLayout';
import getApproachDots from './approachDots';
import { ApproachInsightPlot } from '../../visualization/constants';

const getApproachOptions = (approachData: ApproachInsightPlot) => {
  const simParamTags = ['altMsl', 'latDev'];
  const data = getApproachDataPoints(simParamTags, approachData.data, approachData.tdze);
  const layout = getApproachLayout(simParamTags, approachData);

  getApproachDots(approachData, data, layout);

  return {
    data,
    layout,
    config: {
      displayModeBar: false,
      staticPlot: true,
    },
    style: {
      height: '100%',
      width: '100%',
    },
    useResizeHandler: true,
  };
};

export default getApproachOptions;
