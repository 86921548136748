import moment from 'moment';
import { FullSessionType, SessionEventType } from './constants';
import { FrameType } from '../frames/constants';
import { TimeBoundResourceType } from '../utils/constants';
import config from '../config';

/**
 * Type definition for a session-like object that has a start and end time.
 */

export const getRelatedMarkers = (
  { startedAt, endedAt }: TimeBoundResourceType,
  markers: SessionEventType[],
): SessionEventType[] => markers.filter(({ timestamp }) => {
  if (!startedAt || !timestamp) {
    return false;
  }

  const ts = timestamp.toISOString();

  if (startedAt > ts) {
    return false;
  }

  return !(endedAt && endedAt < ts);
});

export const hasStickShaker = ({ data }: FrameType): boolean => !!data.stickshakerActiveR
  || !!data.stickshakerActiveL
  || !!data.stickshakerActive;

export const hasSessionsExceededDuration = ({
  startedAt,
  endedAt,
}: FullSessionType): boolean => {
  if (!(startedAt && endedAt)) {
    return false;
  }
  const startTime = moment(startedAt);
  const endTime = moment(endedAt);
  const duration = moment.duration(endTime.diff(startTime));
  const durationAsHours = duration.asHours();
  return durationAsHours > config.maxSessionDuration;
};
