import {
  TYPE_PLOTLY,
  VisualizationHandlerType,
  TYPE_BANK_TURN_SVG,
  BankTurnInsightType, VisualizationDefinitionType,
} from '../constants';
import getPlotlyOptions from '../../plotly/plotOptions/steepTurn';

const getBankTurnVisualizations: VisualizationHandlerType<BankTurnInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const visualizations = [];

  if (data.insight && data.insight.payload) {
    data.insight.payload.forEach(occurrence => {
      visualizations.push({
        type: TYPE_BANK_TURN_SVG,
        data: occurrence,
      });
    });
  }

  visualizations.push({
    type: TYPE_PLOTLY,
    data: getPlotlyOptions(data.frames, markers, activity, metaData),
  });

  return visualizations as VisualizationDefinitionType[];
};

export default getBankTurnVisualizations;
