export const SET_INSTRUCTOR_INFO = 'Setting the instructor information';
export const GET_INSTRUCTOR_INFO = 'Recieving the instructor information';

export const setInstructorInfo = (instructorId: number) => ({
  type: SET_INSTRUCTOR_INFO,
  payload: {
    instructorId,
  },
} as const);

export const getInstructorInfo = () => ({
  type: GET_INSTRUCTOR_INFO,
} as const);

export type SetInstructorInfoAction = ReturnType<typeof setInstructorInfo>;
export type GetInstructorInfoAction = ReturnType<typeof getInstructorInfo>;

export type InstructorAction =
  | SetInstructorInfoAction
  | GetInstructorInfoAction;
