import getAirPlotOptions from './air';
import { annotationFlaps, annotationGearUp, annotationStickShaker, annotationV2 } from '../annotations';
import { PlotlyHandlerType } from '../types';

const getGoAroundPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  // The go-around plots are the same as the in-air plots, but with annotations.
  const plotOptions = getAirPlotOptions(frames, markers, activityCode, metaData);

  if (metaData.trainingCenter !== 'NLA') {
    annotationV2(frames, plotOptions, 'cas');
    annotationGearUp(frames, plotOptions, 'altMsl');
    annotationFlaps(frames, plotOptions, 'flapLeverPos');
    annotationStickShaker(frames, plotOptions, 'cas');
  }

  return plotOptions;
};

export default getGoAroundPlotOptions;
