import React from 'react';
import { FrameType } from '../../../frames/constants';
import { NormCodeToNameMap } from '../../../utils/normSummary/constants';
import { NormMarkerSvg } from '../Norms/types';
import { combineNormMarkersLabels } from '../Norms/utils';
import { defaultStroke, SVGRunway, SVGRunwayCenter } from '../Runway/constants';
import { RejectedTakeoffNorm } from './contstants';
import { RejectedTakeoffInsightNorms } from './types';

//  todo: scale
export const getRejectedTakeoffNormsMarkers = (
  norms: RejectedTakeoffInsightNorms,
  normalizeFactor: number,
  frames: FrameType[],
): NormMarkerSvg[] => {
  const mffLabel = frames.filter(x => x.activeMalfunctions.length)[0]?.activeMalfunctions[0] || '';
  const normMarkers: NormMarkerSvg[] = Object.entries(norms)
    .filter(([normCode, { position }]) => normCode && position)
    .map(([normCode, { position, pass }]) => ({
      position,
      label: normCode === RejectedTakeoffNorm.MalfunctionDescription && mffLabel
        ? `${mffLabel.toUpperCase()} ACTIVE`
        : `${NormCodeToNameMap.get(normCode)}`,
      pass: normCode === RejectedTakeoffNorm.MalfunctionDescription ? false : pass,
    }));
  return combineNormMarkersLabels(normMarkers, normalizeFactor);
};

//  todo: scale
export const getV1Label = (v1: number) => (
  <text
    fontFamily="Roboto-Medium, Roboto"
    fontSize="12"
    fontWeight="400"
    fill={defaultStroke.color}
  >
    <tspan x="400" y={SVGRunway.height + SVGRunway.y0 + 30}>
      V1: {v1}
    </tspan>
  </text>
);

//  todo: scale
export const getRejectedTakeoffLatDevLine = (
  latDev: [number, number][],
  normalizeFactor: number,
  normalizeFactorX: number,
  offset: number,
) => {
  const shapes = [];
  const y0 = SVGRunway.height + offset;

  for (let i = 0; i < latDev.length - 1; i++) {
    if (
      i > 0
      && latDev[i][0] === latDev[i - 1][0]
      && latDev[i][1] === latDev[i - 1][1]
    ) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const [x1, x2, y1, y2] = [
      SVGRunwayCenter + latDev[i][0] * normalizeFactorX,
      SVGRunwayCenter + latDev[i + 1][0] * normalizeFactorX,
      y0 - latDev[i][1] * normalizeFactor,
      y0 - latDev[i + 1][1] * normalizeFactor,
    ];

    if (y1 < y0 && y2 < y0) {
      shapes.push(
        <line
          x1={x1}
          x2={x2}
          y1={y1}
          y2={y2}
          stroke="#0097a7"
          strokeLinecap="square"
          strokeWidth="2"
          key={`rejectedTakeofffLatDev_${i}-${x1}-${y1}`}
        />,
      );
    }
  }

  return shapes;
};
