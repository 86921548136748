import * as React from 'react';
import { InputField } from '@/styles/FormStyles';

interface PickerProps {
    onChange?: (event: React.ChangeEvent) => void;
    value?: string;
}
export const TimePicker = (props: PickerProps) => {
  const { value = '', ...inputProps } = props;

  // Control the time value.
  const [time, setTime] = React.useState(value);

  // onChange handler.
  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setTime(target.value);

    if (typeof props.onChange === 'function') {
      props.onChange(event);
    }
  };

  return (
    <InputField
      {...inputProps}
      value={time}
      type="time"
      onChange={handleChange}
    />
  );
};

export default TimePicker;
