import * as constants from './constants';

export const AUTHENTICATE = 'Authentication attempt';
export const VERIFY_TOKEN = 'Verifying token';
export const REQUIRE_TOKEN = 'New token required';
export const LOGOUT = 'Logging out';
export const UPDATE_AUTHENTICATION_STATUS = 'Updating authentication status';

export const authenticate = (username: string, password: string) => ({
  type: AUTHENTICATE,
  payload: { username, password },
} as const);

export const verifyToken = (token?: string) => ({
  type: VERIFY_TOKEN,
  payload: { token },
} as const);

export const requireToken = (handleRedirect?: typeof window.location.assign) => ({
  type: REQUIRE_TOKEN,
  payload: { handleRedirect },
} as const);

export const logout = (redirectTo?: string | null, message?: string) => ({
  type: LOGOUT,
  payload: { message, redirectTo },
} as const);

export const setStatus = (
  status: constants.AuthenticationStatusType,
  token?: string,
  user?: number,
  isInstructorAdmin = false,
) => ({
  type: UPDATE_AUTHENTICATION_STATUS,
  payload: {
    isInstructorAdmin,
    isAuthenticating: status === constants.AUTH_STATUS_VERIFYING,
    user,
    status,
    token,
  },
} as const);

export type AuthenticateAction = ReturnType<typeof authenticate>;
export type VerifyTokenAction = ReturnType<typeof verifyToken>;
export type RequireTokenAction = ReturnType<typeof requireToken>;
export type LogoutAction = ReturnType<typeof logout>;
export type SetStatusAction = ReturnType<typeof setStatus>;

export type AuthAction =
  | AuthenticateAction
  | VerifyTokenAction
  | RequireTokenAction
  | LogoutAction
  | SetStatusAction;
