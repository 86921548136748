import getAirPlotOptions from './air';
import { annotationStickShaker, annotationVRef } from '../annotations';
import { getEngineParam } from '../utils';
import { PlotlyOptions, NullablePlotlyHandlerType } from '../types';

const getLandingFlarePhasePlotOptions: NullablePlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
): PlotlyOptions | null => {
  const wowNIndex = frames.findIndex(({ data: framesData }) => framesData.wowN === true || framesData.wowN === 1.0);

  if (wowNIndex === -1) {
    return null;
  }

  const framesFrombeginingToLiftoff = wowNIndex !== 0 ? frames.slice(0, wowNIndex + 1) : frames;
  const plotOptions = getAirPlotOptions(
    framesFrombeginingToLiftoff,
    markers,
    activityCode,
    metaData,
    // eslint-disable-next-line max-len
    ['radalt', 'roc', 'cas', 'pitch', getEngineParam(metaData.aircraftDesignator, metaData.aircraftType)],
  ) as PlotlyOptions;

  if (metaData.trainingCenter !== 'NLA') {
    annotationVRef(framesFrombeginingToLiftoff, plotOptions, 'cas');
    annotationStickShaker(framesFrombeginingToLiftoff, plotOptions, 'cas');
  }

  return plotOptions;
};

export default getLandingFlarePhasePlotOptions;
