/**
 * This is the main API instance to be reused throughout the app. The client
 * is separated into concerns, and combined here into a singleton.
 */
import { Client } from './client';
import Activities from './Activities';
import Analytics from './Analytics';
import Authentication from './Authentication';
import Equipment from './Equipment';
import Events from './Events';
import Frames from './Frames';
import Grades from './Grades';
import GradingSheets from './GradingSheets';
import Instructors from './Instructors';
import LessonPlans from './LessonPlans';
import Recommendations from './Recommendations';
import Sessions from './Sessions';
import TrainingCenter from './TrainingCenter';

import config from '../config';
import logError from './logError';

export const client = new Client(config);

export default {
  activities: new Activities({ client }),
  analytics: new Analytics({ client }),
  auth: new Authentication({ client }),
  equipment: new Equipment({ client }),
  events: new Events({ client }),
  frames: new Frames({ client }),
  grades: new Grades({ client }),
  gradingSheets: new GradingSheets({ client }),
  lessonPlans: new LessonPlans({ client }),
  recommendations: new Recommendations({ client }),
  sessions: new Sessions({ client }),
  instructors: new Instructors({ client }),
  trainingCenter: new TrainingCenter({ client }),

  logError,
};
