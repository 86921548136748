import {
  all,
  call,
  put,
  take,
  takeLatest,
  Effect,
} from 'redux-saga/effects';
import api from '../api';
import * as actions from './actions';
import * as constants from './constants';
import { getGradeableId, getGradeableType } from './utils';
import * as sessionSagas from '../session/sagas';
import * as sessionActions from '../session/actions';
import * as trainingActions from '../training/actions';
import * as dashboardActions from '../dashboard/actions';
import * as insightActions from '../insight/actions';
import { SessionRunType } from '../session/constants';

function* getGradingSheet(
  sessionRunId: number,
): Generator<Effect, constants.GradingSheetType | false, any> {
  try {
    return yield call(api.sessions.getGradingSheet, sessionRunId);
  } catch (error) {
    yield call(api.logError, error as any);

    return false;
  }
}

/**
 * Updates a grade level through the API.
 */
type UpdateGradeLevelType = Generator<Object, constants.GradeType | Error, any>;
export function* updateGradeValue({ payload }: Partial<actions.UpdateInstructorGradeAction>): UpdateGradeLevelType {
  const { grade, level, type } = payload || {};

  if (!grade || !level) {
    const error = new Error('Invalid grade object or level in updateGradeValue() saga.');

    yield call(api.logError, error);

    return error;
  }

  const gradeableType = type || getGradeableType(grade);
  const gradeableId = getGradeableId(grade, gradeableType);

  if (!gradeableId) {
    const err = new Error(`Invalid gradeable ID for grade ${grade.id} (${gradeableType}) in updateGradeValue() saga.`);

    yield call(api.logError, err);

    return err;
  }

  const apiRequest = grade.id > 0
    ? call(api.grades.update, grade.id, level)
    : call(api.grades.create, gradeableType, gradeableId, level);

  try {
    const { data } = yield apiRequest;

    return data;
  } catch (error) {
    yield call(api.logError, error as any);

    return error as any;
  }
}

/**
 * Loads the net session competency for a session run.
 */
function* getSessionCompetencyGraph({ payload }: actions.LoadingCompetencyGraphAction) {
  const { sessionRunId } = payload;

  try {
    // @ts-ignore
    const competency = yield call(api.analytics.getSpiderGraphCompetency, sessionRunId);

    yield put(actions.updateSpiderGraphCompetency(competency));
  } catch (error) {
    yield call(api.logError, error as any);
  }
}

function* getFilteredActivities({ payload }: actions.LoadingFilteredActivitiesAction) {
  const { runId, flightPhase, condition } = payload;

  try {
    const { data } = yield call(
      api.analytics.getFilteredActivities,
      runId,
      flightPhase,
      condition,
    );

    yield all([
      put(actions.updateFilteredActivities(data.activities)),
      put(actions.updateSpiderGraphCompetency(data.sessionCompetency)),
    ]);
  } catch (error) {
    yield call(api.logError, error as any);
  }
}

/**
 * Watchers.
 */
export default {
  * expectsGradingSheet(): Generator<Effect, void, any> {
    while (true) {
      const action = yield take([
        actions.GRADING_SCREEN_LOADED,
        trainingActions.TRAINING_SCREEN_LOADED,
        insightActions.GRADE_CONTEXT_LOADED,
      ]);

      const [fullSession, sessionRuns] = yield call(sessionSagas.fetchSessionData, action);

      if (!fullSession && !sessionRuns) {
        return;
      }

      // Extract grades from payload.
      const gradesMap: {[key: number]: constants.GradeType[]} = {};
      const gradingSheets = [];

      if (sessionRuns) {
        const gradingSheetRequests: Effect[] = [];

        sessionRuns.forEach((run: SessionRunType) => {
          gradingSheetRequests.push(call(getGradingSheet, run.id));

          if (run.activities) {
            run.activities.forEach(activity => {
              if (!Array.isArray(activity.grades)) {
                return;
              }

              gradesMap[activity.id] = [
                ...activity.grades,
              ];
            });
          }
        });

        const gradingSheetsResult = yield all(gradingSheetRequests);

        if (gradingSheetsResult && gradingSheetsResult.length) {
          gradingSheets.push(...gradingSheetsResult);
        }
      }

      // Store session meta data, along with the grading sheet.
      yield all([
        put(sessionActions.updateSession(fullSession)),
        put(sessionActions.updateRuns(sessionRuns)),
        put(dashboardActions.equipmentDetailsUpdated([fullSession.equipment])),
        put(actions.gradingSheetsUpdated(gradingSheets)),
      ]);
    }
  },
  * watchesGradeUpdates(): Generator<Effect, any, any> {
    while (true) {
      const action = yield take([
        actions.UPDATE_INSTRUCTOR_GRADE,
      ]);

      // Update the grade level.
      const response = yield call(updateGradeValue, action);

      // Alert errors so the instructor can have some indication that something
      // went wrong.
      if (response instanceof Error) {
        yield call(alert, response.message);
      } else if (response) {
        const updatedGrade = {
          ...response.grade,
          tempId: action && action.payload.grade.tempId,
        };

        yield put(
          actions.gradeLevelUpdated(
            updatedGrade,
            response.sessionCompetency,
            response.tableCompetency,
            action.payload.runId,
          ),
        );
      }
    }
  },
  * expectsSessionCompetencyGraph(): Generator<Effect, void, void> {
    yield takeLatest(actions.LOADING_COMPETENCY_GRAPH, getSessionCompetencyGraph);
  },
  * expectsFilteredActivities(): Generator<Effect, void, void> {
    yield takeLatest(actions.RETRIEVE_FILTERED_ACTIVITIES, getFilteredActivities);
  },
};
