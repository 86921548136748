import getAirPlotOptions from './air';
import { annotationStickShaker } from '../annotations';
import { PlotlyHandlerType } from '../types';

const getApproachToStallPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  const plotOptions = getAirPlotOptions(frames, markers, activityCode, metaData);

  if (metaData.trainingCenter !== 'NLA') {
    annotationStickShaker(frames, plotOptions, 'cas');
  }

  return plotOptions;
};

export default getApproachToStallPlotOptions;
