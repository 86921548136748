import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { mediumLightGrey } from '@/theme/palette';

const PaladinTheme = createGlobalStyle`
  .tippy-box[data-theme='paladin'] {
    background-color: ${mediumLightGrey.string()};
    padding: 16px;
    box-shadow: 0 5px 15px 0px black;
    border-radius: 8px;
  }

  .tippy-box[data-theme='hint'] {
    background-color: ${mediumLightGrey.string()};
    padding: 8px;
    box-shadow: 0 5px 15px 0px black;
    border-radius: 8px;
  }


  .tippy-box[data-theme='paladin'], .tippy-box[data-theme='hint'] {
    [data-animatefill] {
      background-color: transparent;
    }

    .tippy-roundarrow {
      fill: ${mediumLightGrey.string()};
    }


    .tippy-backdrop {
      background-color: ${mediumLightGrey.string()};
    }
  }

  .tippy-box[x-placement^='top'] {
    .tippy-box[data-theme='paladin'], .tippy-box[data-theme='hint'] {
      .tippy-arrow {
        border-top-color: ${mediumLightGrey.string()};
      }
    }
  }

  .tippy-box[x-placement^='bottom'] {
    .tippy-box[data-theme='paladin'], .tippy-box[data-theme='hint'] {
      .tippy-arrow {
        border-bottom-color: ${mediumLightGrey.string()};
      }
    }
  }

  .tippy-box[x-placement^='left'] {
    .tippy-box[data-theme='paladin'], .tippy-box[data-theme='hint'] {
      .tippy-arrow {
        border-left-color: ${mediumLightGrey.string()};
      }
    }
  }

  .tippy-box[x-placement^='right'] {
    .tippy-box[data-theme='paladin'], .tippy-box[data-theme='hint'] {
      .tippy-arrow {
        border-left-color: ${mediumLightGrey.string()};
      }
    }
  }
`;

export const TippyThemes = () => (
  <PaladinTheme />
);
