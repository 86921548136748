import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { createSession } from '../utils/routes';

import { Button } from './Button';
import { lightestGrey, mediumGrey, mediumLightGrey, primary, quaternary } from '../theme/palette';
import CalendarIcon from './svg/Calendar.svg';
import TraineesIcon from './svg/Trainees.svg';
import AddIcon from './svg/Add.svg';

type Props = {
  date: moment.Moment,
  buttonRoute: string,
  courseName: string,
  lessonName: string,
  pilotFlying: string,
  pilotMonitoring: string,
};

export const SessionCardRectangle = ({
  date,
  buttonRoute,
  courseName,
  lessonName,
  pilotFlying,
  pilotMonitoring,
}: Props) => {
  const [flying, setFlying] = React.useState<string | null>(null);
  const [monitoring, setMonitoring] = React.useState<string | null>(null);

  if (flying === null) {
    setFlying(pilotFlying);
  }

  if (monitoring === null) {
    setMonitoring(pilotMonitoring);
  }

  return (
    <RectangleWrapper>
      <Wrapper>
        <SessionInfoWrapper>
          <CourseTitle>{courseName}</CourseTitle>
          <LessonWrapper>
            <LessonTitle>{lessonName}</LessonTitle>
          </LessonWrapper>
        </SessionInfoWrapper>
        <NavLink to={buttonRoute}>
          <Button
            width="220px"
            $margin="0"
          >
            SEE DETAILS
          </Button>
        </NavLink>
      </Wrapper>
      <Wrapper>
        <InfoWrapper width="50%">
          <CalendarWrapper />
          {date.format('MMMM Do, h:mm A')}
        </InfoWrapper>
        <InfoWrapper width="50%">
          <TraineeWrapper />
          {flying} <br />{monitoring}
        </InfoWrapper>
      </Wrapper>
    </RectangleWrapper>
  );
};

export const SessionCardSquare = ({
  date,
  buttonRoute,
  courseName,
  lessonName,
  pilotFlying,
  pilotMonitoring,
}: Props) => {
  const [flying, setFlying] = React.useState<string | null>(null);
  const [monitoring, setMonitoring] = React.useState<string | null>(null);

  if (flying === null) {
    setFlying(pilotFlying);
  }

  if (monitoring === null) {
    setMonitoring(pilotMonitoring);
  }

  return (
    <SquareWrapper>
      <Wrapper>
        <SessionInfoWrapper>
          <CourseTitle>{courseName}</CourseTitle>
          <LessonWrapper>
            <LessonTitle>{lessonName}</LessonTitle>
          </LessonWrapper>
        </SessionInfoWrapper>
      </Wrapper>
      <LowerWrapper>
        <InfoWrapper width="250px">
          <CalendarWrapper />
          {date.format('MMMM Do, h:mm A')}
        </InfoWrapper>
        <InfoWrapper width="250px">
          <TraineeWrapper />
          {flying} <br /> {monitoring}
        </InfoWrapper>
        <NavLink to={buttonRoute}>
          <Button
            width="290px"
            $margin="0"
          >
            SEE DETAILS
          </Button>
        </NavLink>
      </LowerWrapper>
    </SquareWrapper>
  );
};

export const SessionCardSquareEmpty = () => (
  <SquareWrapperEmpty>
    <CreateSessionButton data-button="create-session">
      <NavLink to={createSession()}>
        <CreateSessionIcon />
      </NavLink>
    </CreateSessionButton>
  </SquareWrapperEmpty>
);

const RectangleWrapper = styled.div`
  min-height: 130px;
  width:  718px;
  max-width: 100%;
  background: ${mediumGrey.string()};
  border-radius: 5px;
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
`;

const SquareWrapper = styled.div`
  height: 260px;
  min-width: 290px;
  background-color: ${mediumGrey.string()};
  border-radius: 5px;
  padding: 20px;
  margin-right: 20px;
`;

const SquareWrapperEmpty = styled(SquareWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const SessionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CourseTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
`;

const LessonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LessonTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${lightestGrey.string()};
  text-transform: uppercase;
  margin-top: 9px;
`;

const LowerWrapper = styled(Wrapper)`
  flex-direction: column;
  margin-top: 22px;
  height: calc(100% - 69px);
`;

const InfoWrapper = styled.div<{ width: string }>`
  height: 60px;
  width: ${props => props.width};
  background: ${mediumLightGrey.string()};
  border-radius: 3px;
  font-size: 16px;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const CalendarWrapper = styled.div`
  background: center center transparent no-repeat url('${CalendarIcon}');
  height: 22px;
  width: 24px;
  margin: 0 15px 0 0;
`;

const TraineeWrapper = styled(CalendarWrapper)`
  background: center center transparent no-repeat url('${TraineesIcon}');
`;

export const CreateSessionButton = styled.div<{ $fixed?: boolean }>`
  position: ${props => (props.$fixed ? 'fixed' : '')};
  margin: auto;
  bottom: 40px;
  right: 40px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom right, ${primary.string()}, ${quaternary.string()});
`;

export const CreateSessionIcon = styled(CreateSessionButton)`
  background: center center transparent no-repeat url('${AddIcon}');
`;
