/**
 * Wrapper for the contents of a screen.
 */
import styled from 'styled-components';
import { media } from '@/theme/breakepoints';

const ContentContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;

  ${media.xl} {
    width: 1170px;
  }

  ${media.lg} {
    width: 970px;
  }

  ${media.md} {
    width: 750px;
  }

  ${media.sm} {
    width: 100%;
  }

`;

export default ContentContainer;
