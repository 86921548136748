import { all, call, Effect, getContext, put, take, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import { createBrowserRouter } from 'react-router-dom';
import { activeRunIds } from '@/session/selectors';
import { FullSessionType } from '@/session/constants';
import dayjs from 'dayjs';
import { formatDate } from '@/utils/time';
import { EDateFormat } from '@/types/global.types';
import api from '../api';
import * as actions from './actions';
import * as sessionActions from '../session/actions';
import { setCurrentEquipmentActiveRunsIds } from '../session/actions';
import * as dashboardActions from '../dashboard/actions';
import { fetchEquipmentRunningSessions, fetchSessionData } from '../session/sagas';
import { CourseType, LessonPlanActivityType } from './constants';
import { EquipmentType } from '../dashboard/constants';
import { ActivityOptionsParamsType } from '../activities/types';
import * as activityActions from '../activities/actions';
import { liveSessionRun, schedule as scheduleRoute } from '../utils/routes';

/**
 * Retrieves course and lesson plan information from the API.
 */
type FetchCoursePlansType = Generator<any, CourseType[], any>;
export function* fetchCoursePlans(): FetchCoursePlansType {
  try {
    const { results } = yield call(api.lessonPlans.courses, {
      expand: 'lesson_plans.variations',
    });

    return results;
  } catch (error) {
    api.logError(error as any);

    return [];
  }
}

// TODO: this saga belongs in the "dashboard" feature.
export function* fetchEquipment(): Generator<any, EquipmentType[], any> {
  try {
    const { results } = yield call(api.equipment.list);

    return results;
  } catch (error) {
    api.logError(error as any);

    return [];
  }
}

/**
 * Creates a Session Run record.
 */
type CreateRunArgs = {
  payload: {
    pilotFlying: string;
    pilotMonitoring: string;
    fullSessionId: number;
    scheduledFor: string;
  };
};
function* createRun({ payload }: CreateRunArgs): Generator<any, any, any> {
  const { fullSessionId, pilotFlying, pilotMonitoring, scheduledFor } = payload;

  try {
    return yield call(
      api.sessions.createRun,
      fullSessionId,
      pilotFlying,
      pilotMonitoring,
      scheduledFor,
    );
  } catch (error) {
    api.logError(error as any);
    return false;
  }
}

function* removeRun({ payload }: { payload:{plannedRunId:number} }) {
  const { plannedRunId } = payload;

  try {
    yield call(
      api.sessions.removeRun,
      plannedRunId,
    );
  } catch (error) {
    api.logError(error as any);
  }
}

function* navigateToSession(
  {
    sessionId,
    withStartAutomatically,
    equipmentId,
  }:{
    sessionId:number,
    withStartAutomatically?: boolean,
    equipmentId: number,
  },
):Generator<any, void, any> {
  if (sessionId && withStartAutomatically) {
    const runningSessions = yield call(
      fetchEquipmentRunningSessions,
      equipmentId,
    );
    if (runningSessions && (runningSessions.results as FullSessionType[])) {
      const activeRunsId: number[] = runningSessions.results
        .filter((x: FullSessionType) => x.id !== sessionId)
        .map((x: FullSessionType) => x.plannedRuns)
        .flat() || [];

      if (activeRunsId.length) {
        yield put(setCurrentEquipmentActiveRunsIds(activeRunsId, !activeRunIds.length));
      }
    }

    window.location.assign(liveSessionRun(sessionId));
  } else {
    window.location.assign(scheduleRoute());
  }
}

/**
 * Schedules a training session.
 */
function* scheduleSession({ payload }: actions.ScheduleSessionAction):Generator<any, void, any> {
  yield put(actions.setFlags(false, false, true));

  const {
    lessonPlanId,
    lessonPlanVariationId,
    pilotFlying,
    pilotMonitoring,
    equipmentId,
    scheduledDate,
    scheduledTime,
    shouldCreateRelatedRun,
    withStartAutomatically,
  } = payload;

  // Convert scheduled date and time into a timestamp.
  const scheduledFor = dayjs(formatDate(`${scheduledDate} ${scheduledTime}`, EDateFormat.FullDateRequest));

  try {
    // Create FullSession record.
    const fullSession = yield call(
      api.sessions.createFullSession,
      equipmentId,
      scheduledFor.toISOString(),
      lessonPlanId,
      lessonPlanVariationId,
    );

    if (!fullSession) {
      yield put(actions.scheduleSessionCompleted({ error: 'Could not create session.' }));
      api.logError(new Error('Could not create session.'));
      return;
    }

    // Create the first session run.
    const firstRun = yield createRun({
      payload: {
        pilotFlying,
        pilotMonitoring,
        fullSessionId: fullSession.id,
        scheduledFor: scheduledFor.toISOString(),
      },
    });

    if (!firstRun) {
      yield put(actions.scheduleSessionCompleted({ error: 'Could not create session run.' }));
      api.logError(new Error('Could not create session run.'));
      return;
    }

    // Create the second session run.
    if (shouldCreateRelatedRun) {
      yield createRun({
        payload: {
          pilotFlying: pilotMonitoring,
          pilotMonitoring: pilotFlying,
          fullSessionId: fullSession.id,
          scheduledFor: scheduledFor.add(2, 'hours').toISOString(),
        },
      });
    }

    yield put(actions.scheduleSessionCompleted({ fullSession }));

    yield navigateToSession({
      sessionId: fullSession.id,
      equipmentId,
      withStartAutomatically,
    });
  } catch (error) {
    api.logError(error as any);
    yield put(actions.scheduleSessionCompleted({ error }));
  }
}

/**
 * Edit a training session.
 */
function* editSession({ payload }: actions.EditSessionAction): Generator<any, void, any> {
  yield put(actions.setFlags(false, false, true));
  const {
    sessionId,
    lessonPlanId,
    lessonPlanVariationId,
    pilotFlying,
    pilotMonitoring,
    equipmentId,
    scheduledDate,
    scheduledTime,
    shouldCreateRelatedRun,
    withStartAutomatically,
  } = payload;

  // Convert scheduled date and time into a timestamp.
  const scheduledFor = dayjs(formatDate(`${scheduledDate} ${scheduledTime}`, EDateFormat.FullDateRequest));

  try {
    // Create FullSession record.
    const fullSession = yield call(
      api.sessions.editFullSession,
      sessionId,
      equipmentId,
      scheduledFor.toISOString(),
      lessonPlanId,
      lessonPlanVariationId,
    );
    if (!fullSession) {
      yield put(actions.scheduleSessionCompleted({ error: 'Could not create session.' }));
      api.logError(new Error('Could not create session.'));
      return;
    }
    const plannedRuns = fullSession?.data?.plannedRuns;

    if (Array.isArray(plannedRuns)) {
      for (const plannedRunId of plannedRuns) {
        yield removeRun({ payload: { plannedRunId } });
      }
    }

    // Create the first session run.
    const firstRun = yield createRun({
      payload: {
        pilotFlying,
        pilotMonitoring,
        fullSessionId: fullSession.data.id,
        scheduledFor: scheduledFor.toISOString(),
      },
    });

    if (!firstRun) {
      yield put(actions.scheduleSessionCompleted({ error: 'Could not create session run.' }));
      api.logError(new Error('Could not create session run.'));
      return;
    }

    // Create the second session run.
    if (shouldCreateRelatedRun) {
      yield createRun({
        payload: {
          pilotFlying: pilotMonitoring,
          pilotMonitoring: pilotFlying,
          fullSessionId: fullSession.data.id,
          scheduledFor: scheduledFor.add(2, 'hours').toISOString(),
        },
      });
    }

    yield put(actions.editSessionCompleted({ fullSession }));

    yield navigateToSession({
      sessionId: fullSession.data.id,
      equipmentId,
      withStartAutomatically,
    });
  } catch (error) {
    api.logError(error as any);
    yield put(actions.editSessionCompleted({ error }));
  }
}

/**
 * Retrieves a list of activities for a course.
 */
export function* fetchActivityOptions(
  params: ActivityOptionsParamsType,
): Generator<any, LessonPlanActivityType[], any> {
  try {
    return yield call(api.lessonPlans.activities, params);
  } catch (error) {
    api.logError(error as any);

    return [];
  }
}

export function* createLessonPlanVariation({
  payload,
}: actions.CreateLessonPlanVariationAction): Generator<any, void, any> {
  const { lessonPlanId, sessionId, name, activities, onComplete } = payload;

  const lessonPlanVariation = yield call(api.lessonPlans.createVariation, {
    lessonPlan: lessonPlanId,
    fullSession: sessionId,
    name,
    activities,
  });

  if (lessonPlanVariation) {
    yield put(actions.setLessonPlanVariation(lessonPlanVariation));
  } else {
    yield put(actions.setLessonPlanVariation({ error: true }));
  }

  if (typeof onComplete === 'function') {
    yield call(onComplete);
  }
}

/**
 * Watchers.
 */
export default {
  * watchScreenLoaded(): Generator<any, void, any> {
    while (true) {
      const action = yield take([
        actions.VIEW_SESSION_SCREEN_LOADED,
        actions.EDIT_SESSION_SCREEN_LOADED,
      ]);

      yield put(actions.setFlags());

      const [fullSession] = yield call(fetchSessionData, action);

      yield put(actions.setLessonPlan(fullSession));

      if (action.type === actions.EDIT_SESSION_SCREEN_LOADED && fullSession) {
        const equipmentId = fullSession.equipment && fullSession.equipment.equipmentId;
        const activityOptions = yield call(fetchActivityOptions, {
          equipmentId,
        });

        if (activityOptions) {
          yield put(actions.setScheduleActivityOptions(activityOptions));
        }
      }

      yield put(actions.setFlags(false, true));
    }
  },
  * watchCreateScreenLoaded(): Generator<any, void, any> {
    while (true) {
      const action = yield take([
        actions.CREATE_SESSION_SCREEN_LOADED,
        actions.EDIT_SESSION_DETAILS_LOADED,
      ]);

      // Set the loading flag on the schedule store.
      yield put(actions.setFlags());

      // Retrieve course plans.
      const [plans, equipment] = yield all([
        // @ts-ignore
        call(fetchCoursePlans, action),
        // @ts-ignore
        call(fetchEquipment, action),
      ]);

      // Store course plans and unset loading flag.
      yield all([
        put(actions.setCoursePlans(plans)),
        put(dashboardActions.equipmentDetailsUpdated(equipment)),
        put(actions.setFlags(false, true)),
      ]);

      if (action.type === actions.EDIT_SESSION_DETAILS_LOADED) {
        const [fullSession, sessionRuns] = yield call(fetchSessionData, action);
        if (fullSession) {
          const activityOptions = yield call(fetchActivityOptions, fullSession);
          yield put(activityActions.setActivityOptions(activityOptions));

          yield all([
            put(sessionActions.updateSession(fullSession)),
            put(sessionActions.updateRuns(sessionRuns)),
          ]);
        }
      }
    }
  },
  * watchCreateSession(): Generator<any, void, void> {
    yield takeLatest(actions.SCHEDULE_SESSION, scheduleSession);
  },
  * watchEditSession(): Generator<any, void, void> {
    yield takeLatest(actions.EDIT_SESSION_DETAILS, editSession);
  },
  * watchCreateLessonPlanVariation(): Generator<any, void, void> {
    yield takeLatest(
      actions.CREATE_LESSON_PLAN_VARIATION,
      createLessonPlanVariation,
    );
  },
};
