import { FrameType, RUNWAY_REFERENCE_HEIGHT } from '../../../frames/constants';
import { getGearUpAnnotation, getFlapsAnnotations, getVrAnnotation, getRunwayReferenceAnnotation } from './annotations';

const getHeightVelocityPlotOptions = (frames: FrameType[]) => {
  const alt: number[] = [];
  const cas: number[] = [];

  const vrIndex = frames.findIndex(({ data }) => data.cas > +data.vr - 20);

  frames.forEach((frame, index) => {
    if (index >= vrIndex) {
      alt.push(frame.data.radalt);
      cas.push(frame.data.cas);
    }
  });
  const data = [{
    x: cas,
    y: alt,
    mode: 'lines',
  }];

  const layout = {
    annotations: [],
    shapes: [],
    autosize: true,
    clickmode: 'none',
    dragmode: false,
    hovermode: false,
    margin: {
      l: 65,
      r: 10,
      t: 20,
      b: 40,
    },
    mode: 'lines',
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    xaxis: {
      title: 'CAS',
      showticklabels: true,
      tickcolor: '#888',
      ticklen: 3,
      ticks: 'inside',
      color: '#ccc',
      domain: [0, 1],
      showgrid: false,
      type: 'linear',
    },
    yaxis: {
      title: 'ALT',
      showticklabels: true,
      tickcolor: '#888',
      ticklen: 3,
      ticks: 'inside',
      color: '#ccc',
      domain: [0, 1],
      showgrid: false,
      type: 'linear',
      linewidth: 1,
    },
  };

  // @ts-ignore
  getGearUpAnnotation(frames, layout);
  // @ts-ignore
  getFlapsAnnotations(frames, layout);
  // @ts-ignore
  getVrAnnotation(frames, layout);

  getRunwayReferenceAnnotation({
    x0: Math.min(...cas),
    x1: Math.max(...cas),
    y0: RUNWAY_REFERENCE_HEIGHT,
    y1: RUNWAY_REFERENCE_HEIGHT,
  // @ts-ignore
  }, layout);

  return {
    data,
    layout,
    config: {
      displayModeBar: false,
      responsive: true,
    },
    style: {
      height: '100%',
      width: '100%',
    },
    useResizeHandler: true,
  };
};

export default getHeightVelocityPlotOptions;
