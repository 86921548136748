import { FullSessionType } from '../session/constants';
import { ClassifierStatus } from './types';

export const LIST_SCREEN_LOADED = 'Session list screen loaded.';
export const CLEAR_SESSION = 'Session list screen cleared.';
export const LOADING_SESSION_LIST = 'Retrieving session list...';
export const EXTENDED_LOADING_SESSION_LIST = 'Retrieving extended session list...';
export const SESSION_LIST_RECEIVED = 'Received new session list.';
export const EXTENDED_SESSION_LIST_RECEIVED = 'Received extended session list.';
export const SESSION_OVERVIEW_LOADED = 'Session overview screen loaded';
export const GET_FILTERED__SESSIONS = 'Receiving new filtered session list.';
export const EXTEND_SESSION_LIST = 'Loading more sessions...';
export const SET_CLASSIFIER_STATUS = 'Classifier status set...';

export const sessionListScreenLoaded = () => ({
  type: LIST_SCREEN_LOADED,
} as const);

export const clearSessionList = () => ({
  type: CLEAR_SESSION,
} as const);

export const setLoadingSessionList = (isLoading: boolean = true) => ({
  type: LOADING_SESSION_LIST,
  payload: { isLoading },
} as const);

export const setExtendedLoadingSessionList = (isLoading: boolean = true) => ({
  type: EXTENDED_LOADING_SESSION_LIST,
  payload: { isLoading },
} as const);

export const sessionListReceived = (sessions: FullSessionType[], count: number) => ({
  type: SESSION_LIST_RECEIVED,
  payload: { sessions, count },
} as const);

export const extendedSessionListReceived = (sessions: FullSessionType[], count: number) => ({
  type: EXTENDED_SESSION_LIST_RECEIVED,
  payload: { sessions, count },
}) as const;

export const sessionOverviewScreenLoaded = (sessionId: number) => ({
  type: SESSION_OVERVIEW_LOADED,
  payload: { sessionId },
} as const);

export const getFilteredSessions = (showInstructorSessions: boolean, sessionId?: number) => ({
  type: GET_FILTERED__SESSIONS,
  payload: { showInstructorSessions, sessionId },
} as const);

export const extendSessionList = (showInstructorSessions: boolean) => ({
  type: EXTEND_SESSION_LIST,
  payload: { showInstructorSessions },
} as const);

export const setClassifierStatus = (classifierStatus?: ClassifierStatus) => ({
  type: SET_CLASSIFIER_STATUS,
  payload: { classifierStatus },
} as const);

export type SessionListScreenLoadedAction = ReturnType<typeof sessionListScreenLoaded>;
export type SetLoadingSessionListAction = ReturnType<typeof setLoadingSessionList>;
export type SetExtendedLoadingSessionListAction = ReturnType<typeof setExtendedLoadingSessionList>;
export type SessionListReceivedAction = ReturnType<typeof sessionListReceived>;
export type ExtendedSessionListReceivedAction = ReturnType<typeof extendedSessionListReceived>;
export type GetFilteredSessionsAction = ReturnType<typeof getFilteredSessions>;
export type ExtendSessionListAction = ReturnType<typeof extendSessionList>;
export type SetClassifierStatusAction = ReturnType<typeof setClassifierStatus>;
export type SetSessionOverviewLoaded = ReturnType<typeof sessionOverviewScreenLoaded>;
export type ClearSessionList = ReturnType<typeof clearSessionList>;

export type SessionListAction =
| SessionListScreenLoadedAction
| SetLoadingSessionListAction
| SetExtendedLoadingSessionListAction
| SessionListReceivedAction
| ExtendedSessionListReceivedAction
| GetFilteredSessionsAction
| ExtendSessionListAction
| SetClassifierStatusAction
| SetSessionOverviewLoaded
| ClearSessionList;
