import { call, put, takeLatest, Effect } from 'redux-saga/effects';
import { EquipmentType } from '@/dashboard/constants';
import * as actions from './actions';
import api from '../api';

function* getTrainingCenterDetails(): Generator<Effect, void, any> {
  try {
    const details = yield call(api.trainingCenter.get);

    if (details) {
      yield put(actions.trainingCenterDetailsUpdated(details));
    }
  } catch (error) {
    yield call(api.logError, error as any);
  }
}

function* fetchEquipment(): Generator<any, EquipmentType[], any> {
  try {
    const { results } = yield call(api.equipment.list);

    yield put(actions.equipmentDetailsUpdated(results));

    return results;
  } catch (error) {
    api.logError(error as any);

    return [];
  }
}

export default {
  * expectsTrainingCenterDetails(): Generator<Effect, void, void> {
    yield takeLatest(actions.DASHBOARD_LOADED, fetchEquipment);
    yield takeLatest(actions.DASHBOARD_LOADED, getTrainingCenterDetails);
  },
};
