import { call, takeLatest, put } from 'redux-saga/effects';
import api from '../api';
import { getInstructorInfo } from '../instructor/actions';
import { setUserInfo, getUserInfo as getUserInfoAction } from './slice';

export function* getUserInfo() {
  try {
    // @ts-ignore
    const results = yield call(api.auth.getUserInformation);
    if (results) {
      yield put(setUserInfo({ username: results.username, id: results.id }));
      yield put(getInstructorInfo());
    }
    return results;
  } catch (error) {
    yield call(api.logError, error as any);
    return {};
  }
}

/**
 * Watchers.
 */
export default {
  * watchAuthenticate(): Generator<any, void, void> {
    yield takeLatest(getUserInfoAction.type, getUserInfo);
  },
};
