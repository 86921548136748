import { ClientInterface } from './client';
import { InsightResponseType } from '../utils/visualization/constants';
import { RunIdType } from '../session/constants';

// API
export default class Sessions {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  getActivityInsight = async (
    code: string,
    startedAt?: string,
    endedAt?: string,
  ): Promise<InsightResponseType | object | false> => this.client.getResource<InsightResponseType, object>(
    `/insight/activities/${code}/`,
    { startedAt, endedAt },
    { activityCode: null, insight: null },
  );

  getCompetencyDrilldown = async (
    runId: RunIdType,
    competencyCode: string,
  ): Promise<object | false> => this.client.getResource(
    `/session-competency/${runId}/competency-drilldown/`,
    { competencyCode },
  );

  getSpiderGraphCompetency = async (
    runId: RunIdType,
  ): Promise<object | false> => this.client.getResource(`/session-competency/${runId}/spider-graph/`);

  getFilteredActivities = async (
    runId: number,
    flightPhase: string | null,
    condition: string | null,
  ): Promise<object> => this.client.post(
    `/session-runs/${runId}/activities/`,
    { flightPhase, condition },
  );
}
