import getAirPlotOptions from './air';
import attitudesLegend from './attitudesLegend';
import { annotationStickShaker } from '../annotations';
import { getEngineParam } from '../utils';
import { PlotlyHandlerType } from '../types';

const getUnusualAttitudeRecoveryPlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  const plotOptions = getAirPlotOptions(frames, markers, activityCode, metaData, [
    'indSlipskidL',
    'cas',
    ['roll', 'pitch'],
    getEngineParam(metaData.aircraftDesignator, metaData.aircraftType),
  ]);

  if (metaData.trainingCenter !== 'NLA') {
    annotationStickShaker(frames, plotOptions, 'cas');
  }
  attitudesLegend(plotOptions);

  return plotOptions;
};

export default getUnusualAttitudeRecoveryPlotOptions;
