/**
 * Helper component to change the title of the document.
 */
import React from 'react';
import Helmet from 'react-helmet';
import config from '../../config';

type Props = {
  title: string,
};

export default ({ title }: Props) => (
  <Helmet title={`${title} | ${config.appName}`} />
);
