/**
 * Utility functions for simulator data.
 */
import { FrameType } from '../frames/constants';

export type ConditionType = {
  code: string,
  startedAt: string,
  startedAtIndex: number,
  endedAt: string,
  endedAtIndex: number,
};

/**
 * Retrieves malfunction data from a session stream.
 */
export const getConditions = (frames: FrameType[]): ConditionType[] => {
  const conditions: ConditionType[] = [];

  // This map tracks active malfunctions while
  // looping through the sim parameters.
  const tracker = new Map();

  frames.forEach((frame, frameIndex) => {
    if (
      (!Array.isArray(frame.activeMalfunctions) || frame.activeMalfunctions.length < 1)
      && (!Array.isArray(frame.weatherConditions) || frame.weatherConditions.length < 1)
    ) {
      return;
    }

    // Track active malfunctions.
    if (frame.activeMalfunctions.length > 0) {
      frame.activeMalfunctions.forEach(code => {
        if (!tracker.has(code)) {
          tracker.set(code, frameIndex);
        }
      });
    }

    // Track active weather conditions.
    if (frame.weatherConditions.length > 0) {
      frame.weatherConditions.forEach(code => {
        if (!tracker.has(code)) {
          tracker.set(code, frameIndex);
        }
      });
    }

    // Remove inactive malfunctions.
    tracker.forEach((startedAtIndex, code) => {
      if (!frame.activeMalfunctions.includes(code) && !frame.weatherConditions.includes(code)) {
        conditions.push({
          code,
          startedAtIndex,
          startedAt: frames[startedAtIndex].timestamp,
          endedAtIndex: frameIndex,
          endedAt: frame.timestamp,
        });

        tracker.delete(code);
      }
    });
  });

  // Handle malfunctions which go beyond the last frame.
  if (tracker.size > 0) {
    tracker.forEach((startedAtIndex, code) => {
      conditions.push({
        code,
        startedAtIndex,
        startedAt: frames[startedAtIndex].timestamp,
        endedAtIndex: frames.length - 1,
        endedAt: frames[frames.length - 1].timestamp,
      });

      tracker.delete(code);
    });
  }

  return conditions;
};

/**
 * Map of known simulator parameters.
 */
const dataMap: Map<string, string> = new Map([
  ['altMsl', 'ALT MSL [Ft]'],
  ['radalt', 'RADAR ALT [Ft]'],
  ['eng_1N1', 'N1 [%]'],
  ['eng_1Trq', 'TORQUE [%]'],
  ['eng_1ManifoldPressure', 'MANIFOLD PRESS [inHg]'],
  ['gndSpeed', 'GROUND SPEED [Kn]'],
  ['roc', 'ROC [Ft/Min]'],
  ['brakeTempRatioL', 'BRAKE TEMP [%]'],
  ['brakeTemp_1Ind', 'BRAKE TEMP [°C]'],
  ['brakePedForcePilotL', 'BRAKE FORCE [N]'],
  ['brakePedPositionPilotL', 'BRAKE POS L [Deg]'],
  ['brakePedPositionPilotR', 'BRAKE POS R [Deg]'],
  ['brakePedPositionPilotLNorm', 'BRAKE POS L [Norm]'],
  ['brakePedPositionPilotRNorm', 'BRAKE POS R [Norm]'],
  ['hdgTrue', 'TRUE HEADING [Deg]'],
  ['iasL', 'AIRSPEED [Kn]'],
  ['indSlipskidL', 'SLIP-SKID [Deg]'],
  ['flapLeverPos', 'FLAPS'],
  ['battSw', 'BATT SW'],
  ['gen_1ApuSw', 'GEN APU SW'],
  ['navLightsSw', 'NAV LIGHTS SW'],
  ['emerInternLightsSw', 'EMER LTS SW'],
  ['parkingBrakeEngaged', 'PARKING BRAKE'],
  ['floodLightsSw', 'FLOOD LTS SW'],
  ['stbyPwrSw', 'STBY PWR SW'],
  ['anticolLightsSw', 'ANTI COL SW'],
  ['colPosPilot', 'COLUMN DEFLECTION [Deg]'],
  ['colForcePilot', 'COLUMN FORCE [N]'],
  ['accLatBody', 'LATERAL BALANCE [M/S2]'],
  ['whlPosPilot', 'WHEEL ANGLE [Deg]'],
  ['rudPedPosPilot', 'YAW PEDALS [Deg]'],
  ['fcInceptorLPosPitch', 'PITCH SIDE-STICK [Deg]'],
  ['fcInceptorLPosRoll', 'ROLL SIDE-STICK [Deg]'],
]);

/**
 * Converts a sim tag into a short, human-readable name.
 */
export const getShortName = (code: string | string[]): string => {
  // If we have an array of sim parameters, return the
  // name for the first parameter.
  if (Array.isArray(code)) {
    return getShortName(code[0]);
  }

  return dataMap.get(code) || code.toUpperCase();
};
