import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { ThemeProvider } from 'styled-components';
import { Provider as ReduxProvider } from 'react-redux';
import { GlobalStyles } from '@/styles/GlobalStyles';
import { client } from './api';
import AuthGuard from './auth/components/Guard';
import config from './config';
import App from './dashboard/components/App';
import ErrorBoundary from './dashboard/components/ErrorBoundary';
import reporter from './utils/reporter';
import * as serviceWorker from './serviceWorker';
import store from './store';
import theme from './theme';

import '../node_modules/normalize.css/normalize.css';

// Inject store dispatcher into API client.
client.setDispatcher(store.dispatch);

if (config.isDev || config.debugEnvs.includes(config.deployEnv)) {
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_STORE = store;
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ErrorBoundary>
        <AuthGuard>
          <App />
        </AuthGuard>
      </ErrorBoundary>
    </ThemeProvider>
  </ReduxProvider>,
);

// Register service worker.
serviceWorker.register({
  log: reporter.info,
  error: reporter.error,
});
