// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, put, take, Effect, select, cancelled, fork } from 'redux-saga/effects';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { eventChannel, Task } from 'redux-saga';
import * as actions from './actions';
import * as summaryActions from '../summary/actions';
import * as scheduleActions from '../schedule/actions';
import api from '../api';
import { FullSessionType } from '../session/constants';
import { getInstructorInfo } from '../instructor/sagas';
import { getUserInfo } from '../userProfile/sagas';
import { selectInstructorId } from '../instructor/selectors';
import { currentSessionsCount } from './selectors';
import { getActivitiesClassifiedStatusSubscriber } from './subscribers/polling';
import { ClassifierStatus } from './types';

// eslint-disable-next-line max-len
const createChannelActivitiesClassifierStatus = (runId: number) => eventChannel(getActivitiesClassifiedStatusSubscriber(runId));

/**
 * Retrieves a list of FullSession records.
 */
type GetListType = Generator<Effect, { results: FullSessionType[], count: number }, any>;
export function* getSessionList({
  payload,
  type,
}: actions.GetFilteredSessionsAction): GetListType {
  try {
    if (payload && payload.sessionId !== undefined) {
      const session = yield call(api.sessions.get, payload.sessionId);
      return { results: [session], count: 1 };
    }
    if (
      type.toString() === actions.LIST_SCREEN_LOADED
      || (payload && payload.showInstructorSessions)
    ) {
      const instructorId = yield select(selectInstructorId);
      const { results, count } = yield call(
        api.sessions.list,
        undefined,
        undefined,
        undefined,
        undefined,
        instructorId,
      );
      return { results, count };
    }
    const { results, count } = yield call(api.sessions.list);
    return { results, count };
  } catch (error) {
    yield call(api.logError, error as Error);
    return { results: [], count: 0 };
  }
}

export function* getExtendedSessionList({ payload, type }: actions.ExtendSessionListAction): GetListType {
  try {
    const offset: number = yield select(currentSessionsCount);

    if (
      type.toString() === actions.LIST_SCREEN_LOADED
      || (payload && payload.showInstructorSessions)
    ) {
      const instructorId = yield select(selectInstructorId);
      const { results, count } = yield call(
        api.sessions.list,
        undefined,
        offset,
        undefined,
        undefined,
        instructorId,
      );
      return { results, count };
    }
    const { results, count } = yield call(api.sessions.list, undefined, offset);
    return { results, count };
  } catch (error) {
    yield call(api.logError, error as Error);
    return { results: [], count: 0 };
  }
}

//  todo
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* sessionActivitiesClassifiedStatusListener(sessionId: number): Generator<Effect, void, any> {
  const { results } = yield call(api.sessions.getRuns, sessionId, []);
  const run = results[results.length - 1];

  if (!run.endedAt) {
    return;
  }

  const sessionActivitiesClassifiedChannel = yield call(createChannelActivitiesClassifierStatus, run.id);

  try {
    while (true) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const classifierStatus: ClassifierStatus = yield take(sessionActivitiesClassifiedChannel);
      if (classifierStatus.progress === 1) {
        sessionActivitiesClassifiedChannel.close();
      }
      yield put(actions.setClassifierStatus(classifierStatus));
    }
  } finally {
    if (yield cancelled()) {
      sessionActivitiesClassifiedChannel.close();
      yield put(actions.setClassifierStatus(undefined));
    }
  }
}

/**
 * Watchers.
 */
export default {
  * expectsSessionList(): Generator<Effect, void, any> {
    let listener: Task | null = null;

    while (true) {
      const action = yield take([
        actions.LIST_SCREEN_LOADED,
        actions.SESSION_OVERVIEW_LOADED,
        summaryActions.GRADING_SCREEN_LOADED,
        scheduleActions.EDIT_SESSION_DETAILS_LOADED,
        actions.GET_FILTERED__SESSIONS,

      ]);

      if (listener) {
        listener.cancel();
        listener = null;
      }

      if (action.type === actions.LIST_SCREEN_LOADED) {
        yield call(getUserInfo);
        yield call(getInstructorInfo);
      }

      if (action.type === actions.EXTEND_SESSION_LIST) {
        yield put(actions.setExtendedLoadingSessionList());
        const { results, count } = yield call(getExtendedSessionList, action);
        yield put(actions.extendedSessionListReceived(results, count));
      } else {
        yield put(actions.setLoadingSessionList());
        const { results, count } = yield call(getSessionList, action);
        yield put(actions.sessionListReceived(results, count));
      }

      if (action.type === actions.SESSION_OVERVIEW_LOADED && !listener) {
        listener = yield fork(sessionActivitiesClassifiedStatusListener, action.payload.sessionId);
      }
    }
  },

  * watchExtendSessionList() :Generator<Effect, void, any> {
    while (true) {
      const action = yield take([actions.EXTEND_SESSION_LIST]);

      if (action.type === actions.EXTEND_SESSION_LIST) {
        yield put(actions.setExtendedLoadingSessionList());
        const { results, count } = yield call(getExtendedSessionList, action);
        yield put(actions.extendedSessionListReceived(results, count));
      }
    }
  },
  * watchClearSession() :Generator<Effect, void, any> {
    while (true) {
      const action = yield take([actions.CLEAR_SESSION]);

      if (action.type === actions.CLEAR_SESSION) {
        yield put(actions.clearSessionList());
      }
    }
  },
};
