import { ClientInterface } from './client';

export default class Recommendations {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  activity = async (
    activitiesIds: number,
  ): Promise<Object | false> => this.client.getResource<Object, false>(
    '/recommendations/activities/',
    { activitiesIds },
  );

  activityFeedback = async (
    activityId: number,
    instructorApproved: boolean,
  ): Promise<Object | false> => this.client.patch(
    `/recommendations/activities/${activityId}/`,
    { instructorApproved },
  );
}
